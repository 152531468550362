import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/24/solid"
import { UnderDevelopment } from '../../../Component/Errors/UnderDevelopment';
import { documentTitle, inputIsEmailFormat, inputIsOnlyAlphabetic, inputIsPhoneNumberWithCode, validationError } from '../../../helpers';
import BtnSpinner from '../../../Component/BtnSpinner';
import axios from 'axios';
import BreadCrumb from '../../../Component/BreadCrumb';
import PageSpinner from '../../../Component/PageSpinner';
import { uploadHelpInquiry } from '../../../api';

const HelpsPage = () => {

  let navigate = useNavigate();
  // TEST DEPLOY & BUILD
  const [user, setUser] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const [userFullname, setUserFullname] = useState(() => {
    if (user) {
      return user.useraccount_fullname || "";
    }
  });

  const [submitting, setSubmitting] = useState(false);
  const [formPassed, setFormPassed] = useState(false);
  const [validations, setValidations] = useState({});
  const [types, setTypes] = useState(["Error", "Question", "Suggestion", "Other"]);
  const [fileUpload, setFileUpload] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [form, setForm] = useState({
    fullname: "",
    email: "",
    phonenumber: "+62",
    subject: "",
    type: "",
    message: "",
  })

  const submitForm = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const upload = await postUpload();
    if (!upload) {
      return;
    }

    const sanitizeForm = form;
    sanitizeForm.file_id = upload.id;
    try {
      const response = await axios.post(
        "/api/client/help-inquiry", JSON.stringify(sanitizeForm), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = response.data;
      if (result.success) {
        toast.success(result.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1250,
          onClose: () => resetForm(),
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER
      });
    }
    setSubmitting(false);
    setUploading(false);
    setFileUpload(null);
  }

  const postUpload = async (e) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("image", fileUpload);
    const result = await uploadHelpInquiry({ formData });
    if (result.success) {
      return result.data;
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
  }


  const handleFile = (e) => {
    setFileUpload(e.target.files[0]);
  }

  const resetForm = () => {
    setForm({
      fullname: "",
      email: "",
      phonenumber: "+62",
      subject: "",
      type: "",
      message: "",
    })
  }

  useEffect(() => {
    documentTitle("Help & Support")
  }, [])

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    if (form.fullname !== "" && form.last_name !== "" && form.email !== "" && form.phonenumber !== "" && form.subject !== "" && form.type !== "" && form.message) {
      setFormPassed(true);
      const newValidations = { ...validations };
      if (form.phonenumber.length < 11) {
        newValidations.phonenumber = [validationError("phone")];
      } else {
        newValidations.phonenumber = [];
      }
      if (!inputIsEmailFormat(form.email)) {
        newValidations.email = [validationError("email")];
      } else {
        newValidations.email = [];
      }
      setValidations(newValidations);
      // Cek jika semua properti dalam objek validasi kosong
      const allValid = Object.values(newValidations).every(validationArray => validationArray.length === 0);
      setFormPassed(allValid);
    } else {
      setFormPassed(false);
    }
  }, [form]);


  const myStyle = {
    minHeight: 'calc(100vh - 80px)',
    marginTop: '-10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div style={myStyle} className='px-[10px] md:px-[44px] py-[24px]'>
      <ToastContainer />
      <div className='card flex w-full mb-6'>
        <div className='bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6'>
          <InformationCircleIcon className='w-[30px] text-main-violate' />
        </div>
        <div className='flex flex-col justify-around'>
          <p className='text-xl text-grey-600 font-bold'>Help Center</p>
          <BreadCrumb list={[{ href: null, title: "Helps & Center" }]} />
        </div>
      </div>
      <div className='invite-section mb-6'>
        <div className='card flex flex-col w-full'>
          {/* <div className='flex justify-between w-full mb-5'>
            <div>
              <p className='text-lg text-grey-600 font-bold mb-2'>Create new candidate</p>
            </div>
          </div> */}
          <div>
            <form className="bg-grey-lightest" name="create_candidate" onSubmit={submitForm}>
              <div className='grid-cols-1 md:grid-cols-2 grid gap-4 mb-6'>
                <div>
                  <div className="mb-6">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Fullname
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="fullname"
                      id="fullname"
                      placeholder="Type your first name.."
                      value={form.fullname}
                      onChange={(e) => {
                        if (inputIsOnlyAlphabetic(e.target.value)) {
                          setForm({ ...form, fullname: e.target.value })
                        }
                      }}
                      required
                    />
                    {typeof validations.fullname != "undefined" ? validations.fullname.map((e) => {
                      return (<span className="input-error-message">{e}</span>)
                    }) : ""}
                  </div>
                  {/* <div className="mb-6">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Last Name
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="last_name"
                      id="last_name"
                      placeholder="Type your last name.."
                      value={form.last_name}
                      onChange={(e) => {
                        if (inputIsOnlyAlphabetic(e.target.value)) {
                          setForm({ ...form, last_name: e.target.value })
                        }
                      }}
                      required
                    />
                    {typeof validations.last_name != "undefined" ? validations.last_name.map((e) => {
                      return (<span className="input-error-message">{e}</span>)
                    }) : ""}
                  </div> */}
                  <div className="mb-6">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Email Address
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Type your email address.."
                      value={form.email}
                      onChange={(e) => {
                        // if (inputIsOnlyAlphabetic(e.target.value)) {
                        setForm({ ...form, email: e.target.value })
                        // }
                      }}
                      required
                    />
                    {typeof validations.email != "undefined" ? validations.email.map((e) => {
                      return (<span className="input-error-message">{e}</span>)
                    }) : ""}
                  </div>
                  <div>
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Phone Number
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="tel"
                      name="phonenumber"
                      id="phonenumber"
                      placeholder="Type your phone number.."
                      value={form.phonenumber}
                      onChange={(e) => {
                        if (inputIsPhoneNumberWithCode(e.target.value)) {
                          setForm({ ...form, phonenumber: e.target.value })
                        } else {
                          setForm({ ...form, phonenumber: "+62" });
                        }
                      }}
                      required
                    />
                    {typeof validations.phonenumber != "undefined" ? validations.phonenumber.map((e) => {
                      return (<span className="input-error-message">{e}</span>)
                    }) : ""}
                  </div>
                </div>
                <div>
                  <div className="mb-6">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Subject
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="Type your subject.."
                      value={form.subject}
                      onChange={(e) => {
                        if (inputIsOnlyAlphabetic(e.target.value)) {
                          setForm({ ...form, subject: e.target.value })
                        }
                      }}
                      required
                    />
                    {typeof validations.subject != "undefined" ? validations.subject.map((e) => {
                      return (<span className="input-error-message">{e}</span>)
                    }) : ""}
                  </div>
                  <div className="mb-6">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Type
                    </label>
                    <select
                      className="form-select appearance-none
                                    block
                                    w-full
                                    px-3
                                    py-2
                                    text-gray-800
                                    text-[14px]
                                    bg-white bg-clip-padding bg-no-repeat
                                    border border-solid border-gray-300
                                    rounded-md
                                    mt-2
                                    transition
                                    ease-in-out
                                    focus:outline-none focus:border-main-violate border border-line-grey"
                      aria-label="Default select example"
                      value={form.type}
                      onChange={(e) => {
                        setForm({ ...form, type: e.target.value })
                      }}
                    >
                      <option selected disabled value="">
                        Choose your type...
                      </option>
                      {types.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Message Body
                    </label>
                    <textarea
                      className="border w-full p-3 h-[136px] placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="message"
                      id="message"
                      placeholder="Choose your type.."
                      value={form.message}
                      onChange={(e) => {
                        // if (inputIsOnlyAlphabetic(e.target.value)) {
                        setForm({ ...form, message: e.target.value })
                        // }
                      }}
                      required
                    ></textarea>
                    {typeof validations.message != "undefined" ? validations.message.map((e) => {
                      return (<span className="input-error-message">{e}</span>)
                    }) : ""}
                  </div>
                  <div>
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Attachment
                    </label>
                    {uploading ? <PageSpinner padding={"30px"} /> :
                      fileUpload ?
                        <div className="mt-3 mb-3 relative h-[50px] rounded-lg border bg-white flex justify-between items-center hover:outline-none px-4">
                          <span className="block text-soft-grey text-[14px]">
                            {fileUpload.name} {/* {profile.resume_file.replace(/^.*[\\\/]/, "")} */}
                          </span>
                          <button onClick={(e) => {
                            setFileUpload(null);
                          }}>
                            <TrashIcon width={20} />
                          </button>
                        </div>
                        :
                        <input
                          className="border mt-3 mb-3 w-full placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={(e) => handleFile(e)}
                        />}


                    <p className="text-[14px] text-grey-500  leading-[1.5] mb-4">
                      Maximum <b>Image</b> size 2 Mb
                    </p>
                  </div>
                </div>

              </div>
              <div className='flex justify-end'>
                <button
                  className=" bg-main-violate px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                  type="submit"
                  disabled={!formPassed || submitting}
                >
                  {submitting ? <>
                    <BtnSpinner />
                  </> : <>Send</>}

                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div >
  )
}

export default HelpsPage;
