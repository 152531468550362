import {Component } from 'react'

class EmptyContent extends Component {
    render() {
        return (
            <>
                <tr className="whitespace-nowrap border-b-[1px]">
                    <td className="px-6 py-4 text-sm text-gray-500 text-center italic " colSpan={"100%"}>
                        No Records
                    </td>
                </tr>
            </>
        )
    }

}

export default EmptyContent;
