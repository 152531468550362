// jobSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSharedJobPositions } from "../../api";
const initialState = {
  data: [],
  loading: false,
};

export const fetchJobPositions = createAsyncThunk(
  "shared/fetchJobPositions",
  async () => {
    const result = await getSharedJobPositions();
    return result.data;
  }
);

const jobPositionSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchJobPositions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchJobPositions.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchJobPositions.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// export const { setUser, clearNotifications } = notificationSlice.actions;
export default jobPositionSlice.reducer;
