import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../Component/PersonalityRadarChart";
import PageSpinner from "../../../../../Component/PageSpinner";
import RangeList from "../RangeList";
const CommunicateReports = ({ communication }) => {
    const [communicate, setCommunicate] = useState(null);
    const [dataRange, setDataRange] = useState(null);
    useEffect(() => {
        if (communication) {
            let values = [];
            let labels = [];
            let tempDataRange = [];
            communication.data.map((item) => {
                values.push(item.result)
                labels.push(item.name)
                tempDataRange.push({ parameter: item.name, minLabel: item.label2, maxLabel: item.label1, maxRange: "10", minRange: "0", value: item.result, code: item.code.toLowerCase() })
            })
            setCommunicate({
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: '#B638FF7A',
                        borderColor: '#B638FF',
                        borderWidth: 2,
                        hidden: '',
                    },
                ],
            })
            setDataRange(tempDataRange)
        }
    }, [communication])
    return (
        <div className='flex flex-col items-center' id="communicate-pdf">
            <div className='flex w-full items-center mb-5 gap-10 px-4 justify-center' id="radar-personality-communicate">
                <div className="w-[40%] flex justify-center">
                    <div className="max-w-[520px]">
                        {communicate ? <PersonalityRadarChart data={communicate} /> : <PageSpinner />}
                    </div>

                </div>
                <div className="w-[60%]">
                    <p className='font-semibold text-[20px] mb-2'>Communicate</p>
                    <p className="text-gray-500 text-[20px]">Cara individu dalam konteks profesional berinteraksi dan bertukar informasi dengan orang lain. Komunikasi yang efektif adalah keterampilan mendasar di tempat kerja, dan mempertahankan standar profesionalisme yang tinggi dalam komunikasi sangat penting untuk membangun hubungan yang positif, mencapai tujuan, dan membina lingkungan kerja yang produktif.</p>
                </div>
            </div>
            {communicate ? <>
                {dataRange.map((item, index) => (
                    <RangeList data={item} color="#B638FF" />
                ))}
            </> : <></>}

        </div>
    )
}

export default CommunicateReports;