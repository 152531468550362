import axios from 'axios';
export const uploadHelpInquiry = async ({ formData }) => {
    try {
        const response = await axios
            .post(`/api/client/help-inquiry/d/upload`, formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })

        return response.data;
    } catch (error) {
        return false;
    }
}