import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  DocumentDuplicateIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  RectangleStackIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../Component/BreadCrumb";
import PaginationTable from "../../../Component/Tables/PaginationTable";
import SearchTable from "../../../Component/Tables/SearchTable";
import FilterTable from "../../../Component/Tables/FilterTable";
import TableSpinner from "../../../Component/TableSpinner";
import EmptyContent from "../../../Component/Tables/EmptyContent";
import { documentTitle } from "../../../helpers";
import DropdownDots from "../../../Component/Tables/DropdownDots";
import { Dropdown, ToggleSwitch } from "flowbite-react";
import {
  getAssessmentTest,
  switchPublishAssessmentTest,
} from "../../../api/admin/assessmentApi";

const AssessmentPage = () => {
  let navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [tests, setTests] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [filterAssessment, setFilterAssessment] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const fetchTest = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getAssessmentTest(queryString);
    if (result.success) {
      setFilterAssessment(result.additional.filter.assessment);
      setTests(result.data.data);
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setItemsPerPage(result.data.per_page);
      setTableLoading(false);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const handleFilterAssessment = (id) => {
    setQuery({ ...query, assessment_id: id });
    setSelectedAssessment(id);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSelectedAssessment("");
    setQuery({ ...query, assessment_id: ""});
  };

  const handleTogglePublish = async (id) => {
    setTableLoading(true);
    const result = await switchPublishAssessmentTest(id);
    if (result.success) {
      await fetchTest();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      setTableLoading(false);
    }
  };

  useEffect(() => {
    documentTitle("Packages Management");
  }, []);
  useEffect(() => {
    fetchTest();
  }, [query]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {tests.length > 0 ? (
            tests.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.assessment.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.total_question}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.total_job_role}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.total_company}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.total_finished_candidate}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <ToggleSwitch
                      onChange={(e) =>
                        handleTogglePublish(value.id, value.is_publish)
                      }
                      checked={value.is_publish}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <DropdownDots>
                      <Dropdown.Item
                        onClick={(e) =>
                          navigate(
                            `/administrator/assessments/test/${value.id}`
                          )
                        }
                      >
                        <InformationCircleIcon width={16} className="mr-2" />
                        Detail
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) =>
                          navigate(
                            `/administrator/assessments/test/${value.id}/edit`
                          )
                        }
                      >
                        <PencilSquareIcon width={16} className="mr-2" />
                        Edit
                      </Dropdown.Item>
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <ClipboardDocumentListIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Assessment & Test</p>
          <BreadCrumb list={[{ href: null, title: "Assessment & Test" }]} />
        </div>
      </div>
      <div className="card menu-assessment mb-6">
        <div className="flex flex-col w-full rounded-[12px]">
          {/* <p className="text-xl font-semibold text-center mb-6">Create New</p> */}
          <div className="flex gap-4 justify-center ">
            {/* <div
              className="w-[150px] flex items-center flex-col gap-4 cursor-pointer"
              onClick={(e) => navigate("/administrator/assessments-create")}
            >
              <div className="rounded-full bg-white p-6 w-[90px]">
                <RectangleStackIcon className="text-main-violate-500" />
              </div>
              <p className="text-sm font-bold">Manage Category</p>
            </div> */}
            <div
              className="w-[150px] flex items-center flex-col gap-4 cursor-pointer"
              onClick={(e) =>
                navigate("/administrator/assessments/test-create")
              }
            >
              <div className="rounded-full bg-white p-6 w-[90px]">
                {/* <DocumentDuplicateIcon className="text-gray-500" /> */}
                <DocumentDuplicateIcon className="text-orange-secondary-500" />
              </div>
              <p className="text-sm font-bold">Manage Test</p>
            </div>
            {/* <div className="w-[150px] flex items-center flex-col gap-4">
              <div className="rounded-full bg-white p-6 w-[90px]">
                <QuestionMarkCircleIcon className="text-gray-500" />
              </div>
              <p className="text-sm font-bold">Question</p>
            </div>
            <div className="w-[150px] flex items-center flex-col gap-4">
              <div className="rounded-full bg-white p-6 w-[90px]">
                <ClipboardDocumentListIcon className="text-gray-500" />
              </div>
              <p className="text-sm font-bold">Test Module</p>
            </div> */}
            <div
              className="w-[150px] flex items-center flex-col gap-4 cursor-pointer"
              onClick={(e) =>
                navigate("/administrator/assessments/module-tag-create")
              }
            >
              <div className="rounded-full bg-white p-6 w-[90px]">
                {/* <ClipboardDocumentCheckIcon className="text-gray-500" /> */}
                <ClipboardDocumentCheckIcon className="text-pink-500" />
              </div>
              <p className="text-sm font-bold">Manage Tagging</p>
            </div>
          </div>
        </div>
      </div>
      <div className="card flex flex-col w-full">
        <div className="flex justify-between w-full mb-4">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">Test Library</p>
          </div>
          <div className="flex justify-end w-2/2"></div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <PaginationTable
            search={
              <SearchTable
                onSearch={(keyword) => handleSearch(keyword)}
                placeholder={"Search test"}
              />
            }
            filters={[
              <FilterTable
                placeholder={"Select Category"}
                selected={selectedAssessment}
                options={filterAssessment}
                onChange={(value) => handleFilterAssessment(value)}
              />,
            ]}
            currentPage={handlePaginate}
            onClearFilter={(e) => handleClearFilter(e)}
            itemsTo={itemsTo}
            itemsFrom={itemsFrom}
            itemsPerPage={5}
            checkbox={false}
            totalItems={totalItems}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            headers={[
              "NO.",
              "TEST",
              "ASSESSMENT CATEGORY",
              "NUMBER OF QUESTION(S)",
              "NUMBER OF JOB ROLE(S)",
              "COMPANIES",
              "FINISHED CANDIDATE",
              "ACTIVE",
              "ACTION",
            ]}
            content={<TableContent />}
          />
        </div>
      </div>
    </div>
  );
};

export default AssessmentPage;
