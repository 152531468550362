import React from "react";
import "./slider.css"
const RangeList = ({ data, color = "blue" }) => {
    const { parameter, minLabel, maxLabel, maxRange, minRange, value, code } = data;
    const summaryCard = {
        // boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: '12px',
        background: '#FFF',
        // padding: '24px',
    }
    // const result_percentage = ((value / maxRange) * 100) < 0 ? 0 : ((value / maxRange) * 100);
    const result_percentage = (((value + Math.abs(minRange)) / (Math.abs(maxRange) + Math.abs(minRange))) * 100);
    return (
        <div style={summaryCard} className='h-[100%] w-[97%] items-center px-3 mb-6' id={code}>
            <p className='font-semibold mb-4 text-center text-[20px]'>{parameter}</p>
            <div className='flex'>
                <div className='flex w-full gap-4 items-center'>
                    <div className='w-[30%] min-parameter'>
                        <p className='text-gray-500 text-[18px]'>{minLabel}</p>
                    </div>
                    <div className='w-[40%] parameter'>
                        <div className={`slider w-full`} style={{ background: color }}>
                            <div className={`slider-value bg-white font-semibold text-lg mb-4`} style={{ left: result_percentage + "%", color: color }}>{value}</div>
                            <div className={`slider-button bg-white border-2`} style={{ left: result_percentage + "%", borderColor: color }}></div>
                        </div>

                        {/* <input
                            disabled
                            value={value}
                            className={`w-full range-domination ${color}`}
                            type="range" min={minRange} max={maxRange} step="1"
                        /> */}
                    </div>
                    <div className='w-[30%] max-parameter'>
                        <p className='text-gray-500 text-[18px]'>{maxLabel}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RangeList;