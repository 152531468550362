// jobSlice.js
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    interviewsTable: {
        loaded: false,
        data: [
            {
                id: null,
                company_job_position: null,
                name: "-",
                batch: 0,
                date: "-",
                host_email: "-",
                co_host_email: "-",
                created_at: "-",
                updated_at: "-",
            },
        ],
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 1,
        itemsFrom: 1,
        itemsTo: 1,
        filterDepartments: [],
        filterPositions: [],
    }
};

const interviewSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        setInterviewSessions: (state, action) => {
            state.interviewsTable.data = action.payload.data.data;
            state.interviewsTable.totalItems = action.payload.data.total;
            state.interviewsTable.itemsPerPage = action.payload.data.per_page;
            state.interviewsTable.currentPage = action.payload.data.current_page;
            state.interviewsTable.itemsFrom = action.payload.data.from;
            state.interviewsTable.itemsTo = action.payload.data.to;
            // state.jobsTable.filterDepartments = action.payload.additional.filter.job_department;
            // state.jobsTable.filterPositions = action.payload.additional.filter.job_position;
            state.interviewsTable.loaded = true;
        },

    }
});

export const { setInterviewSessions } = interviewSlice.actions;
export default interviewSlice.reducer;