import React from "react";
import { useState, useEffect } from "react";
import CommunicateReports from "../Tabs/CommunicateReports";
import ManageReports from "../Tabs/ManageReports";
import DareReports from "../Tabs/DareReports";
import AdaptReports from "../Tabs/AdaptReports";
import ExcelReports from "../Tabs/ExcelReports";
const Professional = ({ tabChange, currentTab, dataRaw }) => {
    const [activePersonalityTab, setActivePersonalityTab] = useState(null);
    const [communication, setCommunication] = useState([]);
    const [adaption, setAdaption] = useState([]);
    const [daring, setDaring] = useState([]);
    const [excel, setExcel] = useState([]);
    const [management, setManagement] = useState([]);
    const [motivitation, setMotivation] = useState([]);
    const showPersonalityTab = (tabId) => {
        tabChange(tabId);
    };
    useEffect(() => {
        setCommunication(dataRaw.find(item => item.category === 'Communication'));
        setAdaption(dataRaw.find(item => item.category === 'Adaption'));
        setDaring(dataRaw.find(item => item.category === 'Daring'));
        setExcel(dataRaw.find(item => item.category === 'Excel'));
        setManagement(dataRaw.find(item => item.category === 'Management'));
        setMotivation(dataRaw.find(item => item.category === 'Motivation'));
        setActivePersonalityTab(currentTab);
    }, [currentTab])
    return (
        <>
            <div className='tab w-full mb-6'>
                <div className='flex flex-col h-[100%] w-full overflow-x-auto'>
                    <nav className="border-b border-gray-200">
                        <div className="flex space-x-4">
                            <a
                                href="#"
                                className={`${activePersonalityTab === 'comunicate'
                                    ? 'text-[#8F03ED] py-2 px-4 font-semibold border-b-2 border-[#8F03ED]'
                                    : 'text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200'
                                    }`}
                                onClick={() => showPersonalityTab('comunicate')}
                            >
                                Comunicate
                            </a>
                            <a
                                href="#"
                                className={`${activePersonalityTab === 'manage'
                                    ? 'text-[#8F03ED] py-2 px-4 font-semibold border-b-2 border-[#8F03ED]'
                                    : 'text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200'
                                    }`}
                                onClick={() => showPersonalityTab('manage')}
                            >
                                Manage
                            </a>
                            <a
                                href="#"
                                className={`${activePersonalityTab === 'dare'
                                    ? 'text-[#8F03ED] py-2 px-4 font-semibold border-b-2 border-[#8F03ED]'
                                    : 'text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200'
                                    }`}
                                onClick={() => showPersonalityTab('dare')}
                            >
                                Dare
                            </a>
                            <a
                                href="#"
                                className={`${activePersonalityTab === 'adapt'
                                    ? 'text-[#8F03ED] py-2 px-4 font-semibold border-b-2 border-[#8F03ED]'
                                    : 'text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200'
                                    }`}
                                onClick={() => showPersonalityTab('adapt')}
                            >
                                Adapt
                            </a>
                            <a
                                href="#"
                                className={`${activePersonalityTab === 'excel'
                                    ? 'text-[#8F03ED] py-2 px-4 font-semibold border-b-2 border-[#8F03ED]'
                                    : 'text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200'
                                    }`}
                                onClick={() => showPersonalityTab('excel')}
                            >
                                Excel
                            </a>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='mb-2'>
                <div className='flex flex-col w-full'>
                    <div className="mdb:px-4 mdb:py-5 sm:p-0">
                        <div className="py-1">
                            {/* Content for Tab 1 */}
                            {activePersonalityTab === 'comunicate' && (
                                <div className='flex flex-col items-center'>
                                    <CommunicateReports communication={communication} />
                                </div>
                            )}
                            {/* Content for Tab 2 */}
                            {activePersonalityTab === 'manage' && (
                                <div className='flex flex-col items-center'>
                                    <ManageReports management={management} />
                                </div>
                            )}
                            {/* Content for Tab 3 */}
                            {activePersonalityTab === 'dare' && (
                                <>
                                    <DareReports dare={daring} />
                                </>
                            )}
                            {/* Content for Tab 3 */}
                            {activePersonalityTab === 'adapt' && (
                                <div className='flex flex-col items-center'>
                                    <AdaptReports adaption={adaption} />
                                </div>
                            )}
                            {/* Content for Tab 3 */}
                            {activePersonalityTab === 'excel' && (
                                <div className='flex flex-col items-center'>
                                    <ExcelReports excel={excel} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Professional;