import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
function CalendarEventPopup({ onClose, onApply, calendar, durationTime = 30, startTime = "08:00", endTime = "20:00" }) {
  const [events, setEvents] = useState([]);
  const [times, setTimes] = useState([]);

  const handleSelect = (time) => {
    // CHECK IF TIME EXIST IN EVENT REMOTE, IF TIME DOES NOT EXIST, ADD TIME TO EVENT REMOTE
    const index = events.findIndex((event) => event.start_time === time);
    if (index === -1) {
      setEvents([
        ...events,
        {
          start_time: moment(time, "HH:mm").format("HH:mm"),
          end_time: moment(time, "HH:mm").add(durationTime, "minutes").format("HH:mm"),
        },
      ]);
    } else {
      const newEvents = events.filter((event) => event.start_time !== time);
      setEvents(newEvents);
    }
  };

  const handleApply = (e) => {
    e.preventDefault();
    onApply(events);
  }

  useEffect(() => {
    setEvents(calendar.events);
    // GENERATE TIME BASED ON DURATION AND START TIME LIMIT BY END TIME
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    const times = [];
    while (start < end) {
      times.push(start.format("HH:mm"));
      start.add(durationTime, "minutes");
    }
    setTimes(times);
  }, [calendar]);
  
  return (
    // Your alert popup component JSX
    <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate  min-h-[328px] py-2 flex flex-col px-4 py-4">
      <div className="flex justify-between px-2">
        <p className="text-center text-base text-gray-700">
          Add / Edit Event {calendar.data}
        </p>
        <button
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
          onClick={() => {
            onClose();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="flex flex-col items-center  h-full w-full">
        <div className="mb-2 mt-5 w-full">
          <div className="mb-2 grid grid-cols-4 gap-2">
            {times.map((time, index) => (
              <div className="p-2 " key={index}>
                <div
                  className={`${events.some((event) => event.start_time == time) && 'bg-green-1 text-white '} px-2 py-1 border border-1 border-soft-gray rounded-lg w-full text-center hover:cursor-pointer hover:border-green-1`}
                  onClick={() => handleSelect(time)}
                >
                  {time}
                </div>
              </div>
            ))}
          </div>
          {/* <ReactQuill theme="snow" value={value} onChange={setValue} className="h-full w-full" /> */}
        </div>
        <div className="w-full gap-4 px-2 pb-2">
          <button onClick={(e) => handleApply(e)} className="w-full hover:bg-violet-600 cursor-pointer bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed">
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}

export default CalendarEventPopup;
