import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UnderDevelopment } from "../../../../Component/Errors/UnderDevelopment";
import {
  UserGroupIcon,
  UserIcon,
  CubeIcon,
  ChartPieIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  ClipboardIcon,
  UsersIcon,
  UserPlusIcon,
  UserMinusIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import { documentTitle } from "../../../../helpers";
import {
  getReportCandidates,
  getReportCompany,
} from "../../../../api/admin/reportsApi";
import BtnSpinner from "../../../../Component/BtnSpinner";
import ReactApexChart from "react-apexcharts";
import FilterTable from "../../../../Component/Tables/FilterTable";
import PageSpinner from "../../../../Component/PageSpinner";

const ReportPage = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%", // Adjust this value to change the thickness of the donut
          },
        },
      },
      labels: [
        "Invited",
        "Progress",
        "Finished",
        "Not Finished",
        "Good Fit",
        "Not Fit",
      ],
      colors: [
        "#B638FF",
        "#1C64F2",
        "#5850EC",
        "#D61F69",
        "#0694A2",
        "#F7931F",
      ],
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         show: true,
      //         position: 'bottom',
      //       },
      //     },
      //   },
      // ],
      legend: {
        position: "bottom",
        offsetY: 0,
        height: 0,
      },
    },
  });

  const [selectedFilterCompany, setSelectedFilterCompany] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [filterCompany, setFilterCompany] = useState([]);
  const [filterYear, setFilterYear] = useState([]);
  const [filterMonth, setFilterMonth] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [query, setQuery] = useState(null);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    if (report) {
      setChartData({
        ...chartData,
        series: [
          report.invited_candidates,
          report.in_progress_candidates,
          report.finished_candidates,
          report.not_started_candidates,
          report.good_fit_candidates,
          report.not_fit_candidates,
        ],
      });
    }
  }, [report]);

  const fetchCompany = async () => {
    const queryString = new URLSearchParams(query).toString();
    setSummaryLoading(true);
    const result = await getReportCandidates(queryString);
    if (result.success) {
      setReport(result.data);
      setFilterCompany(result.additional.filter.companies);
      setFilterYear(result.additional.filter.years);
      setFilterMonth(result.additional.filter.months);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSummaryLoading(false);
  };

  useEffect(() => {
    documentTitle("Users Management");
  }, []);

  useEffect(() => {
    console.log(query);
    fetchCompany();
  }, [query]);

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <ChartPieIcon className="w-[20px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Candidate Report</p>
          <BreadCrumb list={[{ href: null, title: "Report Clients" }]} />
        </div>
      </div>
      <div className="summary-stats flex space-y-2 xl:space-y-0 xl:space-x-2 xl:flex-row flex-col">
        <div className="card flex xl:w-[30%] w-[100%] flex-col">
          {summaryLoading && <PageSpinner />}
          {!summaryLoading && (
            <>
              <div className=" justify-between items-center mb-3">
                <p className="text-lg text-grey-600 font-bold">Total</p>
                <div className="flex items-center">
                  <p className="text-xl mr-1">{report?.total}</p>
                  <small className="relative font-semibold">candidates</small>
                </div>
              </div>
              <div className="w-full ">
                <div className="max-w-[480px] mx-auto">
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="donut"
                    width={"100%"}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="card-mini flex xl:w-[70%] w-[100%] flex-col space-y-2">
          <div className="filter mb-3 flex mdb:items-center mdb:flex-row flex-col">
            <FilterTable
              placeholder={"Filter Company"}
              selected={selectedFilterCompany}
              options={filterCompany}
              onChange={(value) => {
                setQuery({ ...query, company: value });
                setSelectedFilterCompany(value);
              }}
            />
            <FilterTable
              placeholder={"Month"}
              selected={selectedMonth}
              options={filterMonth}
              onChange={(value) => {
                setQuery({ ...query, month: value });
                setSelectedMonth(value);
              }}
            />
            <FilterTable
              placeholder={"Year"}
              selected={selectedYear}
              options={filterYear}
              onChange={(value) => {
                setQuery({ ...query, year: value });
                setSelectedYear(value);
              }}
            />
            <a
              href="#"
              onClick={(e) => {
                window.location.reload();
              }}
              className="flex text-sm text-gray-500 font-medium hover:text-main-violate-hover mt-1"
            >
              Clear Filter
            </a>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 3xl:grid-cols-3 gap-4">
            <div className="card flex bg-white px-6 py-6 rounded-[12px]">
              <div className="dashboard violate-gradient h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
                <UserGroupIcon width={32} color={"#F5F5F5"} />
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-grey-600 mb-1 font-semibold">
                  Invited Candidates
                </p>
                <div className="flex">
                  {summaryLoading && <BtnSpinner />}
                  {!summaryLoading && (
                    <>
                      <p className="text-xl mr-1">
                        {report?.invited_candidates}
                      </p>
                      <small className="relative top-[12px]">candidates</small>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card flex bg-white px-6 py-6 rounded-[12px]">
              <div className="dashboard purple-gradient h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
                <ClipboardDocumentCheckIcon width={32} color={"#F5F5F5"} />
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-grey-600 mb-1 font-semibold">
                  Finished Candidates
                </p>
                <div className="flex">
                  {summaryLoading && <BtnSpinner />}
                  {!summaryLoading && (
                    <>
                      <p className="text-xl mr-1">
                        {report?.finished_candidates}
                      </p>
                      <small className="relative top-[12px]">
                        from {report.total} candidates
                      </small>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card flex bg-white px-6 py-6 rounded-[12px]">
              <div className="dashboard pink-gradient h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
                <ClipboardIcon width={32} color={"#F5F5F5"} />
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-grey-600 mb-1 font-semibold">
                  Not Finished Candidates
                </p>
                <div className="flex">
                  {summaryLoading && <BtnSpinner />}
                  {!summaryLoading && (
                    <>
                      <p className="text-xl mr-1">
                        {report?.finished_candidates}
                      </p>
                      <small className="relative top-[12px]">
                        from {report.total} candidates
                      </small>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card flex bg-white px-6 py-6 rounded-[12px]">
              <div className="dashboard blue-gradient h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
                <UsersIcon width={32} color={"#F5F5F5"} />
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-grey-600 mb-1 font-semibold">
                  On Progress Candidates
                </p>
                <div className="flex">
                  {summaryLoading && <BtnSpinner />}
                  {!summaryLoading && (
                    <>
                      <p className="text-xl mr-1">
                        {report?.in_progress_candidates}
                      </p>
                      <small className="relative top-[12px]">
                        from {report.total} candidates
                      </small>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card flex bg-white px-6 py-6 rounded-[12px]">
              <div className="dashboard green-gradient h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
                <UserPlusIcon width={32} color={"#F5F5F5"} />
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-grey-600 mb-1 font-semibold">
                  Good Fit Candidates
                </p>
                <div className="flex">
                  {summaryLoading && <BtnSpinner />}
                  {!summaryLoading && (
                    <>
                      <p className="text-xl mr-1">
                        {report?.good_fit_candidates}
                      </p>
                      <small className="relative top-[12px]">
                        from {report.total} candidates
                      </small>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card flex bg-white px-6 py-6 rounded-[12px]">
              <div className="dashboard orange-gradient h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
                <UserMinusIcon width={32} color={"#F5F5F5"} />
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-grey-600 mb-1 font-semibold">
                  Not Fit Candidates
                </p>
                <div className="flex">
                  {summaryLoading && <BtnSpinner />}
                  {!summaryLoading && (
                    <>
                      <p className="text-xl mr-1">
                        {report?.not_fit_candidates}
                      </p>
                      <small className="relative top-[12px]">
                        from {report.total} candidates
                      </small>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
