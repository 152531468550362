import axios from 'axios';
import { toast } from 'react-toastify';

export const getUsers = async (queryString = '') => {
    const response = await axios.get(`/api/client/users?${queryString}`, {
        headers: {
            "Content-Type": "application/json"
        },
    })
    return response.data;
};

export const updateClientStatus = async (id) => {
    try {
        const response = await axios.patch(`/api/client/users/${id}/status`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        if (!response.data.success) {
            toast.error(response.data.message, {
                toastId: "login-success-toast",
                position: toast.POSITION.TOP_CENTER
            });
        }
    } catch (error) {
        toast.error(error.response.data.message, {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER
        });
    }
};