// profileSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    candidatesTable: {
        loaded: false,
        data: [],
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 1,
        itemsFrom: 1,
        itemsTo: 1,
        filterPositions: []
    }
};

const candidatesSlice = createSlice({
    name: 'assessment',
    initialState,
    reducers: {
        setCandidates: (state, action) => {
            state.candidatesTable.data = action.payload.data.data;
            state.candidatesTable.totalItems = action.payload.data.total;
            state.candidatesTable.itemsPerPage = action.payload.data.per_page;
            state.candidatesTable.currentPage = action.payload.data.current_page;
            state.candidatesTable.itemsFrom = action.payload.data.from;
            state.candidatesTable.itemsTo = action.payload.data.to;
            state.candidatesTable.filterPositions = action.payload.additional.filter.job_position;
            state.candidatesTable.filterStatus = action.payload.additional.filter.status;
            state.candidatesTable.filterEducation = action.payload.additional.filter.educational_degree;
            state.candidatesTable.loaded = true;
        },
    },
});

export const { setCandidates } = candidatesSlice.actions;
export default candidatesSlice.reducer;