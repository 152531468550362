import axios from "axios";

export const getPositions = async (queryString = "") => {
  const response = await axios.get(
    `/api/client/assessment/job-position?${queryString}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const updatePause = async (id = "") => {
  const response = await axios.patch(
    `/api/client/assessment/job-position/toggle-pause/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const resendCandidateInvitation = async ({
  candidate_id,
  company_job_position_id,
}) => {
  const response = await axios.post(
    `/api/client/candidate/invitation-resend`,
    { candidate_id, company_job_position_id },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
