import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RectangleStackIcon } from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import { documentTitle } from "../../../../helpers";
import {
  getClientRoleAccessPermission,
  updateRoleAccessPermission,
} from "../../../../api/admin/clientRoleAccessApi";
import PageSpinner from "../../../../Component/PageSpinner";
import SimpleTable from "../../../../Component/Tables/SimpleTable";
import EmptyContent from "../../../../Component/Tables/EmptyContent";
import TableSpinner from "../../../../Component/TableSpinner";
import { Accordion, ToggleSwitch } from "flowbite-react";
import BtnSpinner from "../../../../Component/BtnSpinner";

const PermissionPage = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [groupPermission, setGroupPermission] = useState([]);
  const [access, setAccess] = useState(null);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const fetchAccessPermission = async () => {
    setLoading(true);
    const result = await getClientRoleAccessPermission(id);
    if (result.success) {
      setAccess(result.data.access);
      setPermissions(result.data.permission);
      let grouping = result.data.permission.reduce((acc, curr) => {
        if (!acc[curr.category]) {
          acc[curr.category] = [];
        }
        acc[curr.category].push(curr);
        return acc;
      }, {});

      let arrayGrouping = Object.entries(grouping).map(
        ([category, permissions]) => ({ category, permissions })
      );
      console.log(arrayGrouping);
      setGroupPermission(arrayGrouping);
    } else {
      toast.error(result.message, {
        toastId: "fetch-company-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const sanitizeForm = permissions.map((value) => {
      return {
        id: value.id,
        is_used: value.is_used,
      };
    });
    const result = await updateRoleAccessPermission(id, sanitizeForm);
    if (result.success) {
      toast.success(result.message, {
        toastId: "update-company-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      toast.error(result.message, {
        toastId: "update-company-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  const handleToggleAllowed = (id, is_used) => {
    const newPermissions = [...permissions];
    const index = newPermissions.findIndex((value) => value.id === id);
    newPermissions[index].is_used = !is_used;
    setPermissions(newPermissions);
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Detail Company");
    fetchAccessPermission();
  }, []);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const TableContent = () => {
    if (loading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {permissions.length > 0 ? (
            <>
              {permissions.map((value) => (
                <tr className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.category
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.action}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <ToggleSwitch
                      onChange={(e) =>
                        handleToggleAllowed(value.id, value.is_used)
                      }
                      checked={value.is_used}
                    />
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <RectangleStackIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Detail Permission</p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/client/role-access",
                title: "Client Access",
              },
              {
                href: null,
                title: "Detail Company",
              },
            ]}
          />
        </div>
      </div>
      <div className="card flex flex-col w-[100%]">
        <div className="flex justify-between w-full mb-4">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">
              Access Permission
            </p>
          </div>
        </div>

        <div className="flex mb-4 flex-col">
          {/* <SimpleTable
            checkbox={false}
            // withoutHeader={true}
            headers={["MODULE", "PERMISSION", "ALLOWED"]}
            content={<TableContent />}
          /> */}
          <Accordion collapseAll>
            {groupPermission.map((permission, index) => {
              return (
                <Accordion.Panel>
                  <Accordion.Title id={"permission-" + index}>
                    {permission.category
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </Accordion.Title>
                  {permission.permissions.map((per) => {
                    return (
                      <Accordion.Content className="bg-main-violate-50">
                        <div className="flex justify-between">
                          <p className="mb-2 text-gray-500 dark:text-gray-400">
                            {per.name
                              .split("_")
                              .join(" ")}
                          </p>
                          <div>
                            <ToggleSwitch
                              onChange={(e) =>
                                handleToggleAllowed(per.id, per.is_used)
                              }
                              checked={per.is_used}
                            />
                          </div>
                        </div>
                      </Accordion.Content>
                    );
                  })}
                </Accordion.Panel>
              );
            })}
          </Accordion>
        </div>
        <div className="flex justify-end">
          {submitting || loading ? (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              type=""
              disabled={true}
            >
              <BtnSpinner />
            </button>
          ) : (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              onClick={handleSubmit}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PermissionPage;
