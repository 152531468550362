import React from "react";
import { useState, useEffect } from "react";
import Tooltip from "../../../../../Component/Tooltip";
import Legends from "../../Components/Legends";
import EmptyState from "../../Components/EmptyState";
import AntiCheat from "../Overview/AntiCheat";
import { normalizeToPercentage } from "../../../../../helpers";
const SoftSkills = ({ data }) => {
  const [totalTest, setTotalTest] = useState(0);
  const [showCheating, setShowCheating] = useState([]);
  const StatusLabel = ({
    value,
    max_value,
    custom_label = null,
    min_value = 0,
  }) => {
    // const result_percentage = (value / max_value) * 100;
    const result_percentage = (
      ((value - min_value) / (max_value - min_value)) *
      100
    ).toFixed(0);
    if (result_percentage >= 0 && result_percentage <= 33) {
      return (
        <p className="text-label red text-sm mb-2">
          {custom_label ? custom_label : "Low"}
        </p>
      );
    } else if (result_percentage > 33 && result_percentage <= 66) {
      return (
        <p className="text-label yellow text-sm mb-2">
          {custom_label ? custom_label : "Medium"}
        </p>
      );
    } else if (result_percentage > 66) {
      return (
        <p className="text-label green text-sm mb-2">
          {custom_label ? custom_label : "High"}
        </p>
      );
    }
  };

  const handleShowCheating = (index) => {
    let temp = [...showCheating];
    // CHECK IF INDEX IN ARRAY
    if (temp.includes(index)) {
      // REMOVE INDEEX
      temp = temp.filter((value) => value !== index);
    } else {
      // ADD INDEX
      temp.push(index);
    }
    setShowCheating(temp);
  };

  useEffect(() => {
    let total = 0;
    data.map((value) => {
      if (value.is_choosen) {
        total += 1;
      }
    });
    setTotalTest(total);
  }, [data]);
  return (
    <>
      <Legends />
      {data.map((value, index) => {
        if (value.is_choosen) {
          let total_max_value = value.total_max_value;
          let total_min_value = value.total_min_value;
          let total_result = value.total_result;
          let total_ranking_percentage = 0;
          let total_ranking_position = 0;
          let benchmark_result = 0;
          let benchmark_percentage = 0;
          value.result.forEach((element) => {
            let temp_ranking_percentage = 0;
            let temp_ranking_position = 0;
            element.data.forEach((tag) => {
              // total_max_value += parseFloat(tag.max_value);
              // total_min_value += parseFloat(tag.min_value);
              benchmark_result += parseFloat(tag.benchmark);
              temp_ranking_percentage += parseFloat(tag.ranking_percentage);
              temp_ranking_position += tag.ranking_position;
            });
            total_ranking_percentage += temp_ranking_percentage / element.data.length;
            total_ranking_position += temp_ranking_position / element.data.length;
          });
          benchmark_percentage = normalizeToPercentage(
            benchmark_result,
            total_min_value,
            benchmark_result > total_max_value
              ? benchmark_result
              : total_max_value
          );
          total_ranking_percentage = total_ranking_percentage.toFixed(0);
          total_ranking_position = total_ranking_position.toFixed(0);
          return (
            <>
              <div className="card-mini mdb:card flex flex-col lg:flex-row justify-between w-full mb-5 mt-5">
                <div className="content w-full">
                  <div className="flex flex-col h-[100%]">
                    <div className="flex items-center mb-4 justify-between">
                      <p className="text-lg text-[#374151] font-semibold ">
                        {value.test_name}
                      </p>
                      {value.result.length > 0 && (
                        <button
                          className="flex items-center gap-2 border border-main-violate hover:bg-purple-200  px-2 text-xs leading-[22.4px] text-main-violate font-semibold h-[26px] rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
                          type="button"
                          onClick={() => handleShowCheating(index)}
                        >
                          {showCheating.includes(index)
                            ? "Show Result"
                            : "Show Cheating"}
                        </button>
                      )}
                    </div>
                    {showCheating.includes(index) && (
                      <AntiCheat records={value.records} />
                    )}
                    {!showCheating.includes(index) && (
                      <>
                        {value.result.length > 0 ? (
                          <>
                            <div className="flex w-full overflow-x-auto overflow-y-hidden gap-10 mdb:gap-3 min-h-[280px]">
                              <div className="left-side mdb:w-[20%] h-full flex flex-col gap-3">
                                <p className="text-base text-gray-500">
                                  Test Score
                                </p>
                                <div className="min-w-[120px] flex flex-col py-6 h-full rounded-lg">
                                  <StatusLabel
                                    value={value.total_result}
                                    max_value={value.total_max_value}
                                    min_value={value.total_min_value}
                                    custom_label={
                                      value.custom_result_label
                                        ? value.total_result_label
                                        : null
                                    }
                                  />
                                  <p className="font-semibold mb-2 text-xl mdb:text-4xl">
                                    {normalizeToPercentage(
                                      total_result,
                                      total_min_value,
                                      total_max_value
                                    )}
                                    %
                                  </p>
                                  <p className="text-gray-700 mb-1 font-medium mdb:text-base text-sm">
                                    Benchmark: {benchmark_percentage}%
                                  </p>
                                  <p className="text-green-500 text-sm mb-2 font-bold">
                                  Rank: Top {total_ranking_position <= 10 ? total_ranking_position : (total_ranking_percentage + "%")}
                                  </p>
                                </div>
                              </div>
                              <div className="center-side  mdb:w-[30%] h-full flex flex-col">
                                <p className="text-base text-gray-500 mb-2">
                                  Tagging Score
                                </p>
                                {value.result.map((tag) => {
                                  return tag.data.map((component) => {
                                    // const result_percentage = Math.max( 0,(component.result / component.max_value) *100 );
                                    const result_percentage =
                                      normalizeToPercentage(
                                        component.result,
                                        component.min_value,
                                        component.max_value
                                      );
                                    // const benchmark_percentage = Math.max(
                                    //   0,
                                    //   (component.benchmark /
                                    //     component.max_value) *
                                    //     100
                                    // );
                                    const benchmark_percentage =
                                      normalizeToPercentage(
                                        component.benchmark,
                                        component.min_value,
                                        component.benchmark >
                                          component.max_value
                                          ? component.benchmark
                                          : component.max_value
                                      );
                                    if (
                                      result_percentage >= 0 &&
                                      result_percentage <= 33
                                    ) {
                                      return (
                                        <>
                                          <div className="mb-5">
                                            <p className="font-semibold mb-2 mdb:text-base text-sm">
                                              {component.name}
                                            </p>
                                            <Tooltip
                                              text={
                                                value.custom_result_label
                                                  ? component.result_label
                                                  : "Low"
                                              }
                                            >
                                              <div className="w-full h-[12px] bg-gray-50">
                                                <div
                                                  className="h-full text-center text-xs text-white bg-red-500 rounded-r-sm"
                                                  style={{
                                                    width: `${result_percentage}%`,
                                                  }}
                                                ></div>
                                              </div>
                                            </Tooltip>
                                            <Tooltip
                                              text={
                                                value.custom_result_label
                                                  ? component.benchmark_label
                                                  : "Low"
                                              }
                                            >
                                              <div className="w-full h-[12px]  bg-gray-50">
                                                <div
                                                  className="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                  style={{
                                                    width: `${benchmark_percentage}%`,
                                                  }}
                                                ></div>
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </>
                                      );
                                    } else if (
                                      result_percentage > 33 &&
                                      result_percentage <= 66
                                    ) {
                                      return (
                                        <>
                                          <div className="mb-5">
                                            <p className="font-semibold mb-2 mdb:text-base text-sm">
                                              {component.name}
                                            </p>
                                            <Tooltip
                                              text={
                                                value.custom_result_label
                                                  ? component.result_label
                                                  : "Medium"
                                              }
                                            >
                                              <div className="w-full h-[12px] bg-gray-50">
                                                <div
                                                  className="h-full text-center text-xs text-white bg-yellow-300 rounded-r-sm"
                                                  style={{
                                                    width: `${result_percentage}%`,
                                                  }}
                                                ></div>
                                              </div>
                                            </Tooltip>
                                            <Tooltip
                                              text={
                                                value.custom_result_label
                                                  ? component.benchmark_label
                                                  : "Medium"
                                              }
                                            >
                                              <div className="w-full h-[12px]  bg-gray-50">
                                                <div
                                                  className="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                  style={{
                                                    width: `${benchmark_percentage}%`,
                                                  }}
                                                ></div>
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </>
                                      );
                                    } else if (result_percentage > 66) {
                                      return (
                                        <>
                                          <div className="mb-5">
                                            <p className="font-semibold mb-2 mdb:text-base text-sm">
                                              {component.name}
                                            </p>
                                            <Tooltip
                                              text={
                                                value.custom_result_label
                                                  ? component.result_label
                                                  : "High"
                                              }
                                            >
                                              <div className="w-full h-[12px] bg-gray-50">
                                                <div
                                                  className="h-full text-center text-xs text-white bg-green-500 rounded-r-sm"
                                                  style={{
                                                    width: `${
                                                      (component.result /
                                                        component.max_value) *
                                                      100
                                                    }%`,
                                                  }}
                                                ></div>
                                              </div>
                                            </Tooltip>
                                            <Tooltip
                                              text={
                                                value.custom_result_label
                                                  ? component.benchmark_label
                                                  : "High"
                                              }
                                            >
                                              <div className="w-full h-[12px]  bg-gray-50">
                                                <div
                                                  className="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                  style={{
                                                    width: `${benchmark_percentage}%`,
                                                  }}
                                                ></div>
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </>
                                      );
                                    }
                                  });
                                })}
                              </div>
                              <div className="right-side mdb:w-[50%] h-full flex flex-col gap-3">
                                <p className="text-base text-gray-500">
                                  Description
                                </p>
                                <div className="flex flex-col px-4 py-4 bg-main-violate-50 h-full rounded-lg overflow-y-auto min-w-[220px]">
                                  {value.result.map((tag) => {
                                    return tag.data.map((component) => {
                                      return (
                                        <>
                                          <p className="text-gray-900 mb-3 mdb:text-base text-sm">
                                            <b>{component.name}</b>{" "}
                                            {component.label1}
                                          </p>
                                        </>
                                      );
                                    });
                                  })}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <EmptyState />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        }
      })}
      {totalTest === 0 && (
        <>
          <div className="card flex flex-col lg:flex-row justify-between w-full mb-5">
            <div className="content w-full">
              <div className="flex flex-col h-[100%]">
                <EmptyState text="No test has been chosen for this assessment category" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SoftSkills;
