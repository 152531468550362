import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const HomePage = ({}) => {
  let navigate = useNavigate();
  useEffect(() => {
    navigate('/login', {replace: false});
  })
  
  return (
    <></>
    // <div className="container">
    //   <h1>Home Page</h1>
    // </div>
  )
}

export default HomePage;