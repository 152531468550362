import React from "react";
import { useState, useEffect } from "react";
import Tooltip from "../../../../../Component/Tooltip";
import Legends from "../../Components/Legends";
import EmptyState from "../../Components/EmptyState";
const HardSkills = ({ data }) => {
    return (
        <>
            <Legends />
            <div className='card flex flex-col lg:flex-row justify-between w-full mb-5'>
                <div className='content w-full'>
                    <div className='flex flex-col h-[100%]'>
                        <EmptyState text="No test has been chosen for this assessment category" />
                    </div>
                </div>
            </div>

            {/* <div>
                <div className='card flex flex-col lg:flex-row justify-between w-full mb-5'>
                    <div className='content w-full'>
                        <div className='flex flex-col h-[100%]'>
                            <p className='text-lg text-[#374151] font-semibold mb-4'>Sales</p>
                            <div className='flex w-full gap-3 h-[280px]'>
                                <div className='left-side w-[20%] h-full flex flex-col gap-3'>
                                    <p className='text-base text-gray-500'>Test Score</p>
                                    <div className='flex flex-col py-6 h-full rounded-lg'>
                                        <p className="text-label green text-sm mb-2">High</p>
                                        <p className='font-semibold mb-2 text-4xl'>87%</p>
                                        <p className='text-gray-700 mb-1 font-medium'>Benchmark: 76%</p>
                                        <p className='text-green-500 text-sm mb-2 font-bold'>Rank: Top 15%</p>
                                    </div>
                                </div>
                                <div className='center-side w-[30%] h-full flex flex-col justify-between'>
                                    <p className='text-base text-gray-500'>Tagging Score</p>
                                    <div>
                                        <p className='font-semibold mb-2'>Contract Reading</p>
                                        <Tooltip text={"High"}>
                                            <div class="w-full h-[12px] bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-green-400 rounded-r-sm"
                                                    style={{
                                                        width: `80%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={"Medium"}>
                                            <div class="w-full h-[12px]  bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                    style={{
                                                        width: `50%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <p className='font-semibold mb-2'>Listening - Pitch</p>
                                        <Tooltip text={"High"}>
                                            <div class="w-full h-[12px] bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-yellow-300 rounded-r-sm"
                                                    style={{
                                                        width: `50%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={"Medium"}>
                                            <div class="w-full h-[12px]  bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                    style={{
                                                        width: `30%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>

                                    </div>
                                    <div>
                                        <p className='font-semibold mb-2'>Listening - Presentation</p>
                                        <Tooltip text={"High"}>
                                            <div class="w-full h-[12px] bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-red-500 rounded-r-sm"
                                                    style={{
                                                        width: `30%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={"Medium"}>
                                            <div class="w-full h-[12px]  bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                    style={{
                                                        width: `50%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>

                                    </div>
                                </div>
                                <div className='right-side w-[50%] h-full flex flex-col gap-3'>
                                    <p className='text-base text-gray-500'>Description</p>
                                    <div className='flex flex-col px-4 py-4 bg-main-violate-50 h-full rounded-lg overflow-y-auto '>
                                        <p className='text-gray-900 mb-3'>
                                            <b>Verbal Test</b> is utilized to assess candidates' capacity to read, comprehend, analyze, and interpret data presented in written texts, such as contracts or agreements. Additionally, it evaluates their aptitude for comprehending information conveyed through telephone conversations or presentations.
                                        </p>
                                        <p className='text-gray-900 mb-3'><b>Contract Reading:</b> This involves the ability to extract key information from written agreements, ensuring a clear understanding of terms and obligations. Strong contract reading capabilities enable effective decision-making and communication within marketing contexts.</p>
                                        <p className='text-gray-900 mb-3'><b>Listening - Pitch:</b> Candidates showcase their capacity to attentively absorb information from presentations or telephone calls. They are also evaluated on their ability to effectively deliver persuasive and engaging pitches to convey ideas or products.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card flex flex-col lg:flex-row justify-between w-full mb-5'>
                    <div className='content w-full'>
                        <div className='flex flex-col h-[100%]'>
                            <p className='text-lg text-[#374151] font-semibold mb-4'>Negotitation</p>
                            <div className='flex w-full gap-3 h-[280px]'>
                                <div className='left-side w-[20%] h-full flex flex-col gap-3'>
                                    <p className='text-base text-gray-500'>Test Score</p>
                                    <div className='flex flex-col py-6 h-full rounded-lg'>
                                        <p className="text-label yellow text-sm mb-2">Medium</p>
                                        <p className='font-semibold mb-2 text-4xl'>57%</p>
                                        <p className='text-gray-700 mb-1 font-medium'>Benchmark: 76%</p>
                                        <p className='text-green-500 text-sm mb-2 font-bold'>Rank: Top 15%</p>
                                    </div>
                                </div>
                                <div className='center-side w-[30%] h-full flex flex-col justify-between'>
                                    <p className='text-base text-gray-500'>Tagging Score</p>
                                    <div>
                                        <p className='font-semibold mb-2'>Contract Reading</p>
                                        <Tooltip text={"High"}>
                                            <div class="w-full h-[12px] bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-green-400 rounded-r-sm"
                                                    style={{
                                                        width: `80%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={"Medium"}>
                                            <div class="w-full h-[12px]  bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                    style={{
                                                        width: `50%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <p className='font-semibold mb-2'>Listening - Pitch</p>
                                        <Tooltip text={"High"}>
                                            <div class="w-full h-[12px] bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-yellow-300 rounded-r-sm"
                                                    style={{
                                                        width: `50%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={"Medium"}>
                                            <div class="w-full h-[12px]  bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                    style={{
                                                        width: `30%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>

                                    </div>
                                    <div>
                                        <p className='font-semibold mb-2'>Listening - Presentation</p>
                                        <Tooltip text={"High"}>
                                            <div class="w-full h-[12px] bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-red-500 rounded-r-sm"
                                                    style={{
                                                        width: `30%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={"Medium"}>
                                            <div class="w-full h-[12px]  bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                    style={{
                                                        width: `50%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>

                                    </div>
                                </div>
                                <div className='right-side w-[50%] h-full flex flex-col gap-3'>
                                    <p className='text-base text-gray-500'>Description</p>
                                    <div className='flex flex-col px-4 py-4 bg-main-violate-50 h-full rounded-lg overflow-y-auto '>
                                        <p className='text-gray-900 mb-3'>
                                            <b>Verbal Test</b> is utilized to assess candidates' capacity to read, comprehend, analyze, and interpret data presented in written texts, such as contracts or agreements. Additionally, it evaluates their aptitude for comprehending information conveyed through telephone conversations or presentations.
                                        </p>
                                        <p className='text-gray-900 mb-3'><b>Contract Reading:</b> This involves the ability to extract key information from written agreements, ensuring a clear understanding of terms and obligations. Strong contract reading capabilities enable effective decision-making and communication within marketing contexts.</p>
                                        <p className='text-gray-900 mb-3'><b>Listening - Pitch:</b> Candidates showcase their capacity to attentively absorb information from presentations or telephone calls. They are also evaluated on their ability to effectively deliver persuasive and engaging pitches to convey ideas or products.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card flex flex-col lg:flex-row justify-between w-full mb-5'>
                    <div className='content w-full'>
                        <div className='flex flex-col h-[100%]'>
                            <p className='text-lg text-[#374151] font-semibold mb-4'>Customer Service</p>
                            <div className='flex w-full gap-3 h-[280px]'>
                                <div className='left-side w-[20%] h-full flex flex-col gap-3'>
                                    <p className='text-base text-gray-500'>Test Score</p>
                                    <div className='flex flex-col py-6 h-full rounded-lg'>
                                        <p className="text-label red text-sm mb-2">Low</p>
                                        <p className='font-semibold mb-2 text-4xl'>37%</p>
                                        <p className='text-gray-700 mb-1 font-medium'>Benchmark: 76%</p>
                                        <p className='text-green-500 text-sm mb-2 font-bold'>Rank: Top 15%</p>
                                    </div>
                                </div>
                                <div className='center-side w-[30%] h-full flex flex-col justify-between'>
                                    <p className='text-base text-gray-500'>Tagging Score</p>
                                    <div>
                                        <p className='font-semibold mb-2'>Contract Reading</p>
                                        <Tooltip text={"High"}>
                                            <div class="w-full h-[12px] bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-green-400 rounded-r-sm"
                                                    style={{
                                                        width: `80%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={"Medium"}>
                                            <div class="w-full h-[12px]  bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                    style={{
                                                        width: `50%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <p className='font-semibold mb-2'>Listening - Pitch</p>
                                        <Tooltip text={"High"}>
                                            <div class="w-full h-[12px] bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-yellow-300 rounded-r-sm"
                                                    style={{
                                                        width: `50%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={"Medium"}>
                                            <div class="w-full h-[12px]  bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                    style={{
                                                        width: `30%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>

                                    </div>
                                    <div>
                                        <p className='font-semibold mb-2'>Listening - Presentation</p>
                                        <Tooltip text={"High"}>
                                            <div class="w-full h-[12px] bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-red-500 rounded-r-sm"
                                                    style={{
                                                        width: `30%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={"Medium"}>
                                            <div class="w-full h-[12px]  bg-gray-50">
                                                <div
                                                    class="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                    style={{
                                                        width: `50%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Tooltip>

                                    </div>
                                </div>
                                <div className='right-side w-[50%] h-full flex flex-col gap-3'>
                                    <p className='text-base text-gray-500'>Description</p>
                                    <div className='flex flex-col px-4 py-4 bg-main-violate-50 h-full rounded-lg overflow-y-auto '>
                                        <p className='text-gray-900 mb-3'>
                                            <b>Verbal Test</b> is utilized to assess candidates' capacity to read, comprehend, analyze, and interpret data presented in written texts, such as contracts or agreements. Additionally, it evaluates their aptitude for comprehending information conveyed through telephone conversations or presentations.
                                        </p>
                                        <p className='text-gray-900 mb-3'><b>Contract Reading:</b> This involves the ability to extract key information from written agreements, ensuring a clear understanding of terms and obligations. Strong contract reading capabilities enable effective decision-making and communication within marketing contexts.</p>
                                        <p className='text-gray-900 mb-3'><b>Listening - Pitch:</b> Candidates showcase their capacity to attentively absorb information from presentations or telephone calls. They are also evaluated on their ability to effectively deliver persuasive and engaging pitches to convey ideas or products.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>

    )
}

export default HardSkills;