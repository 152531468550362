import React from "react";
import PageSpinner from "../../../../../../Component/PageSpinner";
import { AcademicCapIcon } from "@heroicons/react/24/solid";
import moment from "moment";

const EducationalSection = ({ educationals, loading = true }) => {
  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <AcademicCapIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Educational History
            </h4>
          </div>
        </div>
        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {educationals.length > 0 ? (
              <>
                {educationals.map((experience) => (
                  <>
                    <div className="flex py-6 px-6 border border-line-gray-200 rounded-md mb-4 px" style={{ justifyContent: "space-between" }}>
                      <div className="desc-section">
                        <p className="font-bold mb-0.5">{experience.institution}</p>
                        <p className="font-light text-slate-600 mb-0.5">
                          {experience.educational_major.name} - {experience.educational_degree.name}
                        </p>
                        <p className="font-light text-slate-500 text-sm mb-2">
                          {moment(experience.start_date).format("DD/MM/Y")} - {experience.end_date ? moment(experience.end_date).format("DD/MM/Y") : "Present"}
                        </p>
                        <p className="text-slate-600 text-base mb-2">
                          <b>Skill</b> : {experience.educational_skills_desc}
                        </p>
                        <p className="text-slate-600 text-base mb-2">
                          {experience.educational_description}
                        </p>
                      </div>
                      <div className="edit-section">
                       
                      </div>
                    </div>
                  </>
                ))}

              </>
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EducationalSection;
