// jobSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getNotifications as getAdminNotification,
  updateNotification as updateAdminNotification,
} from "../../api/admin";
import {
  getNotifications as getClientNotification,
  updateNotification as updateClientNotification,
} from "../../api";
const initialState = {
  data: [],
  nextPage: 1,
  loaded: false,
  loading: false,
};

export const fetchNotifications = createAsyncThunk(
  "notification/fetchNotifications",
  async (page = 1) => {
    const path = window.location.pathname; // Get the current URL path
    const parts = path.split("/"); // Split the path into an array of parts
    const firstPart = parts[1];
    let result;

    if (firstPart === "administrator") {
      result = await getAdminNotification(`limit=7&page=${page}`); // Replace with your API endpoint
    } else {
      result = await getClientNotification(`limit=7&page=${page}`); // Replace with your API endpoint
    }

    return result.data;
  }
);

const notificationSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    clearNotifications: (state) => {
      state.data = [];
      state.nextPage = 1;
      state.loaded = false;
    },
    readNotification: (state, action) => {
      // const result = await
      state.data = state.data.map((notification) => {
        if (notification.id === action.payload) {
          notification.status = 1;
        }
        return notification;
      });

      const path = window.location.pathname; // Get the current URL path
      const parts = path.split("/"); // Split the path into an array of parts
      const firstPart = parts[1];
      if (firstPart === "administrator") {
        updateAdminNotification(action.payload);
      } else {
        updateClientNotification(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.data = state.data.concat(action.payload.data);
      state.loaded = true;
      state.loading = false;
      if (action.payload.next_page_url) {
        state.nextPage = state.nextPage + 1;
      } else {
        state.nextPage = null;
      }
    });
    builder.addCase(fetchNotifications.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setUser, clearNotifications, readNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
