import React from "react";
import { useState, useEffect } from "react";
import dummy from "../../../../../Assets/images/dummy-detail-candidate.webp";
import { Avatar } from "flowbite-react";
import Slider from "react-slick";
import ImageViewer from "react-simple-image-viewer";
const AntiCheat = ({ records = null }) => {
  const settings = {
    arrows: true,
    infinite: false,
    speed: 500,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1720,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageReplicates, setImageReplicates] = useState([]);
  const openImageViewer = async (index, source) => {
    if (source == "capture") {
      setImageReplicates(records.captures.image_captures);
    } else {
      setImageReplicates(records.captures.image_screens);
    }
    setCurrentImage(index);
    setIsViewerOpen(true);
  };
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    if (isViewerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isViewerOpen]);

  return (
    <div className="card flex flex-col justify-between w-full mb-5 anti-cheat">
      <div className="content w-full lg:w-[100%] mb-5">
        <div className="flex flex-col h-[100%]">
          <p className="text-lg text-[#374151] font-semibold">
            Anti-Cheating Monitor Overview
          </p>
          <p className="text-gray-400 font-medium leading-[2]">
            You can see candidate cheating.
          </p>
          {!records?.devices && (
            <div className="w-full text-center text-gray-400 py-8">
              No Data Available
            </div>
          )}
          {records?.devices && (
            <div className="flex flex-col xs:flex-row mt-[20px]">
              <div className="screen-information w-[50%]">
                <div className="flex justify-between px-4 py-4">
                  <p className="text-sm text-gray-400 font-medium">
                    Webcame enabled?
                  </p>
                  {records?.devices && (
                    <>
                      {records?.devices.webcam_enable && (
                        <p className="bg-green-100 text-xs text-green-800 font-medium rounded-[6px] px-3 py-1 flex items-center h-[25px]">
                          Yes
                        </p>
                      )}
                      {!records?.devices.webcam_enable && (
                        <p className="bg-red-100 text-xs text-red-800 font-medium rounded-[6px] px-3 py-1 flex items-center h-[25px]">
                          No
                        </p>
                      )}
                    </>
                  )}
                  {!records?.devices && <>-</>}
                </div>
                <div className="flex justify-between px-4 py-4">
                  <p className="text-sm text-gray-400 font-medium">
                    Microphone enabled?
                  </p>
                  {records?.devices && (
                    <>
                      {records?.devices.microphone_enable && (
                        <p className="bg-green-100 text-xs text-green-800 font-medium rounded-[6px] px-3 py-1 flex items-center h-[25px]">
                          Yes
                        </p>
                      )}
                      {!records?.devices.microphone_enable && (
                        <p className="bg-red-100 text-xs text-red-800 font-medium rounded-[6px] px-3 py-1 flex items-center h-[25px]">
                          No
                        </p>
                      )}
                    </>
                  )}
                  {!records?.devices && <>-</>}
                </div>
                {records?.pinalties.length > 0 && (
                  <>
                    {records?.pinalties.map((penalty, index) => (
                      <div className="flex justify-between px-4 py-4">
                        <p className="text-sm text-gray-400 font-medium">
                          {penalty.description}
                        </p>
                        <span className="flex space-x-4">
                          {penalty.total > 0 && (
                            <>
                              <p>{penalty.total} x</p>
                              <p className="bg-red-100 text-xs text-red-800 font-medium rounded-[6px] px-3 py-1 flex items-center h-[25px]">
                                Cheat
                              </p>
                            </>
                          )}
                          {penalty.total <= 0 && (
                            <>
                              <p className="bg-green-100 text-xs text-green-800 font-medium rounded-[6px] px-3 py-1 flex items-center h-[25px]">
                                No Cheat
                              </p>
                            </>
                          )}
                        </span>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="device-information w-[50%]">
                <div className="flex justify-between px-4 py-4">
                  <p className="text-sm text-gray-400 font-medium w-[50%]">
                    Device use
                  </p>
                  <p className="text-sm text-gray-900 font-medium w-[50%]">
                    {records?.devices ? records.devices.device_user : "-"}
                  </p>
                </div>
                <div className="flex justify-between px-4 py-4">
                  <p className="text-sm text-gray-400 font-medium w-[50%]">
                    Location
                  </p>
                  <p className="text-sm text-gray-900 font-medium w-[50%]">
                    {records?.devices ? records.devices.location_address : "-"}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mb-5">
        <p className="text-lg text-[#374151] font-semibold mb-5">
          Photo Capture
        </p>
        {records?.captures?.image_captures?.length <= 0 && (
          <div className="w-full text-center text-gray-400 py-8">
            No Data Available
          </div>
        )}
        {records?.captures?.image_captures?.length > 0 && !isViewerOpen && (
          <Slider {...settings}>
            {records?.captures?.image_captures.map((file, index) => (
              <div className="capture w-full mb-6 lg:mb-0 lg:w-[30%]  h-[200px] overflow-y-hidden flex items-center justify-center rounded-[10px] px-2">
                <img
                  alt="capture"
                  onClick={() => openImageViewer(index, "capture")}
                  className="w-[100%] h-full object-center object-fit cursor-pointer hover:opacity-80 transition-opacity duration-300 ease-in-out"
                  src={file}
                />
              </div>
            ))}
          </Slider>
        )}
      </div>
      <div>
        <p className="text-lg text-[#374151] font-semibold mb-5">
          Screen Capture
        </p>
        {records?.captures?.image_screens?.length <= 0 && (
          <div className="w-full text-center text-gray-400 py-8">
            No Data Available
          </div>
        )}
        {records?.captures?.image_screens?.length > 0 && !isViewerOpen && (
          <Slider {...settings}>
            {records?.captures?.image_screens.map((file, index) => (
              <div className="capture w-full mb-6 lg:mb-0 lg:w-[30%] h-[200px] overflow-y-hidden flex items-center justify-center rounded-[10px] px-2">
                <img
                  alt="capture"
                  onClick={() => openImageViewer(index, "screen")}
                  className="w-[100%] h-full object-center object-fit cursor-pointer hover:opacity-80 transition-opacity duration-300 ease-in-out"
                  src={file}
                />
              </div>
            ))}
          </Slider>
        )}
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={imageReplicates}
          currentIndex={currentImage}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
};

export default AntiCheat;
