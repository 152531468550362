import React, { Component } from "react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ClipboardDocumentListIcon, ClockIcon } from "@heroicons/react/24/solid";
import "./index.css"
import PopUp from "../PopUp";
import DetailTestPreview from "../DetailTestPreview";

export class SelectTestGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: true,
            showSelectedDetail: {},
            showDetail: false
            // width: 600
        };
    }

    render() {
        const { data, onSelect, onRemove, inSelected = false } = this.props;
        const selectTest = (testId) => {
            onSelect(testId)
        }
        const removeTest = (testId) => {
            onRemove(testId)
        }
        return (
            <div >
                <div
                    style={{
                        width: this.state.width + "px",
                        display: this.state.display ? "grid" : "none"
                    }}
                    className="grid-cols-1 sm:grid-cols-2 3xl:grid-cols-4 xl:grid-cols-3"
                >

                    {data.map((value) => (
                        <div className="px-2 mb-5">
                            <div className={(value.selected ? "card-main-selected" : "card") + " h-[450px] flex flex-col justify-between"} >
                                <div>
                                    <div className={inSelected && 'title-selected'}>
                                        <p className="text-grey-900 font-semibold text-xl lg:text-lg mb-4">{value.name}</p>
                                    </div>
                                    <p className="text-grey-500 text-base lg:text-sm mb-6 text-clamp-3">{value.description}</p>
                                </div>
                                <div>
                                    <span className="flex mb-4">
                                        <ClockIcon className="w-[24px] text-grey-500 mr-2" />
                                        <label>{((value.times) / 60).toFixed(0)} minutes</label>
                                    </span>
                                    <span className="flex mb-4">
                                        <ClipboardDocumentListIcon className="w-[24px] text-grey-500 mr-2" />
                                        <label>{value.total_question} item tests</label>
                                    </span>
                                </div>

                                <div>
                                    {value.selected ? <>
                                        <button
                                            className="mb-4 bg-red-100 w-full px-4 text-[16px] leading-[22.4px] text-red-600 font-semibold h-[46px] rounded-lg hover:bg-red-300 disabled:bg-violet-300 disabled:cursor-not-allowed"
                                            type=""
                                            key={value.id}
                                            onClick={(e) => {
                                                removeTest(value.id)
                                            }}
                                        >
                                            Remove
                                        </button>
                                    </> : <>
                                        <button
                                            className="mb-4 bg-main-violate-100 w-full px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-violet-300 disabled:bg-violet-300 disabled:cursor-not-allowed"
                                            type=""
                                            key={value.id}
                                            onClick={(e) => {
                                                selectTest(value.id)
                                            }}
                                        >
                                            Add
                                        </button>
                                    </>}
                                    <button
                                        className="border border-main-violate w-full px-4 text-[16px] leading-[22.4px] text-main-violate hover:bg-violet-100 font-semibold h-[46px] rounded-lg"
                                        type=""
                                        onClick={(e) => {
                                            this.setState({ showSelectedDetail: value })
                                            this.setState({ showDetail: true })
                                        }}
                                    >
                                        Detail
                                    </button>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>
                {
                    this.state.showDetail ? <PopUp><DetailTestPreview data={this.state.showSelectedDetail} onClose={(e) => this.setState({ ...this.state, showDetail: false })} /></PopUp> : ''
                }
            </div>

        );
    }
}
export default SelectTestGrid;