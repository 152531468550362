import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Avatar, Sidebar } from "flowbite-react";
import { fetchPermissions } from "../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import NavbarDashboard from "../Component/NavbarDashboard";
import PageSpinner from "../Component/PageSpinner";
import {
  BriefcaseIcon,
  UserGroupIcon,
  PresentationChartLineIcon,
  IdentificationIcon,
  BuildingOfficeIcon,
  Cog6ToothIcon,
  InformationCircleIcon,
  ArrowRightOnRectangleIcon,
  Squares2X2Icon,
  ShoppingBagIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import { fetchJobDepartments } from "../features/shared/jobDepartmentSlice";
import { fetchJobPositions } from "../features/shared/jobPositionSlice";
import { fetchNotifications } from "../features/notification/notificationSlice";
import config from "../config";
const MainLayout = () => {
  const dispatch = useDispatch();
  const { data: notifications, loaded } = useSelector(
    (state) => state.notification
  );

  const navigate = useNavigate();
  const permission = useSelector((state) => state.user.permissions);

  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || { base_role: null };
  });

  // const userData = JSON.parse(localStorage.getItem("user"));
  const [openSidebar, setOpenSidebar] = useState(true);
  const [loading, setLoading] = useState(true);
  const sidebar_packages = useRef(null);

  useEffect(() => {
    if (user.base_role === "superadmin") {
      navigate("/administrator/login", { replace: true });
    }
  }, [user]);

  useEffect(() => {
    if (!user.base_role) {
      navigate("/login", { replace: true });
    } else {
      dispatch(fetchPermissions());
      dispatch(fetchJobDepartments());
      dispatch(fetchJobPositions());
      dispatch(fetchNotifications());
    }
  }, []);

  useEffect(() => {
    if (permission) {
      setLoading(false);
    }
  }, [permission]);

  useEffect(() => {
    if (window.location.pathname.startsWith("/client/packages")) {
      let element = document.getElementsByClassName("sidebar-packages");
      element[0]?.nextSibling?.removeAttribute("hidden");
    }
  }, []);

  // useEffect(() => {
  //   if(!openSidebar){
  //     document.body.classList.add("overflow-hidden")
  //   } else {
  //     document.body.classList.remove("overflow-hidden")
  //   }
  // }, [openSidebar])

  const signOut = () => {
    localStorage.removeItem("user");
    window.location.replace("/login");
  };

  // if (loading) {
  //   return (
  //     <div className="flex items-center justify-center h-screen">
  //       <PageSpinner />
  //       <p className="text-gray-500 text-sm font-semibold">Loading App...</p>
  //     </div>
  //   );
  // }
  return (
    <>
      <NavbarDashboard
        openSidebar={(e) => {
          setOpenSidebar(!openSidebar);
        }}
        notifications={notifications}
        loaded={loaded}
      />
      <Sidebar
        aria-label="Sidebar with multi-level dropdown"
        className="w-full sidebar"
      >
        <Sidebar.Items className="w-full md:flex mt-[70px]">
          <div
            className={`${
              (openSidebar ? " translate-x-[-100%] " : " translate-x-[0%] ") +
              "mdb:translate-x-[0%] w-[75%] sm:w-[50%] lg:w-[18%] mdb:w-[25%] lg:max-w-xs py-4 px-2 fixed mdb:relative  bg-white z-40 h-[93%] duration-150 ease-out lg:flex-1 flex flex-col"
            }`}
          >
            <div className="flex-1">
              {/* {!loading && ( */}
              <>
                <Sidebar.ItemGroup>
                  <p className="text-sm text-grey-400 font-semibold px-4">
                    Main Menu
                  </p>

                  <Sidebar.Item
                    className={`${
                      (window.location.pathname.startsWith("/client/dashboard")
                        ? " active "
                        : "") + "sidebar-item hover:cursor-pointer px-4"
                    }`}
                    onClick={(e) => {
                      navigate("/client/dashboard");
                    }}
                    icon={Squares2X2Icon}
                    active={window.location.pathname.startsWith(
                      "/client/dashboard"
                    )}
                  >
                    <p className="text-grey-500">Dashboard</p>
                  </Sidebar.Item>
                  {permission?.job_role?.includes("read") && (
                    <Sidebar.Item
                      className={`${
                        (window.location.pathname.startsWith("/client/jobs")
                          ? " active "
                          : "") + "sidebar-item hover:cursor-pointer px-4"
                      }`}
                      onClick={(e) => {
                        navigate("/client/jobs");
                      }}
                      icon={BriefcaseIcon}
                      active={window.location.pathname.startsWith(
                        "/client/jobs"
                      )}
                    >
                      <p className="text-grey-500">Jobs</p>
                    </Sidebar.Item>
                  )}

                  {/* {permission?.job_role?.includes("read") && ( */}
                  {/* HANDLE ONLY DEVELOPMENT, SHOW PROD AFTER APPROVED */}
                  {config.APP_ENV === "development" && (
                    <Sidebar.Item
                      className={`${
                        (window.location.pathname.startsWith(
                          "/client/interviews"
                        )
                          ? " active "
                          : "") + "sidebar-item hover:cursor-pointer px-4"
                      }`}
                      onClick={(e) => {
                        navigate("/client/interviews");
                      }}
                      icon={VideoCameraIcon}
                      active={window.location.pathname.startsWith(
                        "/client/interviews"
                      )}
                    >
                      <p className="text-grey-500">Interview</p>
                    </Sidebar.Item>
                  )}
                  {/* )} */}

                  {permission?.my_candidate?.includes("read") && (
                    <Sidebar.Item
                      className={`${
                        (window.location.pathname.startsWith(
                          "/client/my-candidates"
                        )
                          ? " active "
                          : "") + "sidebar-item hover:cursor-pointer px-4"
                      }`}
                      onClick={(e) => {
                        navigate("/client/my-candidates");
                      }}
                      active={window.location.pathname.startsWith(
                        "/client/my-candidates"
                      )}
                      icon={UserGroupIcon}
                    >
                      <p className="text-grey-500">My Candidate</p>
                    </Sidebar.Item>
                  )}

                  {permission?.candidate_report?.includes("read") && (
                    <Sidebar.Item
                      className={`${
                        (window.location.pathname.startsWith("/client/reports")
                          ? " active "
                          : "") + "sidebar-item hover:cursor-pointer px-4"
                      }`}
                      onClick={(e) => {
                        navigate("/client/reports");
                      }}
                      active={window.location.pathname.startsWith(
                        "/client/reports"
                      )}
                      icon={PresentationChartLineIcon}
                    >
                      <p className="text-grey-500">Report</p>
                    </Sidebar.Item>
                  )}

                  {permission?.user_management?.includes("read") && (
                    <>
                      <Sidebar.Item
                        className={`${
                          (window.location.pathname.startsWith(
                            "/client/users-management"
                          )
                            ? " active "
                            : "") + "sidebar-item hover:cursor-pointer px-4"
                        }`}
                        onClick={(e) => {
                          navigate("/client/users-management");
                        }}
                        active={window.location.pathname.startsWith(
                          "/client/users-management"
                        )}
                        icon={IdentificationIcon}
                      >
                        <p className="text-grey-500">Manage User</p>
                      </Sidebar.Item>
                    </>
                  )}

                  {permission?.company_profile?.includes("read") && (
                    <>
                      <Sidebar.Item
                        className={`${
                          (window.location.pathname.startsWith(
                            "/client/company-profile"
                          )
                            ? " active "
                            : "") + "sidebar-item hover:cursor-pointer px-4"
                        }`}
                        onClick={(e) => {
                          navigate("/client/company-profile");
                        }}
                        active={
                          window.location.pathname === "/client/company-profile"
                        }
                        icon={BuildingOfficeIcon}
                      >
                        <p className="text-grey-500">Company Profile</p>
                      </Sidebar.Item>
                    </>
                  )}

                  {permission?.package?.includes("read") && (
                    <>
                      <Sidebar.Collapse
                        icon={ShoppingBagIcon}
                        label="Packages"
                        className="sidebar-packages hover:cursor-pointer px-4"
                        ref={sidebar_packages}
                      >
                        <Sidebar.Item
                          className={`${
                            (window.location.pathname == "/client/packages"
                              ? " active "
                              : "") + "sidebar-item hover:cursor-pointer px-4"
                          }`}
                          onClick={(e) => {
                            navigate("/client/packages");
                          }}
                          active={
                            window.location.pathname == "/client/packages"
                          }
                        >
                          <p className="text-grey-500">My Packages</p>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className={`${
                            (window.location.pathname ==
                            "/client/packages/history"
                              ? " active "
                              : "") + "sidebar-item hover:cursor-pointer px-4"
                          }`}
                          onClick={(e) => {
                            navigate("/client/packages/history");
                          }}
                          active={
                            window.location.pathname ==
                            "/client/packages/history"
                          }
                        >
                          <p className="text-grey-500">Transaction List</p>
                        </Sidebar.Item>
                      </Sidebar.Collapse>
                    </>
                  )}
                </Sidebar.ItemGroup>
                <Sidebar.ItemGroup>
                  <p className="text-sm text-grey-400 font-semibold px-4">
                    Get the info
                  </p>
                  {permission?.setting?.includes("read") && (
                    <Sidebar.Item
                      className={`${
                        (window.location.pathname.startsWith("/client/settings")
                          ? " active "
                          : "") + "sidebar-item hover:cursor-pointer px-4"
                      }`}
                      onClick={(e) => {
                        navigate("/client/settings");
                      }}
                      active={window.location.pathname.startsWith(
                        "/client/settings"
                      )}
                      icon={Cog6ToothIcon}
                    >
                      <p className="text-grey-500">Settings</p>
                    </Sidebar.Item>
                  )}

                  <Sidebar.Item
                    className={`${
                      (window.location.pathname.startsWith("/client/helps")
                        ? " active "
                        : "") + "sidebar-item hover:cursor-pointer px-4"
                    }`}
                    onClick={(e) => {
                      navigate("/client/helps");
                    }}
                    active={window.location.pathname === "/client/helps"}
                    icon={InformationCircleIcon}
                  >
                    <p className="text-grey-500">Help & Support</p>
                  </Sidebar.Item>
                  {/* <Sidebar.Item
                  className="sidebar-item hover:cursor-pointer px-4 "
                  onClick={(e) => {
                    signOut();
                    // navigate('/client/helps');
                  }}
                  icon={ArrowRightOnRectangleIcon}
                >
                  <p className="text-grey-500">Logout</p>
                </Sidebar.Item> */}
                </Sidebar.ItemGroup>
              </>
              {/* )} */}
            </div>
            <div className="h-[65px] bg-gray-50 px-2 rounded-md block mdb:hidden">
              <div className="flex justify-between items-center h-full">
                <div className="flex space-x-4 h-full items-center ">
                  <Avatar
                    className=""
                    alt="User settings"
                    img={user?.company?.image}
                    rounded={true}
                  />
                  <div className="flex flex-col text-left">
                    <p className="text-sm font-semibold">
                      {user?.company?.name}
                    </p>
                    <p className="text-sm text-gray-500 font-medium">
                      {user.name}
                    </p>
                  </div>
                </div>
                <ArrowRightOnRectangleIcon
                  onClick={() => signOut()}
                  className="text-gray-500 hover-cursor-pointer cursor-pointer"
                  width={30}
                />
              </div>
            </div>
          </div>
          <div className="content w-[100%] mdb:w-[75%] sm:w-[100%] lg:w-[92%] overflow-x-hidden bg-[#F8F8F8] flex-1">
            {!openSidebar && (
              <>
                <div className="overlay fixed w-full bg-gray-500/[.5] h-full z-10"></div>
              </>
            )}

            <Outlet />
          </div>
        </Sidebar.Items>
      </Sidebar>
    </>
  );
};

export default MainLayout;
