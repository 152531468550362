// jobSlice.js
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    jobsTable: {
        loaded: false,
        data: [
            {
                id: null,
                name: "-",
                total_candidate_started: 0,
                total_candidate_finished: 0,
                created_at: "-",
            },
        ],
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 1,
        itemsFrom: 1,
        itemsTo: 1,
        filterDepartments: [],
        filterPositions: [],
    }
};

const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        setPositions: (state, action) => {
            state.jobsTable.data = action.payload.data.data;
            state.jobsTable.totalItems = action.payload.data.total;
            state.jobsTable.itemsPerPage = action.payload.data.per_page;
            state.jobsTable.currentPage = action.payload.data.current_page;
            state.jobsTable.itemsFrom = action.payload.data.from;
            state.jobsTable.itemsTo = action.payload.data.to;
            state.jobsTable.filterDepartments = action.payload.additional.filter.job_department;
            state.jobsTable.filterPositions = action.payload.additional.filter.job_position;
            state.jobsTable.loaded = true;
        },

    }
});

export const { setPositions } = jobsSlice.actions;
export default jobsSlice.reducer;