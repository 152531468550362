import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../Component/PersonalityRadarChart";
import PageSpinner from "../../../../../Component/PageSpinner";
import RangeList from "../RangeList";

const MotivationReports = ({ data }) => {
    const [communicate, setCommunicate] = useState(null);
    const [dataRange, setDataRange] = useState(null);
    useEffect(() => {
        if (data) {
            let values = [];
            let labels = [];
            let tempDataRange = [];

            data.data.map((item) => {
                values.push(item.result)
                labels.push(item.name)
                tempDataRange.push({ parameter: item.name, minLabel: item.label2, maxLabel: item.label1, maxRange: "10", minRange: "0", value: item.result, code: item.code.toLowerCase() })
            })
            setCommunicate({
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: '#9061F97A',
                        borderColor: '#9061F9',
                        borderWidth: 2,
                        hidden: '',
                    },
                ],
            })
            setDataRange(tempDataRange)
        }
    }, [data])
    return (
        <div className='flex flex-col items-center ' id="manage-section">
            <div className='flex w-full items-center mb-2 gap-10 px-4 justify-center' id="radar-personality-motivation">
                <div className="w-[40%] flex justify-center">
                    <div className="max-w-[520px]">
                        {communicate ? <PersonalityRadarChart data={communicate} /> : <PageSpinner />}
                    </div>

                </div>
                <div className="w-[60%]">
                    <p className='font-semibold text-[20px] mb-2'>Motivation</p>
                    <p className="text-gray-500 text-[20px]">Motivation involves the attitudes, actions, and strategies individuals use to stay motivated and driven in their work. Motivation is a critical aspect of professional success and can have a significant impact on job performance, productivity, and career advancement. </p>
                    {/* <ul className="list-disc list-inside text-gray-500">
                        <li>This person is able to trust his or her judgem ent and overcome her doubts when they are not certain.</li>
                        <li>This person has his or her own opinions but is still sensitiv e t o criticism.</li>
                        <li>This person prefers to rely on an existing approach and is rather convent ional.</li>
                        <li>This person is able to trust his or her judgem ent and overcome her doubts when they are not certain.</li>
                    </ul> */}
                </div>
            </div>
            {communicate ? <>
                {dataRange.map((item, index) => (
                    <RangeList data={item} color="#9061F9" />
                ))}
            </> : <PageSpinner />}

        </div>
    )
}

export default MotivationReports;