import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UnderDevelopment } from "../../../../Component/Errors/UnderDevelopment";
import {
  BuildingOffice2Icon,
  InformationCircleIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import PaginationTable from "../../../../Component/Tables/PaginationTable";
import SearchTable from "../../../../Component/Tables/SearchTable";
import FilterTable from "../../../../Component/Tables/FilterTable";
import TableSpinner from "../../../../Component/TableSpinner";
import EmptyContent from "../../../../Component/Tables/EmptyContent";
import { documentTitle } from "../../../../helpers";
import { getClientRoleAccess, getMyClient } from "../../../../api/admin";
import DropdownDots from "../../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";

const RoleAccessPage = () => {
  let navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [roleAccess, setRoleAccess] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const fetchRoleAccess = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getClientRoleAccess(queryString);
    if (result.success) {
      setRoleAccess(result.data.data);
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setItemsPerPage(result.data.per_page);
      setTableLoading(false);
      setFilterCompany(result.additional.filter.companies);
      setFilterRole(result.additional.filter.roles);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSelectedFilterCompany("");
    setSelectedFilterRole("");
    setQuery({ ...query, company: "", role: "" });
  };

  useEffect(() => {
    documentTitle("Manage Client");
  }, []);

  useEffect(() => {
    fetchRoleAccess();
  }, [query]);

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {roleAccess.length > 0 ? (
            roleAccess.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.code}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <DropdownDots isPublish={value.is_publish}>
                      <Dropdown.Item
                        onClick={(e) => {
                          navigate(`/administrator/client/role-access/${value.id}`);
                        }}
                      >
                        <InformationCircleIcon width={16} className="mr-2" />
                        Edit Permission
                      </Dropdown.Item>
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <UserIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Client Access</p>
          <BreadCrumb list={[{ href: null, title: "Client Access" }]} />
          {/* <p className='text-base text-grey-400 font-medium '>List of positions that have been entered</p> */}
        </div>
      </div>
      <div className="card flex flex-col w-full">
        <div className="flex justify-between w-full mb-8">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">Access List</p>
            {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
          </div>
          <div className="flex justify-end w-2/2"></div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <PaginationTable
            onClearFilter={(e) => handleClearFilter(e)}
            currentPage={handlePaginate}
            itemsTo={itemsTo}
            itemsFrom={itemsFrom}
            itemsPerPage={5}
            checkbox={false}
            totalItems={totalItems}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            headers={["NO.", "ACCESS TYPE", "DESCRIPTION", "ACTION"]}
            content={<TableContent />}
          />
        </div>
      </div>
    </div>
  );
};

export default RoleAccessPage;
