import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IdentificationIcon } from "@heroicons/react/24/solid";
import PaginationTable from "../../../Component/Tables/PaginationTable";
import FilterTable from "../../../Component/Tables/FilterTable";
import EmptyContent from "../../../Component/Tables/EmptyContent";
import TableSpinner from "../../../Component/TableSpinner";
import { ToggleSwitch } from "flowbite-react";
import SearchTable from "../../../Component/Tables/SearchTable";
import { getUsers, updateClientStatus } from "../../../api";
import BreadCrumb from "../../../Component/BreadCrumb";
import PopUp from "../../../Component/PopUp";
import ComfirmStatus from "./Popup/ConfirmStatus";
import { documentTitle } from "../../../helpers";
import { useSelector } from "react-redux";

const UserManagementPage = () => {
  const permission = useSelector((state) => state.user.permissions);
  const filterDepartments = useSelector((state) => state.jobDepartment.data);
  const jobPositions = useSelector((state) => state.jobPosition.data);
  let navigate = useNavigate();

  const [tableLoading, setTableLoading] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  // const [filterDepartments, setFilterDepartments] = useState([]);
  const [filterPositions, setFilterPositions] = useState([]);
  // const [jobPositions, setJobPositions] = useState([]);
  const [query, setQuery] = useState({});
  const [users, setUsers] = useState([]);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [selectedFilterPosition, setSelectedFilterPosition] = useState(null);
  const [selectedFilterDepartment, setSelectedFilterDepartment] =
    useState(null);
  const [currentId, setCurrentId] = useState(null);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const handleToggleStatus = async (id, curent_status) => {
    setConfirmStatus(true);
    setCurrentId(id);
    setCurrentStatus(curent_status);
  };

  const handleChangeStatus = async (approve) => {
    setConfirmStatus(false);
    setCurrentId(null);
    setCurrentStatus(null);
    if (approve) {
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === currentId ? { ...user, status: !user.status } : user
        )
      );

      await updateClientStatus(currentId);
    }
  };

  const handleFilterPosition = (id) => {
    setQuery({ ...query, position: id });
    setSelectedFilterPosition(id);
  };

  const handleFilterDepartment = (id) => {
    setQuery({ ...query, department: id });
    setFilterPositions(
      jobPositions.filter((position) => position.job_department_id === id)
    );
    setSelectedFilterDepartment(id);
    setSelectedFilterPosition("");
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handleLimit = (value) => {
    setQuery({ ...query, limit: value });
  };

  const handleClearFilter = (e) => {
    setSelectedFilterPosition("");
    setSelectedFilterDepartment("");
    setQuery({ ...query, position: "", department: "" });
  };

  const fetchUsers = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getUsers(queryString);
    setUsers(result.data.data);
    setCurrentPage(result.data.current_page);
    setTotalItems(result.data.total);
    setItemsPerPage(result.data.per_page);
    setItemsFrom(result.data.from);
    setItemsTo(result.data.to);
    setItemsPerPage(result.data.per_page);
    setTableLoading(false);
    // setFilterDepartments(result.additional.filter.job_department);
    // setJobPositions(result.additional.filter.job_position);
  };

  useEffect(() => {
    documentTitle("Users Management");
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [query]);

  const StickyContent = () => {
    if (!tableLoading) {
      return (
        <>
          {users.length > 0 ? (
            <>
              {users.map((value, index) => {
                return (
                  <tr className="whitespace-nowrap border-b-[1px]">
                    <td className="px-6 py-[14.5px]">
                      {permission?.user_management?.includes("update") && (
                        <ToggleSwitch
                          onChange={(e) =>
                            handleToggleStatus(value.id, value.status)
                          }
                          checked={value.status}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {users.length > 0 ? (
            users.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px] hover:bg-line-grey cursor-pointer" >
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <input
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                      type="checkbox"
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {value.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {value.email}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {value.access.job_department
                      ? value.access.job_department.name
                      : "-"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {value.access.job_position
                      ? value.access.job_position.name
                      : "-"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {value.base_role.name}
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      {confirmStatus && (
        <PopUp>
          <ComfirmStatus
            status={currentStatus}
            onClose={() => setConfirmStatus(false)}
            onConfirm={(e) => handleChangeStatus(e)}
          />
        </PopUp>
      )}
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <IdentificationIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Users Management</p>
          <BreadCrumb list={[{ href: null, title: "User" }]} />
        </div>
      </div>

      <div className="report-list">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-8">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">User List</p>
              {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
            </div>
            <div className="flex justify-end w-2/2">
              {permission?.user_management?.includes("create") && (
                <button
                  className="bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600"
                  type=""
                  onClick={(e) => {
                    navigate("/client/users-management/create");
                  }}
                >
                  + Create
                </button>
              )}
            </div>
          </div>
          <div className="border-b border-gray-200 rounded-lg shadow">
            <PaginationTable
              search={
                <SearchTable
                  onSearch={(keyword) => handleSearch(keyword)}
                  placeholder={"Search name / email"}
                />
              }
              filters={[
                <FilterTable
                  selected={selectedFilterDepartment}
                  placeholder={"Select Department"}
                  options={filterDepartments}
                  onChange={(value) => handleFilterDepartment(value)}
                />,
                <FilterTable
                  selected={selectedFilterPosition}
                  placeholder={"Select Position"}
                  options={filterPositions}
                  onChange={(value) => handleFilterPosition(value)}
                />,
              ]}
              isSticky={true}
              stickyHeader={["STATUS"]}
              stickyContent={<StickyContent />}
              currentPage={handlePaginate}
              itemsTo={itemsTo}
              itemsFrom={itemsFrom}
              itemsPerPage={itemsPerPage}
              onChangeLimit={(value) => handleLimit(value)}
              onClearFilter={(e) => handleClearFilter()}
              totalItems={totalItems}
              totalPages={Math.ceil(totalItems / itemsPerPage)}
              stickyHeaderClass="h-[52px]"
              headers={[
                "FULL NAME",
                "EMAIL",
                "DEPARTMENT",
                "JOB POSITION",
                "ACCESS",
              ]}
              content={<TableContent />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagementPage;
