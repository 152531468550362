import React from "react";
import { ExclamationTriangleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { CircleStackIcon, CommandLineIcon } from "@heroicons/react/24/solid";
import BtnSpinner from "../../../../Component/BtnSpinner";
function QuotaExceedPopup(props) {
    const { onConfirm, onClose, candidateJob, unlockReport, unlocking = false } = props;

    // const handleConfirm = (value = false) => {
    //     if (typeof onConfirm === "function") {
    //         onConfirm(value);
    //     }
    // };
    const handleClose = (value = false) => {
        if (typeof onClose === "function") {
            onClose(value);
        }
    };
    return (
        // Your alert popup component JSX
        <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate  h-[400px] py-2 flex flex-col px-3 py-3">
            <div className="flex justify-end">
                <button
                    className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
                    onClick={() => {
                        handleClose()
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div className="flex flex-col items-center justify-evenly h-full">
                <CircleStackIcon
                    width={"80px"}
                    className="text-main-violate"
                />
                <div className="text px-2">
                    <p className="font-bold text-center text-lg mb-4">
                        Want to read this report?
                    </p>
                    <p className="text-center text-base text-gray-500">
                        {/* Get more reports, tests, job roles and <b className="text-main-violate">more features by buying tokens or upgrading your package!</b><br /><br /><b>Please contact our sales team.</b> */}
                        Get more reports, tests, job roles and <b className="text-main-violate">more features by buying tokens or upgrading your package!</b><br /><br /><b>Your available candidate quota : {candidateJob.subscription.quota_candidate}</b>
                    </p>
                </div>

                <div className="flex mt-5 justify-center w-[100%] gap-4">
                    <button
                        className="hover:bg-violet-600 cursor-pointer bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[42px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed flex items-center justify-center"
                        onClick={() => {
                            if (candidateJob.subscription.quota_candidate > 0) {
                                unlockReport()
                            }
                        }}
                        disabled={unlocking || candidateJob.subscription.quota_candidate <= 0}
                    >
                        {unlocking ? <BtnSpinner /> : <>
                            Open This Report (<CircleStackIcon width={"18px"} className="text-white mr-1" /> 1)
                        </>}
                    </button>

                </div>
            </div>
        </div>

    );
}

export default QuotaExceedPopup;
