import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RectangleStackIcon } from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import {
  documentTitle,
  inputIsOnlyAlphabetic,
  inputIsPhoneNumberWithCode,
  inputIsUseranme,
} from "../../../../helpers";
import BtnSpinner from "../../../../Component/BtnSpinner";
import { getCompanies } from "../../../../api/admin/companyApi";
import Select from "react-select";
import { getClient, updateClientUser } from "../../../../api/admin";
import PageSpinner from "../../../../Component/PageSpinner";

const EditPage = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [editable, setEditable] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    company_id: "",
    username: "",
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
    phonenumber: "+62",
    base_role: "owner",
    status: 1,
  });
  const [formPassed, setFormPassed] = useState(true);
  const [companies, setCompanies] = useState([]);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const fetchClient = async () => {
    const result = await getClient(id);
    if (result.success) {
      setForm({ ...form, ...result.data, company_id: result.data.company.id });
      setSelectedCompany({
        value: result.data.company.id,
        label: result.data.company.name,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const result = await updateClientUser(form);
    if (result.success) {
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setEditable(false);
    setSubmitting(false);
    fetchCompanies();
  };

  const fetchCompanies = async () => {
    setLoading(true);
    const result = await getCompanies();
    if (result.success) {
      setCompanies(result.data.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setLoading(false);
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Detail User");
    fetchClient();
    fetchCompanies();
  }, []);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <RectangleStackIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Client User Detail</p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/client/users",
                title: "Client",
              },
              {
                href: null,
                title: "Client User Detail",
              },
            ]}
          />
        </div>
      </div>

      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="card flex flex-col w-[50%]">
          <div className="flex justify-between w-full mb-4">
            <div className="flex items-center justify-between w-full">
              <p className="text-xl text-grey-600 font-bold mb-2">
                Detail User
              </p>
              <button
                className="border border-main-violate px-4 text-[14px] h-[35px] text-main-violate font-semibold rounded-lg hover:bg-violet-100 disabled:bg-violet-300 disabled:cursor-not-allowed"
                onClick={(e) => {
                  e.preventDefault();
                  setEditable(!editable);
                }}
              >
                {editable ? "Cancel" : "Edit"}
              </button>
            </div>
          </div>
          <div className="flex space-x-8 mb-4">
            {loading && (
              <div className="flex justify-center w-full">
                <PageSpinner />
              </div>
            )}
            {!loading && (
              <div className="w-[100%] flex space-x-4">
                <div className="w-[100%] flex flex-col space-y-4">
                  <div>
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Company
                    </label>
                    <div className="mt-2 border border-line-grey rounded-lg flex items-center">
                      <Select
                        className="w-full "
                        classNamePrefix={"select"}
                        options={companies.map((option) => {
                          return {
                            value: option.id,
                            label: option.name,
                          };
                        })}
                        value={selectedCompany}
                        onChange={(e) => {
                          setSelectedCompany(e);
                          setForm({ ...form, company_id: e.value });
                        }}
                        isDisabled={true}
                        placeholder={"Select Company"}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Username
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Input username"
                      value={form.username}
                      onChange={(e) => {
                        if (inputIsUseranme(e.target.value)) {
                          setForm({ ...form, username: e.target.value });
                        }
                      }}
                      disabled={!editable}
                      required
                    />
                  </div>
                  <div>
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Fullname
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Input text"
                      value={form.name}
                      onChange={(e) => {
                        if (inputIsOnlyAlphabetic(e.target.value)) {
                          setForm({ ...form, name: e.target.value });
                        }
                      }}
                      disabled={!editable}
                      required
                    />
                  </div>
                  <div>
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Email
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Input email"
                      value={form.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                      disabled={!editable}
                      required
                    />
                  </div>
                  <div>
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Phonenumber
                    </label>
                    <input
                      // ref={this.jobRoleInputRef}
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="phonenumber"
                      id="phonenumber"
                      placeholder="Input text"
                      value={form.phonenumber}
                      disabled={!editable}
                      onChange={(e) => {
                        if (inputIsPhoneNumberWithCode(e.target.value)) {
                          setForm({ ...form, phonenumber: e.target.value });
                        } else {
                          setForm({ ...form, phonenumber: "+62" });
                        }
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-end mt-6">
            {editable && (
              <>
                {submitting ? (
                  <button
                    className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    type=""
                    disabled={true}
                  >
                    <BtnSpinner />
                  </button>
                ) : (
                  <button
                    className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    type="submit"
                    disabled={!formPassed}
                  >
                    Save
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditPage;
