import axios from "axios";

export const getArticleContents = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/article/contents?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getArticleContent = async (id = null) => {
  try {
    const response = await axios.get(`/api/admin/article/contents/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createArticleContent = async (form = null) => {
  try {
    const response = await axios.post(`/api/admin/article/contents`, form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const createArticleTopic = async (form = null) => {
  try {
    const response = await axios.post(`/api/admin/article/categories`, form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateArticleTopic = async (id, form = null) => {
  try {
    const response = await axios.put(
      `/api/admin/article/categories/${id}`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteArticleCategory = async (id) => {
  try {
    const response = await axios.delete(`/api/admin/article/categories/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateArticleContent = async (id = null, form = null) => {
  try {
    const response = await axios.put(
      `/api/admin/article/contents/${id}`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateArticlePublish = async (id, status) => {
  try {
    const response = await axios.patch(
      `/api/admin/article-publish/${id}/${status}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getArticleCategories = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/article/categories?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getArticleCategory = async (id) => {
  try {
    const response = await axios.get(`/api/admin/article/categories/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
