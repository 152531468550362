import React, { Component } from "react";

class MultipleChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: {
        id: "",
        order: "",
        content: "",
        context: "",
        options: [],
      },
      selectedId: null,
    };
  }

  componentDidMount() {
    this.setState({ question: this.props.question });
  }

  handleClick(id) {
    this.setState({ selectedId: id });
  }

  render() {
    // const { question } = this.props;
    return (
      <div className="bg-white max-h-[580px] 2xl:max-h-[820px] xl:max-h-[720px] lg:max-h-[680px] md:max-h-[620px]  rounded-lg  w-full px-6 py-6">
        <div className="flex space-x-2">
          <p>{this.state.question.order}. </p>
          <div>
            {this.state.question.content && (
              <>
                <p className="text-grey-900 text-base font-semibold mb-2">
                  {this.state.question.content}
                </p>
              </>
            )}
            {this.state.question.context && (
              <>
                <p className="text-grey-900 text-base font-semibold mb-2">
                  {this.state.question.context}
                </p>
              </>
            )}
          </div>
        </div>
        <div className="max-w-[720px] mt-4">
          <p className="text-grey-900 text-base">
            {this.state.question.options.map((opt) => (
              <div className="flex items-center mb-3 no-select">
                <input
                  id={"q-" + this.state.question.id + "option-" + opt.id}
                  type="radio"
                  name={"question" + this.state.question.id}
                  value={opt.id}
                  className="w-4 h-4 text-main-violate bg-gray-100 border-gray-300 focus:ring-main-violate dark:focus:ring-main-violate dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor={"q-" + this.state.question.id + "option-" + opt.id}
                  className="ml-2 text-[16px] leading-[140%] "
                >
                  {opt.value}
                </label>
              </div>
            ))}
          </p>
        </div>
      </div>
    );
  }
}

export default MultipleChoice;
