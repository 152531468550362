import axios from 'axios';

export const getSummaries = async () => {
    try {
        const response = await axios
            .get(`/api/admin/dashboard/summaries`, {
                headers: {
                    "Content-Type": "application/json"
                },
            })
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};