import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  PresentationChartLineIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/solid";
import PaginationTable from "../../../Component/Tables/PaginationTable";
import axios from "axios";
import TableSpinner from "../../../Component/TableSpinner";
import EmptyContent from "../../../Component/Tables/EmptyContent";
import FilterTable from "../../../Component/Tables/FilterTable";
import { getReportsCandidate, reportsExport } from "../../../api";
import BtnSpinner from "../../../Component/BtnSpinner";
import BreadCrumb from "../../../Component/BreadCrumb";
import { documentTitle } from "../../../helpers";

const MyCandidatesPage = () => {
  let navigate = useNavigate();
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  const { job_id } = useParams();
  const [downloading, setDownloading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [tableCandidateLoading, setTableCandidateLoading] = useState(1);
  const [candidates, setCandidates] = useState([]);
  const [fillterJobs, setFilterJobs] = useState([]);
  const [fileFormat, setFileFormat] = useState("");
  const [checkAll, setCheckAll] = useState(false);

  const [job, setJob] = useState({
    job_position: "",
    job_role: "",
    job_department: "",
  });

  const getMyCandidates = async (page = 1, itemsPerPage = 5) => {
    setTableCandidateLoading(true);
    setCheckAll(false);
    setSelected([]);
    const result = await getReportsCandidate(job_id, page, itemsPerPage);
    if (result.success) {
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setCandidates(result.data.data);
      setTableCandidateLoading(false);
      setFilterJobs(result.additional.filter.job_role);
    }
  };

  const handleFilterJob = async (value) => {
    navigate(`/client/reports/candidate-list/${value}`);
    setSelected([]);
  };
  // const handleFilterMaxScore = async (value) => {
  //     alert(value);
  // }

  // const handleFilterMinScore = async (value) => {
  //     alert(value);
  // }

  const handleSelected = (candidate_id) => {
    if (selected.includes(candidate_id)) {
      setSelected(selected.filter((item) => item !== candidate_id));
    } else {
      setSelected([...selected, candidate_id]);
    }
  };

  const handleDownload = async (e) => {
    e.preventDefault();
    setDownloading(true);
    const result = await reportsExport(job_id, {
      type: fileFormat,
      candidate_job_ids: selected,
    });
    setDownloading(false);
  };

  const getDetailJob = async () => {
    setLoading(true);
    await axios
      .get(`/api/client/reports/${job_id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setJob(result.data);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMyCandidates();
    getDetailJob();
  }, [job_id]);

  const handleLimit = (value) => {
    // setItemsPerPage(value);
    getMyCandidates(currentPage, value);
  };

  const handlePaginate = (page) => {
    setSelected([]);
    setCheckAll(false);
    getMyCandidates(page, itemsPerPage);
  };

  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);
  };
  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0 || status == 3) {
      return (
        <p className="bg-red-100 text-red-800 font-medium text-center text-xs py-1 px-1 rounded text">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 px-1 rounded text">
          {label}
        </p>
      );
    } else if (status == 2) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 px-1 rounded text">
          {label}
        </p>
      );
    }
  };

  const StickyContent = () => {
    if (!tableCandidateLoading) {
      return (
        <>
          {candidates.length > 0 ? (
            <>
              {candidates.map((candidate, index) => {
                return (
                  <tr className="whitespace-nowrap border-b-[1px]">
                    <td className="px-6 py-[14px]">
                      <div className="text-sm text-gray-500">
                        <StatusLabel
                          status={candidate.status}
                          label={candidate.status_label}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  const TableCandidateContent = () => {
    if (tableCandidateLoading) {
      return <TableSpinner colSpan={"100%"} />;
    } else {
      return (
        <>
          {candidates.length > 0 ? (
            <>
              {candidates.map((candidate, index) => {
                return (
                  <tr className="whitespace-nowrap border-b-[1px]">
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {candidate.status == 2 && (
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                          type="checkbox"
                          checked={selected.includes(candidate.id)}
                          onChange={() => handleSelected(candidate.id)}
                          key={index}
                        />
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {itemsPerPage * (currentPage - 1) + index + 1}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.fullname}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.email}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.previous_title
                          ? candidate.previous_title
                          : "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.university ? candidate.university : "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.education_level
                          ? candidate.education_level
                          : "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.education_major
                          ? candidate.education_major
                          : "-"}
                      </div>
                    </td>
                    {/* <td class="px-6 py-4">
                        <div class="text-sm text-gray-500">{candidate.score ? candidate.score : "-"}</div>
                    </td> */}
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              <EmptyContent />
            </>
          )}
        </>
      );
    }
  };

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    if (checkAll) {
      setSelected(
        candidates
          .map((candidate) => {
            if (candidate.status == 2) {
              return candidate.id;
            }
          })
          .filter((id) => id !== undefined)
      );
    } else {
      setSelected([]);
    }
  }, [checkAll]);

  useEffect(() => {
    documentTitle("Detail Reports");
  }, []);
  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <PresentationChartLineIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Candidate List</p>
          <BreadCrumb
            list={[
              { href: "/client/reports", title: "Reports" },
              { href: null, title: "Candidate List" },
            ]}
          />
        </div>
      </div>
      <div className="candidate-list">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-8">
            {loading ? (
              ""
            ) : (
              <>
                <div className="flex flex-col">
                  <p className="text-xl text-grey-600 font-bold mb-2">
                    {job.job_position.name}
                  </p>
                  {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
                </div>
                <div className="flex flex-col">
                  <p className="text-xl text-grey-600 font-bold mb-2 text-right">
                    {job.job_role}
                  </p>
                  <p className="text-lg text-gray-400 font-medium mb-2 text-right">
                    {job.job_department.name}
                  </p>
                  {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
                </div>
              </>
            )}
          </div>
          <div className="border-b border-gray-200 shadow">
            <PaginationTable
              filters={[
                <FilterTable
                  placeholder={"Select Job"}
                  options={fillterJobs}
                  onChange={(value) => handleFilterJob(value)}
                />,
                // <FilterTable placeholder={"Min Score"} options={filterScore} onChange={(value) => handleFilterMinScore(value)} />,
                // <FilterTable placeholder={"Max Score"} options={filterScore} onChange={(value) => handleFilterMaxScore(value)} />
              ]}
              onCheckAll={(e) => {
                handleCheckAll(e);
                // if (e.target.checked) {
                //   setSelected(candidates.map((candidate) => candidate.id));
                // } else {
                //   setSelected([]);
                // }
              }}
              stickyHeaderClass="h-[52px]"
              isSticky={true}
              stickyHeader={["STATUS"]}
              stickyContent={<StickyContent />}
              checkAll={checkAll}
              checkbox={true}
              currentPage={handlePaginate}
              itemsTo={itemsTo}
              itemsFrom={itemsFrom}
              itemsPerPage={itemsPerPage}
              onChangeLimit={(value) => handleLimit(value)}
              totalItems={totalItems}
              totalPages={Math.ceil(totalItems / itemsPerPage)}
              headers={[
                "NO",
                "FULLNAME",
                "EMAIL",
                "PREVIOUS TITLE",
                "UNIVERSITY",
                "EDU. LEVEL",
                "EDU. MAJOR",
              ]}
              content={<TableCandidateContent />}
            />
          </div>
          <div className="mt-4 flex justify-end gap-4">
            <select
              className="form-select appearance-none block pl-5 pr-3 py-2 text-grey-900 text-[14px] bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 text-[12px] focus:border-main-violate"
              aria-label="Default select example"
              name="filter"
              value={fileFormat}
              onChange={(e) => setFileFormat(e.target.value)}
              required
            >
              <option selected disabled value="">
                File Format
              </option>
              <option selected value="excel">
                Excel (.xlsx)
              </option>
              <option selected value="pdf" disabled>
                PDF (.pdf)
              </option>
            </select>
            <button
              className=" bg-main-violate px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed flex gap-2 items-center"
              type="button"
              disabled={selected.length == 0 || fileFormat == "" || downloading}
              onClick={(e) => {
                handleDownload(e);
              }}
            >
              {downloading ? (
                <BtnSpinner />
              ) : (
                <>
                  <CloudArrowDownIcon width={16} /> Download
                </>
              )}
            </button>
          </div>
          {/* <UnderDevelopment /> */}
        </div>
      </div>
    </div>
  );
};

export default MyCandidatesPage;
