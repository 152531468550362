import { React, useState, useEffect } from "react";
import ProgressCircle from "../../../../Component/ProgressCircle";
import BtnSpinner from "../../../../Component/BtnSpinner";
const OverviewProgress = ({ summaries, summaryLoading }) => {
  return (
    <div className="flex w-[100%] flex-wrap">
      <div className="card-mini flex space-x-2 bg-white rounded-[12px] w-[100%] mdb:w-[50%] xl:w-[25%]">
        <ProgressCircle
          value={(
            (summaries?.active_clients / summaries?.total_clients) *
            100
          ).toFixed(0)}
        />
        <div className="flex flex-col justify-center ">
          <p className="text-grey-600 mb-1 font-semibold">Active Clients</p>
          <div className="flex">
            {summaryLoading && <BtnSpinner />}
            {!summaryLoading && (
              <>
                <p className="text-xl mr-1">{summaries?.active_clients}</p>
                <small className="relative top-[12px]">
                  from {summaries?.total_clients} clients
                </small>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="card-mini flex space-x-2 bg-white rounded-[12px] w-[100%] mdb:w-[50%] xl:w-[25%]">
        <div className="flex-0">
          <ProgressCircle
            value={(
              (summaries?.on_progress_candidates /
                summaries?.finished_candidates) *
              100
            ).toFixed(0)}
          />
        </div>

        <div className="flex flex-col justify-center flex-1">
          <p className="text-grey-600 mb-1 font-semibold content-title overflow-hidden overflow-ellipsis  ">
            Finished Candidates
          </p>
          <div className="flex">
            {summaryLoading && <BtnSpinner />}
            {!summaryLoading && (
              <>
                <p className="text-xl mr-1">{summaries?.finished_candidates}</p>
                <small className="relative top-[12px]">
                  from {summaries?.on_progress_candidates} progress candidates
                </small>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="card-mini flex space-x-2 bg-white rounded-[12px] w-[100%] mdb:w-[50%] xl:w-[25%]">
        <ProgressCircle
          value={(
            (summaries?.active_packages / summaries?.total_packages) *
            100
          ).toFixed(0)}
        />
        <div className="flex flex-col justify-center">
          <p className="text-grey-600 mb-1 font-semibold">Active Packages</p>
          <div className="flex">
            {summaryLoading && <BtnSpinner />}
            {!summaryLoading && (
              <>
                <p className="text-xl mr-1">{summaries?.active_packages}</p>
                <small className="relative top-[12px]">
                  from {summaries?.total_packages} packages
                </small>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="card-mini flex space-x-2 bg-white rounded-[12px] w-[100%] mdb:w-[50%] xl:w-[25%]">
        <ProgressCircle
          value={(
            (summaries?.active_jobs / summaries?.total_jobs) *
            100
          ).toFixed(0)}
        />
        <div className="flex flex-col justify-center">
          <p className="text-grey-600 mb-1 font-semibold">Active Jobs</p>
          <div className="flex">
            {summaryLoading && <BtnSpinner />}
            {!summaryLoading && (
              <>
                <p className="text-xl mr-1">{summaries?.active_jobs}</p>
                <small className="relative top-[12px]">
                  from {summaries?.total_jobs} jobs
                </small>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewProgress;
