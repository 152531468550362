import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { TextInput } from 'flowbite-react';
import { Component } from 'react'
import { textInputTheme } from '../../themes/flowbite-theme';

class SearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeholder: "Search ...",
        };
    }

    handleSearch = (e) => {
        if (e.key === "Enter") {
            this.props.onSearch(e.target.value)
        }
    }
    render() {
        const { placeholder, options, onSearch, search } = this.props
        return (
            <>
                <div className='mt-5 mdb:mb-5 px-3 mdb:py-2 w-full'>
                    <TextInput
                        icon={MagnifyingGlassIcon}
                        theme={textInputTheme}
                        type="text"
                        name="search"
                        value={search}
                        onKeyDown={(e) => {
                            // e.preventDefault()
                            this.handleSearch(e)
                        }}
                        onChange={(e) => this.handleSearch(e.target.value)}
                        placeholder={placeholder ? placeholder : "Search"}
                    />
                </div>
            </>
        )
    }

}

export default SearchTable;