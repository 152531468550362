import ReactDOM from "react-dom/client";
import "tw-elements";
import "./index.css";
import App from "./App";
import axios from "axios";
import store from "./store";
// import * as serviceWorker from './swlistener';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
const path = window.location.pathname; // Get the current URL path
const parts = path.split("/"); // Split the path into an array of parts
const firstPart = parts[1];

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
if (firstPart === "administrator") {
  axios.defaults.headers.common["Authentication"] =
    "$2y$10$lZ4Ir9Wrcd2fvcqcBx3op.JrQpsLxfbNWVrxOSSucMNKjDnnDcgHa";
} else {
  axios.defaults.headers.common["Authentication"] =
    "$2y$10$ix0u7vYSAzYRs2QNOj0lceiogl.6.LtK2r0No2mjhOAZLELWKsPKm";
}
axios.defaults.headers.common["Authorization"] =
  "user" in localStorage
    ? "Bearer " + JSON.parse(localStorage.getItem("user")).token
    : "";
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      window.location.href = "/login";
      //   const messages = error.response.data.message;
      //   const collapsed = Object.values(messages).reduce(
      //     (acc, curr) => acc.concat(curr),
      //     []
      //   );
    } else {
      return error.response;
    }
  }
);
// axios.interceptors.response.use(
//     response => response,
//     error => error.response
//     if(error.response.status === 422){
//         const messages = error.response.data.message;
//         console.log(messages);
//         const collapsed = Object.values(messages).reduce((acc, curr) => acc.concat(curr), []);
//     }

// );

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorkerRegistration.register();
reportWebVitals();
