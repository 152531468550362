import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UnderDevelopment } from "../../../../Component/Errors/UnderDevelopment";
import {
  BuildingOffice2Icon,
  InformationCircleIcon,
  UserCircleIcon,
  UserIcon,
  ChartPieIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import PaginationTable from "../../../../Component/Tables/PaginationTable";
import SearchTable from "../../../../Component/Tables/SearchTable";
import FilterTable from "../../../../Component/Tables/FilterTable";
import TableSpinner from "../../../../Component/TableSpinner";
import EmptyContent from "../../../../Component/Tables/EmptyContent";
import { documentTitle } from "../../../../helpers";
import { getMyClient } from "../../../../api/admin";
import DropdownDots from "../../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import { getCompanies } from "../../../../api/admin/companyApi";
import { getReportCompanies } from "../../../../api/admin/reportsApi";

const CompanyList = () => {
  let navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [companies, setCompanies] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  //   const [filterCompany, setFilterCompany] = useState([]);
  //   const [filterRole, setFilterRole] = useState([]);
  const [selectedFilterCompany, setSelectedFilterCompany] = useState(null);
  const [selectedFilterRole, setSelectedFilterRole] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleFilterRole = (id) => {
    setQuery({ ...query, role: id });
    setSelectedFilterRole(id);
  };

  const handleFilterCompany = (id) => {
    setQuery({ ...query, company: id });
    setSelectedFilterCompany(id);
  };

  const fetchCompanies = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getReportCompanies(queryString);
    if (result.success) {
      setCompanies(result.data.data);
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setItemsPerPage(result.data.per_page);
      setTableLoading(false);
      //   setFilterCompany(result.additional.filter.companies);
      //   setFilterRole(result.additional.filter.roles);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSelectedFilterCompany("");
    setSelectedFilterRole("");
    setQuery({ ...query, company: "", role: "" });
  };

  useEffect(() => {
    documentTitle("Users Management");
  }, []);

  useEffect(() => {
    fetchCompanies();
  }, [query]);

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {companies.length > 0 ? (
            companies.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.email}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.active_packages?.length}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.total_candidates}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.total_user}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <StatusLabel
                      status={value.active}
                      label={value.active ? "Active Hiring" : "Not Hiring"}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <DropdownDots isPublish={value.is_publish}>
                      <Dropdown.Item
                        onClick={(e) => {
                          navigate(`/administrator/report/clients/${value.id}`);
                        }}
                      >
                        <InformationCircleIcon width={16} className="mr-2" />
                        Detail
                      </Dropdown.Item>
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div className="">
      <ToastContainer />
      <PaginationTable
        search={
          <SearchTable
            onSearch={(keyword) => handleSearch(keyword)}
            placeholder={"Search company name"}
          />
        }
        // filters={[
        //   <FilterTable
        //     placeholder={"Select Company"}
        //     selected={selectedFilterCompany}
        //     options={filterCompany}
        //     onChange={(value) => handleFilterCompany(value)}
        //   />,
        //   <FilterTable
        //     placeholder={"Select Role"}
        //     selected={selectedFilterRole}
        //     options={filterRole}
        //     onChange={(value) => handleFilterRole(value)}
        //   />,
        // ]}
        onClearFilter={(e) => handleClearFilter(e)}
        currentPage={handlePaginate}
        itemsTo={itemsTo}
        itemsFrom={itemsFrom}
        itemsPerPage={5}
        checkbox={false}
        totalItems={totalItems}
        totalPages={Math.ceil(totalItems / itemsPerPage)}
        headers={[
          "NO.",
          "COMPANY NAME",
          "COMPANY EMAIL",
          "ACTIVE PACKAGE",
          "TOTAL CANDIDATE",
          "ACTIVE USER",
          "STATUS",
          "ACTION",
        ]}
        content={<TableContent />}
      />
    </div>
  );
};

export default CompanyList;
