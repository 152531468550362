import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BriefcaseIcon,
  BuildingOffice2Icon,
  CalculatorIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../Component/BreadCrumb";
import { documentTitle } from "../../../helpers";
import PageSpinner from "../../../Component/PageSpinner";
import {
  getAssessmentTest,
  getAssessmentTestDetail,
  getTestQuestions,
} from "../../../api/admin/assessmentApi";
import PersonalityA from "../../../Component/QuestionFields/PersonalityA";
import MultipleChoice from "../../../Component/QuestionFields/MultipleChoice";

const DetailPage = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [query, setQuery] = useState({});
  const [test, setTest] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionLoading, setQuestionLoading] = useState();
  const [testLoading, setTestLoading] = useState(1);

  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const fetchTest = async () => {
    setTestLoading(true);
    const result = await getAssessmentTestDetail(id);
    if (result.success) {
      setTest(result.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setTestLoading(false);
  };
  const fetchQuestion = async () => {
    setQuestionLoading(true);
    // const queryString = new URLSearchParams(query).toString();
    const result = await getTestQuestions(id);
    if (result.success) {
      setQuestions(result.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setQuestionLoading(false);
  };

  // const selectedOption = (id = '') => {
  //   questions
  // }

  useEffect(() => {
    documentTitle("Detail Test");
  }, []);

  useEffect(() => {
    fetchTest();
    fetchQuestion();
  }, [query]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <ClipboardDocumentListIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Detail Test</p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/assessments",
                title: "Assessment & Test",
              },
              { href: null, title: "Test Detail" },
            ]}
          />
        </div>
      </div>
      <div className="card mb-6">
        {testLoading && <PageSpinner />}
        {!testLoading && (
          <>
            <p className="text-xl font-bold mb-6">{test.name}</p>
            <div className="flex mb-6 space-x-2">
              <span className="text-pink-600 bg-pink-100 flex w-max-content px-2 py-1 rounded-lg text-sm">
                <CalculatorIcon className="mr-2 text-sm" width={16} />{" "}
                {test.assessment.name}
              </span>
              <span className="bg-main-violate-100 text-humata-purple-500 flex w-max-content px-2 py-1 rounded-lg text-sm">
                <UserGroupIcon className="mr-1 text-sm" width={16} />{" "}
                {test.total_finished_candidate} Candidates
              </span>
              <span className="bg-blue-100 text-blue-600 flex w-max-content px-2 py-1 rounded-lg text-sm">
                <BriefcaseIcon className="mr-1 text-sm" width={16} />{" "}
                {test.total_job_role} Job Roles
              </span>
              <span className="bg-yellow-100 text-yellow-400 flex w-max-content px-2 py-1 rounded-lg text-sm">
                <ClockIcon className="mr-1 text-sm" width={16} />{" "}
                {(test.total_times / 60).toFixed(0)} Minutes
              </span>
              <span className="bg-indigo-100 text-indigo-500 flex w-max-content px-2 py-1 rounded-lg text-sm">
                <QuestionMarkCircleIcon className="mr-1 text-sm" width={16} />{" "}
                {test.total_question} Questions
              </span>
            </div>
            <p className="text-lg font-semibold mb-3">Description</p>
            <p className="text-md">{test.description}</p>
          </>
        )}
      </div>
      <div className="card mb-6 max-h-[720px] overflow-y-auto">
        {questionLoading && <PageSpinner />}
        {!questionLoading && (
          <>
            {questions.map((question) => {
              if (question.type == 1) {
                return <PersonalityA question={question} />;
              } else if (question.type == 3) {
                return <MultipleChoice question={question} />;
              }
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default DetailPage;
