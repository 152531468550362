import axios from 'axios';
import { toast } from 'react-toastify';

export const getSubscriptionTransaction = async (queryString = '') => {
    try {
        const response = await axios
            .get(`/api/client/transaction/company-subscription?${queryString}`, {
                headers: {
                    "Content-Type": "application/json"
                },
            })
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
