import axios from "axios";
import React from "react";
import { PuzzlePieceIcon } from "@heroicons/react/24/solid"
import PageSpinner from "../../../../../../Component/PageSpinner";
const SkillSection = ({ loading = true, skill }) => {
  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <PuzzlePieceIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Skills & Specializations
            </h4>
          </div>
        </div>

        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : skill?.soft_skills != null ? (
          <>
            <div className="mt-5 mb-3 px-5">
              <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                Soft Skill
              </label>
              <div className="mt-4">
                <input
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg border-inherit"
                  type="text"
                  name="phone"
                  id="phone"
                  value={skill?.soft_skills }
                  placeholder="Soft Skill"
                  disabled
                />
              </div>
            </div>
          </>
        ) : (
          <div className="mt-5 mb-3 px-5">
            <p className="font-semibold text-slate-400 text-center">
              <i>No Data</i>{" "}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SkillSection;
