import React from "react";
import { useState, useEffect } from "react";
const Legends = ({ data }) => {
  return (
    <div className="box-legend flex items-end justify-end h-[40px] mdb:items-center mb-2 w-full flex-col mdb:flex-row">
      <div className="mb-1">
        <p className="text-gray-500 text-sm font-semibold mr-4">
          Candidate score
        </p>
      </div>
      <div className="flex">
        <div className="flex items-center mr-4">
          <span className="bg-green-500 h-[16px] w-[16px] rounded-full mr-2"></span>
          <p className="text-gray-500 text-sm font-semibold">High</p>
        </div>
        {/* <div className="flex items-center mr-4">
                <span className="bg-green-400 h-[16px] w-[16px] rounded-full mr-2"></span>
                <p className="text-gray-500 text-sm font-semibold">Medium</p>
            </div> */}
        <div className="flex items-center mr-4">
          <span className="bg-yellow-300 h-[16px] w-[16px] rounded-full mr-2"></span>
          <p className="text-gray-500 text-sm font-semibold">Medium</p>
        </div>
        {/* <div className="flex items-center mr-4">
                <span className="bg-orange-secondary-400 h-[16px] w-[16px] rounded-full mr-2"></span>
                <p className="text-gray-500 text-sm font-semibold">Lower</p>
            </div> */}
        <div className="flex items-center mr-4">
          <span className="bg-red-500 h-[16px] w-[16px] rounded-full mr-2"></span>
          <p className="text-gray-500 text-sm font-semibold">Low</p>
        </div>
        <div className="flex items-center mr-4">
          <span className="bg-gray-200 h-[16px] w-[16px] rounded-full mr-2"></span>
          <p className="text-gray-500 text-sm font-semibold">Benchmark</p>
        </div>
      </div>
    </div>
  );
};

export default Legends;
