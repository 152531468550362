import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  RectangleStackIcon,
  BuildingOfficeIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../Component/BreadCrumb";
import { Avatar, Dropdown } from "flowbite-react";
import { documentTitle, inputIsPhoneNumberWithCode } from "../../../helpers";
import BtnSpinner from "../../../Component/BtnSpinner";
import { createCompany, getCompany } from "../../../api/admin/companyApi";
import moment from "moment";
import { getLandingInqury, updateLandingInquiry } from "../../../api/admin";
import PageSpinner from "../../../Component/PageSpinner";

const DetailPage = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [inquiry, setInquiry] = useState(null);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const confirmInquiry = async (status) => {
    setLoading(true);
    const result = await updateLandingInquiry(id, { status: status });
    if (result.success) {
      fetchInquiry();
    } else {
      toast.error(result.message, {
        toastId: "fetch-company-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      setLoading(false);
    }
  };
  const fetchInquiry = async () => {
    setLoading(true);
    const result = await getLandingInqury(id);
    if (result.success) {
      setInquiry(result.data);
    } else {
      toast.error(result.message, {
        toastId: "fetch-company-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setLoading(false);
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Inquiry Detail");
    fetchInquiry();
  }, []);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <RectangleStackIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Detail Inquiry </p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/client/users",
                title: "Client",
              },
              {
                href: null,
                title: "Detail Company",
              },
            ]}
          />
        </div>
      </div>

      <div className="card flex flex-col w-[100%]">
        {loading && <PageSpinner />}
        {!loading && (
          <>
            <div className="flex w-full mb-6">
              <div className="flex justify-around space-x-4">
                <p className="text-xl text-gray-900 font-semibold">
                  #{inquiry?.ticket}
                </p>
              </div>
            </div>
            <div className="flex mb-4 flex-col">
              <div className="w-[100%] flex mdb:flex-row flex-col mdb:space-x-4 space-y-4 mdb:space-y-0 mb-4">
                <div className="w-full mdb:w-[50%] flex flex-col space-y-4">
                  <div>
                    <label className="text-gray-800 text-lg font-bold leading-[200%]">
                      Company Name
                    </label>
                    <p className="text-md text-gray-600">
                      {inquiry?.company_name ? inquiry?.company_name : "-"}
                    </p>
                  </div>
                  <div>
                    <label className="text-gray-800 text-lg font-bold leading-[200%]">
                      Position
                    </label>
                    <p className="text-md text-gray-600">
                      {inquiry?.pic_position ? inquiry?.pic_position : "-"}
                    </p>
                  </div>
                  <div>
                    <label className="text-gray-800 text-lg font-bold leading-[200%]">
                      Fullname
                    </label>
                    <p className="text-md text-gray-600">
                      {inquiry?.fullname ? inquiry?.fullname : "-"}
                    </p>
                  </div>
                  <div>
                    <label className="text-gray-800 text-lg font-bold leading-[200%]">
                      Date & Time
                    </label>
                    <p className="text-md text-gray-600">
                      {moment(inquiry.created_at).format("DD MMMM YYYY, HH:mm")}
                    </p>
                  </div>
                </div>
                <div className="w-full mdb:w-[50%] flex flex-col space-y-4">
                  <div>
                    <label className="text-gray-800 text-lg font-bold leading-[200%]">
                      Phonenumber
                    </label>
                    <p className="text-md text-gray-600">
                      {inquiry?.phonenumber ? inquiry?.phonenumber : "-"}
                    </p>
                  </div>
                  <div>
                    <label className="text-gray-800 text-lg font-bold leading-[200%]">
                      Email
                    </label>
                    <div className="flex space-x-2 items-center">
                      <p className="text-md text-gray-600">
                        {inquiry?.email ? inquiry?.email : "-"}
                      </p>
                      <a
                        title="Reply Email"
                        href={`mailto:${inquiry?.email}?Subject=subject message`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-main-violate-600 text-main-violate"
                      >
                        <ArrowTopRightOnSquareIcon className="w-4 text-gray-600 text-main-violate" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[100%] flex mdb:flex-row flex-col mdb:space-x-4 space-y-4 mdb:space-y-0 mb-4">
                <div className="">
                  <label className="text-gray-800 text-lg font-bold leading-[200%]">
                    Status
                  </label>
                  <Dropdown
                    className="bg-white-600"
                    color={"gray"}
                    label={inquiry?.status_label}
                    value={inquiry?.status}
                  >
                    <Dropdown.Item
                      value={0}
                      onClick={() => {
                        confirmInquiry(0);
                      }}
                    >
                      Pending
                    </Dropdown.Item>
                    <Dropdown.Item
                      value={1}
                      onClick={() => {
                        confirmInquiry(1);
                      }}
                    >
                      Replied
                    </Dropdown.Item>
                    <Dropdown.Item
                      value={2}
                      onClick={() => {
                        confirmInquiry(2);
                      }}
                    >
                      Closed
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
              <hr className="mt-8 mb-8" />
              <div className="w-[100%] flex mdb:flex-row flex-col mdb:space-x-4 space-y-4 mdb:space-y-0 mb-4">
                <div className="mdb:w-[50%] w-full flex flex-col space-y-4">
                  <div className="mb-4">
                    <label className="text-gray-800 text-lg font-bold leading-[200%]">
                      Subject
                    </label>
                    <p className="text-md text-gray-600">
                      {inquiry?.subject ? inquiry?.subject : "-"}
                    </p>
                  </div>
                </div>
                <div className="mdb:w-[50%] w-full flex flex-col space-y-4">
                  <div>
                    <label className="text-gray-800 text-lg font-bold leading-[200%]">
                      Message
                    </label>
                    <p className="text-md text-gray-600 bg-gray-100 py-2 px-2 rounded-lg w-full min-h-[180px]">
                      {inquiry?.message ? inquiry?.message : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DetailPage;
