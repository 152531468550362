// jobSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSharedJobDepartments } from "../../api";
const initialState = {
  data: [],
  loading: false,
};

export const fetchJobDepartments = createAsyncThunk(
  "shared/fetchJobDepartments",
  async () => {
    const result = await getSharedJobDepartments();
    return result.data;
  }
);

const jobDepartmentSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchJobDepartments.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchJobDepartments.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchJobDepartments.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// export const { setUser, clearNotifications } = notificationSlice.actions;
export default jobDepartmentSlice.reducer;
