import axios from "axios";

export const getPackages = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/subscription/packages?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getPackageSummaries = async () => {
  try {
    const response = await axios.get(
      `/api/admin/subscription/package-summaries`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getPackageTransaction = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/company-transaction?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createPackageTransaction = async (form = null) => {
  try {
    const response = await axios.post(`/api/admin/company-subscription`, form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateStatusPackageTransaction = async (form = null) => {
  try {
    const response = await axios.patch(
      `/api/admin/company-transaction-status`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
