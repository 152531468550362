import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
const Layout = () => {
  //
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      if (location.pathname == "/administrator/login") {
        window.location.replace("/administrator/dashboard");
      }
      if (location.pathname == "/administrator") {
        window.location.replace("/administrator/dashboard");
      }
    } else {
      if (location.pathname == "/administrator") {
        window.location.replace("/administrator/login");
      }
    }
  }, [location]);
  return (
    <>
      <Outlet />
    </>
  );
};

export default Layout;
