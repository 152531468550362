import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../../Component/PersonalityRadarChart";
import RangeList2 from "../Components/RangeList2";
import PageSpinner from "../../../../../../Component/PageSpinner";
const CommunicateReports = ({ communication }) => {
    const [communicate, setCommunicate] = useState(null);
    const [dataRange, setDataRange] = useState(null);
    useEffect(() => {
        if (communication) {
            let values = [];
            let labels = [];
            let tempDataRange = [];
            communication.data.map((item) => {
                values.push(item.result)
                labels.push(item.name)
                tempDataRange.push({ parameter: item.name, minLabel: item.label2, maxLabel: item.label1, maxRange: "10", minRange: "0", value: item.result })
            })
            setCommunicate({
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: '#B638FF7A',
                        borderColor: '#B638FF',
                        borderWidth: 2,
                        hidden: '',
                    },
                ],
            })
            setDataRange(tempDataRange)
        }
    }, [communication])
    return (
        <div className='flex flex-col items-center'>
            <div className='flex w-full items-center mb-7 mdb:mb-5 gap-10 px-2 mdb:px-4 justify-center flex-col mdb:flex-row'>
                <div className="w-full mdb:w-[40%] flex justify-center">
                    <div className="max-w-[520px]">
                        {communicate ? <PersonalityRadarChart data={communicate} /> : <PageSpinner />}
                    </div>

                </div>
                <div className="w-full mdb:w-[60%]">
                    <p className='font-semibold mb-2'>Communicate</p>
                    <p className="text-gray-500">Cara individu dalam konteks profesional berinteraksi dan bertukar informasi dengan orang lain. Komunikasi yang efektif adalah keterampilan mendasar di tempat kerja, dan mempertahankan standar profesionalisme yang tinggi dalam komunikasi sangat penting untuk membangun hubungan yang positif, mencapai tujuan, dan membina lingkungan kerja yang produktif.</p>
                    {/* <p className="text-gray-500"> the way individuals in a professional context interact and exchange information with others. Effective communication is a fundamental skill in the workplace, and maintaining a high standard of professionalism in communication is crucial for building positive relationships, achieving goals, and fostering a productive work environment.</p> */}
                </div>
            </div>
            {communicate ? <>
                {dataRange.map((item, index) => (
                    <RangeList2 data={item} color="purple" />
                ))}
            </> : <PageSpinner />}

        </div>
    )
}

export default CommunicateReports;