import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BriefcaseIcon } from "@heroicons/react/24/solid"
import axios from 'axios';

import CreateForm from './Form/CreateForm';
import BreadCrumb from '../../../Component/BreadCrumb';
import { documentTitle } from '../../../helpers';

const CreatePage = () => {
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  let navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [roles, setRoles] = useState([]);

  const getDepartments = async () => {
    try {
      const response = await axios.get(
        "/api/client/assessment/job-department-list"
      );
      const result = response.data;
      if (result.success) {
        setDepartments(result.data);
      }
    } catch (e) {
      toast.error(error.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  const getPositions = async () => {
    try {
      const response = await axios.get(
        "/api/client/assessment/job-position-list"
      );
      const result = response.data;
      if (result.success) {
        setPositions(result.data);
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  const getRoles = async () => {
    try {
      const response = await axios.get(
        "/api/client/role-list"
      );
      const result = response.data;
      if (result.success) {
        setRoles(result.data);
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  useEffect(() => {
    documentTitle("Create User")
    getDepartments();
    getPositions();
    getRoles();
  }, [])

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: 'calc(100vh - 80px)',
    marginTop: '-10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflowX: "hidden"
  };

  return (
    <div style={myStyle} className='px-[10px] md:px-[44px] py-[24px]'>
      <ToastContainer />
      <div className='card flex w-full mb-6'>
        <div className='bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6'>
          <BriefcaseIcon className='w-[30px] text-main-violate' />
        </div>
        <div className='flex flex-col justify-around'>
          <p className='text-xl text-grey-600 font-bold'>Create User</p>
          <BreadCrumb list={[{ href: '/client/user-management', title: "User" }, { href: null, title: "Create" }]} />
        </div>
      </div>
      <div className='card mb-6 overflow-x-hidden'>
        <CreateForm onSuccess={(e) => {
          navigate("/client/users-management");
        }} departments={departments} positions={positions} roles={roles} />
      </div>
    </div >
  )
}

export default CreatePage;
