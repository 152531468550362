import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { documentTitle } from "../../../../../helpers";
import BtnSpinner from "../../../../../Component/BtnSpinner";
import { useSelector, useDispatch } from "react-redux";
import { setCategories } from "../../../../../features/admin/assessments/questionTagCategorySlice";

import {
  createQuestionTag,
  createQuestionTagCategory,
  getQuestionTagCategories,
} from "../../../../../api/admin/assessmentApi";
import Select from "react-select";

const MasterForm = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [assessment, setAssessment] = useState(null);
  //   const [categories, setCategories] = useState(null);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [form, setForm] = useState({
    question_tag_category_id: null,
    code: null,
    name: null,
    formula_type: null,
    formula: null,
    min_value: null,
    max_value: null,
    label1: null,
    label2: null,
  });
  const categories = useSelector(
    (state) => state.adminQuestionTagCategory.categories
  );

  const [formPassed, setFormPassed] = useState(true);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const fetchCategories = async () => {
    const result = await getQuestionTagCategories();
    if (result.success) {
      dispatch(setCategories(result));
      //   setCategories(result.data);
    }
    setLoadingCategories(false);
  };

  const handleSubmit = async (e) => {
    setSubmitting(true);
    e.preventDefault();
    const sanitizeForm = {
      ...form,
      question_tag_category_id: form.question_tag_category_id.value,
      formula_type: form.formula_type.value,
    };
    const result = await createQuestionTag(sanitizeForm);
    if (result.success) {
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
      setForm({
        question_tag_category_id: "",
        code: "",
        name: "",
        formula_type: "",
        formula: "",
        min_value: "",
        max_value: "",
        label1: "",
        label2: "",
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    if (form.code && form.name && form.formula_type && form.formula) {
      setFormPassed(true);
    } else {
      setFormPassed(false);
    }
  }, [form.code, form.name, form.formula_type, form.formula]);
  useEffect(() => {
    documentTitle("Create Tagging Master & Category");
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categories) {
      console.log("FROM REDUX ", categories);
    }
  }, [categories]);

  return (
    <>
      <ToastContainer />
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="card flex flex-col w-[100%]">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">
                Tagging Master
              </p>
            </div>
          </div>
          <div className="flex space-x-8 mb-4">
            <div className="w-[100%]">
              <div className="mb-4">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Tagging Category *
                </label>
                {loadingCategories && <BtnSpinner />}
                {!loadingCategories && (
                  <>
                    <Select
                      className="py-0.5 rounded-lg mt-2 border border-line-grey"
                      classNamePrefix={"select"}
                      required
                      value={form.question_tag_category_id}
                      options={categories.map((option) => {
                        return {
                          value: option.id,
                          label: option.name,
                        };
                      })}
                      onChange={(e) => {
                        setForm({ ...form, question_tag_category_id: e });
                      }}
                    />
                  </>
                )}
              </div>
              <div className="mb-4">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Tag Code *
                </label>
                <input
                  // ref={this.jobRoleInputRef}
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Input text"
                  value={form.code}
                  onChange={(e) => setForm({ ...form, code: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Tag Name *
                </label>
                <input
                  // ref={this.jobRoleInputRef}
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Input text"
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Formula Type *
                </label>
                <Select
                  className="py-0.5 rounded-lg mt-2 border border-line-grey"
                  classNamePrefix={"select"}
                  value={form.formula_type}
                  required
                  options={[
                    {
                      label: "Option Tag",
                      value: "option_tag",
                    },
                    {
                      label: "Option Weight",
                      value: "option_weight",
                    },
                  ]}
                  onChange={(e) => {
                    setForm({ ...form, formula_type: e });
                  }}
                />
              </div>
              <div className="mb-4">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Formula *
                </label>
                <input
                  // ref={this.jobRoleInputRef}
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Input Formula."
                  value={form.formula}
                  onChange={(e) =>
                    setForm({ ...form, formula: e.target.value })
                  }
                  required
                />
              </div>
              <div className="mb-4 w-full">
                <div className="flex space-x-2">
                  <div className="w-[50%]">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Minimum Value
                    </label>
                    <input
                      // ref={this.jobRoleInputRef}
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Input Min. Value"
                      value={form.min_value}
                      onChange={(e) =>
                        setForm({ ...form, min_value: e.target.value })
                      }
                    />
                  </div>
                  <div className="w-[50%]">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Maximum Value
                    </label>
                    <input
                      // ref={this.jobRoleInputRef}
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Input Max. Value"
                      value={form.max_value}
                      onChange={(e) =>
                        setForm({ ...form, max_value: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4 w-full">
                <div className="flex space-x-2">
                  <div className="w-[50%]">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Minimum value description
                    </label>
                    <input
                      // ref={this.jobRoleInputRef}
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Input Label / Description for minumum value"
                      value={form.label1}
                      onChange={(e) =>
                        setForm({ ...form, label1: e.target.value })
                      }
                    />
                  </div>
                  <div className="w-[50%]">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Maksimum value description
                    </label>
                    <input
                      // ref={this.jobRoleInputRef}
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Input Label / Description for maximum value"
                      value={form.label2}
                      onChange={(e) =>
                        setForm({ ...form, label2: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            {submitting ? (
              <button
                className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type=""
                disabled={true}
              >
                <BtnSpinner />
              </button>
            ) : (
              <button
                className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={!formPassed}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default MasterForm;
