import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircleStackIcon, IdentificationIcon } from "@heroicons/react/24/solid"
import PaginationTable from '../../../Component/Tables/PaginationTable';
import FilterTable from '../../../Component/Tables/FilterTable';
import EmptyContent from '../../../Component/Tables/EmptyContent';
import TableSpinner from '../../../Component/TableSpinner';
import { ToggleSwitch } from 'flowbite-react';
import SearchTable from '../../../Component/Tables/SearchTable';
import { getSubscriptionTransaction, getUsers, updateClientStatus } from '../../../api';
import BreadCrumb from '../../../Component/BreadCrumb';
import moment from 'moment';
import { documentTitle } from '../../../helpers';

const HistoryPage = () => {

    let navigate = useNavigate();
    const [tableLoading, setTableLoading] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(1);
    const [itemsFrom, setItemsFrom] = useState(1);
    const [itemsTo, setItemsTo] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [filterDepartments, setFilterDepartments] = useState([])
    const [filterPositions, setFilterPositions] = useState([])
    const [query, setQuery] = useState({})
    const [users, setUsers] = useState([]);
    const [confirmStatus, setConfirmStatus] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(false);
    const [mySubscriptions, setMySubscriptions] = useState([]);
    const [currentId, setCurrentId] = useState(null);
    // TEST DEPLOY & BUILD
    const [user] = useState(() => {
        return JSON.parse(localStorage.getItem("user")) || null;
    });

    const handleToggleStatus = async (id, curent_status) => {
        setConfirmStatus(true);
        setCurrentId(id);
        setCurrentStatus(curent_status);
    }

    const handleChangeStatus = async (approve) => {
        setConfirmStatus(false);
        setCurrentId(null);
        setCurrentStatus(null);
        if (approve) {
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.id === currentId ? { ...user, status: !user.status } : user
                )
            );

            await updateClientStatus(currentId);
        }
    }

    const handleFilterPosition = (id) => {
        setQuery({ ...query, position: id });
    }

    const handleFilterDepartment = (id) => {
        setQuery({ ...query, department: id });
    }

    const handlePaginate = (page) => {
        setQuery({ ...query, page: page });
    }

    const handleSearch = (keyword) => {
        setQuery({ ...query, search: keyword });
    }

    const StatusLabel = ({ status = 0 }) => {
        if (status == 0) {
            return (
                <p className='bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2'>
                    Unpaid
                </p>
            )
        } else if (status == 1) {
            return (
                <p className='bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2'>
                    Paid
                </p>
            )
        } else if (status == 2) {
            return (
                <p className='bg-red-100 text-red-800 font-medium text-center text-xs py-1 rounded text px-2'>
                    Failed
                </p>
            )
        } else if (status == 3) {
            return (
                <p className='bg-red-100 text-red-800 font-medium text-center text-xs py-1 rounded text px-2'>
                    Cancelled
                </p>
            )
        }
    }

    // const fetchUsers = async () => {
    //     setTableLoading(true);
    //     const queryString = new URLSearchParams(query).toString();
    //     const result = await getUsers(queryString);
    //     setUsers(result.data.data);
    //     setCurrentPage(result.data.current_page)
    //     setTotalItems(result.data.total)
    //     setItemsPerPage(result.data.per_page)
    //     setItemsFrom(result.data.from)
    //     setItemsTo(result.data.to)
    //     setItemsPerPage(result.data.per_page)
    //     setTableLoading(false);
    // }

    // useEffect(() => {
    //     fetchUsers();
    // }, [query])

    const fetchSubscriptions = async () => {
        setTableLoading(true);
        const result = await getSubscriptionTransaction();
        if (result.success) {
            setMySubscriptions(result.data.data);
            setCurrentPage(result.data.current_page)
            setTotalItems(result.data.total)
            setItemsPerPage(result.data.per_page)
            setItemsFrom(result.data.from)
            setItemsTo(result.data.to)
            setItemsPerPage(result.data.per_page)
            setTableLoading(false);
        } else {
            toast.error(result.message, {
                toastId: "signup-failure-toast",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1250,
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchSubscriptions();
    }, [query])


    const TableContent = () => {
        if (tableLoading) {
            return (
                <TableSpinner colSpan={6} />
            )
        } else {
            return (
                <>
                    {mySubscriptions.length > 0 ?
                        mySubscriptions.map((value, index) => {
                            return (
                                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {value.company_transaction.invoice_number}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {value.price}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {value.quantity}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {value?.subscription_package_log ? value?.subscription_package_log.name : "-"}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        <StatusLabel status={value.company_transaction.status} />
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        {moment(value.created_at).format("DD MMM YYYY, HH:mm")}
                                    </td>
                                </tr>
                            )
                        })
                        : <EmptyContent />}

                </>
            )
        }
    }

    // check if logged in
    useEffect(() => {
        if (!user) {
            navigate("/login", { replace: true });
        }
    });

    useEffect(() => {
        documentTitle("Subscription History")
    }, [])
    
    const myStyle = {
        background: `#F8F8F8`,
        minHeight: 'calc(100vh - 80px)',
        marginTop: '-10px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div style={myStyle} className='px-[10px] md:px-[44px] py-[24px]'>
            <ToastContainer />
            <div className='card flex w-full mb-6'>
                <div className='bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6'>
                    <CircleStackIcon className='w-[30px] text-main-violate' />
                </div>
                <div className='flex flex-col justify-around'>
                    <p className='text-xl text-grey-600 font-bold'>History</p>
                    <BreadCrumb list={[{ href: "/client/subscriptions", title: "Subscriptions" }, { href: null, title: "History" }]} />
                </div>
            </div>

            <div className='report-list'>
                <div className='card flex flex-col w-full'>
                    <div className='flex justify-between w-full mb-8'>
                        <div className='flex flex-col'>
                            <p className='text-xl text-grey-600 font-bold mb-2'>History List</p>
                            {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
                        </div>
                    </div>
                    <div className="border-b border-gray-200 rounded-lg shadow">
                        <PaginationTable checkbox={false} search={<SearchTable onSearch={(keyword) => handleSearch(keyword)} placeholder={"Search service"} />} filters={[<FilterTable placeholder={"Select Date"} options={[]} onChange={(value) => handleFilterDepartment(value)} />, <FilterTable placeholder={"Select Year"} options={[]} onChange={(value) => handleFilterPosition(value)} />]} currentPage={handlePaginate} itemsTo={itemsTo} itemsFrom={itemsFrom} itemsPerPage={5} totalItems={totalItems} totalPages={Math.ceil(totalItems / itemsPerPage)} headers={["INVOICE NUMBER", "AMOUNT", "QUANTITY", "SERVICE", "STATUS", "DATE"]} content={<TableContent />} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default HistoryPage;
