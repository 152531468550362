import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UnderDevelopment } from "../../../Component/Errors/UnderDevelopment";
import { Squares2X2Icon } from "@heroicons/react/24/solid";
import BreadCrumb from "../../../Component/BreadCrumb";
import PageSpinner from "../../../Component/PageSpinner";
import BtnSpinner from "../../../Component/BtnSpinner";
import CircleProgressBar from "../../../Component/CircleProgressBar";
import ProgressCircle from "../../../Component/ProgressCircle";
import { getSummaries } from "../../../api/admin/dashboardApi";
import CompanyList from "./Components/CompanyList";
import moment from "moment";
import OverviewProgress from "./Components/OverviewProgress";

const DashboardProfile = () => {
  let navigate = useNavigate();

  const [summaries, setSummaries] = useState(null);
  const [summaryLoading, setSummaryLoading] = useState(false);

  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const fetchSummaries = async () => {
    setSummaryLoading(true);
    const result = await getSummaries();
    if (result.success) {
      setSummaries(result.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSummaryLoading(false);
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    fetchSummaries();
  }, []);

  const myStyle = {
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <Squares2X2Icon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Admin Dashboard</p>
          <BreadCrumb list={[]} />
        </div>
      </div>
      <div className="summary-stats flex flex-col card-mini mb-6">
        <p className="font-semibold text-xl mb-5">
          Overview{" "}
          <span className="text-main-violate">{moment().format("MMMM Y")}</span>
        </p>
        <OverviewProgress
          summaries={summaries}
          summaryLoading={summaryLoading}
        />
      </div>
      <div className="card flex flex-col w-full mb-6">
        <div className="border-b border-gray-200 rounded-lg shadow">
          <div className="flex justify-between w-full pt-4 px-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold">Clients</p>
            </div>
          </div>
          <CompanyList />
        </div>
      </div>
    </div>
  );
};

export default DashboardProfile;
