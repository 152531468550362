import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../Component/PersonalityRadarChart";
import RangeList from "../RangeList";
import PageSpinner from "../../../../../Component/PageSpinner";

const EmotionalQuotientReports = ({ data }) => {
    const [communicate, setCommunicate] = useState(null);
    const [dataRange, setDataRange] = useState(null);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        if (data) {
            let values = [];
            let labels = [];
            let tempTotal = 0;
            let tempDataRange = [];

            data.data.map((item) => {
                values.push(item.result)
                labels.push(item.name)
                tempTotal += item.result
                tempDataRange.push({ parameter: item.name, minLabel: item.label2, maxLabel: item.label1, maxRange: "10", minRange: "0", value: item.result, code: item.code.toLowerCase() })
            })
            setCommunicate({
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: '#E746947A',
                        borderColor: '#E74694',
                        borderWidth: 2,
                        hidden: '',
                    },
                ],
            })
            setDataRange(tempDataRange)
            setTotal(tempTotal);
        }
    }, [data])

    const StatusLabel = ({ value }) => {
        const percentage = ((value.result) / (value.max_value)) * 100;
        if (percentage <= 33) {
            return (
                <p className='bg-red-100 text-red-800 font-medium text-center py-1 px-2 rounded text max-w-max'>
                    Low
                </p>
            )
        } else if (percentage > 33 && percentage <= 66) {
            return (
                <p className='bg-yellow-100 text-yellow-800 font-medium text-center py-1 px-2 rounded text max-w-max'>
                    Medium
                </p>
            )
        } else if (percentage > 66) {
            return (
                <p className='bg-green-100 text-green-800 font-medium text-center py-1 px-2 rounded text max-w-max'>
                    High
                </p>
            )
        }
    }

    const SummaryLabel = ({ value }) => {
        if (value < 360) {
            return (
                <p className='bg-red-100 text-red-800 font-medium text-center  py-1 px-2 rounded text max-w-max'>
                    Low
                </p>
            )
        } else if (value >= 360 && value <= 540) {
            return (
                <p className='bg-yellow-100 text-yellow-800 font-medium text-center  py-1 px-2 rounded text max-w-max'>
                    Medium
                </p>
            )
        } else if (value > 540) {
            return (
                <p className='bg-green-100 text-green-800 font-medium text-center py-1 px-2 rounded text max-w-max'>
                    High
                </p>
            )
        }
    }

    const summaryCard = {
        // boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: '12px',
        background: '#FFF',
        padding: '24px',
    }

    return (
        <div className='flex flex-col items-center'>
            <div className='flex w-full items-center mb-5 gap-10 px-4 justify-center' id="radar-personality-eq">
                <div className="w-[40%] flex justify-center">
                    <div className="max-w-[520px]">
                        {communicate ? <PersonalityRadarChart maxValue={40} data={communicate} /> : <PageSpinner />}
                    </div>
                </div>
                <div className="w-[70%]">
                    <p className='font-semibold text-[20px] mb-2'>Emotional Quotient</p>
                    <SummaryLabel value={total} />
                    <p className='list-disc list-inside text-gray-500 mt-2 text-[20px]'>Emotional intelligence in recruiting refers to the capability of assessing and managing emotions, both in oneself and others. It involves perceiving candidate&rsquo;s emotional cues, understanding their motivations, and gauging their interpersonal skills. Evaluating emotional intelligence aids in selecting candidates who can navigate diverse workplace scenarios and contribute positively to team dynamics.</p>
                </div>
            </div>
            <div className="w-full grid-cols-1 md:grid-cols-2 grid gap-4" id="tagging-eq">
                {
                    data.data.map((item, index) => (
                        <div style={summaryCard} className=''>
                            <p className='font-semibold mb-2 text-[20px]'>{item.name}</p>
                            <StatusLabel value={item} />
                            <div className={`h-[12px] bg-gray-50 mt-6 w-[80%]`}>
                                <div
                                    className="h-full text-center text-xs text-white bg-pink-400"
                                    style={{
                                        width: `${((item.result) / (item.max_value)) * 100}%`,
                                    }}
                                >
                                </div>
                            </div>
                            <div className="h-[150px]">
                                <p className='text-gray-900 mt-6 text-[18px]'>
                                    <b> {item.name} </b> {item.label1}
                                </p>
                            </div>
                        </div>
                    ))
                }

            </div>
            {/* {communicate ? <>
                {dataRange.map((item, index) => (
                    <RangeList data={item} color="pink" />
                ))}
            </> : <PageSpinner />} */}

        </div>
    )
}

export default EmotionalQuotientReports;