import axios from "axios";

export const getJobPositions = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/job-positions?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
