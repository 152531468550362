import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ClipboardDocumentListIcon,
  RectangleStackIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import { documentTitle } from "../../../../helpers";
import Select from "react-select";
import PageSpinner from "../../../../Component/PageSpinner";
import BtnSpinner from "../../../../Component/BtnSpinner";
import {
  createAssessment,
  createAssessmentTest,
} from "../../../../api/admin/assessmentApi";
import { getJobPositions } from "../../../../api/admin/jobApi";

const CreatePage = () => {
  let navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [form, setForm] = useState({
    name: null,
    image: null,
    description: null,
  });
  const [formPassed, setFormPassed] = useState(true);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const resetForm = () => {};

  const handleSubmit = async (e) => {
    setSubmitting(true);
    e.preventDefault();
    const result = await createAssessment(form);
    if (result.success) {
      setAssessment(result.data);
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  const handleFile = (e) => {
    setFileUpload(e);
  };

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    setForm({
      name: "",
      image: "",
      description: "",
    });
  }, [assessment]);

  useEffect(() => {
    documentTitle("Create Assessment Category");
  }, []);

  useEffect(() => {}, [query]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <RectangleStackIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Assessment</p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/assessments",
                title: "Assessment & Test",
              },
              {
                href: null,
                title: "Create Assessment Category",
              },
            ]}
          />
        </div>
      </div>

      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="card flex flex-col w-[50%]">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">
                New Assessment Category
              </p>
            </div>
          </div>
          <div className="flex space-x-8 mb-4">
            <div className="w-[100%]">
              <div className="mb-4">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Category Name
                </label>
                <input
                  // ref={this.jobRoleInputRef}
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Input text"
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Description
                </label>
                <textarea
                  className="border w-full p-3 h-[136px] placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Write text here ..."
                  value={form.description}
                  onChange={(e) => {
                    setForm({ ...form, description: e.target.value });
                  }}
                  required
                ></textarea>
                <div className="flex justify-end">
                  <small className="text-gray-500">250/250</small>
                </div>
              </div>
              {/* <div>
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Question File
                </label>
                {submitting ? (
                  <PageSpinner padding={"30px"} />
                ) : fileUpload ? (
                  <div className="mt-3 mb-3 relative h-[50px] rounded-lg border bg-white flex justify-between items-center hover:outline-none px-4">
                    <span className="block text-soft-grey text-[14px]">
                      {fileUpload.target.files[0].name}{" "}
                    </span>
                    <button
                      onClick={(e) => {
                        setFileUpload(null);
                      }}
                    >
                      <TrashIcon width={20} />
                    </button>
                  </div>
                ) : (
                  <input
                    className="border mt-3 mb-3 w-full placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                    type="file"
                    accept=".xls,.xlsx"
                    onChange={(e) => handleFile(e)}
                  />
                )}

                <p className="text-[14px] text-grey-500  leading-[1.5] mb-4">
                  Format: .png, .jpg, .jpeg, .webp
                </p>
              </div> */}
            </div>
          </div>
          <div className="flex justify-end">
            {submitting ? (
              <button
                className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type=""
                disabled={true}
              >
                <BtnSpinner />
              </button>
            ) : (
              <button
                className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={!formPassed}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePage;
