import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
const Layout = () => {
  //
  const [user, setUser] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      if (user.base_role === "superadmin") {
        // if (location.pathname == "/administrator/login") {

        // }
        navigate("/administrator/login", { replace: true });
      } else {
        if (location.pathname == "/login") {
          navigate("/client/dashboard", { replace: true });
        }
      }
    }
  }, [location]);
  return (
    <>
      <Outlet />
    </>
  );
};

export default Layout;
