import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
};

const questionTagCategorySlice = createSlice({
  name: "question_tag_category",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload.data;
    },
    setCategory: (state, action) => {
      state.categories.push(action.payload.data);
    },
  },
});

export const { setCategories, setCategory } = questionTagCategorySlice.actions;
export default questionTagCategorySlice.reducer;
