import React from "react";
import PageSpinner from "../../../../../../Component/PageSpinner";
import { BriefcaseIcon } from "@heroicons/react/24/solid"
import { findCityById, findStateById } from "../../../../../../helpers";
import moment from "moment";
const WorkExperienceSection = ({ loading, experiences }) => {
  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <BriefcaseIcon width={24} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Work Experience
            </h4>
          </div>
        </div>
        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 md:px-5">
            {experiences.length > 0 ? (
              <>
                {experiences.map((experience) => (
                  <>
                    <div className="flex py-6 px-6 border border-line-gray-200 rounded-md mb-4 px" style={{ justifyContent: "space-between" }}>
                      <div className="desc-section">
                        <p className="font-bold mb-0.5">{experience.job_title}</p>
                        <p className="font-light text-slate-600 mb-0.5 text-sm md:text-base">
                          {experience.company_name} -{" "}
                          {findStateById(experience.province).name},
                          {findCityById(experience.city).name}
                        </p>
                        <p className="font-light text-slate-500 text-sm  mb-2">
                          {moment(experience.start_date).format("DD/MM/Y")} -{" "}
                          {experience.is_current ? "Present" : moment(experience.end_date).format("DD/MM/Y")}
                        </p>
                        <p className="text-slate-600 text-sm md:text-base mb-2">
                          <b>Skill</b> : {experience.related_skill_desc}
                        </p>
                        <p className="text-slate-600 text-sm md:text-base mb-2">
                          {experience.job_description}
                        </p>
                      </div>
                      <div className="edit-section">
                        
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default WorkExperienceSection;
