import axios from "axios";

export const createCompany = async (form = null) => {
  try {
    const response = await axios.post(`/api/admin/company`, form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCompanies = async (queryString = "") => {
  try {
    const response = await axios.get(`/api/admin/company?${queryString}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCompany = async (id = "") => {
  try {
    const response = await axios.get(`/api/admin/company/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCompanyJobRoles = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/company-job-role?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCompanySubscriptions = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/company-subscription?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCompanyTransactions = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/company-transaction?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
