import axios from 'axios';

export const getSummaries = async () => {
    const response = await axios
        .get(`/api/client/dashboard/summary`, {
            headers: {
                "Content-Type": "application/json",
            },
        })

    return response.data;
};