import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CubeIcon,
  InformationCircleIcon,
  PlusCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import PaginationTable from "../../../../Component/Tables/PaginationTable";
import SearchTable from "../../../../Component/Tables/SearchTable";
import FilterTable from "../../../../Component/Tables/FilterTable";
import TableSpinner from "../../../../Component/TableSpinner";
import EmptyContent from "../../../../Component/Tables/EmptyContent";
import { documentTitle } from "../../../../helpers";
import {
  getPackageSummaries,
  getPackageTransaction,
  getPackages,
  updateStatusPackageTransaction,
} from "../../../../api/admin";
import DropdownDots from "../../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import BtnSpinner from "../../../../Component/BtnSpinner";
import moment from "moment";

const TransactionPage = () => {
  let navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [users, setUsers] = useState([]);
  const [summaries, setSummaries] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [summaryLoading, setSummaryLoading] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const fetchPackageTransaction = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getPackageTransaction(queryString);
    if (result.success) {
      setUsers(result.data.data);
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setItemsPerPage(result.data.per_page);
      setTableLoading(false);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const setPaid = async (id) => {
    setTableLoading(true);
    const result = await updateStatusPackageTransaction({
      id: id,
      status: 1,
    });
    if (result.success) {
      toast.success(result.message, {
        toastId: "signup-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      fetchPackageTransaction();
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      setTableLoading(false);
    }
  };

  useEffect(() => {
    documentTitle("Packages Management");
  }, []);
  useEffect(() => {
    fetchPackageTransaction();
  }, [query]);

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };
  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {users.length > 0 ? (
            users.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.company?.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.invoice_number}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.transaction_type?.replace("_", " ")}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {moment(value?.created_at).format("DD MMM YYYY HH:mm")}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.total_price ? value.total_price : "-"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <StatusLabel
                      status={value.status}
                      label={value.status_label}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <DropdownDots>
                      <Dropdown.Item onClick={(e) => {}}>
                        <InformationCircleIcon width={16} className="mr-2" />
                        Detail
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setPaid(value.id);
                        }}
                      >
                        <CheckCircleIcon width={16} className="mr-2" />
                        Set as Paid
                      </Dropdown.Item>
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <CubeIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Transaction Package</p>
          <BreadCrumb list={[{ href: null, title: "Transaction Package" }]} />
          {/* <p className='text-base text-grey-400 font-medium '>List of positions that have been entered</p> */}
        </div>
      </div>

      <div className="card flex flex-col w-full">
        <div className="flex justify-between w-full mb-6">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">
              Transaction List
            </p>
            {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
          </div>
          <div className="flex justify-end">
            <button
              className="flex items-center justify-center bg-main-violate px-3 py-2 text-[16px] leading-[22.4px] text-white font-semibold rounded-lg hover:bg-violet-600 transition-colors duration-200 focus:outline-none disabled:bg-violet-300 disabled:cursor-not-allowed"
              type=""
              onClick={(e) => {
                navigate("/administrator/package-transaction/create");
              }}
            >
              <PlusCircleIcon width={20} className="mr-2" /> Add New Transaction
            </button>
          </div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <PaginationTable
            search={
              <SearchTable
                onSearch={(keyword) => handleSearch(keyword)}
                placeholder={"Search package"}
              />
            }
            currentPage={handlePaginate}
            itemsTo={itemsTo}
            itemsFrom={itemsFrom}
            itemsPerPage={5}
            checkbox={false}
            totalItems={totalItems}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            headers={[
              "NO.",
              "COMPANY NAME",
              "INVOICE NUMBER",
              "TYPE",
              "TRANSACTION DATE",
              "TOTAL PRICE",
              "STATUS",
              "ACTION",
            ]}
            content={<TableContent />}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionPage;
