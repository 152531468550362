import React from "react";
import { Clients } from "../Component/Errors/Clients";

const UnauthorizedPage = ({className = ''}) => {
  return (
    <div className={`${className} w-full h-[100vh] flex items-center `}>
      <Clients text={"Unauthorized"} />
    </div>
  );
};

export default UnauthorizedPage;
