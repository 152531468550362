import axios from "axios";

export const getNotifications = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/client/notifications?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateNotification = async (id = "") => {
  try {
    const response = await axios.put(
      `/api/client/notifications/${id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
