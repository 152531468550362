import { FunnelIcon } from '@heroicons/react/24/solid';
import { Component } from 'react'
import Select from 'react-select'
class FilterTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "",
            placeholder: "Select Department",
            payloads: [
                {
                    value: 1,
                    title: "Department A"
                },
                {
                    value: 2,
                    title: "Department B"
                }
            ]
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected !== prevProps.selected) {
            if (this.props.selected === "") {
                // Jika props.selected adalah string kosong, reset selected ke string kosong
                this.setState({ selected: "" });
            }
        }
    }

    render() {
        const { placeholder, options, selected } = this.props

        if (!options) return (<></>);
        return (
            <>
                <div className='py-1 w-full max-w-full mdb:max-w-[250px] mdb:mr-3'>
                    <div className="relative w-full">
                        <div className='py-0.5 pl-2 mt-2 border border-line-grey rounded-lg flex items-center'>
                            <span>
                                <FunnelIcon width={18} className='text-soft-grey' />
                            </span>
                            <Select className="w-full " classNamePrefix={"select"} options={options.map((option) => {
                                return {
                                    value: option.value,
                                    label: option.title
                                }
                            })} value={this.state.selected} onChange={(e) => {
                                this.setState({ selected: e });
                                this.props.onChange(e.value, e)
                                // this.props.onChangeRaw(e);
                            }} placeholder={placeholder} />
                        </div>



                        {/* <select
                            className="w-full form-select appearance-none block pl-10 pr-3 py-2 text-grey-900 text-[14px] bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 text-[12px] focus:border-main-violate"
                            aria-label="Default select example"
                            name="filter"
                            value={selected}
                            // value={this.props.countryValue}
                            onChange={(e) => {
                                this.props.onChange(e.target.value)
                            }}
                            required
                        >
                            <option selected disabled value="">{placeholder}</option>
                            {options && options.map((e, key) => {
                                return (
                                    <option key={key} value={e.value}>
                                        {e.title}
                                    </option>
                                );
                            })}
                        </select> */}
                    </div>
                </div>
            </>
        )
    }

}

export default FilterTable;