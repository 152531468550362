import React, { Component } from "react";
import { inputIsOnlyAlphabetic } from "../../../../helpers";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "react-select";
import config from "../../../../config";

class CreatePosition extends Component {
  constructor(props) {
    super(props);
    this.jobRoleInputRef = React.createRef();
    this.handleRoleInputFocus = this.handleRoleInputFocus.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.state = {
      selectedDepartment: null,
      selectedPosition: null,
    };
  }

  handleRoleInputFocus() {
    this.jobRoleInputRef.current.focus();
  }

  handleRoleChange(e) {
    const { value } = e.target;
    if (inputIsOnlyAlphabetic(value)) this.props.onRoleChange(value);
  }

  componentDidMount() {}

  render() {
    // const customStyles = {
    //     menu: (provided, state) => ({
    //         ...provided,
    //         maxHeight: '200px'
    //     }),
    // };
    let positionFilter = [];
    const minDate = new Date();
    minDate.setDate(
      minDate.getDate() + parseInt(config.CLIENT_JOB_MIN_DEADLINE)
    );
    const minDateString = minDate.toISOString().split("T")[0];
    const {
      onDepartmentChange,
      onPositionChange,
      jobDepartmentId,
      jobPositionId,
      departments,
      positions,
      jobRole,
      daysToExpiry,
      onDayChange,
    } = this.props;

    positionFilter = positions.filter((position) => {
      return position.job_department_id === jobDepartmentId;
    });
    return (
      <div className="h-[420px]">
        <div className="card grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
          <div className="mb-2">
            <label className="text-gray-800 text-sm font-bold leading-[140%]">
              Department
            </label>
            <Select
              className="py-0.5 rounded-lg mt-2 border border-line-grey"
              classNamePrefix={"select"}
              options={departments.map((option) => {
                return {
                  value: option.id,
                  label: option.name,
                };
              })}
              value={this.state.selectedDepartment}
              onChange={(e) => {
                this.setState({ selectedDepartment: e });
                this.setState({ selectedPosition: null });
                onDepartmentChange(e.value);
              }}
            />
          </div>
          <div className="mb-2">
            <label className="text-gray-800 text-sm font-bold leading-[140%]">
              Job Position
            </label>
            <Select
              className="py-0.5 rounded-lg mt-2 border border-line-grey"
              classNamePrefix={"select"}
              value={this.state.selectedPosition}
              options={positionFilter.map((option) => {
                return {
                  value: option.id,
                  label: option.name,
                };
              })}
              onChange={(e) => {
                this.setState({ selectedPosition: e });
                onPositionChange(e.value);
              }}
            />
          </div>
          <div className="mb-2">
            <label className="text-gray-800 text-sm font-bold leading-[140%]">
              Job Role
            </label>
            <input
              ref={this.jobRoleInputRef}
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="text"
              name="title"
              id="title"
              placeholder="Type your job role..."
              value={jobRole}
              onChange={this.handleRoleChange}
              required
            />
          </div>
          <div className="mb-2 z-index-999">
            <label className="text-gray-800 text-sm font-bold leading-[140%]">
              Deadline
            </label>
            <input
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="date"
              name="title"
              id="title"
              placeholder="Choose date..."
              value={daysToExpiry}
              onChange={(e) => {
                onDayChange(e.target.value);
              }}
              required
              min={minDateString}
            />
            {/* <Datepicker
                            inputClassName={"border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"}
                            minDate={minDateString}
                            useRange={false}
                            asSingle={true}
                            value={{
                                startDate: daysToExpiry,
                                endDate: daysToExpiry,
                            }}
                            // onChange={(e) => {
                            //     setForm({ ...form, found_date: e.startDate })
                            // }}
                            onChange={(e) => {
                                onDayChange(e.startDate);
                            }}
                        /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default CreatePosition;
