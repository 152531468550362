import React, { Component } from "react";
import logo from "../Assets/HumataLogo.png";
import notif from "../Assets/BellOutline.png";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import { BellIcon, LinkIcon } from "@heroicons/react/24/outline";
// import { View } from "@heroicons/react/20/solid"
import { Popover } from "@headlessui/react";
import Notification from "./Notification";
export class NavbarDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: "",
        role: "",
        token: "",
        email: "",
        avatar: "",
        company_slug: "",
        company: {},
      },
      signedOut: false,
    };
  }

  // componentWillUnmount(){
  //   alert("GOOD BYE");
  // }

  handleOpenSidebar() {
    this.props.openSidebar();
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user")) || null;
    if (user) {
      this.setState({ user: user });
    }
    // this.setState({...user, user: })
  }

  signOut = () => {
    localStorage.removeItem("user");
    window.location.replace("/login");
  };

  render() {
    return (
      <>
        <Navbar
          fluid={true}
          rounded={true}
          style={{
            padding: "0px 10px",
            position: "fixed",
            width: "100%",
            zIndex: "999",
          }}
        >
          <Navbar.Brand href="/" className="w-[90px]">
            <img
              src={logo}
              className="mr-3 p-2 m-2"
              alt="Flowbite Logo"
            />
            {/* <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            Flowbite
          </span> */}
          </Navbar.Brand>
          <div className="flex md:order-2 items-center pr-3">
            {/* <BellIcon color="#6B7280" className="mr-6" width={26} /> */}
            {/* <img
              src={notif}
              alt="notif"
              className="h-[32px] w-[32px] mr-5"
            /> */}
            <Popover className="relative mr-4 mt-2">
              <Popover.Button className="border border-0 border-transparent outline-none ring-0 group">
                <BellIcon
                  className={`h-[26px] w-[26px] text-gray-600 ${
                    this.props.notifications.filter(
                      (notification) => notification.status === 0
                    ).length > 0 && "bell-shake"
                  }`}
                />
                {this.props.loaded && (
                  <>
                    {this.props.notifications.filter(
                      (notification) => notification.status === 0
                    ).length > 0 && (
                      <span className="absolute top-0 left-1 h-2 w-2 bg-main-violate-500 rounded-full"></span>
                    )}
                  </>
                )}
              </Popover.Button>

              <Popover.Panel className="absolute z-10 w-[420px] bg-white right-0 shadow-md rounded-lg">
                <Notification />
              </Popover.Panel>
            </Popover>
            <Dropdown
              arrowIcon={false}
              inline={true}
              label={
                <>
                  <div className="flex space-x-4">
                    <Avatar
                      className="hidden mdb:block"
                      alt="User settings"
                      img={this.state.user.company.image}
                      rounded={true}
                    />
                    <div className="flex flex-col text-left hidden mdb:block">
                      <p className="text-sm font-semibold">
                        {this.state.user.company.name}
                      </p>
                      <p className="text-sm text-gray-500 font-medium">
                        {this.state.user.name}
                      </p>
                    </div>
                  </div>
                </>
              }
            >
              <Dropdown.Header>
                <span className="block text-sm font-semibold mb-2">
                  As {this.state.user.base_role}
                </span>
                <span className="block text-sm">{this.state.user.name}</span>
                <span className="block truncate text-sm font-medium mb-4">
                  {this.state.user.email}
                </span>
                {/* <a
                  href={"#"}
                  onClick={() => {
                    alert("SOON - Integration with Google");
                  }}
                  className="text-sm text-blue-500 flex items-center"
                >
                  <LinkIcon className="w-[16px] mr-2"/> Link to Google
                </a> */}
              </Dropdown.Header>
              <Dropdown.Item
                onClick={(e) => {
                  window.location.href = "/client/settings";
                }}
              >
                Settings
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={this.signOut}>Sign out</Dropdown.Item>
            </Dropdown>

            {/* <Navbar.Toggle className="focus:outline-none active:bg-white focus:bg-white focus:border-none lg:hidden" onClick={(e) => {
              this.handleOpenSidebar();
            }} /> */}
            <div className="hamburger relative mdb:hidden">
              <input
                type="checkbox"
                onChange={(e) => this.handleOpenSidebar()}
              />
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <Navbar.Collapse>
            {/* <Navbar.Link href={`/assessment/${this.state.user.company_slug}`} active={true}>
              Assessment
            </Navbar.Link> */}
            {/* <Navbar.Link href="/navbars">About</Navbar.Link>
          <Navbar.Link href="/navbars">Services</Navbar.Link>
          <Navbar.Link href="/navbars">Pricing</Navbar.Link>
          <Navbar.Link href="/navbars">Contact</Navbar.Link> */}
          </Navbar.Collapse>
        </Navbar>
      </>
      // <div>
      //   <nav className="bg-white shadow-lg w-[100%]">
      //     <div className="flex justify-between px-2 h-[80px]">
      //       <a href="#" className="flex items-center py-3 px-2">
      //         <img src={logo} alt="Logo" className="h-[80px] w-[80px] mr-2 mb-3"/>
      //       </a>
      //       <div className="notification flex justify-start items-center py-3 px-2">
      //         <img src={notif} alt="notif" className="h-[32px] w-[32px] mr-2 mb-3"/>
      //         <div className="rounded-full bg-secondary-orange w-[14px] h-[14px] ml-[-22px] mt-[-27px]"></div>
      //         <div className="font-semibold text-white text-[14px] w-[32px] h-[32px] rounded-full bg-dark-blue flex items-center justify-center mr-[30px] mb-3 ml-[15px]">
      //           <button type="button" class="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
      //               <span className="sr-only">Open user menu</span>
      //               <img className="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-3.jpg" alt="user photo"/>
      //           </button>
      //         </div>
      //       </div>
      //     </div>
      //   </nav>
      // </div>
    );
  }
}
export default NavbarDashboard;
