import React, { useEffect, useState } from "react";
import PopUp from "../../../../Component/PopUp";
import CalendarEventPopup from "../Popup/CalendarEventPopup";

const CustomCalendar = ({ startDate, endDate, onApplyCalendar, storedCalendars, duration = 30}) => {
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [showAddEventPopup, setShowAddEventPopup] = useState(false);
  const daysOfWeek = [
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
    "Minggu",
  ];

  const handleEvent = async (id) => {
    const calendar = calendars.filter((cal) => cal).find((cal) => cal.id === id);
    setSelectedCalendar(calendar);
    setShowAddEventPopup(true);
  };

  const handleApplyEvent = (event) => {
    const newCalendars = calendars.map((cal) => {
      if (cal) {
        if (cal.id == selectedCalendar.id) {
          cal.events = event;
        }
      }
      return cal;
    });

    setCalendars(newCalendars)
    setShowAddEventPopup(false)
  };

  useEffect(() => {
    const getDatesInRange = (start, end) => {
      const startDt = new Date(start);
      const endDt = new Date(end);
      const dates = [];

      while (startDt <= endDt) {
        dates.push(new Date(startDt));
        startDt.setDate(startDt.getDate() + 1);
      }

      return dates;
    };

    const getMonthName = (month) => {
      const monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      return monthNames[month - 1];
    };

    const firstDayOfWeek = (new Date(startDate).getDay() + 6) % 7;
    const dates = getDatesInRange(startDate, endDate);
    const totalDays = dates.length + firstDayOfWeek;
    const newCalendar = Array(totalDays).fill(null);

    for (
      let i = firstDayOfWeek, dateIndex = 0;
      i < newCalendar.length && dateIndex < dates.length;
      i++
    ) {
      const date = dates[dateIndex];
      const dayOfMonth = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      newCalendar[i] = {
        id: dateIndex,
        data: `${dayOfMonth.toString().padStart(2, "0")} ${getMonthName(
          month
        )} ${year}`,
        events: [], // contoh data event
      };
      dateIndex++;
    }

    setCalendars(newCalendar);
  }, [startDate, endDate]);

  useEffect(() => {
    onApplyCalendar(calendars)
  },[calendars])

  useEffect(() => {
    if (storedCalendars && storedCalendars.length > 0) {
      setCalendars(storedCalendars)
    }
  }, [storedCalendars])

  return (
    <>
      {showAddEventPopup && (
        <PopUp>
          <CalendarEventPopup
            durationTime={duration}
            onClose={() => setShowAddEventPopup(false)}
            calendar={selectedCalendar}
            onApply={(event) => handleApplyEvent(event)}
          />
        </PopUp>
      )}
      <div className="card mb-4">
        <div style={styles.container}>
          <div className="bg-main-violate-500 rounded-md" style={styles.header}>
            {daysOfWeek.map((day) => (
              <div className="text-white" key={day} style={styles.headerBox}>
                {day}
              </div>
            ))}
          </div>
          <div style={styles.calendar}>
            {calendars.map((calendar, index) => (
              <div
                className={`${calendar?.events?.length > 0 && 'bg-green-1'} h-[100px] border border-1 border-soft-gray hover:cursor-pointer hover:border-green-1`}
                key={index}
                style={styles.dayBox}
                onClick={() => handleEvent(calendar.id)}
              >
                {calendar && (
                  <>
                    {calendar && (
                      <>
                      <p className={`${calendar?.events?.length > 0 && 'text-white'}`}>{calendar.data}</p>
                      <p className={`${calendar?.events?.length > 0 && 'text-white'} mt-2`}>{calendar.events.length} Slot</p>
                      </>
                      
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    width: "100%",
    margin: "0 auto",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "10px",
    padding: "10px 0",
    // backgroundColor: "#e0e0e0",
    // borderBottom: "2px solid #ccc",
  },
  headerBox: {
    textAlign: "center",
    fontWeight: "bold",
  },
  calendar: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "10px",
    padding: "10px 0",
  },
  dayBox: {
    // border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "10px",
    textAlign: "center",
    // backgroundColor: "#f9f9f9",
    fontSize: "0.9em",
  },
  "@media (max-width: 600px)": {
    header: {
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: "5px",
      padding: "5px 0",
    },
    calendar: {
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: "5px",
      padding: "5px 0",
    },
    dayBox: {
      padding: "5px",
      fontSize: "0.8em",
    },
  },
};
export default CustomCalendar;
