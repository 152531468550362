import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  PlusCircleIcon,
  NewspaperIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import PaginationTable from "../../../../Component/Tables/PaginationTable";
import SearchTable from "../../../../Component/Tables/SearchTable";
import TableSpinner from "../../../../Component/TableSpinner";
import EmptyContent from "../../../../Component/Tables/EmptyContent";
import { documentTitle } from "../../../../helpers";
import { deleteArticleCategory, getArticleCategories } from "../../../../api/admin";
import DropdownDots from "../../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import moment from "moment";

const BlogCategoryPage = () => {
  let navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [articles, setArticles] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleDelete = async (id) => {
    setTableLoading(true);
    const result = await deleteArticleCategory(id);
    if (result.success) {
      toast.success(result.message, {
        toastId: "signup-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      fetchPackageTransaction();
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      setTableLoading(false);
    }
  }

  const fetchPackageTransaction = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getArticleCategories(queryString);
    if (result.success) {
      setArticles(result.data.data);
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setItemsPerPage(result.data.per_page);
      setTableLoading(false);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  useEffect(() => {
    documentTitle("Blog Management - Topics");
  }, []);
  useEffect(() => {
    fetchPackageTransaction();
  }, [query]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {articles.length > 0 ? (
            articles.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 max-w-[400px] overflow-x-hidden truncate">
                    {value?.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.slug}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.lang.toUpperCase()}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.total_use} articles
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.created_at
                      ? moment(value?.created_at).format("DD MMM YYYY HH:mm")
                      : "-"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <DropdownDots>
                      <Dropdown.Item
                        onClick={(e) => {
                          navigate(
                            `/administrator/blog-topic/edit/${value.id}`
                          );
                        }}
                      >
                        <PencilSquareIcon width={16} className="mr-2" />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          handleDelete(value.id);
                        }}
                      >
                        <TrashIcon width={16} className="mr-2" />
                        Delete
                      </Dropdown.Item>
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <NewspaperIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Blog Topic</p>
          <BreadCrumb list={[{ href: null, title: "Blog Topic" }]} />
          {/* <p className='text-base text-grey-400 font-medium '>List of positions that have been entered</p> */}
        </div>
      </div>

      <div className="card flex flex-col w-full">
        <div className="flex justify-between w-full mb-6">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">Topic Data</p>
            {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
          </div>
          <div className="flex justify-end">
            <button
              className="flex items-center justify-center bg-main-violate px-3 py-2 text-[16px] leading-[22.4px] text-white font-semibold rounded-lg hover:bg-violet-600 transition-colors duration-200 focus:outline-none disabled:bg-violet-300 disabled:cursor-not-allowed"
              type=""
              onClick={(e) => {
                navigate("/administrator/blog-topic/create");
              }}
            >
              <PlusCircleIcon width={20} className="mr-2" /> Add Topic
            </button>
          </div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <PaginationTable
            search={
              <SearchTable
                onSearch={(keyword) => handleSearch(keyword)}
                placeholder={"Search package"}
              />
            }
            currentPage={handlePaginate}
            itemsTo={itemsTo}
            itemsFrom={itemsFrom}
            itemsPerPage={5}
            checkbox={false}
            totalItems={totalItems}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            headers={["NO.", "NAME", "SLUG", "LANGUAGE", "TOTAL", "DATE", "ACTION"]}
            content={<TableContent />}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogCategoryPage;
