import axios from "axios";

export const getSharedJobDepartments = async (queryString = "") => {
  const response = await axios.get(
    `/api/shared/job-departments`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const getSharedJobPositions = async (queryString = "") => {
  const response = await axios.get(
    `/api/shared/job-positions`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};