import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../../Component/PersonalityRadarChart";
import RangeList2 from "../Components/RangeList2";
import PageSpinner from "../../../../../../Component/PageSpinner";
const ExcelReports = ({ excel }) => {
    const [communicate, setCommunicate] = useState(null);
    const [dataRange, setDataRange] = useState(null);
    useEffect(() => {
        if (excel) {
            let values = [];
            let labels = [];
            let tempDataRange = [];

            excel.data.map((item) => {
                values.push(item.result)
                labels.push(item.name)
                tempDataRange.push({ parameter: item.name, minLabel: item.label2, maxLabel: item.label1, maxRange: "10", minRange: "0", value: item.result })
            })
            setCommunicate({
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: '#0E9F6E7A',
                        borderColor: '#0E9F6E',
                        borderWidth: 2,
                        hidden: '',
                    },
                ],
            })
            setDataRange(tempDataRange)
        }
    }, [excel])
    return (
        <div className='flex flex-col items-center'>
            <div className='flex w-full items-center mb-7 mdb:mb-5 gap-10 px-2 mdb:px-4 justify-center flex-col mdb:flex-row'>
            <div className="w-full mdb:w-[40%] flex justify-center">
                    <div className="max-w-[520px]">
                        {communicate ? <PersonalityRadarChart data={communicate} /> : <PageSpinner />}
                    </div>
                </div>
                <div className="w-full mdb:w-[60%]">
                    <p className='font-semibold mb-2'>Excel</p>
                    <p className="text-gray-500">Kualitas dan tindakan yang ditunjukkan individu ketika mereka mengupayakan yang terbaik dalam pekerjaan, tanggung jawab, dan interaksi mereka dalam konteks profesional.</p>
                    {/* <p className="text-gray-500">The qualities and actions that individuals exhibit when they strive for excellence in their work, responsibilities, and interactions within a professional context.</p> */}
                    {/* <ul className="list-disc list-inside text-gray-500">
                        <li>This person is able to trust his or her judgem ent and overcome her doubts when they are not certain.</li>
                        <li>This person has his or her own opinions but is still sensitiv e t o criticism.</li>
                        <li>This person prefers to rely on an existing approach and is rather convent ional.</li>
                        <li>This person is able to trust his or her judgem ent and overcome her doubts when they are not certain.</li>
                    </ul> */}
                </div>
            </div>
            {communicate ? <>
                {dataRange.map((item, index) => (
                    <RangeList2 data={item} color="green" />
                ))}
            </> : <PageSpinner />}

        </div>
    )
}

export default ExcelReports;