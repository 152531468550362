import React, { useState } from "react";
import {
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import BtnSpinner from "../../../../Component/BtnSpinner";
import { inputIsEmailFormat } from "../../../../helpers";
function EditEmailPopup(props) {
  const { onClose, onSend, email, username, phonenumber, name, loading } =
    props;
  const [newEmail, setNewEmail] = useState("");
  //   const [username, setUsername] = useState("");
  //   const [phonenumber, setPhonenumber] = useState("");
  //   const [name, setName] = useState("");
  const handleSend = (e) => {
    e.preventDefault();
    if (typeof onSend === "function") {
      onSend({ newEmail, username, phonenumber, name });
    }
  };
  return (
    // Your alert popup component JSX
    <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate  min-h-[328px] py-2 flex flex-col px-4 py-4">
      <div className="flex justify-between">
        <p className="text-center text-base text-gray-700">
          Edit Candidate Email
        </p>
        <button
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
          onClick={() => {
            onClose();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <form onSubmit={(e) => handleSend(e)}>
        <div className="flex flex-col items-center  h-full w-full">
          <div className="mb-2 mt-5 w-full">
            <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Current Email
              </label>
              <input
                disabled
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="email"
                name="current_email"
                id="current_email"
                placeholder="Candidate Email"
                value={email}
                required
              />
            </div>
            {/* <ReactQuill theme="snow" value={value} onChange={setValue} className="h-full w-full" /> */}
          </div>
          <div className="mb-8 w-full">
            <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                New Email
              </label>
              <input
                autoComplete="off"
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="email"
                name="new_email"
                id="new_email"
                placeholder="Input new candidate email"
                value={newEmail}
                onChange={(e) => {
                  setNewEmail(e.target.value);
                }}
                required
              />
              {newEmail == email && (
                <span className="input-error-message">
                    New email must be different from current email
                </span>
              )}
            </div>
            {/* <ReactQuill theme="snow" value={value} onChange={setValue} className="h-full w-full" /> */}
          </div>
          <div className="flex  justify-end w-[100%] gap-4">
            <button
              disabled={loading || newEmail == email || !inputIsEmailFormat(newEmail)}
              className="hover:bg-violet-600 cursor-pointer bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
              type="submit"
            >
              {loading && <BtnSpinner />}
              {!loading && <>Submit</>}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditEmailPopup;
