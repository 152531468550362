import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../../Component/PersonalityRadarChart";
import RangeList2 from "../Components/RangeList2";
import PageSpinner from "../../../../../../Component/PageSpinner";

const ManageReports = ({ management }) => {
    const [communicate, setCommunicate] = useState(null);
    const [dataRange, setDataRange] = useState(null);
    useEffect(() => {
        if (management) {
            let values = [];
            let labels = [];
            let tempDataRange = [];

            management.data.map((item) => {
                values.push(item.result)
                labels.push(item.name)
                tempDataRange.push({ parameter: item.name, minLabel: "A reserved individual who does not naturally want to speak in public or in front of new people", maxLabel: "An individual who likes to be the centre of attention, speak in public and who spontaneously meets others", maxRange: "10", minRange: "0", value: item.result })
            })
            setCommunicate({
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: '#F7931F7A',
                        borderColor: '#F7931F',
                        borderWidth: 2,
                        hidden: '',
                    },
                ],
            })
            setDataRange(tempDataRange)
        }
    }, [management])
    return (
        <div className='flex flex-col items-center'>
            <div className='flex w-full items-center mb-7 mdb:mb-5 gap-10 px-2 mdb:px-4 justify-center flex-col mdb:flex-row'>
                <div className="w-full mdb:w-[40%] flex justify-center">
                    <div className="max-w-[520px]">
                        {communicate ? <PersonalityRadarChart data={communicate} /> : <PageSpinner />}
                    </div>

                </div>
                <div className="w-full mdb:w-[60%]">
                    <p className='font-semibold mb-2'>Management</p>
                    <p className="text-gray-500">Perilaku dan sikap yang diharapkan dari individu dalam peran kepemimpinan dan pengawasan dalam suatu organisasi. Ini mencakup seperangkat standar etika dan perilaku yang diharapkan dipatuhi oleh para manajer agar dapat memimpin tim secara efektif, membuat keputusan yang tepat, dan menciptakan lingkungan kerja yang positif.</p>
                    {/* <p className="text-gray-500">the conduct and demeanor expected from individuals in leadership and supervisory roles within an organization. It encompasses a set of ethical and behavioral standards that managers are expected to adhere to in order to effectively lead teams, make informed decisions, and create a positive work environment</p> */}
                    {/* <ul className="list-disc list-inside text-gray-500">
                        <li>This person is able to trust his or her judgem ent and overcome her doubts when they are not certain.</li>
                        <li>This person has his or her own opinions but is still sensitiv e t o criticism.</li>
                        <li>This person prefers to rely on an existing approach and is rather convent ional.</li>
                        <li>This person is able to trust his or her judgem ent and overcome her doubts when they are not certain.</li>
                    </ul> */}
                </div>
            </div>
            {communicate ? <>
                {dataRange.map((item, index) => (
                    <RangeList2 data={item} color="orange" />
                ))}
            </> : <PageSpinner />}

        </div>
    )
}

export default ManageReports;