import React, { Component } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ClipboardDocumentListIcon, ClockIcon, DevicePhoneMobileIcon, EnvelopeIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import "./index.css"
import PopUp from "../PopUp";
import DetailTestPreview from "../DetailTestPreview";
import { Avatar } from "flowbite-react";

export class SelectCandidateCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: true,
            showSelectedDetail: {},
            showDetail: false
            // width: 600
        };
    }

    render() {
        const { data, onSelect, onRemove, inSelected } = this.props;
        const selectTest = (testId) => {
            onSelect(testId)
        }
        const removeTest = (testId) => {
            onRemove(testId)
        }

        const settings = {
            arrows: true,
            infinite: false,
            speed: 500,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1720,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: false,
                    }
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: false,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                    }
                }
            ]
        };
        return (
            <div>
                <div
                    style={{
                        width: this.state.width + "px",
                        display: this.state.display ? "block" : "none"
                    }}
                >
                    <Slider {...settings}>
                        {data.map((value) => (
                            <div className="px-2 w-full">
                                <div className={(value.selected ? "card-main-selected" : "card") + " h-[450px] flex flex-col justify-between"} >
                                    <div className="flex flex-col items-center">
                                        {value.avatar ? <Avatar image={value.avatar} size="large" className="mb-4" /> : <UserCircleIcon className="w-[68px] text-grey-500 mb-4" />}
                                        {/* <UserCircleIcon className="w-[68px] text-grey-500 mb-4" /> */}
                                        <div className={inSelected && 'title-selected'}>
                                            <p className="text-grey-900 font-semibold text-xl lg:text-lg mb-4">{value.name}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="flex mb-4">
                                            <EnvelopeIcon className="w-[24px] text-grey-500 mr-2" />
                                            <label>{value.email}</label>
                                        </span>
                                        <span className="flex mb-4">
                                            <DevicePhoneMobileIcon className="w-[24px] text-grey-500 mr-2" />
                                            <label>{value.phonenumber ? value.phonenumber : "-" }</label>
                                        </span>
                                    </div>

                                    <div>
                                        {value.selected ? <>
                                            <button
                                                className="mb-4 bg-red-100 w-full px-4 text-[16px] leading-[22.4px] text-red-600 font-semibold h-[46px] rounded-lg hover:bg-red-300 disabled:bg-violet-300 disabled:cursor-not-allowed"
                                                type=""
                                                key={value.id}
                                                onClick={(e) => {
                                                    removeTest(value.id)
                                                }}
                                            >
                                                Remove
                                            </button>
                                        </> : <>
                                            <button
                                                className="mb-4 bg-main-violate-100 w-full px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-violet-300 disabled:bg-violet-300 disabled:cursor-not-allowed"
                                                type=""
                                                key={value.id}
                                                onClick={(e) => {
                                                    selectTest(value.id)
                                                }}
                                            >
                                                Add
                                            </button>
                                        </>}
                                        <button
                                            className="border border-main-violate w-full px-4 text-[16px] leading-[22.4px] text-main-violate hover:bg-violet-100 font-semibold h-[46px] rounded-lg"
                                            type=""
                                            onClick={(e) => {
                                                this.setState({ showSelectedDetail: value })
                                                this.setState({ showDetail: true })
                                            }}
                                        >
                                            Detail
                                        </button>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                {
                    this.state.showDetail ? <PopUp><DetailTestPreview data={this.state.showSelectedDetail} onClose={(e) => this.setState({ ...this.state, showDetail: false })} /></PopUp> : ''
                }
            </div>

        );
    }
}
export default SelectCandidateCarousel;