import axios from "axios";

export const getInterviewSession = async (queryString = "") => {
  const response = await axios.get(
    `/api/client/interview?${queryString}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
export const getInterviewScheduleByDate= async (interview_id, date) => {
  const response = await axios.get(
    `/api/client/interview-schedule/${interview_id}/${date}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

