import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../../Component/PersonalityRadarChart";
import RangeList2 from "../Components/RangeList2";
// import RangeList from "../../../PDFView/RangeList";
import PageSpinner from "../../../../../../Component/PageSpinner";

const PersonalityTraits = ({ data }) => {
    const [communicate, setCommunicate] = useState(null);
    const [dataRange, setDataRange] = useState(null);
    useEffect(() => {
        if (data) {
            let values = [];
            let labels = [];
            let tempDataRange = [];

            data.data.map((item) => {
                values.push(item.result)
                labels.push(item.name)
                tempDataRange.push({ parameter: item.name, minLabel: item.label2, maxLabel: item.label1, maxRange: item.max_value, minRange: item.min_value, value: item.result })
            })
            setCommunicate({
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: '#9061F97A',
                        borderColor: '#9061F9',
                        borderWidth: 2,
                        hidden: '',
                    },
                ],
            })
            setDataRange(tempDataRange)
        }
    }, [data])
    return (
        <div className='flex flex-col items-center'>
            <div className='flex w-full items-center mb-5 gap-10 px-4 justify-center'>
                <div className="w-[40%] flex justify-center">
                    <div className="max-w-[520px]">
                        {communicate ? <PersonalityRadarChart data={communicate} /> : <PageSpinner />}
                    </div>
                </div>
                <div className="w-[60%]">
                    <p className='font-semibold mb-2'>Personality Traits</p>
                    <p className="text-gray-500">Karakteristik yang konsisten dan relatif stabil dalam perilaku, pemikiran, dan perasaan seseorang. Sifat-sifat kepribadian adalah aspek-aspek yang membedakan individu satu dengan yang lain dalam hal bagaimana mereka merespon berbagai situasi dan interaksi.</p>
                </div>
            </div>
            {communicate ? <>
                {dataRange.map((item, index) => (
                    <RangeList2 data={item} color="purple" />
                ))}
            </> : <PageSpinner />}

        </div>
    )
}

export default PersonalityTraits;