import React from "react";
import { useState, useEffect } from "react";
import PageSpinner from "../../../../../../Component/PageSpinner";
import { IdentificationIcon, PencilIcon } from "@heroicons/react/24/solid";
import {
  findCityById,
  findCountryById,
  findStateById,
  rupiahFormat,
} from "../../../../../../helpers";
import { Avatar } from "flowbite-react";
const PersonalSection = ({
  about,
  personal,
  account,
  onUpdate,
  personalLoading,
}) => {
  const [user, setUser] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  const [showModal, setShowModal] = React.useState(false);

  //
  useEffect(() => {
    // setForm(personal);
  }, [personal]);

  // useEffect(() => {

  //     if (!showModal) setValidations({});
  // }, [showModal])

  useEffect(() => {
    async function getInformationApi() {
      // await getAchievements();
    }

    getInformationApi();
  }, []);
  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <IdentificationIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Personal Information
            </h4>
          </div>
        </div>
        {personalLoading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            <div className="flex text-sm">
              <div className="flex justify-center py-8 border-r border-grey-200 w-[35%]">
                <div>
                  <div className="h-[80px] rounded-full flex items-center justify-center mb-2">
                    <Avatar
                      size={"lg"}
                      alt="User settings"
                      img={personal.avatar}
                      rounded={true}
                    />
                    {/* <img
                                            src={personal.avatar}
                                            alt="profile"
                                            className="h-[80px] w-[80px] profile-image"
                                        /> */}
                  </div>
                  <p className="text-black text-[16px] font-semibold leading-[140%] text-center mb-2">
                    {personal.fullname}
                  </p>
                  <p className="text-black text-[16px] leading-[140%] text-center mb-2">
                    {personal.email}
                  </p>
                  <p className="text-black text-[14px] font-semibold leading-[140%] text-center mb-2">
                    {personal.phonenumber}
                  </p>
                  <p className="text-black text-[14px] font-semibold leading-[140%] text-center mb-2">
                    {personal.expected_salary
                      ? rupiahFormat(personal.expected_salary) + " /month"
                      : "-"}
                  </p>
                  {/* <p className="text-main-violate text-[14px] font-semibold leading-[140%] text-center mb-2">
                                        {personal.resume_file ? personal.resume_file.replace(/^.*[\\\/]/, "") : ""}
                                    </p> */}
                </div>
              </div>
              <div className="mt-4 w-[75%]">
                <table className="ml-3">
                  <tr>
                    <td className="px-4 py-2 font-semibold">Username</td>
                    <td className="px-4 py-2 text-gray-scale-90">
                      : {account.username}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 font-semibold">Date of Birth</td>
                    <td className="px-4 py-2 text-gray-scale-90">
                      : {personal.date_of_birth}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 font-semibold">Gender</td>
                    <td className="px-4 py-2 text-gray-scale-90">
                      : {personal.gender}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 font-semibold">Marital Status</td>
                    <td className="px-4 py-2 text-gray-scale-90">
                      : {personal.marital}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 font-semibold">Address</td>
                    <td className="px-4 py-2 text-gray-scale-90">
                      : {personal.address}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 font-semibold w-[300px]">
                      Country/ Zip Code
                    </td>
                    <td className="px-4 py-2 text-gray-scale-90">
                      :{" "}
                      {findCountryById(personal.country)
                        ? findCountryById(personal.country).name
                        : ""}{" "}
                      / {personal.zip_code}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 font-semibold">Province/ City</td>
                    <td className="px-4 py-2 text-gray-scale-90">
                      :{" "}
                      {findStateById(personal.province)
                        ? findStateById(personal.province).name
                        : ""}{" "}
                      /{" "}
                      {findCityById(personal.city)
                        ? findCityById(personal.city).name
                        : ""}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalSection;
