import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  RectangleStackIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import { Avatar, Dropdown } from "flowbite-react";
import { documentTitle, inputIsPhoneNumberWithCode } from "../../../../helpers";
import BtnSpinner from "../../../../Component/BtnSpinner";
import { createCompany, getCompany } from "../../../../api/admin/companyApi";
import moment from "moment";

const CreatePage = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState(null);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const fetchCompany = async () => {
    setLoading(true);
    const result = await getCompany(id);
    if (result.success) {
      setCompany(result.data);
      console.log(result.data);
    } else {
      toast.error(result.message, {
        toastId: "fetch-company-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setLoading(false);
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Detail Company");
    fetchCompany();
  }, []);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <RectangleStackIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Detail Company</p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/client/users",
                title: "Client",
              },
              {
                href: null,
                title: "Detail Company",
              },
            ]}
          />
        </div>
      </div>
      <div className="card flex flex-col w-[100%]">
        <div className="flex justify-between w-full mb-4">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">
              Company Profile
            </p>
          </div>
        </div>
        <div className="flex w-full mb-6">
          <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
            {company?.image == null && (
              <BuildingOfficeIcon className="w-[30px] text-main-violate" />
            )}
            {company?.image != null && <Avatar img={company?.image} />}
          </div>
          <div className="flex flex-col justify-around">
            <p className="text-xl text-gray-900 font-semibold">{company?.name}</p>
            <p className="text-sm text-gray-600">
              {company?.code ? company?.code : "-"}
            </p>
            {/* <BreadCrumb list={[{ href: null, title: "Report Clients" }]} /> */}
          </div>
        </div>
        <div className="flex mb-4 flex-col">
          <div className="w-[100%] flex space-x-4">
            <div className="w-[50%] flex flex-col space-y-4">
              {/* <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Code
                </label>
                <p className="text-md text-gray-600">
                  {company?.code ? company?.code : "-"}
                </p>
              </div>
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Company Name
                </label>
                <p className="text-md text-gray-600">
                  {company?.name ? company?.name : "-"}
                </p>
              </div> */}
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Industry
                </label>
                <p className="text-md text-gray-600">
                  {company?.industry ? company?.industry : "-"}
                </p>
              </div>
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Founding Date
                </label>
                <p className="text-md text-gray-600">
                  {company?.found_date ? company?.found_date : "-"}
                </p>
              </div>

              <div className="mb-4">
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Address
                </label>
                <p className="text-md text-gray-600">
                  {company?.address ? company?.address : "-"}
                </p>
              </div>
            </div>
            <div className="w-[50%] flex flex-col space-y-4">
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Phonenumber
                </label>
                <p className="text-md text-gray-600">
                  {company?.phonenumber ? company?.phonenumber : "-"}
                </p>
              </div>
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Company Email
                </label>
                <p className="text-md text-gray-600">
                  {company?.email ? company?.email : "-"}
                </p>
              </div>

              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Website
                </label>
                <p className="text-md text-gray-600">
                  {company?.website ? company?.website : "-"}
                </p>
              </div>
            </div>
          </div>
          <hr className="mt-8 mb-8" />
          <div className="w-[100%] flex space-x-4">
            <div className="w-[50%] flex flex-col space-y-4">
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  PIC Name
                </label>
                <p className="text-md text-gray-600">
                  {company?.person_in_charge ? company?.person_in_charge : "-"}
                </p>
              </div>
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  PIC Job Position
                </label>
                <p className="text-md text-gray-600">
                  {company?.pic_position ? company?.pic_position : "-"}
                </p>
              </div>
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Total Employees
                </label>
                <p className="text-md text-gray-600">
                  {company?.total_employee ? company?.total_employee : "-"}
                </p>
              </div>
            </div>
            <div className="w-[50%] flex flex-col space-y-4">
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Registration Date
                </label>
                <p className="text-md text-gray-600">
                  {company?.created_at
                    ? moment(company?.created_at).format("Y-m-d")
                    : "-"}
                </p>
              </div>
              {/* <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Package
                </label>
                <p className="text-md text-gray-600">{"-"}</p>
              </div> */}
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Package Total
                </label>
                <p className="text-md text-gray-600">
                  {company?.active_packages?.length > 0
                    ? company?.active_packages?.length
                    : "-"}{" "}
                  packages
                </p>
              </div>
              <div>
                <label className="text-gray-800 text-lg font-bold leading-[200%]">
                  Registered Users
                </label>
                <p className="text-md text-gray-600">
                  {company?.total_user ? company?.total_user : "-"} users
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePage;
