import axios from "axios";
import React, { Component } from "react";
import BtnSpinner from "../../../../Component/BtnSpinner";
import { toast } from "react-toastify";
import { inputIsEmailFormat, inputIsOnlyAlphabetic } from "../../../../helpers";
import Tooltip from "../../../../Component/Tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

class CreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      formPassed: false,
      job_department_id: "",
      job_position_id: "",
      client_role_id: "",
      first_name: "",
      last_name: "",
      email: "",
      status: 1,
    };
  }

  handleRoleChange(e) {
    const { value } = e.target;
    this.props.onRoleChange(value);
  }

  onPositionChange(id) {
    this.setState({ job_position_id: id }, () => {
      this.validateForm();
    });
  }

  onAccessChange(id) {
    this.setState({ client_role_id: id }, () => {
      this.validateForm();
    });
  }

  onDepartmentChange(id) {
    this.setState({ job_department_id: id, job_position_id: "" }, () => {
      this.validateForm();
    });
  }

  onChangeFirstName(value) {
    if (inputIsOnlyAlphabetic(value)) {
      this.setState({ first_name: value }, () => {
        this.validateForm();
      });
    }
  }

  onChangeLastName(value) {
    if (inputIsOnlyAlphabetic(value)) {
      this.setState({ last_name: value }, () => {
        this.validateForm();
      });
    }
  }

  onChangeEmail(value) {
    this.setState({ email: value }, () => {
      this.validateForm();
    });
  }

  async handleSubmit() {
    try {
      this.setState({ submitting: true });
      const form = this.state;
      delete form.formPassed;
      delete form.submitting;
      const response = await axios.post(
        "/api/client/users",
        JSON.stringify(form),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      this.setState({ submitting: false });
      if (response.data.success) {
        toast.success(response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1250,
          onClose: () => {
            this.props.onSuccess();
          },
        });
      } else {
        toast.error(response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      this.setState({ submitting: false });
      toast.error(error.response.data.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  validateForm() {
    const {
      job_department_id,
      job_position_id,
      client_role_id,
      first_name,
      last_name,
      email,
    } = this.state;
    if (
      job_department_id != "" &&
      job_position_id != "" &&
      client_role_id != "" &&
      first_name != "" &&
      last_name != "" &&
      email != ""
    ) {
      if (!inputIsEmailFormat(email)) {
        this.setState({ formPassed: false });
      } else {
        this.setState({ formPassed: true });
      }
    } else {
      this.setState({ formPassed: false });
    }
  }

  componentDidMount() {}

  render() {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 14);
    const minDateString = minDate.toISOString().split("T")[0];
    const { departments, positions, roles } = this.props;

    return (
      <>
        <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
          <div className="mb-2">
            <label className="text-gray-800 text-sm font-bold leading-[140%]">
              First Name
            </label>
            <input
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="text"
              name="first_name"
              id="first_name"
              placeholder="Type your first name.."
              value={this.state.first_name}
              onChange={(e) => this.onChangeFirstName(e.target.value)}
              required
            />
          </div>
          <div className="mb-2">
            <label className="text-gray-800 text-sm font-bold leading-[140%]">
              Last Name
            </label>
            <input
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="text"
              name="last_name"
              id="last_name"
              placeholder="Type in your last name.."
              value={this.state.last_name}
              onChange={(e) => this.onChangeLastName(e.target.value)}
              required
              min={minDateString}
            />
          </div>
          <div className="mb-2">
            <label className="text-gray-800 text-sm font-bold leading-[140%]">
              Email
            </label>
            <input
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="email"
              name="email"
              id="email"
              placeholder="Type in your last name.."
              value={this.state.email}
              onChange={(e) => this.onChangeEmail(e.target.value)}
              required
              min={minDateString}
            />
          </div>
          <div className="mb-2">
            <label className="text-gray-800 text-sm font-bold leading-[140%]">
              Department
            </label>
            <select
              className="form-select appearance-none
                                block
                                w-full
                                px-3
                                py-2
                                text-grey-600
                                text-[14px]
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded-md
                                mt-2
                                transition
                                ease-in-out
                                focus:outline-none focus:border-main-violate border border-line-grey"
              aria-label="Default select example"
              value={this.state.job_department_id}
              onChange={(e) => {
                this.onDepartmentChange(e.target.value);
              }}
            >
              <option selected disabled value="">
                Choose your department...
              </option>
              {departments.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2">
            <label className="text-gray-800 text-sm font-bold leading-[140%]">
              Job Position
            </label>
            <select
              className="form-select appearance-none
                                block
                                w-full
                                px-3
                                py-2
                                text-grey-600
                                text-[14px]
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded-md
                                mt-2
                                transition
                                ease-in-out
                                focus:outline-none focus:border-main-violate border border-line-grey"
              aria-label="Default select example"
              value={this.state.job_position_id}
              onChange={(e) => {
                this.onPositionChange(e.target.value);
              }}
            >
              <option selected disabled value="">
                Choose your job position...
              </option>
              {this.state.job_department_id != "" &&
                positions.map((position) => {
                  if (
                    position.job_department_id == this.state.job_department_id
                  ) {
                    return (
                      <option key={position.id} value={position.id}>
                        {position.name}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
          <div className="mb-2">
            <label className="text-gray-800 text-sm font-bold leading-[140%] flex gap-1 items-center">
              Access{" "}
              <Tooltip
              isAbsolute={true}
                element={
                  <div className="p-1">
                    <p className="font-semibold mb-1 text-grey-500">Admin</p>
                    <ol className="list-disc list-inside">
                      <li className="font-normal text-soft-grey text-sm">Dapat digunakan untuk manage posisi yang sudah diberikan access oleh Owner</li>
                      <li className="font-normal text-soft-grey text-sm">Dapat digunakan untuk edit dan view assessment untuk posisi yang di manage</li>
                      <li className="font-normal text-soft-grey text-sm">Dapat digunakan untuk authorise untuk post position / jabatan yang di manage</li>
                    </ol>
                    <p className="font-semibold mt-2 mb-1 text-grey-500">Department</p>
                    <ol className="list-disc list-inside">
                      <li className="font-normal text-soft-grey text-sm">Hanya dapat melihat posisi yang diberikan akses</li>
                    </ol>
                    {/* <p className="font-semibold mt-2 mb-1 text-grey-500">Demo</p>
                    <ol className="list-disc list-inside">
                      <li className="font-normal text-soft-grey text-sm">Untuk akun kebutuhan demo (hanya ada di staging/dev)</li>
                    </ol> */}
                  </div>
                }
                withMinWidth={false}
                type="custom"
                className="max-w-max"
              >
                <InformationCircleIcon className="w-[18px] relative left-[52px]" />
              </Tooltip>
            </label>
            <select
              className="form-select appearance-none
                                block
                                w-full
                                px-3
                                py-2
                                text-grey-600
                                text-[14px]
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded-md
                                mt-2
                                transition
                                ease-in-out
                                focus:outline-none focus:border-main-violate border border-line-grey"
              aria-label="Default select example"
              value={this.state.client_role_id}
              onChange={(e) => {
                this.onAccessChange(e.target.value);
              }}
            >
              <option selected disabled value="">
                Choose your Access..
              </option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
            type="submit"
            disabled={!this.state.formPassed || this.state.submitting}
            onClick={(e) => {
              this.handleSubmit(e);
            }}
          >
            {this.state.submitting ? <BtnSpinner /> : "Save"}
          </button>
        </div>
      </>
    );
  }
}

export default CreateForm;
