import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../../Component/PersonalityRadarChart";
import RangeList2 from "../Components/RangeList2";
import PageSpinner from "../../../../../../Component/PageSpinner";

const DareReports = ({ dare }) => {
    const [communicate, setCommunicate] = useState(null);
    const [dataRange, setDataRange] = useState(null);
    useEffect(() => {
        if (dare) {
            let values = [];
            let labels = [];
            let tempDataRange = [];

            dare.data.map((item) => {
                values.push(item.result)
                labels.push(item.name)
                tempDataRange.push({ parameter: item.name, minLabel: item.label2, maxLabel: item.label1, maxRange: "10", minRange: "0", value: item.result })
            })
            setCommunicate({
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: '#3F83F87A',
                        borderColor: '#3F83F8',
                        borderWidth: 2,
                        hidden: '',
                    },
                ],
            })
            setDataRange(tempDataRange)
        }
    }, [dare])

    return (
        <div className='flex flex-col items-center'>
            <div className='flex w-full items-center mb-7 mdb:mb-5 gap-10 px-2 mdb:px-4 justify-center flex-col mdb:flex-row'>
                <div className="w-full mdb:w-[40%] flex justify-center">
                    <div className="max-w-[520px]">
                        {communicate ? <PersonalityRadarChart data={communicate} /> : <PageSpinner />}
                    </div>
                </div>
                <div className="w-full mdb:w-[60%]">
                    <p className='font-semibold mb-2'>Dare</p>
                    <p className="text-gray-500">Melibatkan individu yang mengambil risiko yang telah diperhitungkan, berpikir kreatif, dan menantang status quo dalam pekerjaan dan proses pengambilan keputusan mereka. Berani dalam konteks profesional berarti memiliki keberanian dan kepercayaan diri untuk mendobrak batasan, mengeksplorasi ide-ide baru, dan mencari solusi inovatif.</p>
                    {/* <p className="text-gray-500">Involves individuals taking calculated risks, thinking creatively, and challenging the status quo in their work and decision-making processes. Being daring in a professional context means having the courage and confidence to push boundaries, explore new ideas, and seek innovative solutions.</p> */}
                    {/* <ul className="list-disc list-inside text-gray-500">
                        <li>This person is able to trust his or her judgem ent and overcome her doubts when they are not certain.</li>
                        <li>This person has his or her own opinions but is still sensitiv e t o criticism.</li>
                        <li>This person prefers to rely on an existing approach and is rather convent ional.</li>
                        <li>This person is able to trust his or her judgem ent and overcome her doubts when they are not certain.</li>
                    </ul> */}
                </div>
            </div>
            {communicate ? <>
                {dataRange.map((item, index) => (
                    <RangeList2 data={item} />
                ))}
            </> : <PageSpinner />}

        </div>
    )
}

export default DareReports;