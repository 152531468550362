import axios from 'axios';

export const getDetailCandidateJob = async (id = '') => {
    try {
        const response = await axios
            .get(`/api/client/candidate-jobs/${id}`, {
                headers: {
                    "Content-Type": "application/json"
                },
            })
        return response.data;
    } catch (error) {
        return error.response.data;
    }

};