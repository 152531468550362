import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BuildingOfficeIcon, PencilIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import PageSpinner from "../../../Component/PageSpinner";
import moment from "moment";
import BtnSpinner from "../../../Component/BtnSpinner";
import BreadCrumb from "../../../Component/BreadCrumb";
import Compressor from "compressorjs";
import { documentTitle } from "../../../helpers";
import { useSelector } from "react-redux";
const CompanyProfilesPage = () => {
  let navigate = useNavigate();
  const imageFileRef = useRef(null);
  const permission = useSelector((state) => state.user.permissions);
  // TEST DEPLOY & BUILD
  const [user, setUser] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const [loading, setLoading] = useState(false);
  const [information, setInformation] = useState({});
  const [uploading, setUploading] = useState(false);
  const [userFullname, setUserFullname] = useState(() => {
    if (user) {
      return user.useraccount_fullname || "";
    }
  });

  const getInformation = async () => {
    setLoading(true);
    await axios
      .get("/api/client/company", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setInformation(result.data);
        }
      })
      .catch((e) => {
        // console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showEditProfileModal = () => {};

  const upload = (e) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (!file) {
      return;
    }
    if (file.size > maxSize) {
      alert(
        "Image size exceeds the maximum limit (2MB). Please select a smaller image."
      );
      return;
    }

    setUploading(true);
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 500,
      minHeight: 500,
      mimeType: "image/webp",
      success(result) {
        const formData = new FormData();
        formData.append("image_file", result);
        axios
          .post("/api/client/company-image/upload", formData)
          .then((response) => {
            const result = response.data;
            if (result.success) {
              setInformation(result.data);
              toast.success(result.message, {
                toastId: "login-success-toast",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1250,
              });
            }
          })
          .catch((e) => {
            toast.error(e.response.data.message, {
              toastId: "login-error-toast",
              position: toast.POSITION.TOP_CENTER,
            });
          })
          .finally(() => {
            setUploading(false);
          });
      },
      error() {},
    });
  };

  useEffect(() => {
    getInformation();
    documentTitle("Company Profile");
  }, []);
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  const leftCard = {
    boxShadow:
      "0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
    borderRadius: "12px",
    background: "#FFF",
    padding: "24px",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />

      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <BuildingOfficeIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Company Profile</p>
          <BreadCrumb list={[{ href: null, title: "Company Profile" }]} />
        </div>
      </div>

      <div className="report-list">
        <div className="card flex flex-col md:flex-row w-full gap-7 justify-center">
          {loading ? (
            <PageSpinner />
          ) : (
            <>
              <div style={leftCard} className="w-full md:w-[25%]">
                <div className="flex flex-col items-center">
                  {information.image_file ? (
                    <>
                      <div className="image-box rounded-[50%] overflow-hidden h-[120px] w-[120px] xl:h-[220px] xl:w-[220px] mb-6">
                        <img
                          src={information.image_file}
                          alt="dummy profile "
                          className="w-full h-full object-cover object-center"
                        />
                      </div>
                    </>
                  ) : (
                    <div className="image-box rounded-[50%] overflow-hidden h-[120px] w-[120px] xl:h-[220px] xl:w-[220px] mb-6 bg-gray-200 px-5 py-5">
                      <BuildingOfficeIcon className="w-full text-gray-400" />
                    </div>
                  )}

                  <div className="w-full">
                    {(
                      <>
                        <button
                          className="order border-main-violate border w-full px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate-100 disabled:border-gray-500 disabled:cursor-not-allowed disabled:text-gray-500 disabled:bg-gray-100"
                          type=""
                          disabled={uploading || !permission?.company_profile?.includes("update") }
                          onClick={(e) => {
                            e.preventDefault();
                            imageFileRef.current.click();
                          }}
                        >
                          {uploading ? <BtnSpinner /> : "Select Logo"}
                        </button>
                        <input
                          type="file"
                          className="h-full w-full opacity-0 cursor-pointer hidden"
                          name="uploadcv"
                          accept="image/*"
                          onChange={upload}
                          ref={imageFileRef}
                        />
                        <p className="text-sm text-grey-500  leading-[1.5] mb-1 mt-3">
                          File size: 10 Mb (maximum). Allowed file extensions:
                          .JPG .JPEG .PNG
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full md:w-[75%]">
                <div className="company-title flex justify-between mb-10">
                  <p className="text-xl font-bold gray-900">Company Profile</p>
                  {permission?.company_profile?.includes("update") && (
                    <>
                      <button
                        className="bg-main-violate px-4 text-[12px] leading-[22.4px] text-white font-semibold h-[32px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed flex items-center"
                        type="submit"
                        onClick={(e) => {
                          navigate("/client/company-profile/edit");
                        }}
                      >
                        <PencilIcon width={15} className="mr-3" /> Edit Profile
                      </button>
                    </>
                  )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="company-body">
                    <div className="grid grid-cols-2 text-[14px] leading-[140%]">
                      <div className="py-2 font-semibold">Company Name</div>
                      <div className="px-4 py-2">: {information.name}</div>
                    </div>
                    <div className="grid grid-cols-2 text-[14px] leading-[140%]">
                      <div className="py-2 font-semibold">Found Date</div>
                      <div className="px-4 py-2">
                        :{" "}
                        {information.found_date
                          ? moment(information.found_date).format(
                              "DD MMMM YYYY"
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 text-[14px] leading-[140%]">
                      <div className="py-2 font-semibold">Industry</div>
                      <div className="px-4 py-2">
                        : {information.industry ? information.industry : "-"}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 text-[14px] leading-[140%]">
                      <div className="py-2 font-semibold">Address</div>
                      <div className="px-4 py-2 ">
                        : {information.address ? information.address : "-"}
                      </div>
                    </div>
                  </div>
                  <div className="company-body">
                    <div className="grid grid-cols-2 text-[14px] leading-[140%]">
                      <div className="py-2 font-semibold">Total Employees</div>
                      <div className="px-4 py-2">
                        :{" "}
                        {information.total_employee
                          ? information.total_employee
                          : "0"}{" "}
                        Employees
                      </div>
                    </div>
                    <div className="grid grid-cols-2 text-[14px] leading-[140%]">
                      <div className="py-2 font-semibold">PIC Name</div>
                      <div className="px-4 py-2">
                        :{" "}
                        {information.person_in_charge
                          ? information.person_in_charge
                          : "-"}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 text-[14px] leading-[140%]">
                      <div className="py-2 font-semibold">
                        Subscription Package
                      </div>
                      <div className="px-4 py-2">: -</div>
                    </div>
                  </div>
                </div>
                <div className="company-title flex mb-5 mt-10">
                  <p className="text-xl font-bold gray-900">Contact</p>
                </div>
                <div className="flex">
                  <div className="company-body w-[55%]">
                    <div className="grid grid-cols-2 text-[14px] leading-[140%]">
                      <div className="py-2 font-semibold">Email</div>
                      <div className="px-4 py-2 flex">
                        {" "}
                        :
                        <p>
                          &nbsp;{information.email ? information.email : "-"}
                        </p>
                        {information.verified ? (
                          <p className="bg-green-100 text-green-800 font-medium text-center text-xs px-2  rounded text ml-4">
                            verified
                          </p>
                        ) : (
                          <p className="bg-red-100 text-red-800 font-medium text-center text-xs px-2  rounded text ml-4">
                            not verified
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 text-[14px] leading-[140%]">
                      <div className="py-2 font-semibold">Phone Number</div>
                      <div className="px-4 py-2 flex">
                        {" "}
                        :
                        <p>
                          &nbsp;
                          {information.phonenumber
                            ? information.phonenumber
                            : "-"}
                        </p>
                        {information.verified ? (
                          <p className="bg-green-100 text-green-800 font-medium text-center text-xs px-2  rounded text ml-4">
                            verified
                          </p>
                        ) : (
                          <p className="bg-red-100 text-red-800 font-medium text-center text-xs px-2  rounded text ml-4">
                            not verified
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* TABLE CONTENT */}
        </div>
      </div>
    </div>
  );
};

export default CompanyProfilesPage;
