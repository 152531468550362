import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PresentationChartLineIcon } from "@heroicons/react/24/solid"
import BtnSpinner from '../../../Component/BtnSpinner';
import axios from 'axios';
import BreadCrumb from '../../../Component/BreadCrumb';
import Select from 'react-select';
import { documentTitle } from '../../../helpers';
const ReportsPage = () => {

  let navigate = useNavigate();
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [positionsFilter, setPositionsFilter] = useState([]);
  const [rolesFilter, setRolesFilter] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [getting] = useState(false);
  const [form, setForm] = useState({
    job_department_id: null,
    job_position_id: null,
    job_id: null
  })

  const getDepartments = async () => {
    try {
      const response = await axios.get(
        "/api/client/assessment/job-department-list"
      );
      const result = response.data;
      if (result.success) {
        setDepartments(result.data);
      }
    } catch (e) {
    }
  };

  const getPositions = async () => {
    try {
      const response = await axios.get(
        "/api/client/assessment/job-position-list"
      );
      const result = response.data;
      if (result.success) {
        setPositions(result.data);
      }
    } catch (e) {
    }
  };

  const getRoles = async () => {
    try {
      const response = await axios.get(
        "/api/client/assessment/job-role-list"
      );
      const result = response.data;
      if (result.success) {
        setRoles(result.data);
      }
    } catch (e) {
    }
  };

  const handleDepartmentChange = (value) => {
    setForm({ ...form, job_department_id: value, job_position_id: "", job_id: "" })
    setPositionsFilter(positions.filter((position) => {
      return position.job_department_id === value;
    }))
  }
  const handlePositionChange = (value) => {
    setForm({ ...form, job_position_id: value, job_id: "" })
    setRolesFilter(roles.filter((role) => {
      return role.job_position_id === value;
    }))
  }
  const handleRoleChange = (value) => {
    setForm({ ...form, job_id: value })
  }
  const getReports = async () => {
    navigate(`/client/reports/candidate-list/${form.job_id}`)
  }

  useEffect(() => {
    documentTitle("Reports")
    getDepartments();
    getPositions();
    getRoles();
  }, [])
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });


  useEffect(() => {
    setPositionsFilter(positions.filter((position) => {
      return position.job_department_id === form.job_department_id;
    }))

  }, [selectedDepartment])

  useEffect(() => {
    setRolesFilter(roles.filter((role) => {
      return role.job_position_id === form.job_position_id;
    }))

  }, [selectedPosition])
  useEffect(() => {
    console.log(rolesFilter)

  }, [rolesFilter])

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: 'calc(100vh - 80px)',
    marginTop: '-10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div style={myStyle} className='px-[10px] md:px-[44px] py-[24px]'>
      <ToastContainer />
      <div className='card flex w-full mb-6'>
        <div className='bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6'>
          <PresentationChartLineIcon className='w-[30px] text-main-violate' />
        </div>
        <div className='flex flex-col justify-around'>
          <p className='text-xl text-grey-600 font-bold'>Reports</p>
          <BreadCrumb list={[{ href: null, title: "Reports" }]} />
        </div>
      </div>

      <div className='reports mb-6'>
        <div className='card flex flex-col w-full'>
          <div>
            {/* <form className="bg-grey-lightest" name="create_candidate" onSubmit={getReports}> */}
            <div className='grid-cols-1 md:grid-cols-2 grid gap-4 mb-6'>
              <div className="mb-2">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Department
                </label>
                <Select className="py-0.5 rounded-lg mt-2 border border-line-grey" placeholder={"Choose your department..."} classNamePrefix={"select"} options={departments.map((option) => {
                  return {
                    value: option.id,
                    label: option.name
                  }
                })} value={selectedDepartment} onChange={(e) => {
                  setSelectedDepartment(e)
                  setSelectedPosition(null)
                  setSelectedRole(null)
                  handleDepartmentChange(e.value);
                }} />

                {/* <select
                  className="form-select appearance-none
                                block
                                w-full
                                px-3
                                py-2
                                text-grey-600
                                text-[14px]
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded-md
                                mt-2
                                transition
                                ease-in-out
                                focus:outline-none focus:border-main-violate border border-line-grey"
                  aria-label="Default select example"
                  value={form.job_department_id}
                  onChange={(e) => {
                    handleDepartmentChange(e.target.value);
                  }}
                >
                  <option selected disabled value="">
                    Choose your department...
                  </option>
                  {departments.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </select> */}

              </div>
              <div className="mb-2">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Job Position
                </label>
                <Select className="py-0.5 rounded-lg mt-2 border border-line-grey" placeholder={"Choose your position..."} classNamePrefix={"select"} options={positionsFilter.map((option) => {
                  return {
                    value: option.id,
                    label: option.name
                  }
                })} value={selectedPosition} onChange={(e) => {
                  setSelectedPosition(e)
                  setSelectedRole(null)
                  handlePositionChange(e.value);
                }} />
                {/* <select
                  className="form-select appearance-none
                                block
                                w-full
                                px-3
                                py-2
                                text-grey-600
                                text-[14px]
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded-md
                                mt-2
                                transition
                                ease-in-out
                                focus:outline-none focus:border-main-violate border border-line-grey"
                  aria-label="Default select example"
                  value={form.job_position_id}
                  onChange={(e) => {
                    handlePositionChange(e.target.value);
                  }}
                >
                  <option selected disabled value="">
                    Choose job position...
                  </option>
                  {form.job_department_id != '' &&
                    positions.map((position) => {
                      if (form.job_department_id == position.job_department_id) {
                        return (
                          <option key={position.id} value={position.id}>
                            {position.name}
                          </option>
                        )
                      }
                    })
                  }
                </select> */}
              </div>
              <div className="mb-2">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Job Role
                </label>
                <Select className="py-0.5 rounded-lg mt-2 border border-line-grey" placeholder={"Choose your roles..."} classNamePrefix={"select"} options={rolesFilter.map((option) => {
                  return {
                    value: option.id,
                    label: option.job_role
                  }
                })} value={selectedRole} onChange={(e) => {
                  setSelectedRole(e)
                  handleRoleChange(e.value);
                }} />
                {/* <select
                  className="form-select appearance-none
                                block
                                w-full
                                px-3
                                py-2
                                text-grey-600
                                text-[14px]
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded-md
                                mt-2
                                transition
                                ease-in-out
                                focus:outline-none focus:border-main-violate border border-line-grey"
                  aria-label="Default select example"
                  value={form.job_id}
                  onChange={(e) => {
                    handleRoleChange(e.target.value);
                  }}
                >
                  <option selected disabled value="">
                    Choose your job roles...
                  </option>
                  {form.job_position_id != '' &&
                    roles.map((role) => {
                      if (form.job_position_id == role.job_position_id) {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.job_role}
                          </option>
                        )
                      }
                    })
                  }

                </select> */}
              </div>
            </div>
            <div className='w-full flex justify-end'>
              <button
                className=" bg-main-violate px-8 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={getting}
                onClick={getReports}
              >
                {getting ? <>
                  <BtnSpinner />
                </> : <>Apply</>}

              </button>
            </div>

            {/* </form> */}

          </div>
        </div>
      </div>
    </div >
  )
}

export default ReportsPage;
