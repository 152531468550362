import React from "react";
import { useState, useEffect } from "react";
import Motivation from "./ParentTabs/Motivation";
import Professional from "./ParentTabs/Professional";
import EmotionalQuotient from "./ParentTabs/EmotionalQuotient";
import EmptyState from "../../Components/EmptyState";
import PersonalityTraits from "./ParentTabs/PersonalityTraits";
import AntiCheat from "../Overview/AntiCheat";
const PersonalityDetail = ({
  parentTabChange,
  tabChange,
  currentTab,
  currentParentTab,
  professionalBehaviour,
  motivation,
  emotional,
  personalityTraits,
}) => {
  const [activePersonalityTab, setActivePersonalityTab] = useState(null);
  const [activePersonalityParentTab, setActivePersonalityParentTab] =
    useState(null);
  const [showCheating, setShowCheating] = useState(false);
  const showPersonalityTab = (tabId) => {
    tabChange(tabId);
  };
  const showPersonalityParentTab = (tabId) => {
    parentTabChange(tabId);
  };

  useEffect(() => {
    setActivePersonalityTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    setActivePersonalityParentTab(currentParentTab);
  }, [currentParentTab]);

  useEffect(() => {
    if (currentParentTab === null) {
      if (professionalBehaviour?.is_choosen) {
        setActivePersonalityParentTab("professional");
      } else if (emotional?.is_choosen) {
        setActivePersonalityParentTab("emotional-quotient");
      } else if (personalityTraits?.is_choosen) {
        setActivePersonalityParentTab("personality-traits");
      }
    }
  }, []);

  const TabContent = () => {
    if (activePersonalityParentTab === "professional") {
      if (professionalBehaviour.result.length > 0) {
        if (showCheating) {
          return <AntiCheat records={professionalBehaviour.records} />;
        }
        return (
          <Professional
            tabChange={tabChange}
            currentTab={currentTab}
            dataRaw={professionalBehaviour.result}
          />
        );
      } else {
        return <EmptyState />;
      }
    } else if (activePersonalityParentTab === "motivation") {
      if (professionalBehaviour.result.length > 0) {
        if (showCheating) {
          return <AntiCheat records={motivation.records} />;
        }
        return (
          <Motivation
            motivation={motivation.result.find(
              (item) => item.category === "Motivations Radar"
            )}
          />
        );
      } else {
        return <EmptyState />;
      }
    } else if (activePersonalityParentTab === "emotional-quotient") {
      if (emotional.result.length > 0) {
        if (showCheating) {
          return <AntiCheat records={emotional.records} />;
        }
        return (
          <EmotionalQuotient
            emotional={emotional.result.length > 0 ? emotional.result[0] : []}
          />
        );
      } else {
        return <EmptyState />;
      }
    } else if (activePersonalityParentTab === "personality-traits") {
      if (personalityTraits.result.length > 0) {
        if (showCheating) {
          return <AntiCheat records={personalityTraits.records} />;
        }
        return (
          <PersonalityTraits
            data={
              personalityTraits.result.length > 0
                ? personalityTraits.result[0]
                : []
            }
          />
        );
      } else {
        return <EmptyState />;
      }
    }
  };
  return (
    <div className="card-mini mdb:card flex flex-col justify-between w-full mb-5 anti-cheat">
      <div className="tab w-full mb-6">
        <div className="flex h-[100%] justify-between items-center w-full overflow-x-auto">
          <nav>
            <div className="flex">
              {professionalBehaviour?.is_choosen && (
                <>
                  <a
                    href="#"
                    className={`${
                      activePersonalityParentTab === "professional"
                        ? "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 border border-gray-200 bg-gray-200 flex-shrink-0"
                        : "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 border border-gray-200 flex-shrink-0"
                    }`}
                    onClick={() =>
                      currentParentTab != "professional" &&
                      showPersonalityParentTab("professional")
                    }
                  >
                    Professional Behaviour
                  </a>
                </>
              )}
              {professionalBehaviour?.is_choosen && (
                <>
                  <a
                    href="#"
                    className={`${
                      activePersonalityParentTab === "motivation"
                        ? "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 border border-gray-200 bg-gray-200 flex-shrink-0"
                        : "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 border border-gray-200 flex-shrink-0"
                    }`}
                    onClick={() =>
                      currentParentTab != "motivation" &&
                      showPersonalityParentTab("motivation")
                    }
                  >
                    Motivation
                  </a>
                </>
              )}

              {emotional?.is_choosen && (
                <>
                  <a
                    href="#"
                    className={`${
                      activePersonalityParentTab === "emotional-quotient"
                        ? "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 border border-gray-200  bg-gray-200 flex-shrink-0"
                        : "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 border border-gray-200 flex-shrink-0"
                    }`}
                    onClick={() =>
                      currentParentTab != "emotional-quotient" &&
                      showPersonalityParentTab("emotional-quotient")
                    }
                  >
                    Emotional Quotient
                  </a>
                </>
              )}

              {personalityTraits?.is_choosen && (
                <>
                  <a
                    href="#"
                    className={`${
                      activePersonalityParentTab === "personality-traits"
                        ? "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 border border-gray-200  bg-gray-200 flex-shrink-0"
                        : "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 border border-gray-200 flex-shrink-0"
                    }`}
                    onClick={() =>
                      currentParentTab != "personality-traits" &&
                      showPersonalityParentTab("personality-traits")
                    }
                  >
                    Personality Traits
                  </a>
                </>
              )}
            </div>
          </nav>
          <button
            className="flex items-center gap-2 border border-main-violate hover:bg-purple-200  px-2 text-xs leading-[22.4px] text-main-violate font-semibold h-[26px] rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
            type="button"
            onClick={(e) => setShowCheating(!showCheating)}
          >
            {showCheating ? "Show Result" : "Show Cheating"}
          </button>
        </div>
      </div>
      <TabContent />
    </div>
  );
};

export default PersonalityDetail;
