import axios from "axios";

export const getMyClient = async (queryString = "") => {
  try {
    const response = await axios.get(`/api/admin/client?${queryString}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getClient = async (id) => {
  try {
    const response = await axios.get(`/api/admin/client/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createClientUser = async (form = null) => {
  try {
    const response = await axios.post(`/api/admin/client`, form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateClientUser = async (form = null) => {
  try {
    const response = await axios.put(`/api/admin/client/${form.id}`, form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


