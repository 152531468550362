import SimpleTable from "../../../../Component/Tables/SimpleTable";
import DropdownDots from "../../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import PopUp from "../../../../Component/PopUp";
import DetailTestPreview from "../../../../Component/DetailTestPreview";
import { useState } from "react";

const PreviewTest = ({ form, department, position, tests, onDelete }) => {
    const [showDetail, setShowDetail] = useState(false);
    const [showSelectedDetail, setShowSelectedDetail] = useState({});
    const handleDetail = (id) => {
        setShowSelectedDetail(tests.find(test => test.id === id));
        setShowDetail(true);
    }
    const handleDelete = (id) => {
        onDelete(id);
    }
    const TableContent = () => {

        return (
            <>
                {tests.map((value, index) => {
                    return (
                        <tr className="whitespace-nowrap border-b-[1px]">
                            <td className="px-6 py-4">
                                <div className="text-sm text-gray-500">{value.name}</div>
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500">
                                {value.assessment.name}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500">
                                {value.question_type_label}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500">
                                {value.total_question}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500">
                                {((value.times) / 60).toFixed(0)} minutes
                            </td>
                            <td className="px-6 py-4">
                                <DropdownDots isPublish={value.is_publish}>
                                    <Dropdown.Item onClick={(e) => handleDetail(value.id)}>
                                        <InformationCircleIcon width={16} className='mr-2' />
                                        Detail
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => handleDelete(value.id)}>
                                        <TrashIcon width={16} className='mr-2' />
                                        Delete
                                    </Dropdown.Item>
                                </DropdownDots>
                            </td>
                        </tr>
                    )
                })}
            </>
        )

    }

    return (
        <>
            <div className='card grid-cols-1 md:grid-cols-2 grid gap-4 mb-6'>
                <div className="mb-2">
                    <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                        Department
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Type your job role..."
                        value={department ? department.name : ""}
                        disabled={true}
                    />
                </div>
                <div className="mb-2">
                    <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                        Job Position
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Type your job role..."
                        value={position ? position.name : ""}
                        disabled={true}
                    />
                </div>
                <div className="mb-2">
                    <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                        Job Role
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Type your job role..."
                        value={form.job_role}
                        disabled={true}
                    />
                </div>
                <div className="mb-2">
                    <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                        Deadline
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                        type="date"
                        name="title"
                        id="title"
                        placeholder='Choose date...'
                        value={form.days_to_expiry}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="card mb-6">
                <div className='flex flex-col justify-around mb-7'>
                    <p className='text-xl text-grey-600 font-bold'>Test List</p>
                </div>
                <div className="border-b border-gray-200 shadow mb-10">
                    <SimpleTable checkbox={false} headers={["TEST", "ASSESSMENT", "QUESTION TYPE", "ITEMS", "TIME", "ACTION"]} content={<TableContent />} />
                </div>
            </div>
            {
                showDetail ? <PopUp><DetailTestPreview data={showSelectedDetail} onClose={(e) => setShowDetail(false)} /></PopUp> : ''
            }
        </>
    );
}

export default PreviewTest;