import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ArrowUturnRightIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import { InformationCircleIcon as InformationCircleIconOutline } from "@heroicons/react/24/outline";
import CheckSpam from "./Popup/CheckSpam";
import EditEmailPopup from "./Popup/EditEmailPopup";
import moment from "moment";
import PaginationTable from "../../../Component/Tables/PaginationTable";
import TableSpinner from "../../../Component/TableSpinner";
import FilterTable from "../../../Component/Tables/FilterTable";
import EmptyContent from "../../../Component/Tables/EmptyContent";
import { Dropdown } from "flowbite-react";
import DropdownDots from "../../../Component/Tables/DropdownDots";
import PopUp from "../../../Component/PopUp";
import MaybeRatePopup from "./Popup/MaybeRatePopup";
import GoodFitPopup from "./Popup/GoodFitPopup";
import NotFitPopup from "./Popup/NotFitPopup";
import SearchTable from "../../../Component/Tables/SearchTable";
import { resendCandidateInvitation } from "../../../api/jobsApi";
import { updateCandidate } from "../../../api/candidateApi";
import { useSelector } from "react-redux";
import MiniForbidden from "../../MiniForbidden";
import axios from "axios";
import { rupiahFormat } from "../../../helpers";
import cities from "../../../data/cities.json";
import provinces from "../../../data/states.json";
import {
  BanknotesIcon,
  MapPinIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/outline";
import PaginationPage from "../../../Component/Tables/PaginationPage";
import PageSpinner from "../../../Component/PageSpinner";
import Tooltip from "../../../Component/Tooltip";
const CandidateInJobList = ({
  id,
  view = "table",
  position = null,
  refresh = false,
}) => {
  let navigate = useNavigate();
  const permission = useSelector((state) => state.user.permissions);
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const [activeTab, setActiveTab] = useState("all");

  const [candidateQuery, setCandidateQuery] = useState(null);
  const [showCheckSpam, setShowCheckSpam] = useState(false);
  const [showMaybeModal, setShowMaybeModal] = useState(false);
  const [showGoodFitModal, setShowGoodFitModal] = useState(false);
  const [showNotFitModal, setShowNotFitModal] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);
  const [currentEditCandidate, setCurrentEditCandidate] = useState(null);
  const [filterExp, setFilterExp] = useState(null);
  const [filterRatingStatus, setFilterRatingStatus] = useState(null);
  const [filterEducation, setFilterEducation] = useState(null);
  const [filterExpectedSalary, setFilterExpectedSalary] = useState(null);
  const [selectedFilterEducation, setSelectedFilterEducation] = useState(null);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [selectedFilterSalary, setSelectedFilterSalary] = useState(null);
  const [selectedFilterExp, setSelectedFilterExp] = useState(null);
  const [currentCandidateJobId, setCurrentCandidateJobId] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [candidates, setCandidates] = useState([]);
  const [tableCandidateLoading, setTableCandidateLoading] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const getCandidateInPosition = async () => {
    setTableCandidateLoading(true);
    const queryString = new URLSearchParams(candidateQuery).toString();
    await axios
      .get(
        `/api/client/assessment/job-position/${id}/candidates?${queryString}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setTableCandidateLoading(false);
          setCandidates(result.data.data);
          setFilterStatus(result.additional.filter.status);
          setFilterRatingStatus(result.additional.filter.rating_status);
          setFilterExpectedSalary(result.additional.filter.expected_salary);
          setFilterEducation(result.additional.filter.last_education);
          setFilterExp(result.additional.filter.experience);
          setCurrentPage(result.data.current_page);
          setItemsPerPage(result.data.per_page);
          setItemsFrom(result.data.from);
          setItemsTo(result.data.to);
          setTotalItems(result.data.total);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleSearch = (keyword) => {
    setCandidateQuery({ ...candidateQuery, search: keyword });
  };

  const handleFilterStatus = (keyword) => {
    setCandidateQuery({ ...candidateQuery, status: keyword });
  };
  const handleFilterSalary = (keyword) => {
    setSelectedFilterSalary(keyword);
    setCandidateQuery({ ...candidateQuery, expected_salary: keyword });
  };

  const handleFilterRatingStatus = (keyword) => {
    setCandidateQuery({ ...candidateQuery, rating_status: keyword });
  };
  const handleFilterExp = (keyword) => {
    setSelectedFilterExp(keyword);
    setCandidateQuery({ ...candidateQuery, experience: keyword });
  };
  const handleFilterEducation = (keyword) => {
    setSelectedFilterEducation(keyword);
    setCandidateQuery({ ...candidateQuery, last_education: keyword });
  };
  const handleChangeLimit = (value) => {
    setItemsPerPage(value);
    setCandidateQuery({ ...candidateQuery, limit: value });
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    // setSelectedFilterRatingStatus("");
    setSelectedFilterStatus("");
    setSelectedFilterSalary("");
    setSelectedFilterExp("");
    setSelectedFilterEducation("");
    setCandidateQuery({
      ...candidateQuery,
      rating_status: "",
      status: "",
      expected_salary: "",
      experience: "",
      last_education: "",
    });
  };

  useEffect(() => {
    getCandidateInPosition();
  }, [candidateQuery]);

  const handlePaginate = (page) => {
    setCandidateQuery({ ...candidateQuery, page: page });
  };

  const handleDetailCandidate = (id, candidate_job_id) => {
    navigate(`/client/my-candidates/${id}/${candidate_job_id}`);
  };

  const handleMaybeConfirm = (value) => {
    if (value) {
      submitApprovalCandidate(1);
    }
    setShowMaybeModal(false);
    setCurrentCandidateJobId(null);
  };

  const handleNotFitConfirm = (value) => {
    submitApprovalCandidate(0, value);
    setShowNotFitModal(false);
    setCurrentCandidateJobId(null);
  };

  const handleGoodFitConfirm = (value) => {
    submitApprovalCandidate(2, value);
    setShowGoodFitModal(false);
    setCurrentCandidateJobId(null);
  };

  const handleResendCandidate = async (candidate_id) => {
    setTableCandidateLoading(true);
    const result = await resendCandidateInvitation({
      candidate_id: candidate_id,
      company_job_position_id: position.id,
    });
    setTableCandidateLoading(false);
    if (result.success) {
      setShowCheckSpam(true);
    } else {
      toast.error(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    // setShowGoodFitModal(false)
    // setCurrentCandidateJobId(null)
  };

  const handleEditEmailCandidate = async (candidate_id, candidate) => {
    setShowEditEmail(true);
    setCurrentEditCandidate(candidate);
  };

  const handleApprovalCandidate = async (candidateJobId, rate) => {
    setCurrentCandidateJobId(candidateJobId);
    if (rate == 1) {
      setShowMaybeModal(true);
    } else if (rate == 2) {
      setShowGoodFitModal(true);
    } else {
      setShowNotFitModal(true);
    }
  };

  const handleSubmitEditEmail = async (value) => {
    setUpdating(true);
    const form = {
      username: value.username,
      phonenumber: value.phonenumber,
      email: value.newEmail,
      name: value.name,
    };
    const result = await updateCandidate(currentEditCandidate.id, form);
    if (result.success) {
      setCurrentEditCandidate(null);
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
      setShowEditEmail(false);
      getCandidateInPosition();
    } else {
      if (result.status_code == 422) {
        let messages = Object.values(result.message).flat();
        toast.error(messages[0], {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(result.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setUpdating(false);
  };

  // const handleLiveInterview = async (candidate_id) => {}

  const submitApprovalCandidate = async (rate, email_body = "") => {
    setTableCandidateLoading(true);
    await axios
      .post(
        `/api/client/assessment/job-position/toggle-rate/${currentCandidateJobId}/${rate}`,
        {
          email_body: email_body,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success(result.message, {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1250,
          });
          getCandidateInPosition();
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const StickyContent = () => {
    if (!tableCandidateLoading) {
      return (
        <>
          {candidates.length > 0 ? (
            <>
              {candidates.map((candidate) => {
                return (
                  <tr className="whitespace-nowrap border-b-[1px]">
                    <td className="px-6 py-[18.4px]">
                      <DropdownDots>
                        <Dropdown.Item
                          onClick={(e) =>
                            handleDetailCandidate(
                              candidate.id,
                              candidate.candidate_job_id
                            )
                          }
                        >
                          <InformationCircleIcon
                            color="#6B7280"
                            width={16}
                            className="mr-2"
                          />{" "}
                          Detail
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) =>
                            handleEditEmailCandidate(candidate.id, candidate)
                          }
                        >
                          <PencilSquareIcon
                            color="#6B7280"
                            width={16}
                            className="mr-2"
                          />{" "}
                          Change Email
                        </Dropdown.Item>
                        {candidate.status == 0 && (
                          <>
                            <Dropdown.Item
                              onClick={(e) =>
                                handleResendCandidate(candidate.id)
                              }
                            >
                              <ArrowUturnRightIcon
                                color="#6B7280"
                                width={16}
                                className="mr-2"
                              />{" "}
                              Resend Invitation
                            </Dropdown.Item>
                          </>
                        )}

                        {candidate.status != 2 ? (
                          <></>
                        ) : (
                          <>
                            <Dropdown.Item
                              onClick={(e) =>
                                handleApprovalCandidate(
                                  candidate.candidate_job_id,
                                  2
                                )
                              }
                            >
                              <CheckCircleIcon
                                color="#6B7280"
                                width={16}
                                className="mr-2"
                              />{" "}
                              Good Fit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) =>
                                handleApprovalCandidate(
                                  candidate.candidate_job_id,
                                  1
                                )
                              }
                            >
                              <QuestionMarkCircleIcon
                                color="#6B7280"
                                width={16}
                                className="mr-2"
                              />{" "}
                              Maybe
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) =>
                                handleApprovalCandidate(
                                  candidate.candidate_job_id,
                                  0
                                )
                              }
                            >
                              <XCircleIcon
                                color="#6B7280"
                                width={16}
                                className="mr-2"
                              />{" "}
                              Not Fit
                            </Dropdown.Item>
                          </>
                        )}
                      </DropdownDots>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  const StatusInvitationLabel = ({ status = 0 }) => {
    if (status == 0) {
      return (
        <p className="bg-red-100 text-red-800 font-medium text-center text-xs py-1 rounded text px-2">
          Not Used
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          Used
        </p>
      );
    }
  };
  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0 || status == 3) {
      return (
        <p className="bg-red-100 text-red-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 2) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };

  const TableCandidateContent = () => {
    if (tableCandidateLoading) {
      return <TableSpinner colSpan={"100%"} />;
    } else {
      return (
        <>
          {candidates.length > 0 ? (
            <>
              {candidates.map((candidate, index) => {
                return (
                  <tr
                    className="whitespace-nowrap border-b-[1px] hover:bg-line-grey cursor-pointer"
                    onClick={() =>
                      handleDetailCandidate(
                        candidate.id,
                        candidate.candidate_job_id
                      )
                    }
                  >
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {itemsPerPage * (currentPage - 1) + index + 1}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.name}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.email}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {moment(candidate.created_at).format("MMM D, YYYY")}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate?.personal_detail?.educational
                          ? candidate?.personal_detail?.educational
                              ?.educational_degree.name +
                            ", " +
                            candidate?.personal_detail?.educational?.institution
                          : "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.personal_detail.work_experience != null
                          ? moment
                              .duration(
                                candidate.personal_detail.work_experience,
                                "months"
                              )
                              .years() +
                            " Years, " +
                            moment
                              .duration(
                                candidate.personal_detail.work_experience,
                                "months"
                              )
                              .months() +
                            " Months"
                          : "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.expected_salary
                          ? rupiahFormat(candidate.expected_salary)
                          : "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <StatusLabel
                        status={candidate.status}
                        label={candidate.status_label}
                      />
                    </td>
                    <td className="px-6 py-4">
                      <StatusInvitationLabel
                        status={candidate?.invitation?.is_used}
                      />
                    </td>
                    <td className="px-6 py-4">
                      {candidate.rating_status ? (
                        <StatusLabel
                          status={candidate.rating_status}
                          label={candidate.rating_status_label}
                        />
                      ) : candidate.rating_status == 0 ? (
                        <StatusLabel
                          status={candidate.rating_status}
                          label={candidate.rating_status_label}
                        />
                      ) : (
                        "-"
                      )}
                    </td>

                    {/* <td className="px-6 py-4">
                    <DropdownDots>
                      <Dropdown.Item onClick={(e) => handleDetailCandidate(candidate.id, candidate.candidate_job_id)}>
                        <InformationCircleIcon color='#6B7280' width={16} className='mr-2' /> Detail
                      </Dropdown.Item>
                      {candidate.status != 2 ? <></> : <>
                        <Dropdown.Item onClick={(e) => handleApprovalCandidate(candidate.candidate_job_id, 2)}>
                          <CheckCircleIcon color='#6B7280' width={16} className='mr-2' /> Good Fit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={(e) => handleApprovalCandidate(candidate.candidate_job_id, 1)}>
                          <QuestionMarkCircleIcon color='#6B7280' width={16} className='mr-2' /> Maybe
                        </Dropdown.Item>
                        <Dropdown.Item onClick={(e) => handleApprovalCandidate(candidate.candidate_job_id, 0)}>
                          <XCircleIcon color='#6B7280' width={16} className='mr-2' /> Not Fit
                        </Dropdown.Item></>}

                    </DropdownDots>
                  </td> */}
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              <EmptyContent />
            </>
          )}
        </>
      );
    }
  };

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    if (refresh) {
      getCandidateInPosition();
    }
  }, [refresh]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <ToastContainer />
      {showCheckSpam && (
        <PopUp>
          <CheckSpam
            onClose={() => setShowCheckSpam(false)}
            onConfirm={(e) => setShowCheckSpam(false)}
          />
        </PopUp>
      )}
      {showEditEmail && (
        <PopUp>
          <EditEmailPopup
            onClose={() => {
              setCurrentEditCandidate(null);
              setShowEditEmail(false);
            }}
            onSend={(value) => handleSubmitEditEmail(value)}
            email={currentEditCandidate.email}
            username={currentEditCandidate.username}
            phonenumber={currentEditCandidate.phonenumber}
            name={currentEditCandidate.name}
            loading={updating}
          />
        </PopUp>
      )}
      {showMaybeModal ? (
        <PopUp>
          <MaybeRatePopup
            onClose={() => setShowMaybeModal(false)}
            onConfirm={(e) => handleMaybeConfirm(e)}
          />
        </PopUp>
      ) : (
        ""
      )}
      {showGoodFitModal ? (
        <PopUp>
          <GoodFitPopup
            onSend={(value) => handleGoodFitConfirm(value)}
            onClose={() => setShowGoodFitModal(false)}
          />
        </PopUp>
      ) : (
        ""
      )}
      {showNotFitModal ? (
        <PopUp>
          <NotFitPopup
            onSend={(value) => handleNotFitConfirm(value)}
            onClose={() => setShowNotFitModal(false)}
          />
        </PopUp>
      ) : (
        ""
      )}
      <div className="mb-4">
        <div className="">
          <nav className="bg-gray-100 rounded-md mdb:bg-transparent">
            <div className="flex space-x-4 overflow-x-auto  ">
              <div className="w-[10px] flex items-center">
                <Tooltip
                  isAbsolute={true}
                  element={
                    <div className="p-1">
                      <p className="font-semibold mb-1 text-grey-500">All</p>
                      <ol className="list-disc list-inside">
                        <li className="font-normal text-soft-grey text-sm">
                          Menampilkan semua kandidat yang ada
                        </li>
                      </ol>
                      <p className="font-semibold mt-2 mb-1 text-grey-500">
                        Prescreen
                      </p>
                      <ol className="list-disc list-inside">
                        <li className="font-normal text-soft-grey text-sm">
                          Kandidat yang telah di invite / sedang dalam tahap
                          pengerjaan
                        </li>
                        <li className="font-normal text-soft-grey text-sm">
                          Kandidat yang belum di rate oleh perusahaan
                        </li>
                      </ol>
                      <p className="font-semibold mt-2 mb-1 text-grey-500">
                        Not Fit
                      </p>
                      <ol className="list-disc list-inside">
                        <li className="font-normal text-soft-grey text-sm">
                          Kandidat yang telah telah di rate sebagai Not Fit
                          (tidak sesuai)
                        </li>
                      </ol>
                      <p className="font-semibold mt-2 mb-1 text-grey-500">
                        Maybe
                      </p>
                      <ol className="list-disc list-inside">
                        <li className="font-normal text-soft-grey text-sm">
                          Kandidat yang telah di rate sebagai Maybe (masih dalam
                          petimbangan)
                        </li>
                      </ol>
                      <p className="font-semibold mt-2 mb-1 text-grey-500">
                        Good Fit
                      </p>
                      <ol className="list-disc list-inside">
                        <li className="font-normal text-soft-grey text-sm">
                          Kandidat yang telah di rate sebagai Good Fit (sesuai)
                        </li>
                      </ol>
                    </div>
                  }
                  withMinWidth={false}
                  type="custom"
                  className="max-w-max"
                >
                  <InformationCircleIconOutline
                    className="w-[18px] relative "
                    color="#797C86"
                  />
                </Tooltip>
              </div>
              <a
                href="#"
                className={`${
                  activeTab === "all"
                    ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg flex-shrink-0"
                    : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab("all");
                  handleFilterRatingStatus(null);
                }}
              >
                All
              </a>
              {filterRatingStatus?.length > 0 &&
                filterRatingStatus?.map((value, index) => (
                  <a
                    href="#"
                    className={`${
                      activeTab == value.value
                        ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg flex-shrink-0"
                        : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab(value.value);
                      handleFilterRatingStatus(value.value);
                    }}
                  >
                    {value.title}
                  </a>
                ))}
            </div>
          </nav>
        </div>
      </div>
      {permission?.my_candidate?.includes("read") ? (
        <>
          {view == "table" && (
            <div className="border-b border-gray-200 shadow">
              <PaginationTable
                filters={[
                  // <FilterTable
                  //   placeholder={"Select Status"}
                  //   options={filterStatus}
                  //   onChange={(value) => handleFilterStatus(value)}
                  //   selected={selectedFilterStatus}
                  // />,
                  <FilterTable
                    placeholder={"Select Salary"}
                    options={filterExpectedSalary}
                    onChange={(value) => handleFilterSalary(value)}
                    selected={selectedFilterSalary}
                  />,
                  <FilterTable
                    placeholder={"Select Experience"}
                    options={filterExp}
                    onChange={(value) => handleFilterExp(value)}
                    selected={selectedFilterExp}
                  />,
                  <FilterTable
                    placeholder={"Last Education"}
                    options={filterEducation}
                    onChange={(value) => handleFilterEducation(value)}
                    selected={selectedFilterEducation}
                  />,
                  // <FilterTable
                  //   placeholder={"Select Rate Status"}
                  //   options={filterRatingStatus}
                  //   onChange={(value) => handleFilterRatingStatus(value)}
                  //   selected={selectedFilterRatingStatus}
                  // />,
                ]}
                isSticky={true}
                stickyHeader={["ACTION"]}
                stickyContent={<StickyContent />}
                onClearFilter={(e) => handleClearFilter(e)}
                search={
                  <SearchTable
                    onSearch={(keyword) => handleSearch(keyword)}
                    placeholder={"Search Candidate Name"}
                  />
                }
                checkbox={false}
                currentPage={handlePaginate}
                itemsTo={itemsTo}
                itemsFrom={itemsFrom}
                itemsPerPage={10}
                totalItems={totalItems}
                totalPages={Math.ceil(totalItems / itemsPerPage)}
                headers={[
                  "NO",
                  "FULLNAME",
                  "EMAIL",
                  "INVITED ON",
                  "LAST EDUCATION",
                  "WORK EXPERIENCE",
                  "SALARY EXPECTATION",
                  "STATUS",
                  "INVITATION STATUS",
                  "RATE",
                ]}
                content={<TableCandidateContent />}
              />
            </div>
          )}
          {view == "card" && (
            <div className="flex gap-3 flex-col ">
              <div className="flex justify-between items-center">
                <div className="flex gap-1 w-[70%]">
                  <FilterTable
                    placeholder={"Select Salary"}
                    options={filterExpectedSalary}
                    onChange={(value) => handleFilterSalary(value)}
                    selected={selectedFilterSalary}
                  />
                  <FilterTable
                    placeholder={"Select Experience"}
                    options={filterExp}
                    onChange={(value) => handleFilterExp(value)}
                    selected={selectedFilterExp}
                  />
                  <FilterTable
                    placeholder={"Last Education"}
                    options={filterEducation}
                    onChange={(value) => handleFilterEducation(value)}
                    selected={selectedFilterEducation}
                  />
                  <a
                    href="#"
                    onClick={(e) => handleClearFilter(e)}
                    className="flex text-sm items-center text-gray-500 font-medium hover:text-main-violate-hover mt-1"
                  >
                    Clear
                  </a>
                </div>
                <div className="w-[30%]">
                  <SearchTable
                    onSearch={(keyword) => handleSearch(keyword)}
                    placeholder={"Search Candidate Name"}
                  />
                </div>
              </div>
              {tableCandidateLoading && (
                <div className="h-[180px] flex items-center justify-center border border-gray-200 mt-4">
                  <PageSpinner />
                </div>
              )}
              {!tableCandidateLoading && candidates.length == 0 && (
                <div className="h-[180px] flex items-center justify-center border border-gray-200 mt-4">
                  <div className="px-6 py-4 text-sm text-gray-500 text-center italic ">
                    No Records
                  </div>
                </div>
              )}

              {!tableCandidateLoading && candidates.length > 0 && (
                <>
                  <div className="max-h-[680px] overflow-y-auto flex gap-3 flex-col relative">
                    {candidates.map((candidate, index) => {
                      return (
                        <div
                          className="border p-4 border-gray-200 hover:shadow-md cursor-pointer flex-shrink-0"
                          onClick={() =>
                            handleDetailCandidate(
                              candidate.id,
                              candidate.candidate_job_id
                            )
                          }
                        >
                          <div className="w-full flex justify-between">
                            <div className="max-w-max mb-3">
                              {candidate.rating_status ? (
                                <StatusLabel
                                  status={candidate.rating_status}
                                  label={candidate.rating_status_label}
                                />
                              ) : candidate.rating_status == 0 ? (
                                <StatusLabel
                                  status={candidate.rating_status}
                                  label={candidate.rating_status_label}
                                />
                              ) : (
                                <p className="bg-blue-100 text-blue-500 font-medium text-center text-xs py-1 rounded text px-2">
                                  Prescreen
                                </p>
                              )}
                            </div>
                            <div
                              className="hover:bg-gray-200 p-1 h-max rounded-full transition duration-200"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <DropdownDots>
                                <Dropdown.Item
                                  onClick={(e) =>
                                    handleDetailCandidate(
                                      candidate.id,
                                      candidate.candidate_job_id
                                    )
                                  }
                                >
                                  <InformationCircleIcon
                                    color="#6B7280"
                                    width={16}
                                    className="mr-2"
                                  />{" "}
                                  Detail
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={(e) =>
                                    handleEditEmailCandidate(
                                      candidate.id,
                                      candidate
                                    )
                                  }
                                >
                                  <PencilSquareIcon
                                    color="#6B7280"
                                    width={16}
                                    className="mr-2"
                                  />{" "}
                                  Change Email
                                </Dropdown.Item>
                                {candidate.status == 0 && (
                                  <>
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        handleResendCandidate(candidate.id)
                                      }
                                    >
                                      <ArrowUturnRightIcon
                                        color="#6B7280"
                                        width={16}
                                        className="mr-2"
                                      />{" "}
                                      Resend Invitation
                                    </Dropdown.Item>
                                  </>
                                )}
                              </DropdownDots>
                            </div>
                          </div>

                          <p className="font-semibold mb-1">{candidate.name}</p>
                          <p className="text-xs text-gray-500 mb-3">
                            Invited on{" "}
                            {moment(candidate.invitation.created_at).format(
                              "MMM D, YYYY"
                            )}
                          </p>
                          <p className="text-sm font-semibold mb-2 flex gap-1">
                            {position?.job_role}
                            <p className="text-sm font-normal text-gray-500">
                              (
                              {candidate.personal_detail.work_experience != null
                                ? moment
                                    .duration(
                                      candidate.personal_detail.work_experience,
                                      "months"
                                    )
                                    .years() +
                                  " Years, " +
                                  moment
                                    .duration(
                                      candidate.personal_detail.work_experience,
                                      "months"
                                    )
                                    .months() +
                                  " Months"
                                : "-"}
                              )
                            </p>
                          </p>
                          <div className="flex gap-1">
                            <MapPinIcon className="w-3" />
                            <p className="text-sm">
                              {
                                provinces.find(
                                  (province) =>
                                    province.id == candidate.province
                                )?.name
                              }
                              ,{" "}
                              {
                                cities.find((city) => city.id == candidate.city)
                                  ?.name
                              }
                            </p>
                            <p className="text-sm">·</p>
                            <AcademicCapIcon className="w-3" />
                            <p className="text-sm">
                              {candidate.personal_detail.educational
                                ? candidate.personal_detail.educational
                                    .educational_degree.name
                                : "-"}
                            </p>
                            <p className="text-sm">·</p>
                            <BanknotesIcon className="w-3" />
                            <p className="text-sm">
                              {candidate.expected_salary
                                ? rupiahFormat(candidate.expected_salary) +
                                  " monthly"
                                : "-"}
                            </p>
                          </div>
                          <hr className="mt-3 mb-2" />
                          <div className="flex gap-2">
                            {candidate.status != 2 ? (
                              <></>
                            ) : (
                              <>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    handleApprovalCandidate(
                                      candidate.candidate_job_id,
                                      2
                                    );
                                    e.stopPropagation();
                                  }}
                                >
                                  <CheckCircleIcon
                                    color="#6B7280"
                                    width={16}
                                    className="mr-2"
                                  />{" "}
                                  Good Fit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    handleApprovalCandidate(
                                      candidate.candidate_job_id,
                                      1
                                    );
                                    e.stopPropagation();
                                  }}
                                >
                                  <QuestionMarkCircleIcon
                                    color="#6B7280"
                                    width={16}
                                    className="mr-2"
                                  />{" "}
                                  Maybe
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    handleApprovalCandidate(
                                      candidate.candidate_job_id,
                                      0
                                    );
                                    e.stopPropagation();
                                  }}
                                >
                                  <XCircleIcon
                                    color="#6B7280"
                                    width={16}
                                    className="mr-2"
                                  />{" "}
                                  Not Fit
                                </Dropdown.Item>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <PaginationPage
                    currentPage={handlePaginate}
                    itemsTo={itemsTo}
                    itemsFrom={itemsFrom}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    totalPages={Math.ceil(totalItems / itemsPerPage)}
                    onChangeLimit={(value) => handleChangeLimit(value)}
                  />
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <MiniForbidden className="rounded-lg" />
      )}
    </>
  );
};

export default CandidateInJobList;
