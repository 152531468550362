import axios from "axios";

export const getLandingInquries = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/landing-inquiries?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getLandingInqury = async (id = "") => {
  try {
    const response = await axios.get(`/api/admin/landing-inquiries/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateLandingInquiry = async (id = "", form) => {
  try {
    const response = await axios.put(
      `/api/admin/landing-inquiries/${id}`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
