import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Cog6ToothIcon } from "@heroicons/react/24/solid"
import { UnderDevelopment } from '../../../Component/Errors/UnderDevelopment';
import BreadCrumb from '../../../Component/BreadCrumb';
import { documentTitle } from '../../../helpers';

const SettingsPage = () => {

  let navigate = useNavigate();
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Setting")
}, [])

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: 'calc(100vh - 80px)',
    marginTop: '-10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div style={myStyle} className='px-[10px] md:px-[44px] py-[24px]'>
      <ToastContainer />
      <div className='card flex w-full mb-6'>
        <div className='bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6'>
          <Cog6ToothIcon className='w-[30px] text-main-violate' />
        </div>
        <div className='flex flex-col justify-around'>
          <p className='text-xl text-grey-600 font-bold'>Settings</p>
          <BreadCrumb list={[{ href: null, title: "Settings" }]} />
        </div>
      </div>

      <div className='report-list'>
        <div className='card flex flex-col w-full'>
          <UnderDevelopment />
          {/* TABLE CONTENT */}
        </div>
      </div>
    </div >
  )
}

export default SettingsPage;
