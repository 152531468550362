import React from "react";
import { NoSymbolIcon } from "@heroicons/react/24/outline";

const MiniForbidden = ({ className = "" }) => {
  return (
    <div className={`${className} w-full flex items-center bg-gray-200`}>
      <div className="w-full flex flex-col items-center py-3">
        <NoSymbolIcon width={40} className="text-grey-400 mb-3" />
        <p className="text-base text-grey-400 font-medium ">Forbidden</p>
      </div>
    </div>
  );
};

export default MiniForbidden;
