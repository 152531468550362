import React from "react";
import { useState, useEffect } from "react";
import Tooltip from "../../../../../Component/Tooltip";
import Legends from "../../Components/Legends";
import EmptyState from "../../Components/EmptyState";
const Cognitive = ({ data }) => {
    const [totalTest, setTotalTest] = useState(0);
    const StatusLabel = ({ value, max_value }) => {
        const result_percentage = (value / max_value) * 100;
        if (result_percentage >= 0 && result_percentage <= 33) {
            return (<p className="text-label red mb-2">Low</p>)
        } else if (result_percentage > 33 && result_percentage <= 66) {
            return (<p className="text-label yellow mb-2">Medium</p>)
        } else if (result_percentage > 66) {
            return (<p className="text-label green mb-2">High</p>)
        }
    }

    const TagBenchmark = () => {
        return (<Tooltip text={"High"}>
            <div className="w-full h-[12px]  bg-gray-50">
                <div
                    className="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                    style={{
                        width: `70%`,
                    }}
                >
                </div>
            </div>
        </Tooltip>)
    }

    useEffect(() => {
        let total = 0;
        data.map((value, index) => {
            if (value.is_choosen) {
                total += 1;
            }
        })
        setTotalTest(total);
    }, [data])
    return (
        <>
            <Legends />
            {data.map((value, index) => {
                if (value.is_choosen) {
                    return (<>
                        <div className='px-9 py-5 flex flex-col lg:flex-row justify-between w-full mb-5'>
                            <div className='content w-full'>
                                <div className='flex flex-col h-[100%]'>
                                    <p className='text-lg text-[#374151] font-semibold mb-4'>{value.test_name}</p>
                                    {value.result.length > 0 ? <><div className='flex w-full gap-3'>
                                        <div className='left-side w-[20%] h-full flex flex-col gap-3'>
                                            <p className='text-base text-gray-500'>Test Score</p>
                                            <div className='flex flex-col py-6 h-full rounded-lg'>
                                                <StatusLabel value={value.total_result} max_value={value.total_max_value} />
                                                <p className='font-semibold mb-2 text-4xl'>{((value.total_result / value.total_max_value) * 100).toFixed(0)}%</p>
                                                <p className='text-gray-700 mb-1 font-medium'>Benchmark: 70%</p>
                                                <p className='text-green-500 text-sm mb-2 font-bold'>Rank: Top 15%</p>
                                            </div>
                                        </div>
                                        <div className='center-side w-[30%] h-full flex flex-col'>
                                            <p className='text-base text-gray-500 mb-2'>Tagging Score</p>
                                            {
                                                value.result.map((tag, index) => {
                                                    return tag.data.map((component, key) => {
                                                        const result_percentage = Math.max(0, (component.result / component.max_value) * 100);
                                                        if (result_percentage >= 0 && result_percentage <= 33) {
                                                            return (
                                                                <>
                                                                    <div className="mb-5">
                                                                        <p className='font-semibold mb-2 text-[18px]'>{component.name}</p>
                                                                        <Tooltip text={value.custom_result_label ? component.result_label : "Low"}>
                                                                            <div className="w-full h-[12px] bg-gray-50">
                                                                                <div
                                                                                    className="h-full text-center text-xs text-white bg-red-500 rounded-r-sm"
                                                                                    style={{
                                                                                        width: `${result_percentage}%`,
                                                                                    }}
                                                                                >
                                                                                </div>
                                                                            </div>
                                                                        </Tooltip>
                                                                        <TagBenchmark />
                                                                    </div>
                                                                </>
                                                            )
                                                        } else if (result_percentage > 33 && result_percentage <= 66) {
                                                            return (
                                                                <>
                                                                    <div className="mb-5">
                                                                        <p className='font-semibold mb-2 text-[18px]'>{component.name}</p>
                                                                        <Tooltip text={value.custom_result_label ? component.result_label : "Medium"}>
                                                                            <div className="w-full h-[12px] bg-gray-50">
                                                                                <div
                                                                                    className="h-full text-center text-xs text-white bg-yellow-300 rounded-r-sm"
                                                                                    style={{
                                                                                        width: `${result_percentage}%`,
                                                                                    }}
                                                                                >
                                                                                </div>
                                                                            </div>
                                                                        </Tooltip>
                                                                        <TagBenchmark />
                                                                    </div>
                                                                </>
                                                            )
                                                        } else if (result_percentage > 66) {
                                                            return (
                                                                <>
                                                                    <div className="mb-5">
                                                                        <p className='font-semibold mb-2 text-[18px]'>{component.name}</p>
                                                                        <Tooltip text={value.custom_result_label ? component.result_label : "High"}>
                                                                            <div className="w-full h-[12px] bg-gray-50">
                                                                                <div
                                                                                    className="h-full text-center text-xs text-white bg-green-400 rounded-r-sm"
                                                                                    style={{
                                                                                        width: `${(component.result / component.max_value) * 100}%`,
                                                                                    }}
                                                                                >
                                                                                </div>
                                                                            </div>
                                                                        </Tooltip>
                                                                        <TagBenchmark />
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    })

                                                })
                                            }
                                        </div>
                                        <div className='right-side w-[50%] h-full flex flex-col gap-3'>
                                            <p className='text-base text-gray-500'>Description</p>
                                            <div className='flex flex-col px-4 py-4 bg-main-violate-50 h-full rounded-lg'>
                                                {
                                                    value.result.map((tag, index) => {
                                                        return tag.data.map((component, key) => {
                                                            return (
                                                                <>
                                                                    <p className='text-gray-900 mb-3 text-[18px]'>
                                                                        <b>{component.name}</b> {component.label1}
                                                                    </p>
                                                                </>
                                                            )
                                                        })
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div></> : <EmptyState />}

                                </div>
                            </div>
                        </div>
                    </>);
                }

            })}
            {totalTest === 0 && <>
                <div className='flex flex-col lg:flex-row justify-between w-full mb-5'>
                    <div className='content w-full'>
                        <div className='flex flex-col h-[100%]'>
                            <EmptyState />
                        </div>
                    </div>
                </div>
            </>}
        </>

    )
}

export default Cognitive;