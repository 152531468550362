import React, { Component } from "react";

class PersonalityA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: {
        order: "",
        content: "",
        context: "",
        options: [],
      },
      selectedId: null,
    };
  }

  componentDidMount() {
    this.setState({ question: this.props.question });
  }

  handleClick(id) {
    this.setState({ selectedId: id });
  }

  render() {
    // const { question } = this.props;
    return (
      <div className="bg-white max-h-[580px] 2xl:max-h-[820px] xl:max-h-[720px] lg:max-h-[680px] md:max-h-[620px]  rounded-lg  w-full px-6 py-6">
        <div className="flex space-x-2">
          <p>{this.state.question.order}. </p>
          <div>
            {this.state.question.content && (
              <>
                <p className="text-grey-900 text-base font-semibold mb-2">
                  {this.state.question.content}
                </p>
              </>
            )}
            {this.state.question.context && (
              <>
                <p className="text-grey-900 text-base font-semibold mb-2">
                  {this.state.question.context}
                </p>
              </>
            )}
          </div>
        </div>
        <div className="max-w-[720px]">
          <p className="text-grey-900 text-base">
            {this.state.question.options.map((opt) => (
              <div
                className={`${
                  (this.state.selectedId == opt.id
                    ? "border-main-violate "
                    : "border-line-grey-1 hover:border-2 hover:font-bold ") +
                  "rounded-[8px] h-[48px] border px-5 py-3 mt-3 cursor-pointer"
                }`}
                onClick={() => this.handleClick(opt.id)}
              >
                <p className="text-[16px] leading-[140%] no-select">
                  {opt.value}
                </p>
              </div>
            ))}
          </p>
        </div>
      </div>
    );
  }
}

export default PersonalityA;
