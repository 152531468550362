import React from "react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { MapPinIcon } from "@heroicons/react/24/solid";
import cities from "../../../../../../data/cities.json";
import states from "../../../../../../data/states.json";
import countries from "../../../../../../data/countries.json";

const PreferenceSection = ({ preferences }) => {
  return (
    <>
      <div className={`border-grey-200 border w-full rounded-md mt-4 bg-white`}>
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <MapPinIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Location Preferences
            </h4>
          </div>
        </div>

        <div className="mt-5 mb-3 px-5">
          {preferences.length > 0 ? (
            <>
              {preferences.map((preference) => (
                <div
                  className="flex py-2 px-6 border border-line-gray-200 rounded-md mb-4 items-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="desc-section text-sm">
                    {cities.find((city) => city.id == preference.city)?.name},{" "}
                    {
                      states.find((state) => state.id == preference.province)
                        ?.name
                    }
                    ,{" "}
                    {
                      countries.find(
                        (country) => country.id == preference.country
                      )?.name
                    }
                  </p>
                </div>
              ))}
            </>
          ) : (
            <p className="font-semibold text-slate-400 text-center">
              <i>No Data</i>{" "}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default PreferenceSection;
