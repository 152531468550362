import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layouts/Layout";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
// import SignupPage from './Pages/SignupPage';
import LogoutPage from "./Pages/LogoutPage";
import NotFoundPage from "./Pages/NotFoundPage";
import ClientProfileIntroPage from "./Pages/ClientProfileIntroPage";
import AppAdmin from "./AppAdmin";
import PDFView from "./Pages/Client/MyCandidatesPage/PDFView";
import AppClient from "./AppClient";
// TEST DEPLOY
const App = () => {
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || { base_role: null };
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="client/my-candidates/:id/:candidate_job_id/export"
          element={<PDFView />}
        />
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="welcome" element={<ClientProfileIntroPage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>

        {/* CLIENT */}
        <Route path="client/*" element={<AppClient />} />
        {/* ADMINISTRATOR | SUPERADMIN */}
        <Route path="administrator/*" element={<AppAdmin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
