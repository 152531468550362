import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../../Component/PersonalityRadarChart";
import RangeList2 from "../Components/RangeList2";
import PageSpinner from "../../../../../../Component/PageSpinner";

const AdaptReports = ({ adaption }) => {
    const [communicate, setCommunicate] = useState(null);
    const [dataRange, setDataRange] = useState(null);
    useEffect(() => {
        if (adaption) {
            let values = [];
            let labels = [];
            let tempDataRange = [];

            adaption.data.map((item) => {
                values.push(item.result)
                labels.push(item.name)
                tempDataRange.push({ parameter: item.name, minLabel: item.label2, maxLabel: item.label1, maxRange: "10", minRange: "0", value: item.result })
            })
            setCommunicate({
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: '#E3A0087A',
                        borderColor: '#E3A008',
                        borderWidth: 2,
                        hidden: '',
                    },
                ],
            })
            setDataRange(tempDataRange)
        }
    }, [adaption])

    return (
        <div className='flex flex-col items-center'>
            <div className='flex w-full items-center mb-7 mdb:mb-5 gap-10 px-2 mdb:px-4 justify-center flex-col mdb:flex-row'>
            <div className="w-full mdb:w-[40%] flex justify-center">
                    <div className="max-w-[520px]">
                        {communicate ? <PersonalityRadarChart data={communicate} /> : <PageSpinner />}
                    </div>
                </div>
                <div className="w-full mdb:w-[60%]">
                    <p className='font-semibold mb-2'>Adaption</p>
                    <p className="text-gray-500">Kemampuan untuk menyesuaikan diri dengan keadaan, lingkungan, teknologi, atau peran yang baru dan berubah secara fleksibel dan efektif. Adaptasi adalah keterampilan penting di tempat kerja modern, di mana perubahan sering kali terjadi secara konstan.</p>
                    {/* <p className="text-gray-500">The ability to adjust to new and changing circumstances, environments, technologies, or roles in a flexible and effective manner. Adaptation is an essential skill in the modern workplace, where change is often constant.</p> */}
                    {/* <ul className="list-disc list-inside text-gray-500">
                        <li>This person is able to trust his or her judgem ent and overcome her doubts when they are not certain.</li>
                        <li>This person has his or her own opinions but is still sensitiv e t o criticism.</li>
                        <li>This person prefers to rely on an existing approach and is rather convent ional.</li>
                        <li>This person is able to trust his or her judgem ent and overcome her doubts when they are not certain.</li>
                    </ul> */}
                </div>
            </div>
            {communicate ? <>
                {dataRange.map((item, index) => (
                    <RangeList2 data={item} color="yellow" />
                ))}
            </> : <PageSpinner />}

        </div>
    )
}
export default AdaptReports;