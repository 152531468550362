import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
function PurchasePackagePopup(props) {
    const { onCall, onClose } = props;

    const handleClose = (value = false) => {
        if (typeof onClose === "function") {
            onClose(value);
        }
    };
    return (
        // Your alert popup component JSX
        <div className="px-2">
            <div className="bg-white rounded-lg shadow lg:w-[780px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate py-2 flex flex-col px-4 overflow-y-auto ">
                <div className="flex justify-between border-b pt-2 pb-4 mb-4">
                    <p className="font-semibold text-base">Packages Pricing</p>
                    <button
                        className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="flex h-full flex-col md:flex-row gap-3 px-1 md:px-4">
                    <div className="card w-full md:w-[50%]">
                        <p className="text-gray-500 mb-2 text-lg">Starter</p>
                        <div className="flex items-end mb-6">
                            <p className="text-xl md:text-4xl font-bold mr-2">
                                Rp 160K
                            </p>
                            <p className="text-base md:text-lg text-gray-400 mb-2">
                                /candidates
                            </p>
                        </div>
                        <div>
                            <p className="flex text-gray-500 mb-4">
                                <CheckCircleIcon className="text-main-violate w-6 h-6 mr-4 " /> 1 Job Role
                            </p>
                            <p className="flex text-gray-500 mb-4">
                                <CheckCircleIcon className="text-main-violate w-6 h-6 mr-4 " /> 6 Tests
                            </p>
                            <p className="flex text-gray-500">
                                <CheckCircleIcon className="text-main-violate w-6 h-6 mr-4 " /> 50 Candidate token
                            </p>
                        </div>
                    </div>
                    <div className="card w-full md:w-[50%]">
                        <p className="text-gray-500 mb-2 text-lg">Enterprise</p>
                        <div className="flex items-end mb-6">
                            <p className="text-4xl font-bold mr-2">
                                Rp 150K
                            </p>
                            <p className="text-lg text-gray-400 mb-2">
                                /candidates
                            </p>
                        </div>
                        <div>
                            <p className="flex text-gray-500 mb-4">
                                <CheckCircleIcon className="text-main-violate w-6 h-6 mr-4 " /> 1 Job Role
                            </p>
                            <p className="flex text-gray-500 mb-4">
                                <CheckCircleIcon className="text-main-violate w-6 h-6 mr-4 " /> 6 Tests
                            </p>
                            <p className="flex text-gray-500">
                                <CheckCircleIcon className="text-main-violate w-6 h-6 mr-4 " /> 100 Candidate token
                            </p>
                        </div>
                    </div>
                </div>
                <div className="py-5 px-4 border-b">
                    <p className="text-gray-500">Contact our business team to purchase the package</p>
                </div>
                <div className="pt-4 pb-2 px-4">
                    <button
                        className="flex items-center gap-2 bg-main-violate  px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg"
                        type=""
                        onClick={(e) => { e.preventDefault(); }}
                    >
                        Contact Business Team
                    </button>
                </div>
            </div>
        </div>


    );
}

export default PurchasePackagePopup;
