import { HomeIcon } from "@heroicons/react/24/solid";
import { Breadcrumb } from "flowbite-react";
import React, { Component } from "react";

export class BreadCrumb extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { fromHome = true, list } = this.props
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    return (
      <Breadcrumb aria-label="Default breadcrumb example" className="mt-1">
        {fromHome && <Breadcrumb.Item
          href={user.role == "admin" ? "/administrator/dashboard" : "/client/dashboard"}
          icon={HomeIcon}
        >
          <p className="text-sm">
            Dashboard
          </p>
        </Breadcrumb.Item>}
        {list.map((item, index) => {
          return (
            <>
              {item.href && <>
                <Breadcrumb.Item href={item.href}>
                  <p className="text-sm">
                    {item.title}
                  </p>

                </Breadcrumb.Item>
              </>}
              {!item.href && <>
                <Breadcrumb.Item>
                  <p className="text-sm">
                    {item.title}
                  </p>
                </Breadcrumb.Item>
              </>}

            </>
          )

        })}
      </Breadcrumb>
    );
  }
}
export default BreadCrumb;
