import React from "react";
import { useState, useEffect } from "react";
import BtnSpinner from "../../../Component/BtnSpinner";


const CompanyForm = ({ onContinue, updating, userName }) => {
    const [disabled, setDisabled] = useState(true);
    const [name, setCompanyName] = useState("");
    const [found_date, setFoundingDate] = useState("");
    const [address, setAddress] = useState("");
    const [industry, setIndustry] = useState("");
    const handleContinue = (e) => {
        e.preventDefault();
        onContinue({ name, found_date, address, industry }, 3);
    };

    useEffect(() => {
        if (name.length < 3 || found_date == "" || address.length < 3 || industry.length < 3) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [name, found_date, address, industry])
    return (
        <div className="bg-white rounded-lg shadow w-full lg:w-[483px] mb-[100px] px-10 py-8 h-[520px]">
            <h1 className="font-semibold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                Welcome {userName}
            </h1>

            <div className="w-full justify-center">
                <div className="mb-6">
                    <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        Company Name
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                        type="text"
                        name="company_name"
                        id="company_name"
                        value={name}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Type your company name.."

                    />
                </div>
                <div className="mb-6">
                    <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        Founding Date
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                        type="date"
                        name="founding_date"
                        id="founding_date"
                        value={found_date}
                        onChange={(e) => setFoundingDate(e.target.value)}
                        placeholder="Type your fonding date.."

                    />
                </div>
                <div className="mb-6">
                    <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        Address
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                        type="tetx"
                        name="address"
                        id="founding_date"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Type your address.."

                    />
                </div>
                <div className="mb-6">
                    <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        Industry
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                        type="tetx"
                        name="address"
                        id="founding_date"
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                        placeholder="Type your industry.."

                    />
                </div>
            </div>
            <div className="flex mt-5 justify-center">
                <button
                    className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    style={{ textAlign: "-webkit-center" }}
                    disabled={updating || disabled}
                    onClick={handleContinue}
                >
                    {updating ? <BtnSpinner /> : "Continue"}
                </button>
            </div>
        </div>
    )
}

export default CompanyForm;