import React, { Component } from "react";
import "./custom.css"
import Tooltip from "../Tooltip";
class SingleHorizonal extends Component {
    render() {
        const { data } = this.props;
        const blockWidth = 100 / data.length;
        return (
            <div className="heatmap-container">
                {data.map((point, index) => {
                    if (point.label == "" || point.label == null) {
                        return (
                            <div
                                key={index}
                                className={`heatmap-block ${point.intensity}`}
                                style={{ flexBasis: `${blockWidth}%` }} // Set lebar blok sesuai dengan perhitungan
                            >

                                {point.intensity}

                            </div>
                        );
                    } else {
                        return (
                            <Tooltip text={point.label}>
                                <div
                                    key={index}
                                    className={`heatmap-block ${point.intensity}`}
                                    style={{ flexBasis: `${blockWidth}%` }} // Set lebar blok sesuai dengan perhitungan
                                >

                                    {point.intensity}

                                </div>
                            </Tooltip>
                        );
                    }

                })}
            </div>
        );
    }
}

export default SingleHorizonal;