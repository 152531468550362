import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import "./index.css";
import PopUp from "../PopUp";
import DetailTestPreview from "../DetailTestPreview";
import { Avatar } from "flowbite-react";

export class SelectCandidateGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: true,
      showSelectedDetail: {},
      showDetail: false,
      // width: 600
    };
  }

  render() {
    const {
      data,
      onSelect,
      onRemove,
      onShowProfile,
      inSelected = false,
      selectedCandidates = [],
    } = this.props;
    const selectTest = (testId) => {
      onSelect(testId);
    };
    const removeTest = (testId) => {
      onRemove(testId);
    };
    const showProfile = (profile) => {
      onShowProfile(profile);
    }
    return (
      <div>
        <div
          style={{
            width: this.state.width + "px",
            display: this.state.display ? "grid" : "none",
          }}
          className="grid-cols-1 sm:grid-cols-2 3xl:grid-cols-4 xl:grid-cols-3"
        >
          {data.map((value) => (
            <div className="px-2 w-full">
              <div
                className={
                  (value.selected ? "card-main-selected" : "card") +
                  " h-[420px] flex flex-col justify-between"
                }
              >
                <div className="flex flex-col items-center">
                  {value.avatar && (
                    // create a rounded image PLEASE USING IMG
                    <div>
                      <img
                        src={value.avatar}
                        alt="avatar"
                        className="w-[100px] h-[100px] rounded-full mb-4"
                      />
                    </div>
                  )}
                  {!value.avatar && (
                    <UserCircleIcon className="w-[100px] text-grey-500 mb-4" />
                  )}
                  <div className={inSelected && "title-selected"}>
                    <p className="text-grey-900 font-semibold text-xl lg:text-lg mb-4">
                      {value.name}
                    </p>
                  </div>
                </div>
                <div>
                  <span className="flex mb-4">
                    <EnvelopeIcon className="w-[20px] text-grey-500 mr-2" />
                    <label className="text-sm">{value.email}</label>
                  </span>
                  <span className="flex mb-4">
                    <DevicePhoneMobileIcon className="w-[20px] text-grey-500 mr-2" />
                    <label className="text-sm">{value.phonenumber ? value.phonenumber : "-"}</label>
                  </span>
                  <span className="flex mb-4">
                    <UserIcon className="w-[20px] text-grey-500 mr-2" />
                    <label className="text-sm">{value.gender ? value.gender : "-"}</label>
                  </span>
                </div>
                <div className="flex gap-2">
                  {selectedCandidates.some((item) => item.id == value.id) ? (
                    <>
                      <button
                        className="mb-4 bg-red-100 w-full px-4 text-[16px] leading-[22.4px] text-red-600 font-semibold h-[46px] rounded-lg hover:bg-red-300 disabled:bg-violet-300 disabled:cursor-not-allowed"
                        type=""
                        key={value.id}
                        onClick={(e) => {
                          removeTest(value);
                        }}
                      >
                        Remove
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="mb-4 bg-main-violate-100 w-full px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-violet-300 disabled:bg-violet-300 disabled:cursor-not-allowed"
                        type=""
                        key={value.id}
                        onClick={(e) => {
                          selectTest(value);
                        }}
                      >
                        Add
                      </button>
                    </>
                  )}
                  <button
                    className="border border-main-violate w-full px-4 text-[16px] leading-[22.4px] text-main-violate hover:bg-violet-100 font-semibold h-[46px] rounded-lg"
                    type=""
                    onClick={(e) => {
                      showProfile(value);
                    }}
                  >
                    Detail
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {this.state.showDetail ? (
          <PopUp>
            <DetailTestPreview
              data={this.state.showSelectedDetail}
              onClose={(e) =>
                this.setState({ ...this.state, showDetail: false })
              }
            />
          </PopUp>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default SelectCandidateGrid;
