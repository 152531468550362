import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { BriefcaseIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import JobList from "./JobList";
import BreadCrumb from "../../../Component/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { documentTitle } from "../../../helpers";
import { useSelector } from "react-redux";
const PositionPage = () => {
  let navigate = useNavigate();
  const permission = useSelector((state) => state.user.permissions);
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  useEffect(() => {
    documentTitle("Jobs");
    // fetchMySubscriptions();
  }, []);
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      {/* {showCreateJobTokenPopup && <PopUp><CreateJobTokenPopup onClose={(e) => setShowCreateJobTokenPopup(false)} onSend={(serial) => handleApplySerial(serial)} validating={validating} /></PopUp>} */}
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <BriefcaseIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Jobs</p>
          <BreadCrumb list={[{ href: null, title: "Jobs" }]} />
          {/* <p className='text-base text-grey-400 font-medium '>List of positions that have been entered</p> */}
        </div>
      </div>

      <div className="position-list">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">Job List</p>
              {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
            </div>
            <div className="flex justify-end w-2/2">
              {permission?.job_role?.includes("create") && (
                <button
                  className="flex items-center justify-center bg-main-violate px-3 py-2 text-[16px] leading-[22.4px] text-white font-semibold rounded-lg hover:bg-violet-600 transition-colors duration-200 focus:outline-none disabled:bg-violet-300 disabled:cursor-not-allowed"
                  type=""
                  onClick={(e) => {
                    navigate("/client/jobs/create");
                  }}
                >
                  <PlusCircleIcon width={20} className="mr-2" /> Create Job
                </button>
              )}
            </div>
          </div>
          <JobList />
        </div>
      </div>
    </div>
  );
};

export default PositionPage;
