import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UnderDevelopment } from "../../../../Component/Errors/UnderDevelopment";
import {
  BuildingOffice2Icon,
  InformationCircleIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import PaginationTable from "../../../../Component/Tables/PaginationTable";
import SearchTable from "../../../../Component/Tables/SearchTable";
import FilterTable from "../../../../Component/Tables/FilterTable";
import TableSpinner from "../../../../Component/TableSpinner";
import EmptyContent from "../../../../Component/Tables/EmptyContent";
import { documentTitle } from "../../../../helpers";
import { getMyClient } from "../../../../api/admin";
import DropdownDots from "../../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";

const UserPage = () => {
  let navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [users, setUsers] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterCompany, setFilterCompany] = useState([]);
  const [filterRole, setFilterRole] = useState([]);
  const [selectedFilterCompany, setSelectedFilterCompany] = useState(null);
  const [selectedFilterRole, setSelectedFilterRole] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleFilterRole = (id) => {
    setQuery({ ...query, role: id });
    setSelectedFilterRole(id);
  };

  const handleFilterCompany = (id) => {
    setQuery({ ...query, company: id });
    setSelectedFilterCompany(id);
  };

  const fetchUsers = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getMyClient(queryString);
    if (result.success) {
      setUsers(result.data.data);
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setItemsPerPage(result.data.per_page);
      setTableLoading(false);
      setFilterCompany(result.additional.filter.companies);
      setFilterRole(result.additional.filter.roles);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSelectedFilterCompany("");
    setSelectedFilterRole("");
    setQuery({ ...query, company: "", role: "" });
  };

  useEffect(() => {
    documentTitle("Manage Client");
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [query]);

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {users.length > 0 ? (
            users.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <a
                      href={`/administrator/client/company/${value.company.id}`}
                      className="text-blue-500 font-medium underline"
                    >
                      {value.company.name}
                    </a>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.role}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.email}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <StatusLabel
                      status={value.status}
                      label={value.status_label}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <DropdownDots isPublish={value.is_publish}>
                      <Dropdown.Item onClick={(e) => {
                        navigate(`/administrator/client/users/${value.id}`);
                      }}>
                        <InformationCircleIcon width={16} className="mr-2" />
                        Detail
                      </Dropdown.Item>
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <UserIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">User</p>
          <BreadCrumb list={[{ href: null, title: "Client" }]} />
          {/* <p className='text-base text-grey-400 font-medium '>List of positions that have been entered</p> */}
        </div>
      </div>
      <div className="card flex w-full mb-6">
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Create New</p>
          <div className="flex mt-4 space-x-6">
            {/* <button
              className="order border-main-violate border  px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:hover:bg-main-violate-100"
              type=""
              onClick={(e) => {}}
            >
              <span className="flex">
                <UserCircleIcon width={20} className="mr-2" /> New User Role
              </span>
            </button> */}
            <button
              className="order border-main-violate border  px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:hover:bg-main-violate-100"
              type=""
              onClick={(e) => {
                navigate("/administrator/client/create-user");
              }}
            >
              <span className="flex">
                <UserIcon width={20} className="mr-2" /> New User Account
              </span>
            </button>
            <button
              className="order border-main-violate border px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:hover:bg-main-violate-100"
              type=""
              onClick={(e) => {
                navigate("/administrator/client/create-company");
              }}
            >
              <span className="flex">
                <BuildingOffice2Icon width={20} className="mr-2" /> New Company
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="card flex flex-col w-full">
        <div className="flex justify-between w-full mb-8">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">User List</p>
            {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
          </div>
          <div className="flex justify-end w-2/2"></div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <PaginationTable
            search={
              <SearchTable
                onSearch={(keyword) => handleSearch(keyword)}
                placeholder={"Search user name / email / company name"}
              />
            }
            filters={[
              <FilterTable
                placeholder={"Select Company"}
                selected={selectedFilterCompany}
                options={filterCompany}
                onChange={(value) => handleFilterCompany(value)}
              />,
              <FilterTable
                placeholder={"Select Role"}
                selected={selectedFilterRole}
                options={filterRole}
                onChange={(value) => handleFilterRole(value)}
              />,
            ]}
            onClearFilter={(e) => handleClearFilter(e)}
            currentPage={handlePaginate}
            itemsTo={itemsTo}
            itemsFrom={itemsFrom}
            itemsPerPage={5}
            checkbox={false}
            totalItems={totalItems}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            headers={[
              "NO.",
              "COMPANY NAME",
              "USER NAME",
              "USER ROLE",
              "EMAIL",
              "STATUS",
              "ACTION",
            ]}
            content={<TableContent />}
          />
        </div>
      </div>
    </div>
  );
};

export default UserPage;
