
import { Component } from 'react'
class SimpleTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            header: [],
        };
    }

    componentDidMount() {
        // console.log(this.props.stickyContent)
    }

    render() {
        const { filters, search, content, headers, checkbox = true, onClearFilter, isSticky = false, stickyHeader = [], stickyContent = null, withoutHeader = false, checkAll = false, onCheckAll, stickyHeaderClass = '' } = this.props
        return (
            <>
                <div className='flex justify-between mdb:flex-row flex-col-reverse'>
                    <div className='flex w-full mdb:w-[70%] flex-wrap mt-5 mb-5 px-3'>
                        {filters ? <>
                            {filters.map((filter) => filter)}
                        </> : ''}

                        {filters && <a href="#" onClick={(e) => onClearFilter(e)} className="flex text-sm items-center text-gray-500 font-medium hover:text-main-violate-hover mt-1">Clear</a>}

                    </div>
                    <div className='flex w-full mdb:w-[30%]'>
                        {
                            search ? <>{search}</> : ''
                        }
                    </div>
                </div>
                <div className='flex '>
                    <div className='overflow-x-scroll w-full'>
                        <table className='w-full'>
                            {!withoutHeader && <>
                                <thead className="bg-gray-50">
                                    <tr>
                                        {checkbox ? <>
                                            <th className="px-6 py-4 text-xs text-gray-500 text-left">
                                                <input
                                                    checked={checkAll}
                                                    onChange={(e) => this.props.onCheckAll(e)}
                                                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                                                    type="checkbox"
                                                />
                                            </th>
                                        </> : <></>}

                                        {headers.map((header, index) => {
                                            // if (isSticky && index === headers.length - 1) return (<></>)
                                            // else
                                            return (
                                                <th className="px-6 py-4 text-xs text-gray-500 text-left" style={{textWrap: "nowrap"}}>
                                                    {header}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                            </>}
                            
                            <tbody className="bg-white">
                                {content}
                            </tbody>
                        </table>
                    </div>
                    {isSticky && (
                        (stickyContent && <>
                            <div className="action-header">
                                <table className='w-full'>
                                    <thead className="bg-gray-50">
                                        {stickyHeader.map((header, index) => {
                                            return (
                                                <tr>
                                                    <th className={`${stickyHeaderClass} px-6 py-4 text-xs text-gray-500 text-left`} >{header}</th>
                                                </tr>
                                            )
                                        })}

                                    </thead>
                                    <tbody className="bg-white">
                                        {stickyContent}
                                    </tbody>
                                </table>
                            </div>
                        </>)

                    )}
                </div>

            </>
        )
    }

}

export default SimpleTable;