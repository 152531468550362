import React, { Component } from "react";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);
class PersonalityRadarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            options: {
                scales: {
                    r: {
                        grid: {
                            circular: true
                        },
                        suggestedMin: 1, // Set the minimum value to 1
                        suggestedMax: this.props.maxValue ? this.props.maxValue : 10, // Set the maximum value to 10
                        beginAtZero: true,
                        pointLabels: {
                            callback: function (value, index, values) {
                                if (value.length > 15) {
                                    return value.substring(0, 10) + '...';
                                } else {
                                    return value;
                                }
                            }
                        },
                        ticks: {
                            display: false,
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }

        }
    }

    render() {
        return (
            <Radar data={this.state.data} options={this.state.options} />
        );
    }
}

export default PersonalityRadarChart;