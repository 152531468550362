import axios from "axios";
import { useDispatch } from "react-redux";

export const getAssessments = async (queryString = "") => {
  try {
    const response = await axios.get(`/api/admin/assessment?${queryString}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAssessmentTest = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/assessment-test?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const switchPublishAssessmentTest = async (test_id = "") => {
  try {
    const response = await axios.patch(
      `/api/admin/assessment-test/${test_id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAssessmentTestDetail = async (id = "") => {
  try {
    const response = await axios.get(`/api/admin/assessment-test/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getTestQuestions = async (test_id = "") => {
  try {
    const response = await axios.get(
      `/api/admin/assessment-test/${test_id}/questions`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createAssessment = async (form = null) => {
  try {
    const response = await axios.post(`/api/admin/assessment`, form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createAssessmentTest = async (form = null) => {
  try {
    const response = await axios.post(`/api/admin/assessment-test`, form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateAssessmentTest = async (form = null, test_id) => {
  try {
    const response = await axios.put(
      `/api/admin/assessment-test/${test_id}`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const importQuestionTest = async (test_id = "", formData = null) => {
  try {
    const response = await axios.post(
      `/api/admin/assessment-test/${test_id}/questions-import`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const checkImportQuestionStatus = async (test_id = "") => {
  try {
    const response = await axios.get(
      `/api/admin/assessment-test/${test_id}/questions-import`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const submitImportQuestionTest = async (test_id = "", form = null) => {
  try {
    const response = await axios.post(
      `/api/admin/assessment-test/${test_id}/questions/store-import`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const submitImportGroupQuestionTest = async (test_id = "", form = null) => {
  try {
    const response = await axios.post(
      `/api/admin/assessment-test/${test_id}/questions/store-group-import`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteImportQuestionTest = async (id = "", test_id = "") => {
  try {
    const response = await axios.delete(
      `/api/admin/assessment-test/${test_id}/questions-import/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createQuestionTagCategory = async (form = null) => {
  try {
    const response = await axios.post(
      `/api/admin/question-tag/categories`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createQuestionTag = async (form = null) => {
  try {
    const response = await axios.post(`/api/admin/question-tag/master`, form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getQuestionTags = async (queryString = '') => {
  try {
    const response = await axios.get(`/api/admin/question-tag/master?${queryString}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getQuestionTagCategories = async () => {
  try {
    const response = await axios.get(`/api/admin/question-tag/categories`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
