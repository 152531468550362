import React, { useState, cloneElement } from 'react';
import './index.css'; // Import your CSS for styling

const Tooltip = ({ text = "", children, type = "default", element = "", top = "", className = "", withMinWidth = true, isAbsolute = false }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const clonedChildren = cloneElement(children, {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave
  });

  const Content = () => {
    if (type === "default") {
      return (
        <div className={`${className} tooltip-container w-full ${withMinWidth ? 'min-w-[200px]' : ''} ${isAbsolute ? 'absolute' : 'relative'}`}>
          {clonedChildren}
          <div className={`tooltip ${showTooltip ? 'active' : ''}`}>
            <div className="tooltip-content">{text}</div>
            <div className="tooltip-arrow"></div>
          </div>
        </div>
      );
    } else if (type === "custom") {
      return (
        <div className={`${className} tooltip-container w-full ${withMinWidth ? 'min-w-[200px]' : ''} ${isAbsolute ? 'absolute' : 'relative'}`}>
          {clonedChildren}
          <div className={`tooltip-custom ${showTooltip ? 'active' : ''}`} style={{ top: `${top}` }}>
            <div className='tooltip-custom-box'>{element}</div>
            <div className="tooltip-custom-arrow"></div>
          </div>
        </div>
      );
    }
  };

  return (
    <Content />
  );
};

export default Tooltip;