import React from "react";
import { ExclamationTriangleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { CommandLineIcon } from "@heroicons/react/24/solid";
function QuotaExceedPopup(props) {
    const { onConfirm, onClose } = props;

    // const handleConfirm = (value = false) => {
    //     if (typeof onConfirm === "function") {
    //         onConfirm(value);
    //     }
    // };
    const handleClose = (value = false) => {
        if (typeof onClose === "function") {
            onClose(value);
        }
    };
    return (
        // Your alert popup component JSX
        <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate  h-[480px] py-2 flex flex-col px-3 py-3">
            <div className="flex justify-end">
                <button
                    className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
                    onClick={() => {
                        handleClose()
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div className="flex flex-col items-center justify-evenly h-full">
                <ExclamationTriangleIcon
                    width={"129px"}
                    height={"115px"}
                    className="text-yellow-300"
                />
                <div className="text px-2">
                    <p className="font-bold text-center text-lg mb-4">
                        Oops!
                    </p>
                    <p className="text-center text-base">
                        You have exceeded of your quota test. Please contact our sales team to increase your quota.
                    </p>
                </div>

                {/* <div className="flex mt-5 justify-center w-[100%] gap-4">
                    <button
                        className="hover:bg-violet-600 cursor-pointer bg-main-violate w-[90px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                        onClick={(e) => handleConfirm(true)}
                    >
                        Oke
                    </button>

                </div> */}
            </div>
        </div>

    );
}

export default QuotaExceedPopup;
