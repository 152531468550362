import React, { useEffect, useState } from "react";
import {
  BellAlertIcon,
  BellIcon,
  BellSlashIcon,
  ArrowPathIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import PageSpinner from "../PageSpinner";
import {
  fetchNotifications,
  clearNotifications,
  readNotification,
} from "../../features/notification/notificationSlice";
import { useNavigate } from "react-router-dom";
import { CH_ADMIN, CH_CLIENT } from "../../channels";
import moment from "moment";

const Notification = ({ onOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOpenNotification = (id) => {
    onOpen(id);
  };
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const {
    data: notifications,
    loaded,
    nextPage,
    loading,
  } = useSelector((state) => state.notification);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const scrollPosition = scrollTop + clientHeight;
    const scrollLimit = scrollHeight * 0.95;

    if (scrollTop > lastScrollTop && scrollPosition >= scrollLimit) {
      // Update the last scroll position only when scrolling down
      setLastScrollTop(scrollTop);
      if (notifications && nextPage) {
        if (!loading) {
          dispatch(fetchNotifications(nextPage));
        }
      }
    }
  };

  const handleRefresh = () => {
    dispatch(clearNotifications());
    dispatch(fetchNotifications());
  };

  const handleRead = async (id = null, target_id = null, channel = "") => {
    dispatch(readNotification(id));

    const path = window.location.pathname; // Get the current URL path
    const parts = path.split("/"); // Split the path into an array of parts
    const firstPart = parts[1];
    if (firstPart === "administrator") {
      const chn = CH_ADMIN[channel];
      if (chn) {
        navigate(chn.url + "/" + target_id);
      }
    } else {
      const chn = CH_CLIENT[channel];
      if (chn) {
        // navigate(chn.url + "/" + target_id);
        navigate(chn.url);
      }
    }
  };

  useEffect(() => {
    if (notifications.length <= 0) {
      setLastScrollTop(0);
    }
  }, [notifications]);
  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center border-b border-gray-200 py-3 px-4">
          <p className="text-[16px] font-semibold text-gray-500">
            Notifications
          </p>
          <button
            className="text-sm font-semibold text-gray-500 text-[14px]"
            onClick={(e) => handleRefresh()}
          >
            <ArrowPathIcon className="h-4 w-4 text-text-link group-hover:text-text-active transition-colors duration-200 transition-transform ease-in-out transform hover:rotate-180" />
          </button>
        </div>
        {}
        {!loading && (
          <>
            <div
              className="flex flex-col gap-5 max-h-[320px] overflow-y-auto"
              onScroll={(e) => handleScroll(e)}
            >
              {notifications.length == 0 && (
                <div className="flex justify-center flex-col items-center h-[180px]">
                  <BellSlashIcon className="h-8 w-8 text-gray-400 mb-2" />
                  <h1 className="text-base font-semibold">No notifications</h1>
                  <p className="text-sm">You are all caught up</p>
                </div>
              )}
            </div>
          </>
        )}

        {notifications.length > 0 && (
          <div
            className="flex flex-col gap-2 max-h-[320px] overflow-y-auto pb-4"
            onScroll={(e) => handleScroll(e)}
          >
            {notifications.map((notification) => (
              <div
                className="flex items-center space-x-4 cursor-pointer hover:bg-gray-200 px-4 py-2"
                onClick={() =>
                  handleRead(
                    notification.id,
                    notification.target_id,
                    notification.channel
                  )
                }
              >
                {notification.status == 0 && (
                  <BellAlertIcon className="h-6 w-6 text-main-violate flex-shrink-0" />
                )}
                {notification.status == 1 && (
                  <BellIcon className="h-6 w-6 text-gray-400 flex-shrink-0" />
                )}
                <div>
                  <h1 className="text-sm font-semibold mb-1">
                    {notification.title}
                  </h1>
                  <p className="text-[12px]">{notification.message}</p>
                  <p className="text-[11px] mt-2">
                    {moment(notification.created_at).fromNow()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        {loading && (
          <div className="py-2">
            <PageSpinner />
          </div>
        )}
      </div>
    </>
  );
};

export default Notification;
