import axios from "axios";
import moment from "moment";

export const getMyCandidates = async (queryString = "") => {
  const response = await axios.get(`/api/client/candidates?${queryString}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const updateCandidate = async (id = "", form = null) => {
  const response = await axios.put(`/api/client/candidates/${id}`, form, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getDetailData = async (id = "", data_type = "personal") => {
  const response = await axios.get(
    `/api/client/candidate-profiles/${id}/${data_type}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
