import React from "react";

export default function DotSixVertical({
  size = 18, // or any default size of your choice
  color = "black", // or any color of your choice
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.625 6.75C9.24632 6.75 9.75 6.24632 9.75 5.625C9.75 5.00368 9.24632 4.5 8.625 4.5C8.00368 4.5 7.5 5.00368 7.5 5.625C7.5 6.24632 8.00368 6.75 8.625 6.75Z"
        fill="#404040"
      />
      <path
        d="M15.375 6.75C15.9963 6.75 16.5 6.24632 16.5 5.625C16.5 5.00368 15.9963 4.5 15.375 4.5C14.7537 4.5 14.25 5.00368 14.25 5.625C14.25 6.24632 14.7537 6.75 15.375 6.75Z"
        fill="#404040"
      />
      <path
        d="M8.625 13.125C9.24632 13.125 9.75 12.6213 9.75 12C9.75 11.3787 9.24632 10.875 8.625 10.875C8.00368 10.875 7.5 11.3787 7.5 12C7.5 12.6213 8.00368 13.125 8.625 13.125Z"
        fill="#404040"
      />
      <path
        d="M15.375 13.125C15.9963 13.125 16.5 12.6213 16.5 12C16.5 11.3787 15.9963 10.875 15.375 10.875C14.7537 10.875 14.25 11.3787 14.25 12C14.25 12.6213 14.7537 13.125 15.375 13.125Z"
        fill="#404040"
      />
      <path
        d="M8.625 19.5C9.24632 19.5 9.75 18.9963 9.75 18.375C9.75 17.7537 9.24632 17.25 8.625 17.25C8.00368 17.25 7.5 17.7537 7.5 18.375C7.5 18.9963 8.00368 19.5 8.625 19.5Z"
        fill="#404040"
      />
      <path
        d="M15.375 19.5C15.9963 19.5 16.5 18.9963 16.5 18.375C16.5 17.7537 15.9963 17.25 15.375 17.25C14.7537 17.25 14.25 17.7537 14.25 18.375C14.25 18.9963 14.7537 19.5 15.375 19.5Z"
        fill="#404040"
      />
    </svg>
  );
}
