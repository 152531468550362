import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../Component/PersonalityRadarChart";
import Slider from "react-slick";
import EmptyState from "../../Components/EmptyState";
import StatsTable from "../../../../../Component/Tables/StatsTable";
import { Tooltip } from "chart.js";
const SoftSkillsOverview = ({ data }) => {

    useEffect(() => {
        // console.log("SOFT SKILLS DATA " , data)
    }, [data])
    const TooltipContent = ({ overall = 0, average = 0 }) => {
        return (
            <>
                <div className="flex items-center mr-4">
                    {overall >= 0 && overall <= 33 && <>
                        <span className="bg-red-500 h-[8px] w-[8px] rounded-full mr-2"></span>
                    </>}
                    {overall > 33 && overall <= 66 && <>
                        <span className="bg-yellow-300 h-[8px] w-[8px] rounded-full mr-2"></span>
                    </>}
                    {overall > 66 && <>
                        <span className="bg-green-500 h-[8px] w-[8px] rounded-full mr-2"></span>
                    </>}
                    <p className='font-semibold'>Overall: <b>{overall}%</b></p>
                </div>
                <div className="flex items-center mr-4">
                    <span className="bg-gray-200 h-[8px] w-[8px] rounded-full mr-2"></span>
                    <p className='font-semibold'>Average: <b>{average}%</b></p>
                </div>

            </>
        )
    }
    const TableContent = ({ content = [] }) => {
        let total_row = 0;
        const table_content = content.map((item, index) => {
            if (item.result.length <= 0) {
                return (<></>)
            } else {
                let total_max_value = 0;
                let total_result = 0;
                let result_percentage = 0;
                item.result.forEach((element) => {
                    element.data.forEach((tag) => {
                        total_max_value += parseFloat(tag.max_value)
                        total_result += parseFloat(tag.result)
                    })
                })
                result_percentage = ((total_result / total_max_value) * 100).toFixed(0)
                total_row += 1;
                return (
                    <>
                        <tr className="whitespace-nowrap  bg-gray-50 rounded-lg">
                            <td className="px-6 py-4 text-sm text-gray-900 max-w-[100px] font-semibold w-[20%]">
                                {item.test_name}
                            </td>
                            <td className="py-4 text-sm text-gray-500 max-w-[90px] w-[15%]">
                                {result_percentage >= 0 && result_percentage <= 33 && <>
                                    <p className="text-label red mb-2 font-semibold">Low</p>
                                    <p className='text-green-500 text-sm mb-2 font-bold'>Top 15%</p>
                                </>}
                                {result_percentage > 33 && result_percentage <= 66 && <>
                                    <p className="text-label yellow mb-2 font-semibold">Medium</p>
                                    <p className='text-green-500 text-sm mb-2 font-bold'>Top 15%</p>
                                </>}
                                {result_percentage > 66 && <>
                                    <p className="text-label green mb-2 font-semibold">High</p>
                                    <p className='text-green-500 text-sm mb-2 font-bold'>Top 15%</p>
                                </>}
                            </td>
                            <td className="py-4 text-sm text-gray-500 flex flex-col">
                                <p className="text-lg mb-1 text-gray-700"><b>{result_percentage}%</b> </p>
                                <p>Benchmark <b>{result_percentage}%</b> </p>
                            </td>

                            <td className="py-4 text-sm text-gray-500 w-[50%] pr-6">
                                {(() => {
                                    if (result_percentage >= 0 && result_percentage <= 33) {
                                        return (
                                            <>

                                                <div className="w-full h-[12px]  bg-gray-100 ">
                                                    <div
                                                        className="h-full text-center text-xs text-white bg-red-500 rounded-r-sm"
                                                        style={{
                                                            width: `${result_percentage}%`,
                                                        }}
                                                    >
                                                    </div>
                                                </div>


                                                <div className="w-full h-[12px]  bg-gray-100">
                                                    <div
                                                        className="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                        style={{
                                                            width: `${result_percentage}%`,
                                                        }}
                                                    >
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    } else if (result_percentage >= 33 && result_percentage <= 66) {
                                        return (
                                            <>

                                                <div className="w-full h-[12px]  bg-gray-100">
                                                    <div
                                                        className="h-full text-center text-xs text-white bg-yellow-300 rounded-r-sm"
                                                        style={{
                                                            width: `${result_percentage}%`,
                                                        }}
                                                    >
                                                    </div>
                                                </div>

                                                <div className="w-full h-[12px]  bg-gray-100">
                                                    <div
                                                        className="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                        style={{
                                                            width: `${result_percentage}%`,
                                                        }}
                                                    >
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    } else if (result_percentage > 66) {
                                        return (<>
                                            <div className="w-full h-[12px]  bg-gray-100">
                                                <div
                                                    className="h-full text-center text-xs text-white bg-green-500 rounded-r-sm"
                                                    style={{
                                                        width: `${result_percentage}%`,
                                                    }}
                                                >
                                                </div>
                                            </div>

                                            <div className="w-full h-[12px]  bg-gray-100">
                                                <div
                                                    className="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                                                    style={{
                                                        width: `${result_percentage}%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </>)
                                    }

                                })()}
                            </td>
                        </tr >
                        <tr className="h-[16px]"></tr>
                    </>
                )
            }
        })
        if (total_row <= 0) {
            if(content.filter((item) => item.is_choosen).length <= 0){
                return (<><td colSpan={4}><EmptyState text="No test has been chosen for this assessment category" /></td></>)
            } else{
                return (<><td colSpan={4}><EmptyState  /></td></>)
            }
        }
        return table_content
    }
    return (
        <div className='card flex flex-col lg:flex-row justify-between w-full mb-5 anti-cheat'>

            <div className='content w-full'>
                <div className='flex flex-col h-[100%]'>
                    <p className='text-lg text-[#374151] font-semibold mb-4'>Soft Skills</p>
                    <StatsTable checkbox={false} headers={["Test", "Category", "Score", "Overall"]} content={<TableContent content={data} />} />
                </div>
            </div>
        </div>
    )
}

export default SoftSkillsOverview;