import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  documentTitle,
  inputIsEmailFormat,
  inputIsOnlyAlphabetic,
  inputIsPhoneNumberWithCode,
  inputMaxDate,
  validationError,
} from "../../../helpers";
import { ToastContainer, toast } from "react-toastify";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";
import PageSpinner from "../../../Component/PageSpinner";
import Datepicker from "react-tailwindcss-datepicker";
import BtnSpinner from "../../../Component/BtnSpinner";
import BreadCrumb from "../../../Component/BreadCrumb";
const EditPage = () => {
  let navigate = useNavigate();
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const [loading, setLoading] = useState(false);
  const [totalEmploye] = useState([
    "1-10",
    "11-50",
    "51-200",
    "201-500",
    "501-1000",
    "1001-5000",
    "5001-10000",
    "> 10000",
  ]);
  const [industries, setIndustries] = useState([]);
  const [formPassed, setFormPassed] = useState(false);
  const [validations, setValidations] = useState({});
  const [form, setForm] = useState({
    id: "",
    name: "",
    found_date: "",
    person_in_charge: "",
    email: "",
    industry: "",
    phonenumber: "+62",
    address: "",
    total_employee: "",
  });

  const [submiting, setSubmiting] = useState(false);
  const submit = async () => {
    setSubmiting(true);
    try {
      // BACKGOUND SYNC
      navigator.serviceWorker.ready.then(function (registration) {
        console.log("Service Worker Ready");
        return registration.sync.register("syncData;" + JSON.stringify(form), {
          data: form,
        });
      });
      console.log("Pesan berhasil dijadwalkan untuk disinkronkan.");
    } catch (error) {
      console.error("Gagal menjadwalkan sinkronisasi:", error);
    }
    await axios
      .put(`/api/client/company/${form.id}`, JSON.stringify(form), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success(result.message, {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1250,
          });
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.data.status_code == 422) {
          toast.error("Validation error, please check form", {
            toastId: "login-error-toast",
            position: toast.POSITION.TOP_CENTER,
          });
          setValidations(response.data.message);
        } else {
          toast.error(e.response.data.message, {
            toastId: "login-error-toast",
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .finally(() => {
        setSubmiting(false);
      });
  };

  const getInformation = async () => {
    setLoading(true);
    await axios
      .get("/api/client/company", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setForm(result.data);
        }
      })
      .catch((e) => {
        // console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getIndutries = async () => {
    try {
      const response = await axios.get("/api/client/company-industry-list");
      const result = response.data;
      if (result.success) {
        setIndustries(result.data);
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleIndustryChange = (value) => {
    setForm({ ...form, industry: value });
  };

  useEffect(() => {
    getInformation();
    getIndutries();
    documentTitle("Edit Company Profile");
  }, []);
  // validation
  useEffect(() => {
    if (
      form.email !== "" &&
      form.person_in_charge !== "" &&
      form.address !== "" &&
      form.total_employee !== ""
    ) {
      setFormPassed(true);
      const newValidations = { ...validations };
      if (form.phonenumber) {
        if (form.phonenumber.length < 11) {
          newValidations.phonenumber = [validationError("phone")];
        } else {
          newValidations.phonenumber = [];
        }
      }

      if (!inputIsEmailFormat(form.email)) {
        newValidations.email = [validationError("email")];
      } else {
        newValidations.email = [];
      }
      setValidations(newValidations);
      // Cek jika semua properti dalam objek validasi kosong
      const allValid = Object.values(newValidations).every(
        (validationArray) => validationArray.length === 0
      );
      setFormPassed(allValid);
    } else {
      setFormPassed(false);
    }
  }, [form]);

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <BuildingOfficeIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Company Profile</p>
          <BreadCrumb
            list={[
              { href: "/client/company-profile", title: "Company Profile" },
              { href: null, title: "Edit" },
            ]}
          />
        </div>
      </div>

      <div className="reports mb-6">
        <div className="card flex flex-col w-full">
          {loading ? (
            <PageSpinner />
          ) : (
            <>
              <div>
                {/* <form className="bg-grey-lightest" name="create_candidate" onSubmit={getReports}> */}
                <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
                  <div className="mb-2">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Company Name
                    </label>
                    <input
                      readOnly
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Company Name"
                      value={form.name}
                      onChange={(e) => {
                        setForm({ ...form, name: e.target.value });
                      }}
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Found Date
                    </label>
                    {/* <input
                                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                                        type="date"
                                        max={inputMaxDate(-1)}
                                        name="found_date"
                                        id="found_date"
                                        placeholder="Company Name"
                                        value={form.found_date}
                                        onChange={(e) => {
                                            setForm({ ...form, found_date: e.target.value })
                                        }}
                                    /> */}
                    <Datepicker
                      inputClassName={
                        "border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      }
                      maxDate={inputMaxDate(-1)}
                      useRange={false}
                      asSingle={true}
                      value={{
                        startDate: form.found_date,
                        endDate: form.found_date,
                      }}
                      onChange={(e) => {
                        setForm({ ...form, found_date: e.startDate });
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      PIC Name
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="person_in_charge"
                      id="person_in_charge"
                      placeholder="PIC Name"
                      value={form.person_in_charge}
                      onChange={(e) => {
                        if (inputIsOnlyAlphabetic(e.target.value))
                          setForm({
                            ...form,
                            person_in_charge: e.target.value,
                          });
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Email
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Company Email"
                      value={form.email}
                      onChange={(e) => {
                        setForm({ ...form, email: e.target.value });
                      }}
                    />
                    {typeof validations.email != "undefined"
                      ? validations.email.map((e) => {
                          return (
                            <span className="input-error-message">{e}</span>
                          );
                        })
                      : ""}
                  </div>
                  <div className="mb-2">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Phone
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="phonenumber"
                      id="phonenumber"
                      placeholder="Company Phonenumber"
                      value={form.phonenumber}
                      onChange={(e) => {
                        if (inputIsPhoneNumberWithCode(e.target.value)) {
                          setForm({ ...form, phonenumber: e.target.value });
                        } else {
                          setForm({ ...form, phonenumber: "+62" });
                        }
                      }}
                    />
                    {typeof validations.phonenumber != "undefined"
                      ? validations.phonenumber.map((e) => {
                          return (
                            <span className="input-error-message">{e}</span>
                          );
                        })
                      : ""}
                  </div>
                  <div className="mb-2">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Industry
                    </label>
                    <select
                      className="form-select appearance-none
                                    block
                                    w-full
                                    px-3
                                    py-2
                                    text-gray-800
                                    text-[14px]
                                    bg-white bg-clip-padding bg-no-repeat
                                    border border-solid border-gray-300
                                    rounded-md
                                    mt-2
                                    transition
                                    ease-in-out
                                    focus:outline-none focus:border-main-violate border border-line-grey"
                      aria-label="Default select example"
                      value={form.industry}
                      onChange={(e) => {
                        handleIndustryChange(e.target.value);
                      }}
                    >
                      <option selected disabled value="">
                        Choose your industry...
                      </option>
                      {industries.map((industry) => (
                        <option key={industry.id} value={industry.name}>
                          {industry.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-2">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Address
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Company Address"
                      value={form.address}
                      onChange={(e) => {
                        setForm({ ...form, address: e.target.value });
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Total Employees
                    </label>
                    <div className="flex">
                      <select
                        className="form-select appearance-none
                                            block
                                            w-[100%]
                                            px-3
                                            py-2
                                            text-grey-600
                                            text-[14px]
                                            bg-white bg-clip-padding bg-no-repeat
                                            border border-solid border-gray-300
                                            rounded-md
                                            mt-2
                                            transition
                                            ease-in-out
                                            focus:outline-none focus:border-main-violate border border-line-grey"
                        aria-label="Default select example"
                        value={form.total_employee}
                        onChange={(e) => {
                          setForm({ ...form, total_employee: e.target.value });
                        }}
                      >
                        <option selected disabled value="">
                          Choose a number
                        </option>
                        {totalEmploye.map((total) => (
                          <option key={total} value={total}>
                            {total} Employees
                          </option>
                        ))}
                      </select>
                      {/* <MinusIcon className='mt-2 w-[4%] text-gray-400' />
                                        <select
                                            className="form-select appearance-none
                                            block
                                            w-[48%]
                                            px-3
                                            py-2
                                            text-grey-600
                                            text-[14px]
                                            bg-white bg-clip-padding bg-no-repeat
                                            border border-solid border-gray-300
                                            rounded-md
                                            mt-2
                                            transition
                                            ease-in-out
                                            focus:outline-none focus:border-main-violate border border-line-grey"
                                            aria-label="Default select example"
                                            value={form.max_employee}
                                            onChange={(e) => {
                                                setForm({ ...form, max_employee: e.target.value })
                                            }}
                                        >
                                            <option selected disabled value="">
                                                Choose a number
                                            </option>
                                            {totalEmploye.map((total) => (
                                                <option key={total} value={total}>
                                                    {total}
                                                </option>
                                            ))}
                                        </select> */}
                    </div>
                  </div>
                </div>
                <div className="w-full flex justify-end">
                  <button
                    className=" bg-main-violate px-8 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    type="submit"
                    disabled={submiting || !formPassed}
                    onClick={submit}
                  >
                    {submiting ? (
                      <>
                        <BtnSpinner />
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </button>
                </div>

                {/* </form> */}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditPage;
