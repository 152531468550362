import { BookOpenIcon, ClipboardDocumentListIcon, ClockIcon } from '@heroicons/react/24/solid';
import { ClockIcon as ClockIconOutline } from '@heroicons/react/24/outline';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DotSixVertical from '../Icons/DotSixVertical';

class DetailTestPreview extends Component {
    constructor(props) {
        super(props);
        this.jobRoleInputRef = React.createRef();
        this.state = {
            sampleForm: 1,
            personalityA: {
                selectedId: "",
                options: [
                    {
                        id: 1,
                        content: "Excited and Fun"
                    },
                    {
                        id: 2,
                        content: "Meaningful"
                    }
                ]
            },
            multipleAnswers: {
                options: [
                    {
                        id: 1,
                        content: "Used to identify particular cell on Worksheet"
                    },
                    {
                        id: 2,
                        content: "A combination of column number and row letter"
                    },
                    {
                        id: 3,
                        content: "Respective cell destination"
                    },
                    {
                        id: 4,
                        content: "The cell format tabs"
                    },
                ]
            },
            multipleChoices: {
                options: [
                    {
                        id: 1,
                        content: "Used to identify particular cell on Worksheet"
                    },
                    {
                        id: 2,
                        content: "A combination of column number and row letter"
                    },
                    {
                        id: 3,
                        content: "Respective cell destination"
                    },
                    {
                        id: 4,
                        content: "The cell format tabs"
                    },
                ]
            },
            sorting: {
                options: [
                    {
                        id: 1,
                        content: "United States of America"
                    },
                    {
                        id: 2,
                        content: "Indonesia"
                    },
                    {
                        id: 3,
                        content: "India"
                    },
                    {
                        id: 4,
                        content: "China"
                    },
                ]
            },
            personalityB: {
                selectedId: "",
            }
        }
    }

    componentDidMount() {
        this.setState({ sampleForm: this.props.data.question_type })
    }

    handleClose() {
        this.props.onClose();
    }

    handleClick = (id) => {
        this.setState((prevState) => ({
            personalityA: {
                ...prevState.personalityA,
                selectedId: id
            }
        }));
    }

    handleSelectPersonalityB = (id) => {
        this.setState((prevState) => ({
            personalityB: {
                ...prevState.personalityB,
                selectedId: id
            }
        }));
    }

    onDragEnd = (result) => {
        if (!result.destination) return; // If dropped outside a droppable area, do nothing
    };

    render() {
        const SampleForm = () => {
            if (this.state.sampleForm == 1) {
                return (
                    <>
                        <p className='text-grey-900 text-base font-semibold mb-6'>Select an Option</p>
                        <p className='text-grey-900 text-base'>
                            {this.state.personalityA.options.map((opt) => (
                                <div
                                    className={`${(opt.id == this.state.personalityA.selectedId ? 'border-main-violate ' : 'border-line-grey-1 hover:border-2 hover:font-bold ') + 'rounded-[8px] h-[48px] border  px-5 py-3 mt-3 cursor-pointer'}`}
                                    onClick={() => this.handleClick(opt.id)}
                                >
                                    <p className="text-[16px] leading-[140%] no-select">{opt.content}</p>
                                </div>
                            ))}
                        </p>
                    </>
                )
            } else if (this.state.sampleForm == 2) {
                return (
                    <>
                        <p className='text-grey-900 text-base font-semibold mb-6'>Choose the most close answer to the statement</p>
                        <div className="flex mt-0 lg:mt-[30px] ml-0 lg:ml-[40px] mb-4">
                            <p className="text-[14px] leading-[140%] mr-4 ">
                                Not suitable
                            </p>
                            <div className="flex">
                                <button
                                    className={`${(this.state.personalityB.selectedId == 1 ? 'bg-main-violate ' : '') + 'border border-line-grey-1 w-[20px] h-[20px] md:w-[20px] md:h-[20px] rounded-full ml-4'}`}
                                    type="button"
                                    onClick={(e) => this.handleSelectPersonalityB(1)}
                                ></button>
                                <button
                                    className={`${(this.state.personalityB.selectedId == 2 ? 'bg-main-violate ' : '') + 'border border-line-grey-1 w-[20px] h-[20px] md:w-[20px] md:h-[20px] rounded-full ml-4'}`}
                                    type="button"
                                    onClick={(e) => this.handleSelectPersonalityB(2)}
                                ></button>
                                <button
                                    className={`${(this.state.personalityB.selectedId == 3 ? 'bg-main-violate ' : '') + 'border border-line-grey-1 w-[20px] h-[20px] md:w-[20px] md:h-[20px] rounded-full ml-4'}`}
                                    type="button"
                                    onClick={(e) => this.handleSelectPersonalityB(3)}
                                ></button>
                                <button
                                    className={`${(this.state.personalityB.selectedId == 4 ? 'bg-main-violate ' : '') + 'border border-line-grey-1 w-[20px] h-[20px] md:w-[20px] md:h-[20px] rounded-full ml-4'}`}
                                    type="button"
                                    onClick={(e) => this.handleSelectPersonalityB(4)}
                                ></button>
                                <button
                                    className={`${(this.state.personalityB.selectedId == 5 ? 'bg-main-violate ' : '') + 'border border-line-grey-1 w-[20px] h-[20px] md:w-[20px] md:h-[20px] rounded-full ml-4'}`}
                                    type="button"
                                    onClick={(e) => this.handleSelectPersonalityB(5)}
                                ></button>
                            </div>

                            <p className="text-[14px] leading-[140%] ml-4">
                                Suitable
                            </p>
                        </div>
                    </>

                )
            } else if (this.state.sampleForm == 5) {
                return (
                    <>
                        <p className='text-grey-900 text-base font-semibold mb-6'>Fill in the blank</p>
                        <input
                            className="border w-full p-3 h-[48px] placeholder:text-sm rounded-lg focus:ring-2 focus:outline-none focus:ring-main-violate border border-line-grey"
                            type="text"
                            name="answerr"
                            id="answerr"
                            placeholder="Type Your Answer"
                        />
                    </>
                )
            } else if (this.state.sampleForm == 3) {
                return (
                    <>
                        <p className='text-grey-900 text-base font-semibold mb-6'>Select an option</p>
                        {this.state.multipleChoices.options.map((option) => {
                            return (
                                <div className="flex items-center mb-3 no-select">
                                    <input
                                        id="purple-radio1"
                                        type="radio"
                                        name="multiple"
                                        value={option.id}
                                        className="w-4 h-4 text-main-violate bg-gray-100 border-gray-300 focus:ring-main-violate dark:focus:ring-main-violate dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="purple-radio1"
                                        className="ml-2 text-[16px] leading-[140%] "
                                    >
                                        {option.content}
                                    </label>
                                </div>
                            )

                        })}

                    </>
                )
            } else if (this.state.sampleForm == 6) {
                return (
                    <>
                        <p className='text-grey-900 text-base font-semibold mb-6'>Drag and Sort</p>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="list">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="space-y-3 mt-3"
                                    >
                                        {this.state.sorting.options.map((option, index) => {
                                            return (
                                                <Draggable
                                                    key={`option-${option.id}`}
                                                    draggableId={`option-${option.id}`} // Ensure this matches the id of the item
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            className="flex"
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                        >

                                                            <div className="rounded-[8px] w-full border border-line-grey-1 hover:border-2 hover:font-bold px-5 py-3 cursor-pointer">
                                                                <p className="text-[16px] leading-[140%] flex justify-between no-select">
                                                                    {option.content} <DotSixVertical />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>

                )
            } else if (this.state.sampleForm == 4) {
                return (
                    <>
                        <p className='text-grey-900 text-base font-semibold mb-6'>Select some correct answers</p>
                        {this.state.multipleAnswers.options.map((option) => {
                            return (
                                <div className="radio-button mt-3">
                                    <div className="flex items-center mb-3">
                                        <input
                                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                                            type="checkbox"
                                            id={`option_${option.id}`}
                                            name="answer[]"
                                        />
                                        <label
                                            htmlFor="purple-radio1"
                                            className="ml-2 text-[16px] leading-[140%] "
                                        >
                                            {option.content}
                                        </label>
                                    </div>
                                </div>
                            )

                        })}

                    </>

                )
            }
        }
        return (
            <div className="bg-white max-h-[580px] 2xl:max-h-[820px] xl:max-h-[720px] lg:max-h-[680px] md:max-h-[620px] overflow-y-scroll rounded-lg shadow w-full outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate px-6 py-6">
                <h2 className='text-xl font-semibold leading-[150%] mb-6'>Detail Test</h2>
                <div className="card">
                    <p className="text-grey-900 font-semibold text-xl mb-4">{this.props.data.name}</p>
                    <p className="text-grey-500 text-base mb-12">{this.props.data.description}</p>
                    <div className='grid-cols-1 md:grid-cols-3 grid gap-4'>
                        <div className='flex flex-col items-center'>
                            <BookOpenIcon width={24} className='text-grey-600' />
                            <p className="text-grey-900 text-base mb-2 mt-2">Assessment Category</p>
                            <p className="text-grey-500 text-sm">{this.props.data.assessment.name}</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <ClockIcon width={24} className='text-grey-600' />
                            <p className="text-grey-900 text-base mb-2 mt-2">Time</p>
                            <p className="text-grey-500 text-sm">{(this.props.data.times / 60).toFixed(0)} Minutes</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <ClipboardDocumentListIcon width={24} className='text-grey-600' />
                            <p className="text-grey-900 text-base mb-2 mt-2">Item</p>
                            <p className="text-grey-500 text-sm">{this.props.data.total_question} Item test</p>
                        </div>
                    </div>
                </div>
                <div className='flex w-full px-8 py-4'>
                    <div className="flex flex-col mt-10 w-[80%]">
                        <small className="text-gray-500 text-end ">50%</small>
                        <div className="w-full h-[7px] bg-white-1 rounded-full mt-1">
                            <div
                                className="h-full text-center text-xs text-white bg-main-violate rounded-full"
                                style={{
                                    width: `50%`,
                                }}
                            >
                            </div>
                        </div>
                        <small className="text-gray-900 text-end mt-2">01/15 Completed</small>
                    </div>
                    <div className='flex mt-10 items-center w-[20%] justify-end'>
                        <ClockIconOutline width={24} />
                        <p className="text-grey-900 text-base ml-2 mr-4">Test Item</p>
                        <p className="text-grey-900 text-base font-bold">15:00</p>
                    </div>
                </div>
                <div className='grid-cols-1 md:grid-cols-2 grid gap-6 px-8 py-6 mt-10'>
                    <div>
                        <p className='text-grey-900 text-base font-semibold mb-6'>Sample Question</p>
                        <p className='text-grey-900 text-base'>When faced with a new experience, I usually ask myself whether the experience will be</p>
                    </div>
                    <div>

                        <SampleForm />
                    </div>
                </div>
                <div className='w-full flex justify-end'>
                    <button
                        className="border border-line-grey-1 text-grey-98 hover:border-2 cursor-pointer  w-[160px] px-4 py-2 text-[16px] leading-[22.4px] text-black border-line-grey-1 font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed mr-4"
                        type="submit"
                        onClick={(e) => this.handleClose()}
                    >
                        Close
                    </button>
                    {/* <button
                        className="hover:bg-violet-600 cursor-pointer bg-main-violate  w-[160px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed"
                        type="submit"
                        onClick={(e) => this.handleClose()}
                    >
                        Add
                    </button> */}
                </div>

            </div>

        );
    }
}

export default DetailTestPreview;