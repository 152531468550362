import React from "react";
import { useState, useEffect } from "react";
import PageSpinner from "../../../../../../Component/PageSpinner";
import { PencilIcon, PlusIcon, SparklesIcon } from "@heroicons/react/24/solid";
const AboutSection = ({ about, loading }) => {
  const [user, setUser] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    about: "",
  });
  //



  useEffect(() => {
    setForm({ ...form, about: about });
  }, [about])


  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <SparklesIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              About
            </h4>
          </div>
        </div>
        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {form.about ? (
              <div className="mt-4">
                <input
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg border-inherit"
                  type="text"
                  name="phone"
                  id="phone"
                  value={form.about}
                  placeholder="About"
                  disabled
                />
              </div>
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AboutSection;
