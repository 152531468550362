import React from "react";
import { useState, useEffect } from "react";
import "./range-list2.css"
const RangeList = ({ data, color = "blue" }) => {
    const { parameter, minLabel, maxLabel, maxRange, minRange, value, code } = data;
    const summaryCard = {
        boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: '12px',
        background: '#FFF',
        padding: '24px',
    }
    const result_percentage = (((value + Math.abs(minRange)) / (Math.abs(maxRange) + Math.abs(minRange))) * 100);
    return (
        <div style={summaryCard} className='h-[100%] w-[97%] items-center mb-5 px-3' id={code}>
            <p className='font-semibold mb-5 text-center'>{parameter}</p>
            <div className='flex'>
                <div className='flex flex-col mdb:flex-row w-full gap-4 items-center space-y-12 mdb:space-y-0'>
                    <div className='w-full mdb:w-[30%] min-parameter'>
                        <p className='text-gray-500'>{minLabel}</p>
                    </div>
                    <div className='w-full mdb:w-[40%] parameter'>
                        <div className={`slider w-full ${color}`}>
                            <div className={`slider-value bg-white font-semibold ${color}`} style={{ left: result_percentage + "%" }}>{value}</div>
                            <div className={`slider-button bg-white border-2 ${color}`} style={{ left: result_percentage + "%" }}></div>
                        </div>
                    </div>
                    <div className='w-full mdb:w-[30%] max-parameter'>
                        <p className='text-gray-500'>{maxLabel}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RangeList;