import React from "react";

function PopUp({ onClose, children }) {
  return (
    <>
      <div className="popup-alert justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate z-[999]" style={{ background: "#7a727240" }}>
        <div className="relative w-auto my-6 mx-auto max-w-4xl">
          {children}
        </div>
      </div>
    </>
  );
}
export default PopUp;
