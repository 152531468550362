// jobSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMyPermission } from "../../api/permissionApi";
const initialState = {
  user: {
    name: null,
    username: null,
    email: null,
    email_verified_at: null,
    client_access_id: null,
    base_role: null,
    company: {
      id: null,
      name: null,
      email: null,
      phonenumber: null,
      person_in_charge: null,
      found_date: null,
      address: null,
      industry: null,
      total_employee: null,
      slug: null,
      image: null,
      verified: null,
      active: null,
    },
    token: null,
  },
  permissions: null,
  //   permissions: {
  //     user: ["read", "create", "update", "delete"],
  //   },
};

export const fetchPermissions = createAsyncThunk(
  "user/fetchPermissions",
  async () => {
    const result = await getMyPermission(); // Replace with your API endpoint
    return result.data;
  }
);

const userSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
    });
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
