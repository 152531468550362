import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RectangleStackIcon } from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import {
  documentTitle,
  inputIsOnlyAlphabetic,
  inputIsPhoneNumberWithCode,
  inputIsUseranme,
  validationError,
} from "../../../../helpers";
import BtnSpinner from "../../../../Component/BtnSpinner";
import { createCompany, getCompanies } from "../../../../api/admin/companyApi";
import Select from "react-select";
import { createClientUser } from "../../../../api/admin";

const CreatePage = () => {
  let navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [validations, setValidations] = useState({
    password: [],
    password_confirmation: [],
  });
  const [form, setForm] = useState({
    company_id: "",
    username: "",
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
    phonenumber: "+62",
    base_role: "owner",
    status: 1,
  });
  const [formPassed, setFormPassed] = useState(false);
  const [companies, setCompanies] = useState([]);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const resetForm = () => {
    setSelectedCompany(null);
    setForm({
      company_id: "",
      username: "",
      name: "",
      email: "",
      password: "",
      confirmpassword: "",
      phonenumber: "+62",
      base_role: "owner",
      status: 1,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(form)
    setSubmitting(true);
    const result = await createClientUser(form);
    if (result.success) {
      resetForm();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  const fetchCompanies = async () => {
    const result = await getCompanies();
    if (result.success) {
      setCompanies(result.data.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    let newValidations = { ...validations };
    if (form.password == "") {
      setFormPassed(false);
    } else {
      if (form.confirmpassword != form.password) {
        newValidations = {
          ...newValidations,
          password_confirmation: [validationError("password_confirmation")],
        };
        setFormPassed(false);
      } else {
        newValidations = {
          ...newValidations,
          password_confirmation: [],
        };
        setFormPassed(true);
      }

      if (form.password.length < 8) {
        newValidations = {
          ...newValidations,
          password: [validationError("password")],
        };
        setFormPassed(false);
      } else {
        newValidations = {
          ...newValidations,
          password: [],
        };
        setFormPassed(true);
      }
    }

    setValidations(newValidations);
  }, [form.confirmpassword, form.password]);

  useEffect(() => {
    documentTitle("Create User");
    fetchCompanies();
  }, []);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <RectangleStackIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Create Company</p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/client/users",
                title: "Client",
              },
              {
                href: null,
                title: "Create Company",
              },
            ]}
          />
        </div>
      </div>

      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="card flex flex-col w-[50%]">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">
                New User Form
              </p>
            </div>
          </div>
          <div className="flex space-x-8 mb-4">
            <div className="w-[100%] flex space-x-4">
              <div className="w-[100%] flex flex-col space-y-4">
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Company
                  </label>
                  <div className="py-0.5 pl-2 mt-2 border border-line-grey rounded-lg flex items-center">
                    <Select
                      className="w-full "
                      classNamePrefix={"select"}
                      options={companies.map((option) => {
                        return {
                          value: option.id,
                          label: option.name,
                        };
                      })}
                      value={selectedCompany}
                      onChange={(e) => {
                        setSelectedCompany(e);
                        setForm({ ...form, company_id: e.value });
                      }}
                      placeholder={"Select Company"}
                    />
                  </div>
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Username
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Input username"
                    value={form.username}
                    onChange={(e) => {
                      if (inputIsUseranme(e.target.value)) {
                        setForm({ ...form, username: e.target.value });
                      }
                    }}
                    required
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Fullname
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Input text"
                    value={form.name}
                    onChange={(e) => {
                      if (inputIsOnlyAlphabetic(e.target.value)) {
                        setForm({ ...form, name: e.target.value });
                      }
                    }}
                    required
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Email
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Input email"
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                    required
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Phonenumber
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="phonenumber"
                    id="phonenumber"
                    placeholder="Input text"
                    value={form.phonenumber}
                    onChange={(e) => {
                      if (inputIsPhoneNumberWithCode(e.target.value)) {
                        setForm({ ...form, phonenumber: e.target.value });
                      } else {
                        setForm({ ...form, phonenumber: "+62" });
                      }
                    }}
                    required
                  />
                </div>
                <div className="flex space-x-4 w-full">
                  <di className="w-[50%]">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Password
                    </label>
                    <input
                      // ref={this.jobRoleInputRef}
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Input password"
                      value={form.password}
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                      required
                    />
                    {typeof validations.password != "undefined"
                      ? validations.password.map((e) => {
                          return (
                            <span className="input-error-message">{e}</span>
                          );
                        })
                      : ""}
                  </di>
                  <div className="w-[50%]">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Confirm Password
                    </label>
                    <input
                      // ref={this.jobRoleInputRef}
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="password"
                      name="confirmpassword"
                      id="confirmpassword"
                      placeholder="Input text"
                      value={form.confirmpassword}
                      onChange={(e) => {
                        setForm({ ...form, confirmpassword: e.target.value });
                      }}
                      required
                    />
                    {typeof validations.password_confirmation != "undefined"
                      ? validations.password_confirmation.map((e) => {
                          return (
                            <span className="input-error-message">{e}</span>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-6">
            {submitting ? (
              <button
                className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type=""
                disabled={true}
              >
                <BtnSpinner />
              </button>
            ) : (
              <button
                className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={!formPassed}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePage;
