import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgLogin from "../Assets/bgAuth.webp";
import "../css/login.css";
import BtnSpinner from "../Component/BtnSpinner";
import PasswordInput from "../Component/PasswordInput";
import FooterGuest from "../Component/FooterGuest";
import { documentTitle } from "../helpers";
import { BRAND_HIGHR_2 } from "../constants";

const LoginPage = () => {
  const [isPwa, setIsPwa] = useState(false);
  const [validations, setValidations] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signing, setSigning] = useState(false);
  const handleEmailChange = (e) => {
    const value = e.target.value || "";
    setEmail(value);
  };

  const handlePasswordChange = (password) => {
    setPassword(password);
  };

  const handleLogin = (e) => {
    let defaultRedirect = "/client/dashboard";
    if (e.success) {
      localStorage.setItem("user", JSON.stringify(e.data));
      let user = JSON.parse(localStorage.getItem("user"));
      // if (!data.company) {
      //   defaultRedirect = "/welcome";
      // }
      toast.success("Logged in as " + user.email + " (" + user.name + ")", {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
        onClose: () => window.location.replace(defaultRedirect),
      });
    } else {
      if (e.status_code === 422) {
        setValidations(e.message);
        toast.error(e.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(e.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSigning(true);
    const loginData = JSON.stringify({
      email: email,
      password: password,
    });
    axios
      .post("/api/client/login", loginData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        handleLogin(response.data);
      })
      .catch((e) => {
        handleLogin(e.response.data);
      })
      .finally(() => {
        setSigning(false);
      });
  };

  // TEST 2
  const myStyle = {
    backgroundImage: `linear-gradient(242.55deg, rgba(143, 3, 237, 0.7) 0%, rgba(85, 33, 181, 0.7) 99.39%), url(${bgLogin})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  useEffect(() => {
    documentTitle("Login");
    if (
      window.navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      setIsPwa(true);
      console.log("App is running in PWA mode");
    } else {
      setIsPwa(false);
    }
  }, []);

  // CHANGE AT 19/06/2023
  return (
    <>
      {isPwa && (
        <div className="h-[40px] w-full bg-[#ffb821] fixed flex justify-between items-center px-4">
          <p className="text-sm text-white">Are you administrator ?</p>
          <div className="flex gap-2">
            <a
              href="/administrator/login"
              className="text-sm text-white bg-[#6E670D] px-2 py-1 rounded-md"
            >
              Login Here
            </a>
            {/* <a className="text-sm text-white bg-[#6E4F0D] px-2 py-1 rounded-md">
            No
          </a> */}
          </div>
        </div>
      )}

      <div style={myStyle}>
        {/* IF PWA SHOW PROMPT TO LOGIN AS ADMINISTRATOR */}

        <div className="mx-auto pt-12 h-full flex flex-col md:justify-center lg:max-w-[1360px]">
          <ToastContainer />
          <div className="px-4 flex w-full flex-col md:flex-row">
            <div className="w-full md:w-1/2 text-center md:text-left mb-6">
              <p className="text-xl md:text-2xl text-white-1 mb-[16px] font-normal">
                Welcome To
              </p>
              <p className="text-xl md:text-6xl text-white-1 leading-[110%] font-light md:max-w-sm">
                {BRAND_HIGHR_2}
              </p>
            </div>
            <div className="w-full md:w-1/2 flex justify-center md:justify-end">
              <div className="bg-white rounded-lg shadow w-full lg:w-[483px] mb-[100px] px-10 py-8">
                <h1 className="font-bold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                  Login To {BRAND_HIGHR_2}
                </h1>
                {/* <p className="mt-3 text-gray-800 text-black text-center text-[14px] leading-[140%] tracking-normal">
                Hello candidate!
              </p>
              <p className=" text-gray-800 text-black text-center text-[14px] leading-[140%] tracking-normal">
                Please input your email and password to continue.
              </p> */}
                <form
                  className="bg-grey-lightest"
                  name="login"
                  onSubmit={handleSubmit}
                >
                  <div className="w-full justify-center">
                    <div className="mb-5">
                      <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        Email
                      </label>
                      <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Email"
                      />
                      {typeof validations.email != "undefined"
                        ? validations.email.map((e, index) => {
                            return (
                              <span className="input-error-message" key={index}>
                                {e}
                              </span>
                            );
                          })
                        : ""}
                    </div>
                    <div className="mb-[16px]">
                      <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        Password
                      </label>
                      <PasswordInput
                        onChange={handlePasswordChange}
                        onKeyDown={handleKeyDown}
                      />
                      {typeof validations.password != "undefined"
                        ? validations.password.map((e, index) => {
                            return (
                              <span className="input-error-message" key={index}>
                                {e}
                              </span>
                            );
                          })
                        : ""}
                    </div>
                    <div className="flex justify-between mb-10">
                      <div className="flex justify-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer focus:border-white"
                            type="checkbox"
                            id="inlineCheckbox1"
                            value="option1"
                          />
                          <label className="form-check-label inline-block text-grey-darkest text-[12px] leading-[16.8px]">
                            Remember Me
                          </label>
                        </div>
                      </div>
                      {/* <Link
                      to="/forgot-password"
                      className="text-grey-darkest hover:text-black text-[12px] leading-[16.8px] font-semibold mt-2 underline text-main-violate"
                    >
                      Forgot Password
                    </Link> */}
                    </div>
                  </div>

                  <div className="flex mt-5 justify-center">
                    <button
                      className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                      type="submit"
                      style={{ textAlign: "-webkit-center" }}
                      disabled={signing}
                    >
                      {signing ? <BtnSpinner /> : "Login"}
                    </button>
                  </div>
                  {/* <h4 className="text-gray-700 text-center text-sm tracking-normal mt-3">
                  Don’t have an account yet?
                  <Link
                    to="/signup"
                    className="ml-1 text-gray-700 text-center text-sm tracking-normal font-semibold underline"
                  >
                    Create Account
                  </Link>
                </h4> */}
                </form>
              </div>
            </div>
          </div>
        </div>
        <FooterGuest />
      </div>
    </>
  );
};

export default LoginPage;
