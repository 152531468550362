import React, { Component } from "react";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'

export class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPassword: true,
      password: ""
    };
  }

  handlePasswordChange = (e) => {
    e.preventDefault();
    this.setState({ password: e.target.value });
    this.props.onChange(e.target.value);
  }

  handleVisibility = (e) => {
    e.preventDefault();
    this.setState({ isPassword: !this.state.isPassword })
  }
  render() {
    return (
      <div className="relative w-full">
        <input
          className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
          type={this.state.isPassword ? "password" : "text"}
          value={this.state.password}
          onChange={this.handlePasswordChange}
          autoComplete="off"
          placeholder={this.props.placeholder ? this.props.placeholder : "Password"}
          onKeyDown={(e) => {
            // e.preventDefault()
            this.props.onKeyDown(e)
          }}
        />
        <button
          className="absolute inset-y-0 right-0 flex items-center px-2"
          onClick={this.handleVisibility}
        >
          {/* <img src={eye} alt="icon" className="h-[20px] w-[18px]" /> */}
          {this.state.isPassword ? (
            <EyeIcon className="h-5 w-5 text-grey-70" />
          ) : (
            <EyeSlashIcon className="h-5 w-5 text-grey-70" />
          )}
        </button>
      </div>
    );
  }
}
export default PasswordInput;
