import axios from "axios";

export const getClientRoleAccess = async (queryString = "") => {
  try {
    const response = await axios.get(
      `/api/admin/client-role-access?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getClientRoleAccessPermission = async (id = "") => {
  try {
    const response = await axios.get(`/api/admin/client-role-access/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateRoleAccessPermission = async (id = "", form = null) => {
  try {
    const response = await axios.put(
      `/api/admin/client-role-access/${id}`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
