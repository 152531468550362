import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  RectangleStackIcon,
  PlusSmallIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import { documentTitle, rupiahFormat } from "../../../../helpers";
import BtnSpinner from "../../../../Component/BtnSpinner";
import { getCompanies } from "../../../../api/admin/companyApi";
import Select from "react-select";
import {
  createClientUser,
  createPackageTransaction,
  getPackages,
} from "../../../../api/admin";

const CreatePage = () => {
  let navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [packages, setPackages] = useState([]);
  const [validations, setValidations] = useState({
    password: [],
    password_confirmation: [],
  });
  const [form, setForm] = useState({
    company_id: "",
    type: "quota",
    items: [
      {
        test_quota: 1,
        job_role_quota: 1,
        candidate_quota: 1,
      },
    ],
  });
  const [company, setCompany] = useState({
    id: "",
    name: "",
    email: "",
    person_in_charge: "",
    pic_position: "",
    phonenumber: "",
    verified: "",
    active: "",
  });
  const [formPassed, setFormPassed] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const resetForm = () => {
    setSelectedCompany(null);
    setForm({
      company_id: "",
      type: "quota",
      items: [
        {
          test_quota: 1,
          job_role_quota: 1,
          candidate_quota: "",
        },
      ],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sanitizedForm = {
      ...form,
      items: form.items.map((item) => {
        return {
          job_role_quota: parseInt(item.job_role_quota),
          candidate_quota: parseInt(item.candidate_quota),
          test_quota: parseInt(item.test_quota),
        };
      }),
    };
    setSubmitting(true);
    const result = await createPackageTransaction(sanitizedForm);
    if (result.success) {
      resetForm();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  const addItem = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      items: [
        ...form.items,
        {
          job_role_quota: 1,
          candidate_quota: 1
        },
      ],
    });
  };

  const fetchCompanies = async () => {
    const result = await getCompanies("limit=1000");
    if (result.success) {
      setCompanies(result.data.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const fetchPackages = async () => {
    const result = await getPackages("limit=1000");
    if (result.success) {
      setPackages(result.data.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  useEffect(() => {
    let formPassed = true;
    // let totalPrice = 0;
    form.items.forEach((item) => {
      if (item.job_role_quota == "" || item.candidate_quota == "") {
        formPassed = false;
      }
      // totalPrice +=
      //   item.quantity *
      //   packages.find((x) => x.id == item.subscription_package_id)?.price;
    });
    // setTotalPrice(totalPrice ? totalPrice : 0);
    if (form.company_id == "") {
      formPassed = false;
    }
    setFormPassed(formPassed);
  }, [form]);
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Create User");
    fetchCompanies();
    fetchPackages();
  }, []);

  useEffect(() => {
    // console.log(company);
  }, [company]);

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-red-100 text-red-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <RectangleStackIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">
            Create Package Transaction
          </p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/package-transaction",
                title: "Transaction Package",
              },
              {
                href: null,
                title: "Create Transaction Package",
              },
            ]}
          />
        </div>
      </div>

      {/* <form onSubmit={(e) => handleSubmit(e)}> */}
      <div className="card flex flex-col w-[100%]">
        <div className="flex justify-between w-full mb-4">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">
              Transaction Form
            </p>
          </div>
        </div>
        <div className="flex space-y-4 mdb:space-y-0 mdb:space-x-12 mb-4 mdb:flex-row flex-col">
          <div className="w-full mdb:w-[50%] flex space-x-4">
            <div className="w-[100%] flex flex-col space-y-4">
              <div>
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Company
                </label>
                <div className="py-0.5 pl-2 mt-2 border border-line-grey rounded-lg flex items-center">
                  <Select
                    className="w-full "
                    classNamePrefix={"select"}
                    options={companies.map((option) => {
                      return {
                        value: option.id,
                        label: option.name,
                      };
                    })}
                    value={selectedCompany}
                    onChange={(e) => {
                      setSelectedCompany(e);
                      setCompany(companies.find((x) => x.id == e.value));
                      setForm({
                        ...form,
                        company_id: e.value,
                      });
                    }}
                    placeholder={"Select Company"}
                  />
                </div>
              </div>
              <div>
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Company Email
                </label>
                <input
                  disabled
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Company Email"
                  value={company?.email ? company?.email : "-"}
                  onChange={(e) => {
                    // if (inputIsUseranme(e.target.value)) {
                    //   setForm({ ...form, username: e.target.value });
                    // }
                  }}
                  required
                />
              </div>
              <div>
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  PIC Name
                </label>
                <input
                  disabled
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="phonenumber"
                  id="phonenumber"
                  placeholder="PIC Name"
                  value={
                    company?.person_in_charge ? company?.person_in_charge : "-"
                  }
                  onChange={(e) => {}}
                  required
                />
              </div>
              <div>
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  PIC Phonenumber
                </label>
                <input
                  disabled
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="phonenumber"
                  id="phonenumber"
                  placeholder="PIC Phonenumber"
                  value={company?.phonenumber ? company?.phonenumber : "-"}
                  onChange={(e) => {}}
                  required
                />
              </div>
              <div>
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  PIC Position
                </label>
                <input
                  disabled
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="phonenumber"
                  id="phonenumber"
                  placeholder="PIC Position"
                  value={company?.pic_position ? company?.pic_position : "-"}
                  onChange={(e) => {}}
                  required
                />
              </div>
              <div>
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Company Status
                </label>
                <div className="flex space-x-2 mt-2">
                  <StatusLabel
                    status={company?.verified}
                    label={company?.verified ? "Verified" : "Not Verified"}
                  />
                  <StatusLabel
                    status={company?.active}
                    label={company?.verified ? "Active" : "Not Active"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mdb:w-[50%]">
            <div className="bg-gray-100 p-2 rounded-lg h-[480px] overflow-y-auto">
              {form.items.map((item, index) => (
                <>
                  <div className="w-full flex mdb:flex-row flex-col border border-lg max-h-content p-4 mdb:space-y-0 space-y-2 mdb:space-x-2 rounded-lg bg-white mb-2">
                    <div className="w-full mdb:w-[30%]">
                      <label className="text-gray-800 text-sm font-bold leading-[140%]">
                        Job Role Quota
                      </label>
                      {/* <div className="py-0.5 pl-2 mt-2 border border-line-grey rounded-lg flex items-center"> */}
                      <input
                        disabled
                        className="border w-full p-3 h-11 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                        type="number"
                        name="amount[]"
                        id="amount"
                        placeholder="Job Role Quota"
                        value={form.items[index].job_role_quota}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            items: form.items.map((x, i) => {
                              if (i == index) {
                                return {
                                  ...x,
                                  job_role_quota: e.target.value,
                                };
                              } else {
                                return x;
                              }
                            }),
                          });
                        }}
                        required
                      />
                      {/* <Select
                          className="w-full "
                          classNamePrefix={"select"}
                          options={packages
                            .filter((x) => x.type == 1)
                            .filter(
                              (x) =>
                                !form.items.some(
                                  (item) =>
                                    item.subscription_package_id === x.id
                                )
                            ) //CAN CHANGE
                            .map((option) => {
                              return {
                                value: option.id,
                                label: option.name,
                              };
                            })}
                          value={form.items[index].package}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              items: form.items.map((x, i) => {
                                if (i == index) {
                                  return {
                                    ...x,
                                    package: e,
                                    subscription_package_id: e.value,
                                  };
                                } else {
                                  return x;
                                }
                              }),
                            });
                          }}
                          placeholder={"Select Package"}
                        /> */}
                      {/* </div> */}
                    </div>
                    <div className="w-full mdb:w-[30%]">
                      <label className="text-gray-800 text-sm font-bold leading-[140%]">
                        Candidate Quota
                      </label>
                      <input
                        className="border w-full p-3 h-11 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                        type="number"
                        name="amount[]"
                        id="amount"
                        placeholder="Candidate Quota"
                        value={form.items[index].candidate_quota}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            items: form.items.map((x, i) => {
                              if (i == index) {
                                return {
                                  ...x,
                                  candidate_quota: e.target.value,
                                };
                              } else {
                                return x;
                              }
                            }),
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="w-full mdb:w-[30%]">
                      <label className="text-gray-800 text-sm font-bold leading-[140%]">
                        Test Quota
                      </label>
                      <input
                        className="border w-full p-3 h-11 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                        type="number"
                        name="amount[]"
                        id="amount"
                        placeholder="Candidate Quota"
                        value={form.items[index].test_quota}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            items: form.items.map((x, i) => {
                              if (i == index) {
                                return {
                                  ...x,
                                  test_quota: e.target.value,
                                };
                              } else {
                                return x;
                              }
                            }),
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="w-full mdb:w-[10%]">
                      <label className="text-gray-800 text-sm font-bold leading-[140%]">
                        Action
                      </label>
                      <div className="h-full py-4 pl-2">
                        <span>
                          <TrashIcon
                            className="cursor-pointer"
                            title="remove item"
                            width={20}
                            color="red"
                            onClick={() => {
                              setForm({
                                ...form,
                                items: form.items.filter((x, i) => i != index),
                              });
                            }}
                          />
                        </span>
                      </div>
                      {/* <input
                        disabled
                        className="border w-full p-3 h-11 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                        type="number"
                        name="total_price[]"
                        id="total_price"
                        min={1}
                        placeholder=""
                        value={
                          packages?.find(
                            (x) => x.id == item?.subscription_package_id
                          )?.price * form?.items[index].quantity
                        }
                        required
                      /> */}
                    </div>
                  </div>
                </>
              ))}
              <button
                className="order border-main-violate border px-2 text-[14px] leading-[22.4px] text-main-violate font-semibold h-[36px] rounded-lg hover:hover:bg-main-violate-100 mt-2"
                type=""
                onClick={(e) => {
                  addItem(e);
                }}
              >
                <span className="flex">
                  <PlusSmallIcon width={18} className="mr-2" /> Add Item
                </span>
              </button>
            </div>
            <div className="flex justify-end p-2">
              {/* <p className="text-gray-800 text-sm font-bold leading-[140%]">
                Total Price:{" "}
                <span className="ml-2">{rupiahFormat(totalPrice)}</span>
              </p> */}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-3">
          {submitting ? (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              type=""
              disabled={true}
            >
              <BtnSpinner />
            </button>
          ) : (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              onClick={(e) => handleSubmit(e)}
              disabled={!formPassed}
            >
              Create
            </button>
          )}
        </div>
      </div>
      {/* </form> */}
    </div>
  );
};

export default CreatePage;
