import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RectangleStackIcon } from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import { documentTitle, inputIsPhoneNumberWithCode } from "../../../../helpers";
import BtnSpinner from "../../../../Component/BtnSpinner";
import { createCompany } from "../../../../api/admin/companyApi";

const CreatePage = () => {
  let navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [totalEmploye] = useState(["1-10", "11-50", "51-200", "201-500", "501-1000", "1001-5000", "5001-10000", "> 10000"])
  const [form, setForm] = useState({
    code: null,
    name: null,
    email: null,
    phonenumber: "+62",
    person_in_charge: null,
    pic_position: null,
    found_date: null,
    address: null,
    industry: null,
    total_employee: null,
    verified: 1,
    active: 1,
  });
  const [formPassed, setFormPassed] = useState(true);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const resetForm = () => {
    setForm({
      code: "",
      name: "",
      email: "",
      phonenumber: "+62",
      person_in_charge: "",
      pic_position: "",
      found_date: "",
      address: "",
      industry: "",
      total_employee: 0,
      verified: 1,
      active: 1,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);
    const result = await createCompany(form);
    if (result.success) {
      resetForm();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Create Company");
  }, []);

  useEffect(() => {}, [form]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <RectangleStackIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Create Company</p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/client/users",
                title: "Client",
              },
              {
                href: null,
                title: "Create Company",
              },
            ]}
          />
        </div>
      </div>

      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="card flex flex-col w-[100%]">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">
                New Company Form
              </p>
            </div>
          </div>
          <div className="flex space-x-8 mb-4">
            <div className="w-[100%] flex space-x-4">
              <div className="w-[50%] flex flex-col space-y-4">
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Company Code
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="code"
                    id="code"
                    placeholder="Input text"
                    value={form.code}
                    onChange={(e) => setForm({ ...form, code: e.target.value })}
                    required
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Company Name
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Input text"
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                    required
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Founding Date
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="date"
                    name="found_date"
                    id="found_date"
                    placeholder="Input text"
                    value={form.found_date}
                    onChange={(e) =>
                      setForm({ ...form, found_date: e.target.value })
                    }
                    required
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Industry
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Input text"
                    value={form.industry}
                    onChange={(e) =>
                      setForm({ ...form, industry: e.target.value })
                    }
                    required
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Phonenumber
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="phonenumber"
                    id="phonenumber"
                    placeholder="Input text"
                    value={form.phonenumber}
                    onChange={(e) => {
                      if (inputIsPhoneNumberWithCode(e.target.value)) {
                        setForm({ ...form, phonenumber: e.target.value });
                      } else {
                        setForm({ ...form, phonenumber: "+62" });
                      }
                    }}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Address
                  </label>
                  <textarea
                    className="border w-full p-3 h-[136px] placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Write text here ..."
                    value={form.address}
                    onChange={(e) => {
                      setForm({ ...form, address: e.target.value });
                    }}
                    required
                  ></textarea>
                  <div className="flex justify-end">
                    <small className="text-gray-500">250/250</small>
                  </div>
                </div>
              </div>
              <div className="w-[50%] flex flex-col space-y-4">
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    PIC Name
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="person_in_charge"
                    id="person_in_charge"
                    placeholder="Input text"
                    value={form.person_in_charge}
                    onChange={(e) =>
                      setForm({ ...form, person_in_charge: e.target.value })
                    }
                    required
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    PIC Job Position
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="pic_position"
                    id="pic_position"
                    placeholder="Input text"
                    value={form.pic_position}
                    onChange={(e) =>
                      setForm({ ...form, pic_position: e.target.value })
                    }
                    required
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Company Email
                  </label>
                  <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Input text"
                    value={form.email}
                    onChange={(e) => {
                      setForm({ ...form, email: e.target.value });
                    }}
                    required
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Total Employees
                  </label>
                  <select
                    className="form-select appearance-none
                                            block
                                            w-[100%]
                                            px-3
                                            py-2
                                            text-grey-600
                                            text-[14px]
                                            bg-white bg-clip-padding bg-no-repeat
                                            border border-solid border-gray-300
                                            rounded-md
                                            mt-2
                                            transition
                                            ease-in-out
                                            focus:outline-none focus:border-main-violate border border-line-grey"
                    aria-label="Default select example"
                    value={form.total_employee}
                    onChange={(e) => {
                      setForm({ ...form, total_employee: e.target.value });
                    }}
                  >
                    <option selected disabled value="">
                      Choose a number
                    </option>
                    {totalEmploye.map((total) => (
                      <option key={total} value={total}>
                        {total} Employees
                      </option>
                    ))}
                  </select>
                  {/* <input
                    // ref={this.jobRoleInputRef}
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="number"
                    name="total_employee"
                    id="total_employee"
                    placeholder="Input text"
                    value={form.total_employee}
                    onChange={(e) =>
                      setForm({ ...form, total_employee: e.target.value })
                    }
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            {submitting ? (
              <button
                className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type=""
                disabled={true}
              >
                <BtnSpinner />
              </button>
            ) : (
              <button
                className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={!formPassed}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePage;
