import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import BtnSpinner from "../../../../Component/BtnSpinner";
import { inputIsEmailFormat, rupiahFormat } from "../../../../helpers";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import AboutSection from "../../MyCandidatesPage/Sections/Profile/Sections/AboutSection";
import SkillSection from "../../MyCandidatesPage/Sections/Profile/Sections/SkillSection";
import EducationalSection from "../../MyCandidatesPage/Sections/Profile/Sections/EducationalSection";
import WorkExperienceSection from "../../MyCandidatesPage/Sections/Profile/Sections/WorkExperienceSection";
import SocialNetworkSection from "../../MyCandidatesPage/Sections/Profile/Sections/SocialNetworkSection";
import provinces from "../../../../data/states.json";
import cities from "../../../../data/cities.json";
import PreferenceSection from "../../MyCandidatesPage/Sections/Profile/Sections/PreferenceSection";
function CandidateProfilePopup({ onClose, profile }) {
  useEffect(() => {
    console.log(profile);
  }, [profile]);
  return (
    // Your alert popup component JSX
    <div className="bg-white rounded-lg shadow lg:w-[880px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate  min-h-[328px] py-2 flex flex-col px-4 py-4 h-[80vh]">
      <div className="flex justify-between px-2 items-start mb-3">
        <div className="flex gap-4">
          <div>
            {profile.avatar && (
              // create a rounded image PLEASE USING IMG
              <div>
                <img
                  src={profile.avatar}
                  alt="avatar"
                  className="w-[60px] h-[60px] rounded-full"
                />
              </div>
            )}
            {!profile.avatar && (
              <UserCircleIcon className="w-[60px] text-grey-500" />
            )}
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-gray-700 font-semibold text-md">
              {profile.name}
            </p>
            <div className="flex gap-1">
              <p className="text-gray-400 text-sm">
                {profile.date_of_birth
                  ? moment().diff(
                      moment(profile.date_of_birth, "YYYY-MM-DD"),
                      "years"
                    )
                  : "-"}{" "}
                tahun
              </p>
              <p className="text-gray-400 text-sm">·</p>
              <p className="text-gray-400 text-sm">
                {profile.gender != null &&
                  profile.gender == "female" &&
                  "Perempuan"}
                {profile.gender != null &&
                  profile.gender == "male" &&
                  "Laki-laki"}
                {profile.gender == null && "-"}
              </p>
              <p className="text-gray-400 text-sm">·</p>
              <p className="text-gray-400 text-sm">
                {
                  provinces.find((province) => province.id == profile.province)
                    ?.name
                }
                , {cities.find((city) => city.id == profile.city)?.name}
              </p>
              <p className="text-gray-400 text-sm">·</p>
              <p className="text-gray-400 text-sm">
                {profile.personal_detail.educational
                  ? profile.personal_detail.educational.educational_degree.name
                  : "-"}
              </p>
              <p className="text-gray-400 text-sm">·</p>
              <p className="text-gray-400 text-sm">
                {profile.expected_salary ? rupiahFormat(profile.expected_salary) + " /bulan" : "-"} 
              </p>
            </div>
          </div>
        </div>

        <button
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
          onClick={() => {
            onClose();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="flex flex-col h-full w-full overflow-y-auto">
        <AboutSection about={profile.about} loading={false} />
        <PreferenceSection preferences={profile.personal_detail.preferences} />
        <WorkExperienceSection
          loading={false}
          experiences={profile.personal_detail.experiences}
        />
        <EducationalSection
          educationals={profile.personal_detail.educationals}
          loading={false}
        />
        <SkillSection loading={false} skill={profile.personal_detail.skills} />
        <SocialNetworkSection
          loading={false}
          socials={profile.personal_detail.networks}
        />
      </div>
    </div>
  );
}

export default CandidateProfilePopup;
