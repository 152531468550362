import React from "react";
import axios from "axios";
import PageSpinner from "../../../../../../Component/PageSpinner";
import { ArrowRightCircleIcon, DocumentIcon } from "@heroicons/react/24/solid";
import moment from "moment";
const ResumeSection = ({ loading = false, resumes }) => {
  const [downloading, setDownloading] = React.useState(false);
  const handleDownload = async (key, filename = "resume") => {
    setDownloading(true);
    const response = await axios.get(`/api/file/${key}`, {
      responseType: "blob",
    });
    let a = document.createElement("a");
    let url = URL.createObjectURL(response.data);

    // Set the href and download attributes of the a element
    a.href = url;
    a.download = `${filename}.pdf`;

    // Append the a element to the body
    document.body.appendChild(a);

    // Trigger a click event on the a element
    a.click();

    // Remove the a element from the body
    document.body.removeChild(a);

    // Release blob URL
    URL.revokeObjectURL(url);
    setDownloading(false);
  };
  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <DocumentIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Curriculum Vitae
            </h4>
          </div>
        </div>
        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {resumes.length > 0 ? (
              <>
                {resumes.map((resume) => {
                  return (
                    <div className="card-mini w-full mb-2">
                      <div className="content flex items-center">
                        <div className="left-side w-[90%]">
                          <p className="font-semibold text-gray-900 mb-1">
                            {resume.file_name}
                          </p>
                          <p className="font-normal text-gray-500 mb-3">
                            Uploaded on{" "}
                            {moment(resume.created_at).format("DD MMMM YYYY")}{" "}
                          </p>
                          <button
                            disabled={downloading}
                            className="rounded-lg border px-2 py-2 border-gray-200 w-full mb-2 max-w-max flex items-center cursor-pointer hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                            onClick={() => {
                              handleDownload(
                                resume?.file?.key,
                                resume.file_name
                              );
                            }}
                          >
                            {downloading && <PageSpinner />}
                            {!downloading && (
                              <>
                                <p className="text-sm font-medium mr-2">
                                  Preview CV
                                </p>
                                <ArrowRightCircleIcon width={18} />
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ResumeSection;
