import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import PageSpinner from "../../../../Component/PageSpinner";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
function InterviewSchedulePopup({ onClose, schedules = [], loading = false }) {
  return (
    // Your alert popup component JSX
    <div className="bg-white rounded-lg shadow lg:w-[880px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate  min-h-[328px] py-2 flex flex-col px-4 py-4 h-[80vh]">
      <div className="flex justify-between px-2 items-start mb-3">
        <p className="text-center text-base text-gray-700">
          Date at{" "}
          {schedules[0]?.date && moment(schedules[0]?.date).format("LL")}
        </p>
        <button
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
          onClick={() => {
            onClose();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="flex flex-col h-full w-full overflow-y-auto px-2 py-3">
        {loading && <PageSpinner />}
        {!loading && (
          <div className="flex flex-wrap">
            {schedules.length > 0 &&
              schedules.map((schedule, index) => (
                <>
                  <div key={index} className="w-1/3 mb-6">
                    <div className="pr-2">
                      <div className="border border-1 border-soft-grey-2 py-2 rounded-md mb-1 bg-main-violate">
                        <p className="text-base font-semibold text-white text-center">
                          {schedule.start_time}
                        </p>
                      </div>
                    </div>
                    <div className="pr-2">
                      <div
                        className={"card-mini flex flex-col justify-between"}
                      >
                        {schedule.attendee && (
                          <div className="flex flex-col gap-2">
                            {schedule.attendee.candidate.avatar && (
                              <div className=" mx-auto">
                                <img
                                  src={schedule.attendee.candidate.avatar}
                                  alt="avatar"
                                  className="w-[100px] h-[100px] rounded-full"
                                />
                              </div>
                            )}
                            {!schedule.attendee.candidate.avatar && (
                              <UserCircleIcon className="w-[100px] text-grey-500 mb-4 mx-auto" />
                            )}
                            <div>
                              <p className="text-grey-900 font-semibold text-base mb-4">
                                {schedule.attendee.candidate.fullname}
                              </p>
                              <span className="flex mb-2">
                                <UserIcon className="w-[16px] text-grey-500 mr-2" />
                                <label className="text-sm">
                                  {schedule.attendee.candidate.gender}
                                </label>
                              </span>
                              <span className="flex mb-2">
                                <EnvelopeIcon className="w-[16px] text-grey-500 mr-2" />
                                <label className="text-sm">
                                  {schedule.attendee.candidate.email}
                                </label>
                              </span>
                              <span className="flex mb-2">
                                <DevicePhoneMobileIcon className="w-[16px] text-grey-500 mr-2" />
                                <label className="text-sm">
                                  {schedule.attendee.candidate.phonenumber
                                    ? schedule.attendee.candidate.phonenumber
                                    : "-"}
                                </label>
                              </span>
                            </div>
                          </div>
                        )}
                        {!schedule.attendee && (
                          <div className="flex flex-col gap-2">
                            <UserCircleIcon className="w-[100px] text-grey-500 mx-auto" />
                            <div>
                              <p className="text-grey-900 font-semibold text-base mb-4">
                                No Attendee
                              </p>
                              <span className="flex mb-2">
                                <UserIcon className="w-[16px] text-grey-500 mr-2" />
                                <label className="text-sm">-</label>
                              </span>
                              <span className="flex mb-2">
                                <EnvelopeIcon className="w-[16px] text-grey-500 mr-2" />
                                <label className="text-sm">-</label>
                              </span>
                              <span className="flex mb-2">
                                <DevicePhoneMobileIcon className="w-[16px] text-grey-500 mr-2" />
                                <label className="text-sm">-</label>
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default InterviewSchedulePopup;
