import axios from "axios";
import moment from "moment";

export const getMyPermission = async () => {
  const response = await axios.get(`/api/client/user/my-permission`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
