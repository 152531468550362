import { React, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircleStackIcon, CubeTransparentIcon,ShoppingCartIcon} from "@heroicons/react/24/solid"
import { getMySubscriptions } from '../../../api';
import BreadCrumb from '../../../Component/BreadCrumb';
import { PageSpinner } from '../../../Component/PageSpinner';
import { documentTitle } from '../../../helpers';
import PopUp from '../../../Component/PopUp';
import PurchasePackagePopup from './Components/PurchasePackagePopup';
const SubscriptionsPage = () => {
    const [loading, setLoading] = useState(true);
    const [mySubscriptions, setMySubscriptions] = useState([]);
    const [showPurchasePackage, setShowPurchasePackage] = useState(false);
    let navigate = useNavigate();
    // TEST DEPLOY & BUILD
    const [user] = useState(() => {
        return JSON.parse(localStorage.getItem("user")) || null;
    });
    const [activeTab, setActiveTab] = useState('active');
    const fetchSubscriptions = async () => {
        setLoading(true);
        const result = await getMySubscriptions();
        if (result.success) {
            setMySubscriptions(result.data);
        } else {
            toast.error(result.message, {
                toastId: "signup-failure-toast",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1250,
            });
        }
        setLoading(false);
    }
    const showTab = (tabId) => {
        setActiveTab(tabId);
    };
    useEffect(() => {
        fetchSubscriptions();
        documentTitle("Subscription")
    }, [])

    // check if logged in
    useEffect(() => {
        if (!user) {
            navigate("/login", { replace: true });
        }
    });

    const myStyle = {
        minHeight: 'calc(100vh - 80px)',
        marginTop: '-10px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div style={myStyle} className='px-[10px] md:px-[44px] py-[24px]'>

            <ToastContainer />
            {showPurchasePackage && <PopUp><PurchasePackagePopup onClose={() => setShowPurchasePackage(false)} /></PopUp>}
            <div className='card flex w-full mb-6'>
                <div className='bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6'>
                    <CircleStackIcon className='w-[30px] text-main-violate' />
                </div>
                <div className='flex flex-col justify-around'>
                    <p className='text-xl text-grey-600 font-bold'>Subscriptions</p>
                    <BreadCrumb list={[{ href: null, title: "Subscriptions" }]} />
                </div>
            </div>
            {/* <h2 className='text-lg md:text-xl font-semibold leading-[150%] mb-6'>Subscriptions</h2> */}
            {/* <div className='summary-information flex mb-6 bg-white p-4 space-x-3 rounded-lg'>
                <UnderDevelopment />
            </div> */}

            {/* <HeroSection /> */}
            {/* <div className='summary-information flex mb-6 bg-white p-4 space-x-3 rounded-lg'>
                <div className='w-[45%] card flex bg-white px-6 py-6 rounded-[12px]'>
                    <div className='subscription job-role-token h-[86px] w-[86px] mr-6 rounded flex items-center justify-center'>
                        <CubeIcon width={32} color={"#F5F5F5"} />
                    </div>
                    <div className='flex flex-col justify-center'>
                        <p className='text-grey-900 mb-1 font-semibold'>Job Role Token</p>
                        <div className='flex'>
                            <p className='text-xl mr-1'>1</p>
                            <small className="relative top-[12px]"> from 1 tokens </small>
                        </div>
                    </div>
                </div>
                <div className='card flex w-full bg-white px-6 py-6 rounded-[12px]'>
                    <div className='flex flex-col justify-between w-full'>
                        <div className='flex flex-col w-full'>
                            <div className="flex flex-col justify-between">
                                <p className='text-lg text-grey-600 font-bold mb-2 flex'><CreditCardIcon width={25} className='mr-2 text-orange-500' /> Billing Information</p>
                                <p className='text-base text-grey-700 font-light'>Your subscriptions will end on November 02 2023, renew or upgrade your package before that.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {loading && <PageSpinner />}
            {!loading && <>
                <div className='card bg-white mb-3'>
                    <div className="flex justify-between">
                        <div className="flex gap-2 items-center mb-[20px]">
                            <h2 className='text-xl font-semibold leading-[150%]'>Packages</h2>
                            {/* <div className='w-[20px]'>
                                <Tooltip text={"This pacakage has been activated on Job Role"}>
                                    <div className="max-w-content h-[12px]"><InformationCircleIcon width={20} className='text-gray-500' /></div>
                                </Tooltip>
                            </div> */}
                        </div>
                        <div className='flex gap-2'>
                           
                            <button
                                className="flex items-center gap-2 bg-main-violate  px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg"
                                type=""
                                onClick={(e) => { e.preventDefault(); setShowPurchasePackage(true) }}
                            >
                                <ShoppingCartIcon className="w-[16px]" /> Puchase Package
                            </button>
                        </div>

                    </div>
                    <div className='mb-4'>
                        <div>
                            <nav className="border-gray-200">
                                <div className="flex space-x-4">
                                    <a
                                        href="#"
                                        className={`${activeTab === 'active'
                                            ? 'text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg'
                                            : 'text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200'
                                            }`}
                                        onClick={(e) => { e.preventDefault(); showTab('active') }}
                                    >
                                        Active
                                    </a>
                                    <a
                                        href="#"
                                        className={`${activeTab === 'inactive'
                                            ? 'text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg'
                                            : 'text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200'
                                            }`}
                                        onClick={(e) => { e.preventDefault(); showTab('inactive') }}
                                    >
                                        Inactive
                                    </a>
                                </div>
                            </nav>
                        </div>
                    </div>
                    {
                        activeTab === 'active' && <>
                            <div className='grid-cols-1 lg:grid-cols-3 grid gap-4 mb-6'>
                                {(mySubscriptions.filter((subscription) => subscription.company_job_position != null).length == 0) && <div className='border-[2px] border-gray-200 border-dashed py-6 rounded-[12px] bg-gray-50 flex justify-center text-gray-500 h-[150px] items-center'>
                                    No Active Package
                                </div>}
                                {mySubscriptions.map((subscription) => {
                                    if (subscription.company_job_position) {
                                        return (<>
                                            <div className='card w-full bg-white py-6 rounded-[12px]'>
                                                <div className='flex mb-4 justify-between'>
                                                    <div className=' flex items-center'>
                                                        <div className='subscription candidate-token h-[50px] w-[50px] mr-4 rounded flex items-center justify-center'>
                                                            <CubeTransparentIcon width={25} color={"#F5F5F5"} />
                                                        </div>
                                                        <div>
                                                            <p className='text-lg text-grey-600 font-bold flex'>{subscription?.subscription_package ? subscription?.subscription_package.name : "-"}</p>
                                                            <p className='text-base text-grey-700 font-light'>Serial: {subscription.serial}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className='text-xs font-semibold bg-green-200 text-green-700 px-2 py-1 max-h-content rounded-md'>Active</p>
                                                    </div>
                                                </div>

                                                <div className='grid-cols-1 grid w-full rounded-[12px] gap-3'>
                                                    <div className='w-full card-mini flex justify-between bg-white rounded-[12px]'>
                                                        <div className='flex flex-col justify-center'>
                                                            <p className='text-grey-500 mb-1 font-normal'>Job Role</p>
                                                            <div className='flex items-center'>
                                                                <p className='text-lg text-gray-900 font-semibold'>{subscription.company_job_position ? subscription.company_job_position.job_role : "Package"} </p>
                                                            </div>
                                                        </div>
                                                        <small className='text-main-violate mt-1 ml-2'><Link to={`/client/jobs/detail/${subscription.company_job_position.id}`}>View Job Role</Link></small>
                                                    </div>
                                                    <div className='w-full card-mini flex bg-white rounded-[12px]'>
                                                        <div className='flex flex-col justify-center'>
                                                            <p className='text-grey-500 mb-1 font-normal'>Tests</p>
                                                            <div className='flex'>
                                                                <p className='text-xl mr-1'>{subscription.quota_test}</p>
                                                                <small className="relative top-[12px]"> available from {subscription.base_quota_test} test </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-full card-mini flex bg-white rounded-[12px]'>
                                                        <div className='flex flex-col justify-center'>
                                                            <p className='text-grey-500 mb-1 font-normal'>Candidates</p>
                                                            <div className='flex'>
                                                                <p className='text-xl mr-1'>{subscription.quota_candidate}</p>
                                                                <small className="relative top-[12px]"> available from {subscription.base_quota_candidate} candidates </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                    } else {
                                        return (<></>)
                                    }
                                })}
                            </div>
                        </>
                    }
                    {
                        activeTab === 'inactive' && <>
                            <div className='grid-cols-1 lg:grid-cols-3 grid gap-4 mb-6'>
                                {(mySubscriptions.filter((subscription) => subscription.company_job_position == null).length == 0) && <div className='border-[2px] border-gray-200 border-dashed py-6 rounded-[12px] bg-gray-50 flex justify-center text-gray-500 h-[150px] items-center'>
                                    No Inactive Package
                                </div>}
                                {mySubscriptions.map((subscription) => {
                                    if (!subscription.company_job_position) {
                                        return (<>
                                            <div className='card w-full bg-white py-6 rounded-[12px]'>
                                                <div className='flex mb-4 justify-between'>
                                                    <div className=' flex items-center'>
                                                        <div className='subscription candidate-token h-[50px] w-[50px] mr-4 rounded flex items-center justify-center'>
                                                            <CubeTransparentIcon width={25} color={"#F5F5F5"} />
                                                        </div>
                                                        <div>
                                                            <p className='text-lg text-grey-600 font-bold flex'>{subscription?.subscription_package ? subscription?.subscription_package.name : "Package"}</p>
                                                            <p className='text-base text-grey-700 font-light'>Serial: {subscription.serial}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className='text-xs font-semibold bg-yellow-200 text-yellow-700 px-2 py-1 max-h-content rounded-md'>Inactive</p>
                                                    </div>
                                                </div>
                                                <div className='grid-cols-1 grid w-full rounded-[12px] gap-3'>
                                                    <div className='w-full card-mini flex justify-between bg-white rounded-[12px]'>
                                                        <div className='flex flex-col justify-center'>
                                                            <p className='text-grey-500 mb-1 font-normal'>Job Role</p>
                                                            <div className='flex items-center'>
                                                                <p className='text-lg text-gray-900 font-semibold'>-</p>
                                                            </div>
                                                        </div>
                                                        <small className='text-main-violate ml-2'><Link to={`/client/jobs/create?code=${subscription.serial}`}>Create Job Role</Link></small>
                                                    </div>
                                                    <div className='w-full card-mini flex bg-white rounded-[12px]'>
                                                        <div className='flex flex-col justify-center'>
                                                            <p className='text-grey-500 mb-1 font-normal'>Tests</p>
                                                            <div className='flex'>
                                                                <p className='text-xl mr-1'>{subscription.quota_test}</p>
                                                                <small className="relative top-[12px]"> available from {subscription.base_quota_test} test </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-full card-mini flex bg-white rounded-[12px]'>
                                                        <div className='flex flex-col justify-center'>
                                                            <p className='text-grey-500 mb-1 font-normal'>Candidates</p>
                                                            <div className='flex'>
                                                                <p className='text-xl mr-1'>{subscription.quota_candidate}</p>
                                                                <small className="relative top-[12px]"> available from {subscription.base_quota_candidate} candidates </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                    } else {
                                        return (<>

                                        </>)
                                    }

                                })}
                            </div>
                        </>
                    }

                </div>
            </>}


        </div >
    )
}

export default SubscriptionsPage;
