import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ClipboardDocumentListIcon,
  CubeIcon,
  InformationCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../Component/BreadCrumb";
import PaginationTable from "../../../Component/Tables/PaginationTable";
import SearchTable from "../../../Component/Tables/SearchTable";
import FilterTable from "../../../Component/Tables/FilterTable";
import TableSpinner from "../../../Component/TableSpinner";
import EmptyContent from "../../../Component/Tables/EmptyContent";
import { documentTitle } from "../../../helpers";
import {
  getPackageSummaries,
  getPackages,
} from "../../../api/admin";
import DropdownDots from "../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import BtnSpinner from "../../../Component/BtnSpinner";

const PackagePage = () => {
  let navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [users, setUsers] = useState([]);
  const [summaries, setSummaries] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [summaryLoading, setSummaryLoading] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const fetchPackages = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getPackages(queryString);
    if (result.success) {
      setUsers(result.data.data);
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setItemsPerPage(result.data.per_page);
      setTableLoading(false);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const fetchSummary = async () => {
    setSummaryLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getPackageSummaries(queryString);
    if (result.success) {
      setSummaries(result.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSummaryLoading(false);
  };

  useEffect(() => {
    documentTitle("Packages Management");
  }, []);
  useEffect(() => {
    fetchSummary();
    fetchPackages();
  }, [query]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {users.length > 0 ? (
            users.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.type_label}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.max_job_role ? value.max_job_role : "-"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.max_test ? value.max_test : "-"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.max_candidate ? value.max_candidate : "-"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.price ? value.price : "-"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <DropdownDots>
                      <Dropdown.Item onClick={(e) => {}}>
                        <InformationCircleIcon width={16} className="mr-2" />
                        Detail
                      </Dropdown.Item>
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <CubeIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Package</p>
          <BreadCrumb list={[{ href: null, title: "Package" }]} />
          {/* <p className='text-base text-grey-400 font-medium '>List of positions that have been entered</p> */}
        </div>
      </div>
      <div className="summary-stats grid-cols-1 lg:grid-cols-3 grid gap-4 mb-6">
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div className="dashboard total-test h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
            <ClipboardDocumentListIcon width={32} color={"#F5F5F5"} />
          </div>
          {/* <img src={dummy1} alt='dummy' className='mr-6' /> */}
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Active Package</p>
            <div className="flex">
              {summaryLoading && <BtnSpinner />}
              {!summaryLoading && (
                <>
                  <p className="text-xl mr-1">{summaries.active_package}</p>
                  <small className="relative top-[12px]">
                    from {summaries.total_active_package}
                  </small>
                </>
              )}
            </div>

            {/* <ArrowDownCircleIcon className='text-grey-600 w-[16px]' /> */}
          </div>
        </div>
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div className="dashboard finished-test h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
            <ClipboardDocumentListIcon width={32} color={"#F5F5F5"} />
          </div>
          {/* <img src={dummy1} alt='dummy' className='mr-6' /> */}
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Active Test Token</p>
            <div className="flex">
              {summaryLoading && <BtnSpinner />}
              {!summaryLoading && (
                <>
                  <p className="text-xl mr-1">{summaries.active_test_token}</p>
                  <small className="relative top-[12px]">
                    from {summaries.total_active_test_token}
                  </small>
                </>
              )}
            </div>

            {/* <ArrowDownCircleIcon className='text-grey-600 w-[16px]' /> */}
          </div>
        </div>
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div className="dashboard profile-percentage h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
            <UserGroupIcon width={32} color={"#F5F5F5"} />
          </div>
          {/* <img src={dummy1} alt='dummy' className='mr-6' /> */}
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Active Candidate Token</p>
            <div className="flex">
              {summaryLoading && <BtnSpinner />}
              {!summaryLoading && (
                <>
                  <p className="text-xl mr-1">{summaries.active_candidate_token}</p>
                  <small className="relative top-[12px]">
                    from {summaries.total_active_candidate_token}
                  </small>
                </>
              )}
            </div>

            {/* <ArrowDownCircleIcon className='text-grey-600 w-[16px]' /> */}
          </div>
        </div>
      </div>
      <div className="card flex flex-col w-full">
        <div className="flex justify-between w-full mb-4">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">Package List</p>
          </div>
          <div className="flex justify-end w-2/2"></div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <PaginationTable
            search={
              <SearchTable
                onSearch={(keyword) => handleSearch(keyword)}
                placeholder={"Search package"}
              />
            }
            currentPage={handlePaginate}
            itemsTo={itemsTo}
            itemsFrom={itemsFrom}
            itemsPerPage={5}
            checkbox={false}
            totalItems={totalItems}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            headers={[
              "NO.",
              "PACKAGE NAME",
              "TYPE",
              "JOB ROLE QUOTA",
              "TEST QUOTA",
              "CANDIDATE TOKEN",
              "PRICE",
              "ACTION",
            ]}
            content={<TableContent />}
          />
        </div>
      </div>
    </div>
  );
};

export default PackagePage;
