import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import PaginationTable from "../../../../../Component/Tables/PaginationTable";
import TableSpinner from "../../../../../Component/TableSpinner";
import FilterTable from "../../../../../Component/Tables/FilterTable";
import EmptyContent from "../../../../../Component/Tables/EmptyContent";
import SearchTable from "../../../../../Component/Tables/SearchTable";
import DropdownDots from "../../../../../Component/Tables/DropdownDots";
import {
  getCompanyJobRoles,
  getCompanySubscriptions,
  getCompanyTransactions,
} from "../../../../../api/admin/companyApi";
import { getQuestionTags } from "../../../../../api/admin/assessmentApi";
import { Dropdown } from "flowbite-react";
const TagList = ({ company_id }) => {
  let navigate = useNavigate();
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  const [positions, setPositions] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [selectedFilterDepartment, setSelectedFilterDepartment] =
    useState(null);
  const [selectedFilterPosition, setSelectedFilterPosition] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [query, setQuery] = useState(null);

  const fetchPositions = async () => {
    setTableLoading(true);
    // const sanitizedQuery = {
    //   ...query,
    //   company: company_id,
    // };
    const queryString = new URLSearchParams(query).toString();
    const result = await getQuestionTags(queryString);
    if (result.success) {
      setPositions(result.data.data);
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setItemsPerPage(result.data.per_page);
    }
    setTableLoading(false);
  };

  const handleDetail = (id) => {
    // navigate(`/client/jobs/detail/${id}`);
  };

  useEffect(() => {
    fetchPositions();
  }, [query]);

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSelectedFilterDepartment("");
    setSelectedFilterPosition("");
    setQuery({ ...query, position: "", department: "" });
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {positions?.length > 0 ? (
            positions?.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  {/* <td className="px-6 py-4 text-sm text-gray-500">
                    <input
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                      type="checkbox"
                    />
                  </td> */}
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {itemsPerPage * (currentPage - 1) + index + 1}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-blue-500">{value?.code}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{value?.name}</div>
                  </td>

                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900 flex flex-col space-y-2">
                      <span>{value?.tag_category?.name}</span>
                      <span className="text-blue-500">
                        {value?.tag_category?.code}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.formula_type}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.formula}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.min_value}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.max_value}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.label1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.label2}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.created_at
                      ? moment(value.created_at).format("Y-m-d")
                      : "-"}
                  </td>
                  <td className="px-6 py-4">
                    <DropdownDots isPublish={value.is_publish}>
                      <Dropdown.Item onClick={(e) => handleDetail(value.id)}>
                        <InformationCircleIcon width={16} className="mr-2" />
                        Edit
                      </Dropdown.Item>
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 2 || status == 3 || status == 4) {
      return (
        <p className="bg-red-100 text-red-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <ToastContainer />
      <div className="border-b border-gray-200 rounded-lg shadow">
        <PaginationTable
          checkbox={false}
          onClearFilter={(e) => handleClearFilter(e)}
          search={
            <SearchTable
              onSearch={(keyword) => handleSearch(keyword)}
              placeholder={"Search Tag Code / Tag Name"}
            />
          }
          isSticky={true}
          currentPage={handlePaginate}
          itemsTo={itemsTo}
          itemsFrom={itemsFrom}
          itemsPerPage={5}
          totalItems={totalItems}
          totalPages={Math.ceil(totalItems / itemsPerPage)}
          headers={[
            "NO",
            "CODE",
            "NAME",
            "CATEGORY",
            "FORMULA TYPE",
            "FORMULA",
            "MIN VALUE",
            "MAX VALUE",
            "MIN LABEL VALUE",
            "MAX LABEL VALUE",
            "CREATED AT",
            ""
          ]}
          content={<TableContent />}
        />
      </div>
    </>
  );
};

export default TagList;
