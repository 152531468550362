import { useEffect, useState } from "react";
import "moment/locale/id";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  UserCircleIcon,
  UserIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../Component/BreadCrumb";
import PageSpinner from "../../../Component/PageSpinner";
import { getInterviewScheduleByDate } from "../../../api";
import PopUp from "../../../Component/PopUp";
import CandidateProfilePopup from "./Popup/CandidateProfilePopup";
import InterviewSchedulePopup from "./Popup/InterviewSchedulePopup";

const DetailPage = () => {
  const { id } = useParams();
  const [interview, setInterview] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const getInterview = async () => {
    setLoading(true);
    const response = await axios.get("/api/client/interview/" + id);
    const result = response.data;
    setInterview(result.data);
    setLoading(false);
  };
  const daysOfWeek = [
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
    "Minggu",
  ];

  const handleEvent = async (calendar) => {
    setLoadingDetail(true);
    setShowDetail(true);
    const date = moment(calendar.data).format("YYYY-MM-DD");
    const result = await getInterviewScheduleByDate(interview.id, date);
    if(result.success){
      setSelectedSchedule(result.data);
    } else {
      toast.error(result.message);
      setShowDetail(true);
    }
    setLoadingDetail(false);
  };

  useEffect(() => {
    if (interview) {
      const getDatesInRange = (start, end) => {
        const startDt = new Date(start);
        const endDt = new Date(end);
        const dates = [];

        while (startDt <= endDt) {
          dates.push(new Date(startDt));
          startDt.setDate(startDt.getDate() + 1);
        }

        return dates;
      };

      const getMonthName = (month) => {
        const monthNames = [
          "Januari",
          "Februari",
          "Maret",
          "April",
          "Mei",
          "Juni",
          "Juli",
          "Agustus",
          "September",
          "Oktober",
          "November",
          "Desember",
        ];
        return monthNames[month - 1];
      };

      const firstDayOfWeek = (new Date(interview.start_date).getDay() + 6) % 7;
      const dates = getDatesInRange(interview.start_date, interview.end_date);
      const totalDays = dates.length + firstDayOfWeek;
      const newCalendar = Array(totalDays).fill(null);

      for (
        let i = firstDayOfWeek, dateIndex = 0;
        i < newCalendar.length && dateIndex < dates.length;
        i++
      ) {
        const date = dates[dateIndex];
        const dateInString = moment(date).format("YYYY-MM-DD");
        // FIND EVENT FROM INTERVIEW SCHEDULE
        const event = interview.schedules.find(
          (schedule) => schedule.date == dateInString
        );
        const dayOfMonth = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        newCalendar[i] = {
          id: dateIndex,
          data: `${dayOfMonth.toString().padStart(2, "0")} ${getMonthName(
            month
          )} ${year}`,
          events: event ? event.times : [], // contoh data event
        };
        dateIndex++;
      }
      setCalendars(newCalendar);
    }
  }, [interview]);

  useEffect(() => {
    getInterview();
  }, []);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflowX: "hidden",
  };
  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      {showDetail && (
        <PopUp>
          <InterviewSchedulePopup
            loading={loadingDetail}
            onClose={() => setShowDetail(false)}
            schedules={selectedSchedule}
          />
        </PopUp>
      )}

      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <VideoCameraIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Detail Session</p>
          <BreadCrumb
            list={[
              { href: "/client/interviews", title: "Interviews" },
              { href: null, title: "Detail Session" },
            ]}
          />
        </div>
      </div>
      {loading && <PageSpinner />}
      {!loading && (
        <>
          <div className="card mb-6">
            <div className="flex flex-col justify-around mb-7">
              <p className="text-xl text-grey-600 font-bold">Session Detail</p>
            </div>
            <div className="grid-cols-1 md:grid-cols-2 grid gap-4 ">
              <div className="mb-2">
                <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                  Name
                </label>
                <input
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Type your job role..."
                  value={interview?.name}
                  disabled={true}
                />
              </div>
              <div className="mb-2">
                <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                  Job Role
                </label>
                <input
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Type your job role..."
                  value={interview?.company_job_position?.job_role}
                  disabled={true}
                />
              </div>
              <div className="mb-2">
                <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                  Start Date
                </label>
                <input
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="date"
                  name="start_date"
                  id="start_date"
                  placeholder="Choose date..."
                  value={interview?.start_date}
                  disabled={true}
                />
              </div>
              <div className="mb-2">
                <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                  End Date
                </label>
                <input
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="date"
                  name="end_date"
                  id="end_date"
                  placeholder="Choose date..."
                  value={interview?.end_date}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="card mb-6">
            <div className="flex flex-col justify-around mb-7">
              <p className="text-xl text-grey-600 font-bold">
                Session Schedules{" "}
              </p>
            </div>
            <div>
              <div style={styles.container}>
                <div
                  className="bg-main-violate-500 rounded-md"
                  style={styles.header}
                >
                  {daysOfWeek.map((day) => (
                    <div
                      className="text-white"
                      key={day}
                      style={styles.headerBox}
                    >
                      {day}
                    </div>
                  ))}
                </div>
                <div style={styles.calendar}>
                  {calendars.map((calendar, index) => (
                    <div
                      className={`${
                        calendar?.events?.length > 0
                          ? calendar.events.filter((event) => event.attendee)
                              .length == calendar?.events?.length
                            ? "bg-main-violate"
                            : " bg-green-1"
                          : "bg-soft-grey"
                      } h-[100px] border border-1 border-soft-gray hover:cursor-pointer hover:border-green-1`}
                      key={index}
                      style={styles.dayBox}
                      onClick={() => {
                        if (calendar?.events?.length > 0) {
                          handleEvent(calendar);
                        }
                      }}
                    >
                      {calendar && (
                        <>
                          {calendar.events.length > 0 && (
                            <>
                              <p
                                className={`${
                                  calendar?.events?.length > 0 && "text-white"
                                }`}
                              >
                                {calendar.data}
                              </p>
                              <p
                                className={`${
                                  calendar?.events?.length > 0 && "text-white"
                                } mt-2`}
                              >
                                {
                                  calendar.events.filter(
                                    (event) => event.attendee
                                  ).length
                                }
                                /{calendar.events.length} Slot
                              </p>
                            </>
                          )}
                          {calendar.events.length == 0 && (
                            <>
                              <p>{calendar.data}</p>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-6">
            <div className="flex flex-col justify-around mb-7">
              <p className="text-xl text-grey-600 font-bold">
                Candidate Invitees
              </p>
            </div>
            <div>
              <div
                style={{
                  display: "grid",
                }}
                className="grid-cols-1 sm:grid-cols-2 3xl:grid-cols-4 xl:grid-cols-3"
              >
                {interview?.invitees?.map((value) => (
                  <div className="px-2 w-full">
                    <div
                      className={"card h-[320px] flex flex-col justify-between"}
                    >
                      <div className="flex flex-col items-center">
                        {value.candidate.avatar && (
                          // create a rounded image PLEASE USING IMG
                          <div>
                            <img
                              src={value.candidate.avatar}
                              alt="avatar"
                              className="w-[100px] h-[100px] rounded-full mb-4"
                            />
                          </div>
                        )}
                        {!value.candidate.avatar && (
                          <UserCircleIcon className="w-[100px] text-grey-500 mb-4" />
                        )}
                        <div>
                          <p className="text-grey-900 font-semibold text-xl lg:text-lg mb-4">
                            {value.candidate.fullname}
                          </p>
                        </div>
                      </div>
                      <div>
                        <span className="flex mb-3">
                          <UserIcon className="w-[18px] text-grey-500 mr-2" />
                          <label className="text-sm">{value.candidate.gender}</label>
                        </span>
                        <span className="flex mb-3">
                          <EnvelopeIcon className="w-[18px] text-grey-500 mr-2" />
                          <label className="text-sm">{value.candidate.email}</label>
                        </span>
                        <span className="flex">
                          <DevicePhoneMobileIcon className="w-[18px] text-grey-500 mr-2" />
                          <label className="text-sm">
                            {value.candidate.phonenumber
                              ? value.candidate.phonenumber
                              : "-"}
                          </label>
                        </span>
                      </div>
                      {/* <div>
                        <button
                          className="border border-main-violate w-full px-4 text-[16px] leading-[22.4px] text-main-violate hover:bg-violet-100 font-semibold h-[46px] rounded-lg"
                          type=""
                          onClick={(e) => {
                            alert("ON PROGRESS");
                          }}
                        >
                          Detail
                        </button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    margin: "0 auto",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "10px",
    padding: "10px 0",
  },
  headerBox: {
    textAlign: "center",
    fontWeight: "bold",
  },
  calendar: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "10px",
    padding: "10px 0",
  },
  dayBox: {
    borderRadius: "4px",
    padding: "10px",
    textAlign: "center",
    fontSize: "0.9em",
  },
  "@media (max-width: 600px)": {
    header: {
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: "5px",
      padding: "5px 0",
    },
    calendar: {
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: "5px",
      padding: "5px 0",
    },
    dayBox: {
      padding: "5px",
      fontSize: "0.8em",
    },
  },
};
export default DetailPage;
