"use client";

import { useEffect, useState } from "react";
import "./carousel.css";
import PageSpinner from "../../../../Component/PageSpinner";
import SelectCandidateCarousel from "../../../../Component/SelectCandidate/SelectCandidateCarousel";
import SelectCandidateGrid from "../../../../Component/SelectCandidate/SelectCandidateGrid";
import FilterTable from "../../../../Component/Tables/FilterTable";
const SelectCandidate = ({
  candidates,
  onSelect,
  onRemove,
  onShowProfile,
  selectedCandidates = [],
  quotaTest = null,
  filters = null,
}) => {
  const [seeAll, setSeeAll] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  useEffect(() => {}, [quotaTest]);


  return (
    <>
      {/* <div className="card mb-7 flex items-center space-x-3">
                <div className="bg-main-violate-100 rounded-lg w-[35px] p-1">
                    <CheckIcon className="w-full text-main-violate" />
                </div>
                <p className="text-gray-500">You can only select up to {quotaTest != null ? quotaTest : 'unlimited'} test. Browse the test library and add the most relevant tests.</p>
            </div> */}

      <div className="card mb-10">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold leading-[150%] mb-6">
            Selected Candidates
          </h2>
        </div>
        {selectedCandidates.length > 0 ? (
          <SelectCandidateGrid
            data={selectedCandidates}
            selectedCandidates={selectedCandidates}
            onSelect={onSelect}
            onRemove={onRemove}
            inSelected={true}
            onShowProfile={onShowProfile}
          />
        ) : (
          <div className="flex justify-center">
            <p className="text-gray-500">
              You have not selected any candidate yet.
            </p>
          </div>
        )}
      </div>

      <div className="card mb-10">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold leading-[150%] mb-4">
            Candidates
          </h2>

          {/* <button
            className="flex items-center gap-2 border border-main-violate  px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[36px] rounded-lg"
            type=""
            onClick={(e) => setSeeAll(!seeAll)}
          >
            See All <ChevronRightIcon className="w-[16px]" />
          </button> */}
        </div>
        <div className="px-2 mb-6 flex w-[85%] md:w-[70%] flex-wrap">
          <FilterTable
            placeholder={"Select Gender"}
            options={filters.gender}
            selected={selectedGender}
            onChange={(e) => {
              setSelectedGender(e);
            }}
          />
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedGender("");
            }}
            className="flex text-sm items-center text-gray-500 font-medium hover:text-main-violate-hover mb-1"
          >
            Clear
          </a>
        </div>

        {seeAll ? (
          <>
            {candidates.length <= 0 && (
              <div className="flex justify-center">
                <p className="text-gray-500">
                  No candidates with good fit rated available for this job.
                </p>
              </div>
            )}
            {candidates.length > 0 && (
              <SelectCandidateGrid
                selectedCandidates={ selectedCandidates}
                data={selectedGender ? candidates.filter((candidate) => candidate.gender == selectedGender) : candidates}
                onSelect={onSelect}
                onShowProfile={onShowProfile}
                onRemove={onRemove}
              />
            )}
          </>
        ) : (
          <>
            {loading ? (
              <PageSpinner />
            ) : (
              <>
                {candidates.length <= 0 && (
                  <div className="flex justify-center">
                    <p className="text-gray-500">
                      You have not selected any test yet.
                    </p>
                  </div>
                )}
                {candidates.length > 0 && (
                  <SelectCandidateCarousel
                    data={candidates}
                    onSelect={onSelect}
                    onShowProfile={onShowProfile}
                    onRemove={onRemove}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SelectCandidate;
