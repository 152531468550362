import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  getCognitiveReports,
  getSoftSkillReports,
  unlockCandidateReport,
} from "../../../api/candidateReportsApi";
import SoftSkills from "./Tabs/SoftSkills";
import HardSkills from "./Tabs/HardSkills";
import Cognitive from "./Tabs/Cognitive";
import Personality from "./Tabs/Overview/Personality";
import PersonalityTabParent from "./Tabs/Personality/PersonalityDetail";
import SoftSkillsOverview from "./Tabs/Overview/SoftSkills";
import HardSkillsOverview from "./Tabs/Overview/HardSkills";
import CognitiveOverview from "./Tabs/Overview/Cognitive";
import Legends from "./Components/Legends";
import AntiCheat from "./Tabs/Overview/AntiCheat";
import {
  ArrowRightIcon,
  DocumentArrowDownIcon,
  LockClosedIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import PageSpinner from "../../../Component/PageSpinner";
import { Avatar } from "flowbite-react";
import Profile from "./Sections/Profile/Profile";
import BreadCrumb from "../../../Component/BreadCrumb";
import { getDetailCandidateJob } from "../../../api";
import PopUp from "../../../Component/PopUp";
import QuotaExceedPopup from "./Components/QuotaExceedPopup";
import { documentTitle } from "../../../helpers";
import moment from "moment";
const MyCandidatesPage = () => {
  let navigate = useNavigate();
  // TEST DEPLOY & BUILD
  const { id, candidate_job_id } = useParams();
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const [showQuotaExceedPopup, setShowQuotaExceedPopup] = useState(false);
  const [personalityLoading, setPersonalityLoading] = useState(true);
  const [candidateJob, setCandidateJob] = useState(null);
  const [cognitiveLoading, setCognitiveLoading] = useState(true);
  const [softSkillLoading, setSoftSkillLoading] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const [activePersonalityTab, setActivePersonalityTab] =
    useState("comunicate");
  const [activePersonalityParentTab, setActivePersonalityParentTab] =
    useState(null);
  const [professionalBehavior, setProfessionalBehaviour] = useState(null);
  const [emotionalIntelligence, setEmotionalIntelligence] = useState(null);
  const [personalityTraits, setPersonalityTraits] = useState(null);
  const [softSkills, setSoftSkills] = useState([]);
  const [cognitives, setCognitives] = useState([]);
  const [motivation, setMotivation] = useState([]);
  const [unlocking, setUnlocking] = useState(false);
  const [profile, setProfile] = useState({
    job: {
      role: "",
    },
  });
  const [loading, setLoading] = useState(false);

  const getProfile = async () => {
    setLoading(true);
    await axios
      .get(`/api/client/candidates/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setProfile(result.data);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPersonality = async () => {
    setPersonalityLoading(true);
    await axios
      .get(
        `/api/client/candidate/${id}/${candidate_job_id}/reports/personality`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const result = response.data;
        if (result.success) {
          const professional_behaviour = result.data.find(
            (item) => item.test === "professional-behaviour"
          );
          const emotional_intelligence = result.data.find(
            (item) => item.test === "emotional-intelligence"
          );
          const personality_traits = result.data.find(
            (item) => item.test === "personality-traits"
          );
          if (professional_behaviour) {
            setProfessionalBehaviour(professional_behaviour);
            setMotivation(professional_behaviour);
          }
          if (emotional_intelligence) {
            setEmotionalIntelligence(emotional_intelligence);
          }
          if (personality_traits) {
            setPersonalityTraits(personality_traits);
          }
          // const emotional_intelligence = result.data.find((item) => item.test === 'emotional_intelligence');
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .finally(() => {
        setPersonalityLoading(false);
      });
  };

  const fetchCandidateJob = async () => {
    const result = await getDetailCandidateJob(candidate_job_id);
    if (result.success) {
      setCandidateJob(result.data);
    } else {
      toast.error(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const fetchCognitive = async () => {
    setCognitiveLoading(true);
    const result = await getCognitiveReports(id, candidate_job_id);
    if (result.success) {
      setCognitives(result.data);
    }
    setCognitiveLoading(false);
  };

  const fetchSoftSkill = async () => {
    setSoftSkillLoading(true);
    const result = await getSoftSkillReports(id, candidate_job_id);
    if (result.success) {
      setSoftSkills(result.data);
    }
    setSoftSkillLoading(false);
  };

  const postUnlokCandidateReport = async () => {
    setUnlocking(true);
    const result = await unlockCandidateReport(candidate_job_id);
    if (result.success) {
      setShowQuotaExceedPopup(false);
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
      await fetchCandidateJob();
    } else {
      toast.error(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setUnlocking(false);
  };

  const showTab = (tabId) => {
    setActiveTab(tabId);
  };

  const handleExport = () => {
    window.open(`/client/my-candidates/${id}/${candidate_job_id}/export`);
  };

  const handleShowProfile = () => {
    setShowProfile(!showProfile);
  };

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Detail Candidate");
    fetchCandidateJob();
    getProfile();
  }, []);

  const callData = async () => {
    await getPersonality();
    await fetchSoftSkill();
    await fetchCognitive();
  };

  useEffect(() => {
    if (candidateJob) {
      if (candidateJob.is_reportable) {
        // callData();
        getPersonality();
        fetchSoftSkill();
        fetchCognitive();
      } else {
        setShowQuotaExceedPopup(true);
      }
    }
  }, [candidateJob]);

  const myStyle = {
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const SoftSkillTab = ({ data }) => {
    return (
      <>
        <SoftSkills data={softSkills} />
      </>
    );
  };
  const HardSkillTab = ({ data }) => {
    return <HardSkills />;
  };
  const CognitiveTab = ({ data }) => {
    return <Cognitive data={cognitives} />;
  };
  const PersonalityTab = ({ data }) => {
    return (
      <>
        <PersonalityTabParent
          tabChange={(value) => setActivePersonalityTab(value)}
          currentTab={activePersonalityTab}
          parentTabChange={(value) => setActivePersonalityParentTab(value)}
          currentParentTab={activePersonalityParentTab}
          professionalBehaviour={professionalBehavior}
          motivation={motivation}
          emotional={emotionalIntelligence}
          personalityTraits={personalityTraits}
        />
      </>
    );
  };
  const OverviewTab = ({ data }) => {
    return (
      <>
        <Personality
          professionalBehaviour={professionalBehavior}
          motivation={motivation}
          emotional={emotionalIntelligence}
          personalityTraits={personalityTraits}
        />
        <Legends />
        {softSkills.filter((item) => item.is_choosen).length > 0 && (
          <SoftSkillsOverview data={softSkills} />
        )}
        {/* <HardSkillsOverview /> */}
        {cognitives.filter((item) => item.is_choosen).length > 0 && (
          <CognitiveOverview data={cognitives} />
        )}
        {candidateJob && candidateJob.records && (
          <AntiCheat records={candidateJob.records} />
        )}
      </>
    );
  };

  const data = {
    contractReading: 75,
    listeningPitch: 50,
    listeningPresentation: 75,
  };

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0 || status == 3) {
      return (
        <p className="bg-red-100 text-red-800 font-medium text-center text-xs py-1 rounded text px-2 max-w-max">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2 max-w-max">
          {label}
        </p>
      );
    } else if (status == 2) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2 max-w-max">
          {label}
        </p>
      );
    }
  };

  return (
    <>
      <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
        {showQuotaExceedPopup && (
          <PopUp>
            <QuotaExceedPopup
              onClose={(e) => setShowQuotaExceedPopup(false)}
              candidateJob={candidateJob}
              unlockReport={postUnlokCandidateReport}
              unlocking={unlocking}
            />
          </PopUp>
        )}
        <ToastContainer />
        <div className="card flex w-full mb-6">
          <div className="bg-main-violate-50 w-[25%] mdb:w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
            <UserGroupIcon className="w-[30px] text-main-violate" />
          </div>
          <div className="flex flex-col justify-around w-[75%]">
            <p className="text-lg mdb:text-xl text-grey-600 font-bold">
              Candidate Assessment Report
            </p>
            <div className="hidden md:block">
              <BreadCrumb
                list={[
                  { href: "/client/my-candidates", title: "My Candidates" },
                  { href: null, title: "Reports" },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="mb-6">
          {/* <div className='pdf-wrap' id="cool">
                        <CommunicateReports communication={professionalBehavior.find(item => item.category === 'Communication')} />
                    </div> */}
          <div className=" flex w-full lg:space-x-2 md:flex-row flex-col">
            <div className="card w-[100%] md:w-[50%] flex">
              {loading ? (
                <PageSpinner />
              ) : (
                <>
                  <div className="avatar xl:w-[20%] w-[30%] flex items-center">
                    <div className="w-full">
                      <div className="h-[70px] w-[70px] lg:h-[80px] lg:w-[80px] xl:w-[80px] xl:h-[80px] overflow-hidden rounded-full">
                        {profile.avatar ? (
                          <img
                            alt="avatar"
                            className="w-[100%] object-center object-cover rounded-[10px] h-full object-cover object-center"
                            src={profile.avatar}
                          />
                        ) : (
                          <Avatar
                            size={"lg"}
                            alt="User settings"
                            rounded={true}
                            src={profile.avatar}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="avatar xl:w-[80%]" id="lol">
                    <div className="flex flex-col xl:flex-row">
                      <div className="xl:w-[60%] w-full">
                        <p className="text-base xl:text-lg  font-bold mb-1">
                          {profile.name}
                        </p>
                        <p className="text-sm  text-gray-600 mb-1">
                          {profile.email}
                        </p>
                        <p className="text-sm text-gray-600 mb-1">
                          {profile.phonenumber}
                        </p>
                      </div>
                      <div className="avatar xl:w-[40%] w-full mt-2 xl:mt-0 flex xl:justify-end justify-start">
                        <div className="flex flex-col gap-2">
                          <button
                            className="flex items-center gap-2 border border-main-violate hover:bg-purple-200  px-4 text-sm leading-[22.4px] text-main-violate font-semibold h-[36px] rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
                            type="button"
                            onClick={(e) => handleShowProfile()}
                          >
                            {showProfile ? (
                              <>
                                Close Profile <XMarkIcon width={16} />
                              </>
                            ) : (
                              <>
                                Full Profile <ArrowRightIcon width={16} />
                              </>
                            )}
                          </button>
                          {candidateJob && (
                            <>
                              <button
                                className="flex items-center gap-2 border border-main-violate hover:bg-purple-200  px-4 text-sm leading-[22.4px] text-main-violate font-semibold h-[36px] rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
                                type="button"
                                onClick={(e) => handleExport()}
                                disabled={!candidateJob.is_reportable}
                              >
                                Export PDF <DocumentArrowDownIcon width={20} />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <p className='text-gray-600'>Link</p> */}
                  </div>
                </>
              )}
            </div>
            <div className="card w-[100%] md:w-[50%] flex ">
              {loading ? (
                <PageSpinner />
              ) : (
                <>
                  <div className="avatar w-[33%]">
                    <p className="font-semibold mb-1">Role</p>
                    <p className="text-sm text-gray-600">
                      {candidateJob?.company_job_position.job_role}
                    </p>
                  </div>
                  <div className="avatar w-[33%]">
                    <p className="font-semibold mb-1">Completed</p>
                    <p className="text-sm text-gray-600">
                      {candidateJob?.finished_at
                        ? moment(candidateJob.finished_at).format(
                            "DD MMMM YYYY"
                          )
                        : "-"}
                    </p>
                  </div>
                  <div className="avatar w-[33%]">
                    <p className="font-semibold mb-1">Status</p>
                    <StatusLabel
                      status={candidateJob?.status}
                      label={candidateJob?.status_label}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {showProfile ? (
          <>
            <Profile />
          </>
        ) : (
          <>
            {candidateJob && (
              <>
                {candidateJob.is_reportable == true && (
                  <>
                    <div className="mb-4">
                      <div className="">
                        <nav className="bg-gray-100 rounded-md mdb:bg-transparent">
                          <div className="flex space-x-4 overflow-x-auto  ">
                            <a
                              href="#"
                              className={`${
                                activeTab === "overview"
                                  ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg flex-shrink-0"
                                  : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0"
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                showTab("overview");
                              }}
                            >
                              Overview
                            </a>
                            <a
                              href="#"
                              className={`${
                                activeTab === "personality"
                                  ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg flex-shrink-0"
                                  : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0"
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                showTab("personality");
                              }}
                            >
                              Personality
                            </a>
                            {/* CHECK IS CHOOSEN */}
                            {softSkills.filter((item) => item.is_choosen)
                              .length > 0 && (
                              <a
                                href="#"
                                className={`${
                                  activeTab === "soft-skills"
                                    ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg flex-shrink-0"
                                    : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  showTab("soft-skills");
                                }}
                              >
                                Soft Skills
                              </a>
                            )}

                            {/* <a
                                            href="#"
                                            className={`${activeTab === 'hard-skills'
                                                ? 'text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg'
                                                    : 'text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200'
                                                }`}
                                            onClick={(e) => { e.preventDefault(); showTab('hard-skills') }}
                                        >
                                            Hard Skills
                                        </a> */}

                            {/* CHECK IS CHOOSEN */}
                            {cognitives.filter((item) => item.is_choosen)
                              .length > 0 && (
                              <a
                                href="#"
                                className={`${
                                  activeTab === "cognitive"
                                    ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg"
                                    : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  showTab("cognitive");
                                }}
                              >
                                Cognitive
                              </a>
                            )}
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div className="mb-6">
                      <div className="flex flex-col w-full">
                        <div className="mdb:px-4 mdb:py-5 sm:p-0">
                          <div className="py-1">
                            {/* Content for Tab 1 */}
                            {activeTab === "overview" && (
                              <>
                                {!personalityLoading ? (
                                  <OverviewTab />
                                ) : (
                                  <PageSpinner />
                                )}
                              </>
                            )}
                            {/* Content for Tab 2 */}
                            {activeTab === "personality" && (
                              <>
                                {personalityLoading ? (
                                  <div className="bg-white p-4 rounded-lg">
                                    <PageSpinner />
                                  </div>
                                ) : (
                                  <PersonalityTab />
                                )}
                              </>
                            )}
                            {/* Content for Tab 3 */}
                            {activeTab === "soft-skills" && (
                              <>
                                {softSkillLoading ? (
                                  <div className="bg-white p-4 rounded-lg">
                                    <PageSpinner />
                                  </div>
                                ) : (
                                  <SoftSkillTab />
                                )}
                              </>
                            )}
                            {/* Content for Tab 3 */}
                            {activeTab === "hard-skills" && (
                              <>
                                <HardSkillTab />
                              </>
                            )}
                            {/* Content for Tab 3 */}
                            {activeTab === "cognitive" && (
                              <>
                                {cognitiveLoading ? (
                                  <div className="bg-white p-4 rounded-lg">
                                    <PageSpinner />
                                  </div>
                                ) : (
                                  <CognitiveTab />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {candidateJob.is_reportable == false && (
                  <>
                    <div className="card mb-6">
                      <div className="w-full py-6 flex flex-col items-center">
                        <LockClosedIcon
                          width={120}
                          className="text-main-violate"
                        />
                        <p className="mt-4 mb-4 text-gray-500 font-semibold">
                          Report Locked
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MyCandidatesPage;
