import React, { Component } from "react";
import { inputIsOnlyAlphabetic } from "../../../../helpers";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "react-select";
import config from "../../../../config";

class CreateSession extends Component {
  constructor(props) {
    super(props);
    this.nameInputRef = React.createRef();
    this.handleNameInputFocus = this.handleNameInputFocus.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.state = {
      // selectedDepartment: null,
      // selectedPosition: null,
      selectedRole: null,
      selectedDuration: 30,
    };
  }

  handleNameInputFocus() {
    this.nameInputRef.current.focus();
  }

  handleNameChange(e) {
    const { value } = e.target;
    if (inputIsOnlyAlphabetic(value)) this.props.onNameChange(value);
  }

  componentDidMount() {}

  render() {
    // const customStyles = {
    //     menu: (provided, state) => ({
    //         ...provided,
    //         maxHeight: '200px'
    //     }),
    // };
    // let positionFilter = [];
    // let roleFilter = [];
    const minDate = new Date();
    minDate.setDate(
      minDate.getDate() + parseInt(config.CLIENT_JOB_MIN_DEADLINE)
    );
    const minDateString = minDate.toISOString().split("T")[0];
    const {
      // onDepartmentChange,
      // onPositionChange,
      onJobRoleChange,
      // jobDepartmentId,
      // jobPositionId,
      // departments,
      // positions,
      jobRoles,
      jobRole,
      name,
      startDate,
      endDate,
      duration,
      onStartDateChange,
      onEndDateChange,
      onDurationChange
    } = this.props;

    // positionFilter = positions.filter((position) => {
    //   return position.job_department_id === jobDepartmentId;
    // });

    // roleFilter = jobRoles.filter((role) => {
    //   return role.job_position_id === jobPositionId;
    // });
    return (
      <div className="h-[420px]">
        <div className="card">
          <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
            <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Name
              </label>
              <input
                ref={this.nameInputRef}
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="title"
                id="title"
                placeholder="Type your session name"
                value={name}
                onChange={this.handleNameChange}
                required
              />
            </div>
          </div>
          <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
            <div className="mb-2 z-index-999">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Start Date
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="date"
                name="title"
                id="title"
                placeholder="Choose date..."
                value={startDate}
                onChange={(e) => {
                  onStartDateChange(e.target.value);
                }}
                required
                min={minDateString}
              />
            </div>
            <div className="mb-2 z-index-999">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                End Date
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="date"
                name="title"
                id="title"
                placeholder="Choose date..."
                value={endDate}
                onChange={(e) => {
                  onEndDateChange(e.target.value);
                }}
                required
                min={minDateString}
              />
            </div>
          </div>
          <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
            {/* <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Department
              </label>
              <Select
                className="py-0.5 rounded-lg mt-2 border border-line-grey"
                classNamePrefix={"select"}
                options={departments.map((option) => {
                  return {
                    value: option.id,
                    label: option.name,
                  };
                })}
                value={this.state.selectedDepartment}
                onChange={(e) => {
                  this.setState({ selectedDepartment: e });
                  this.setState({ selectedPosition: null });
                  onDepartmentChange(e.value);
                }}
              />
            </div> */}
            {/* <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Job Position
              </label>
              <Select
                className="py-0.5 rounded-lg mt-2 border border-line-grey"
                classNamePrefix={"select"}
                value={this.state.selectedPosition}
                options={positionFilter.map((option) => {
                  return {
                    value: option.id,
                    label: option.name,
                  };
                })}
                onChange={(e) => {
                  this.setState({ selectedPosition: e });
                  onPositionChange(e.value);
                }}
              />
            </div> */}
            <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Duration
              </label>
              <select
                className="form-select appearance-none block w-[100%] px-3 py-2 text-grey-600 text-[14px] bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-md mt-2 transition ease-in-out focus:outline-none focus:border-main-violate border border-line-grey"
                aria-label="Default select example"
                value={duration}
                onChange={(e) => {
                  this.setState({ selectedDuration: e });
                  onDurationChange(e.target.value);
                }}
              >
                {/* <option selected disabled value="">
                  Choose a number
                </option> */}
                <option selected value={30}>
                  30 Minutes
                </option>
                <option value={60}>
                  60 Minutes
                </option>
                <option value={90}>
                  90 Minutes
                </option>
              </select>
            </div>
            <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Job Role
              </label>
              <Select
                className="py-0.5 rounded-lg mt-2 border border-line-grey"
                classNamePrefix={"select"}
                value={jobRole}
                options={jobRoles.map((option) => {
                  return {
                    value: option.id,
                    label: option.job_role,
                  };
                })}
                onChange={(e) => {
                  this.setState({ selectedRole: e });
                  onJobRoleChange(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateSession;
