import React from "react";
import PageSpinner from "../../../../../../Component/PageSpinner";
import { GlobeAltIcon} from "@heroicons/react/24/solid";
const SocialNetworkSection = ({ loading = true, socials }) => {
  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <GlobeAltIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Social Networks
            </h4>
          </div>
        </div>
        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {(socials?.linkedin != "" && socials?.medium != "" && socials?.dribble != "" && socials?.github != "" && socials?.website != "") ? (
              <div className="card">
                {socials?.linkedin ? <div className="flex text-[14px] leading-[140%]">
                  <div className="px-4 py-2 font-semibold min-w-[100px]">Linkedin</div>
                  <div className="px-4 py-2">: <a className="text-blue-700 font-semibold" href={socials?.linkedin}>{socials?.linkedin}</a></div>
                </div> : ''}

                {socials?.medium ? <div className="flex text-[14px] leading-[140%]">
                  <div className="px-4 py-2 font-semibold min-w-[100px]">Medium</div>
                  <div className="px-4 py-2">: <a className="text-blue-700 font-semibold" href={socials?.medium}>{socials?.medium}</a></div>
                </div> : ''}

                {socials?.dribbble ? <div className="flex text-[14px] leading-[140%]">
                  <div className="px-4 py-2 font-semibold min-w-[100px]">Dribbble</div>
                  <div className="px-4 py-2">: <a className="text-blue-700 font-semibold" href={socials?.dribbble}>{socials?.dribbble}</a></div>
                </div> : ''}

                {socials?.github ? <div className="flex text-[14px] leading-[140%]">
                  <div className="px-4 py-2 font-semibold min-w-[100px]">Github</div>
                  <div className="px-4 py-2">: <a className="text-blue-700 font-semibold" href={socials?.github}>{socials?.github}</a></div>
                </div> : ''}

                {socials?.website ? <div className="flex text-[14px] leading-[140%]">
                  <div className="px-4 py-2 font-semibold min-w-[100px]">Website</div>
                  <div className="px-4 py-2">: <a className="text-blue-700 font-semibold" href={socials?.website}>{socials?.website}</a></div>
                </div> : ''}

              </div>
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SocialNetworkSection;
