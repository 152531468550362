import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BriefcaseIcon,
  XCircleIcon,
  ClockIcon,
} from "@heroicons/react/24/solid";
import PaginationTable from "../../../Component/Tables/PaginationTable";
import axios from "axios";

import TableSpinner from "../../../Component/TableSpinner";
import EmptyContent from "../../../Component/Tables/EmptyContent";
import BtnSpinner from "../../../Component/BtnSpinner";
import BreadCrumb from "../../../Component/BreadCrumb";
import CheckSpam from "./Popup/CheckSpam";
import PopUp from "../../../Component/PopUp";
import { documentTitle } from "../../../helpers";

const PreviewImportPage = () => {
  let navigate = useNavigate();
  const { id, importJobId } = useParams();

  const [user, setUser] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const [userFullname, setUserFullname] = useState(() => {
    if (user) {
      return user.useraccount_fullname || "";
    }
  });

  const [activeImport, setActiveImport] = useState(null);
  const [tableCandidateLoading, setTableCandidateLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [query, setQuery] = useState({});
  const [position, setPosition] = useState({
    id: null,
    name: "",
    company_id: null,
    total_candidate_started: 0,
    total_candidate_finished: 0,
    days_to_expiry: "",
    tags: [],
    is_publish: "",
    created_at: "",
    updated_at: "",
  });

  const [tests, setTests] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showCheckSpam, setShowCheckSpam] = useState(false);

  const getActiveImport = async () => {
    await axios
      .get(`/api/client/candidate/invitation/${id}/import`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setActiveImport(result.data);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getPosition = async () => {
    await axios
      .get(`/api/client/assessment/job-position/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setPosition(result.data.position);
          setTests(result.data.tests);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getTableImport = async () => {
    setTableCandidateLoading(true);
    const queryString = new URLSearchParams(query).toString();
    await axios
      .get(
        `/api/client/candidate/invitation/${id}/import/${importJobId}?${queryString}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setCandidates(result.data.data);
          setCurrentPage(result.data.current_page);
          setTotalItems(result.data.total);
          setItemsPerPage(result.data.per_page);
          setItemsFrom(result.data.from);
          setItemsTo(result.data.to);
          setItemsPerPage(result.data.per_page);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .finally(() => {
        setTableCandidateLoading(false);
      });
  };

  const handleSubmit = async () => {
    setProcessing(true);
    setSubmitting(true);
    await axios
      .post(
        `/api/client/candidate/invitation-import`,
        {
          import_id: importJobId,
          company_job_position_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success(result.message, {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1250,
          });
          setShowCheckSpam(true);
        }
      })
      .catch((e) => {
        setProcessing(false);
        setSubmitting(false);
        toast.error(e.response.data.message);
      });
  };

  const handleConfirmSpam = () => {
    setShowCheckSpam(false);
    navigate(`/client/jobs/detail/${id}`);
  };
  const handleDelete = async () => {
    setProcessing(true);
    setDeleting(true);
    await axios
      .delete(`/api/client/candidate/invitation/${id}/import/${importJobId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success(result.message, {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1250,
            onClose: () => {
              navigate(`/client/jobs/detail/${id}`);
            },
          });
        }
      })
      .catch((e) => {
        setProcessing(false);
        setDeleting(false);
        toast.error(e.response.data.message);
      });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  useEffect(() => {
    getPosition();
    getActiveImport();
    documentTitle("Import Job Preview");
  }, []);

  useEffect(() => {
    getTableImport();
  }, [query]);

  const TableCandidateContent = () => {
    if (tableCandidateLoading) {
      return <TableSpinner colSpan={"100%"} />;
    } else {
      return (
        <>
          {candidates.length > 0 ? (
            <>
              {candidates.map((candidate, index) => {
                return (
                  <tr className="whitespace-nowrap border-b-[1px]">
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {itemsPerPage * (currentPage - 1) + index + 1}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.row_data.fullname}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.row_data.email}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {position?.job_role}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {position?.job_position?.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 overflow-hidden max-w-[200px]">
                      {candidate.is_error &&
                        candidate.error_messages.map((msg, index) => {
                          return <StatusLabel status={0} label={msg} />;
                        })}
                      {/* <StatusLabel status={candidate.status} label={candidate.information} /> */}
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              <EmptyContent />
            </>
          )}
        </>
      );
    }
  };

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p
          className="bg-red-100 text-red-800 font-medium text-center text-xs py-1 rounded break-words px-2 mb-1"
          style={{ textWrap: "wrap" }}
        >
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text">
          {label}
        </p>
      );
    }
  };

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      {showCheckSpam && (
        <PopUp>
          <CheckSpam
            onClose={() => handleConfirmSpam()}
            onConfirm={(e) => handleConfirmSpam()}
          />
        </PopUp>
      )}
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <BriefcaseIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Detail Job</p>
          <BreadCrumb
            list={[
              { href: "/client/jobs", title: "Jobs" },
              { href: `/client/jobs/detail/${id}`, title: id },
              { href: null, title: "Import Preview" },
            ]}
          />
        </div>
      </div>
      <div className="candidate-list">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-2">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">
                Preview Candidate {position?.job_role}
              </p>
              {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
            </div>
          </div>
          {activeImport && (
            <>
              {activeImport.total_error > 0 && (
                <div className="card-mini-danger mb-6">
                  <div className="card-header flex mb-1">
                    <XCircleIcon width={20} />
                    <p className="ml-2 font-semibold"> Error Files </p>
                  </div>
                  <div className="card-body flex">
                    <p className="text-sm">
                      {" "}
                      Your file has an input error, you can still submit data
                      with ignoring malformed data. Or, you can correct first
                      than re-upload again{" "}
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
          {candidates.length <= 0 && (
            <>
              <div className="card-mini-primary mb-6">
                <div className="card-header flex mb-1">
                  <ClockIcon width={20} />
                  <p className="ml-2 font-semibold"> Data Still Processing </p>
                </div>
                <div className="card-body flex">
                  <p className="text-sm">
                    Your data is still proccessing / importing, please refresh
                    this page periodically
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="border-b border-gray-200 shadow">
            <PaginationTable
              checkbox={false}
              currentPage={handlePaginate}
              itemsTo={itemsTo}
              itemsFrom={itemsFrom}
              itemsPerPage={5}
              totalItems={totalItems}
              totalPages={Math.ceil(totalItems / itemsPerPage)}
              headers={[
                "NO",
                "FULLNAME",
                "EMAIL",
                "JOB ROLE",
                "JOB POSITION",
                "INFORMATION",
              ]}
              content={<TableCandidateContent />}
            />
          </div>
          <div className="flex mt-2 gap-2 justify-end">
            {activeImport && (
              <>
                {candidates.length > 0 && (
                  <button
                    className="border border-red-500 px-4 text-[16px] leading-[22.4px] text-red-500 font-semibold h-[40px] rounded-lg hover:bg-red-200 disabled:bg-gray-300 disabled:cursor-not-allowed mt-2"
                    disabled={processing || deleting}
                    onClick={(e) => handleDelete()}
                  >
                    {deleting ? <BtnSpinner /> : "Delete"}
                  </button>
                )}

                {/* <button
                            class="border border-main-violate px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[40px] rounded-lg hover:bg-violet-300 disabled:bg-violet-300 disabled:cursor-not-allowed mt-2"
                            onClick={(e) => navigate(`/client/jobs/detail/${id}/import-preview`)}
                        >
                            Re-Upload
                        </button> */}

                {candidates.length > 0 && (
                  <button
                    className=" bg-main-violate px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed mt-2"
                    onClick={(e) => handleSubmit()}
                    disabled={processing || submitting}
                  >
                    {submitting ? <BtnSpinner /> : "Submit"}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewImportPage;
