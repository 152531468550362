import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ClipboardDocumentListIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import { documentTitle } from "../../../../helpers";
import Select from "react-select";
import PageSpinner from "../../../../Component/PageSpinner";
import BtnSpinner from "../../../../Component/BtnSpinner";
import {
  checkImportQuestionStatus,
  createAssessmentTest,
  deleteImportQuestionTest,
  getAssessmentTestDetail,
  getAssessments,
  importQuestionTest,
  submitImportQuestionTest,
  submitImportGroupQuestionTest,
  updateAssessmentTest,
} from "../../../../api/admin/assessmentApi";
import { getJobPositions } from "../../../../api/admin/jobApi";
import { ToggleSwitch } from "flowbite-react";

const EditPage = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  const [query, setQuery] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [groupFileUpload, setGroupFileUpload] = useState(null);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({
    name: null,
    image: null,
    description: null,
    reason: null,
    tags: null,
    time_type: null,
    language: "id",
    assessment_id: null,
  });

  const [assessments, setAssessments] = useState([]);
  const [jobPositions, setJobPositions] = useState([]);
  const [test, setTest] = useState(null);
  const [importQuestion, setImportQuestion] = useState(null);
  const [formPassed, setFormPassed] = useState(true);
  const [withQuestionGroup, setWithQuestionGroup] = useState({
    label: "No",
    value: false,
  });
  const [reuploadQuestion, setReuploadQuestionn] = useState(true);
  const [newVersion, setNewVersion] = useState(true);
  const [newTest, setNewTest] = useState(null);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const resetForm = () => {
    setForm({
      name: null,
      image: null,
      description: null,
      reason: null,
      tags: null,
      time_type: null,
      language: "id",
      assessment_id: null,
    });
  };
  const handleSubmit = async (e) => {
    setSubmitting(true);
    e.preventDefault();
    const sanitizeForm = {
      name: form.name,
      image: form.image,
      description: form.description,
      reason: form.reason,
      tags: form.tags.value,
      time_type: form.time_type.value,
      language: form.language.value,
      assessment_id: form.assessment_id.value,
      replicate: newVersion,
      reupload_question: reuploadQuestion,
    };

    const result = await updateAssessmentTest(sanitizeForm, id);
    if (result.success) {
      setNewTest(result.data);
      if (!reuploadQuestion) {
        toast.success(result.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        resetForm();
        setTimeout(() => {
          navigate(`/administrator/assessments/test/${newTest.id}`);
        }, 1000);
        setSubmitting(false);
      }
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      setSubmitting(false);
    }
  };

  const handleFile = (e) => {
    setFileUpload(e);
    // console.log(e.target.files[0].name);
  };

  const handleGroupFile = (e) => {
    setGroupFileUpload(e);
    // console.log(e.target.files[0].name);
  };

  const handleImportQuestion = async () => {
    let type = "";
    if (form.assessment_id.label == "Personality") {
      type = "personality";
    } else if (form.assessment_id.label == "Soft Skills") {
      type = "soft_skill";
    } else if (form.assessment_id.label == "Hard Skills") {
      type = "hard_skill";
    } else if (form.assessment_id.label == "Cognitive") {
      type = "cognitive";
    }

    if (withQuestionGroup) {
      const formGroupData = new FormData();
      formGroupData.append("file", groupFileUpload.target.files[0]);
      formGroupData.append("file_kind", "group");
      formGroupData.append("type", type);

      const resultGroup = await importQuestionTest(test.id, formGroupData);
      if (!resultGroup.success) {
        setSubmitting(false);
        return toast.error(resultGroup.message, {
          toastId: "signup-failure-toast",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1250,
        });
      } else {
        await handleImportGroupQuestionCheck(resultGroup.data.id);
      }
    }

    const formData = new FormData();
    formData.append("file", fileUpload.target.files[0]);
    formData.append("file_kind", "question");
    formData.append("type", type);

    const result = await importQuestionTest(newTest.id, formData);
    if (result.success) {
      setImportQuestion(result.data);
      toast.success("Success to create test & import question", {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  const handleImportGroupQuestionCheck = async (import_id) => {
    const result = await checkImportQuestionStatus(test.id);
    if (result.success) {
      if (result.data.total_error <= 0) {
        const submitImportResult = await submitImportGroupQuestionTest(
          test.id,
          {
            import_id: import_id,
          }
        );
        if (submitImportResult.success) {
          setForm({
            name: null,
            image: null,
            description: null,
            reason: null,
            tags: null,
            time_type: null,
            language: "id",
            assessment_id: null,
          });
        } else {
          await deleteImportQuestionTest(import_id, test.id);
          toast.error("Question group failed to store", {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
          });
        }
        return true;
      } else {
        await deleteImportQuestionTest(import_id, test.id);
        toast.error("Question group has an error", {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        return false;
      }
    } else {
      toast.error("Question group failed to check and please try again", {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
  };

  const handleImportQuestionCheck = async () => {
    const result = await checkImportQuestionStatus(newTest.id);
    if (result.success) {
      if (result.data.total_error <= 0) {
        const submitImportResult = await submitImportQuestionTest(newTest.id, {
          import_id: importQuestion.id,
        });
        if (submitImportResult.success) {
          resetForm();
          toast.success(
            "Success update test to new version & import question",
            {
              toastId: "login-success-toast",
              position: toast.POSITION.TOP_CENTER,
            }
          );
          setTimeout(() => {
            navigate(`/administrator/assessments/test/${newTest.id}`);
          }, 1000);
        }
      } else {
        await deleteImportQuestionTest(importQuestion.id, newTest.id);
        toast.success("Question failed to import and please try again", {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      toast.success(
        "Success to create test & failed to import question check",
        {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };
  const fetchTest = async () => {
    // setTestLoading(true);
    const result = await getAssessmentTestDetail(id);
    if (result.success) {
      let selectedPosition = null;
      if (result.data.tags) {
        let positionRaw = jobPositions.find(
          (position) => position.slug == result.data.tags[0]
        );
        if (positionRaw) {
          selectedPosition = {
            value: positionRaw.slug,
            label: positionRaw.name,
          };
        }
      }

      const sanitizeData = {
        name: result.data.name,
        image: result.data.image,
        description: result.data.description,
        reason: result.data.reason,
        tags: selectedPosition,
        time_type: {
          label:
            result.data.time_type == "in_test" ? "Per Test" : "Per Question",
          value: result.data.time_type == "in_test" ? "in_test" : "in_question",
        },
        language: {
          label: result.data.language == "id" ? "Indonesia" : "English",
          value: result.data.language == "id" ? "id" : "en",
        },
        assessment_id: {
          label: result.data.assessment.name,
          value: result.data.assessment.id,
        },
      };

      setTest(result.data);
      setForm(sanitizeData);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    // setTestLoading(false);
  };

  const fetchAssessments = async () => {
    const result = await getAssessments();
    if (result.success) {
      setAssessments(result.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };
  const fetchJobPositions = async () => {
    const queryString = new URLSearchParams({ limit: 1000 }).toString();
    const result = await getJobPositions(queryString);
    // console.log(result);
    if (result.success) {
      setJobPositions(result.data.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const getData = async () => {
    await fetchAssessments();
    await fetchJobPositions();
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Packages Management");
    getData();
  }, []);

  useEffect(() => {
    if (jobPositions.length > 0) {
      fetchTest();
    }
  }, [jobPositions]);

  useEffect(() => {
    if (test) {
      setLoading(false);
    }
  }, [test]);

  useEffect(() => {
    if (newTest) {
      if (reuploadQuestion) {
        handleImportQuestion();
      }
    }
  }, [newTest]);

  useEffect(() => {
    if (reuploadQuestion) {
      setNewVersion(true);
    }
  }, [reuploadQuestion]);

  useEffect(() => {
    if (test) {
      handleImportQuestionCheck();
    }
  }, [importQuestion]);

  useEffect(() => {}, [query]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <ClipboardDocumentListIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Assessment & Test</p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/assessments",
                title: "Assessment & Test",
              },
              {
                href: null,
                title: "Edit Test",
              },
            ]}
          />
        </div>
      </div>

      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">Edit Test</p>
            </div>
          </div>
          {loading && <PageSpinner />}
          {!loading && (
            <>
              <div className="mb-8 mt-2">
                <div className="flex space-x-2 items-center">
                  <ToggleSwitch
                    onChange={(e) => {
                      if (reuploadQuestion) {
                        setNewVersion(true);
                      } else {
                        setNewVersion(!newVersion);
                      }
                    }}
                    checked={newVersion}
                    // disabled={reuploadQuestion}
                  />
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Update to New Version
                  </label>
                </div>
              </div>
              <div className="flex space-x-0 mdb:space-x-8 mb-4 flex-col mdb:flex-row">
                <div className="w-[100%] mdb:w-[50%]">
                  <div className="mb-4">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Test Name
                    </label>
                    <input
                      // ref={this.jobRoleInputRef}
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Type test name"
                      value={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="mb-4 flex space-x-4">
                    <div className="w-[50%]">
                      <label className="text-gray-800 text-sm font-bold leading-[140%]">
                        Assessment Category
                      </label>
                      <Select
                        className="py-0.5 rounded-lg mt-2 border border-line-grey"
                        classNamePrefix={"select"}
                        value={form.assessment_id}
                        options={assessments.map((option) => {
                          return {
                            value: option.id,
                            label: option.name,
                          };
                        })}
                        onChange={(e) => {
                          setForm({ ...form, assessment_id: e });
                        }}
                      />
                    </div>
                    <div className="w-[50%]">
                      <label className="text-gray-800 text-sm font-bold leading-[140%]">
                        Test Language
                      </label>
                      <Select
                        className="py-0.5 rounded-lg mt-2 border border-line-grey"
                        classNamePrefix={"select"}
                        value={form.language}
                        required
                        options={[
                          {
                            value: "id",
                            label: "Indonesia",
                          },
                          {
                            value: "en",
                            label: "English",
                          },
                        ]}
                        onChange={(e) => {
                          setForm({ ...form, language: e });
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-4 w-[50%]">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Time Type
                    </label>
                    <Select
                      className="py-0.5 rounded-lg mt-2 border border-line-grey"
                      classNamePrefix={"select"}
                      value={form.time_type}
                      required
                      options={[
                        {
                          value: "in_test",
                          label: "Per Test",
                        },
                        {
                          value: "in_question",
                          label: "Per Question",
                        },
                      ]}
                      onChange={(e) => {
                        setForm({ ...form, time_type: e });
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Description of this test
                    </label>
                    <textarea
                      className="border w-full p-3 h-[136px] placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="description"
                      id="description"
                      placeholder="Write short summary of this test here. Example: This numerical reasoning test evaluates candidates general aptitude with numbers and their skill in interpreting them for a wide range of applications. This test will help you identify candidates with strong numerical skills."
                      value={form.description}
                      onChange={(e) => {
                        setForm({ ...form, description: e.target.value });
                      }}
                      required
                    ></textarea>
                    <div className="flex justify-end">
                      <small className="text-gray-500">250/250</small>
                    </div>
                  </div>
                </div>
                <div className="w-[100%] mdb:w-[50%] mb-2">
                  <div className="mb-4">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Use this test to hire
                    </label>
                    <textarea
                      className="border w-full p-3 h-[136px] placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                      type="text"
                      name="reason"
                      id="reason"
                      placeholder="Write roles that can use this test here. Example: Any role in which numerical aptitude is important, especially if it involves dealing with financials (budgets, forecasts, business cases, etc.) or interpreting and developing (number-heavy) spreadsheets"
                      value={form.reason}
                      onChange={(e) => {
                        setForm({ ...form, reason: e.target.value });
                      }}
                      required
                    ></textarea>
                    <div className="flex justify-end">
                      <small className="text-gray-500">250/250</small>
                    </div>
                  </div>
                  <div className="mb-8">
                    <label className="text-gray-800 text-sm font-bold leading-[140%]">
                      Recommended for
                    </label>
                    <Select
                      className="py-0.5 rounded-lg mt-2 border border-line-grey"
                      classNamePrefix={"select"}
                      value={form.tags}
                      options={jobPositions.map((option) => {
                        return {
                          value: option.slug,
                          label: option.name,
                        };
                      })}
                      onChange={(e) => {
                        setForm({ ...form, tags: e });
                      }}
                    />
                  </div>
                  <div>
                    <div className="flex items-center mb-3 space-x-2 ">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                        type="checkbox"
                        id={`replace_question`}
                        checked={reuploadQuestion}
                        value={reuploadQuestion}
                        onChange={() => setReuploadQuestionn(!reuploadQuestion)}
                      />
                      <label className="text-gray-800 text-sm font-bold leading-[140%]">
                        Reupload Question
                      </label>
                    </div>
                  </div>
                  {reuploadQuestion && (
                    <>
                      <div className="mb-4 mt-4">
                        <label className="text-gray-800 text-sm font-bold leading-[140%]">
                          With Question Group *
                        </label>
                        <Select
                          className="py-0.5 rounded-lg mt-2 border border-line-grey"
                          classNamePrefix={"select"}
                          value={withQuestionGroup}
                          options={[
                            {
                              value: false,
                              label: "No",
                            },
                            {
                              value: true,
                              label: "Yes",
                            },
                          ]}
                          onChange={(e) => {
                            setWithQuestionGroup(e);
                          }}
                        />
                      </div>
                      {withQuestionGroup?.value && (
                        <div>
                          <label className="text-gray-800 text-sm font-bold leading-[140%]">
                            Question Group File *
                          </label>
                          {submitting ? (
                            <PageSpinner padding={"30px"} />
                          ) : groupFileUpload ? (
                            <div className="mt-3 mb-3 relative h-[50px] rounded-lg border bg-white flex justify-between items-center hover:outline-none px-4">
                              <span className="block text-soft-grey text-[14px]">
                                {groupFileUpload.target.files[0].name}{" "}
                              </span>
                              <button
                                onClick={(e) => {
                                  setGroupFileUpload(null);
                                }}
                              >
                                <TrashIcon width={20} />
                              </button>
                            </div>
                          ) : (
                            <input
                              className="border mt-3 mb-3 w-full placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                              type="file"
                              accept=".xls,.xlsx"
                              onChange={(e) => handleGroupFile(e)}
                            />
                          )}

                          <p className="text-[14px] text-grey-500  leading-[1.5] mb-4">
                            Format: .xlsx
                          </p>
                        </div>
                      )}
                      <div>
                        {submitting ? (
                          <PageSpinner padding={"30px"} />
                        ) : fileUpload ? (
                          <div className="mt-3 mb-3 relative h-[50px] rounded-lg border bg-white flex justify-between items-center hover:outline-none px-4">
                            <span className="block text-soft-grey text-[14px]">
                              {fileUpload.target.files[0].name}{" "}
                            </span>
                            <button
                              onClick={(e) => {
                                setFileUpload(null);
                              }}
                            >
                              <TrashIcon width={20} />
                            </button>
                          </div>
                        ) : (
                          <input
                            className="border mt-3 mb-3 w-full placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                            type="file"
                            accept=".xls,.xlsx"
                            onChange={(e) => handleFile(e)}
                          />
                        )}

                        <p className="text-[14px] text-grey-500  leading-[1.5] mb-4">
                          Format: .xlsx
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                {submitting ? (
                  <button
                    className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    type=""
                    disabled={true}
                  >
                    <BtnSpinner />
                  </button>
                ) : (
                  <button
                    className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    type="submit"
                    disabled={!formPassed}
                  >
                    Save
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default EditPage;
