import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LockClosedIcon, VideoCameraIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import CreateSession from "./Sections/CreateSession";
import PreviewSession from "./Sections/PreviewSession";
import BtnSpinner from "../../../Component/BtnSpinner";
import BreadCrumb from "../../../Component/BreadCrumb";
import PopUp from "../../../Component/PopUp";
import { getMySubscriptions, validateSubscriptionSerial } from "../../../api";
import PageSpinner from "../../../Component/PageSpinner";
import config from "../../../config";
import { documentTitle } from "../../../helpers";
import Stepper from "./Stepper";
import SelectCalendar from "./Sections/SelectCalendar";
import SelectCandidate from "./Sections/SelectCandidate";
import moment from "moment";
import CandidateProfilePopup from "./Popup/CandidateProfilePopup";

const CreatePage = () => {
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  let navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [formPassed, setFormPassed] = useState(false);
  const [formPosition, setFormPosition] = useState(1);
  const [jobRole, setJobRole] = useState(null);
  const [filters, setFilters] = useState(null);
  const [jobRoles, setJobRoles] = useState([]);
  const [tests, setTests] = useState([]);
  const [filterAssessment, setFilterAssessment] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [showCreateJobTokenPopup, setShowCreateJobTokenPopup] = useState(false);
  const [mySubscriptions, setMySubscriptions] = useState([]);
  const [validating, setValidating] = useState(false);
  const [checking, setChecking] = useState(true);
  const [createable, setCreateable] = useState(false);
  const [packageApplied, setPackageApplied] = useState(null);
  const [quotaTest, setQuotaTest] = useState(null);
  const [showQuotaExceed, setShowQuotaExceed] = useState(false);
  const [currentSerial, setCurrentSerial] = useState(null);
  const [form, setForm] = useState({
    // job_department_id: "",
    // job_position_id: "",
    company_job_position_id: "",
    start_date: "",
    end_date: "",
    name: "",
    duration: 30,
  });
  const [showProfile, setShowProfile] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("code")) {
      setCurrentSerial(params.get("code"));
    }
  }, []);

  const getJobRoles = async () => {
    try {
      const response = await axios.get("/api/client/assessment/job-role-list");
      const result = response.data;
      if (result.success) {
        setJobRoles(result.data);
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    documentTitle("Create Jobs");
    getJobRoles();
  }, []);

  useEffect(() => {
    if (formPosition == 1) {
      if (
        form.start_date != "" &&
        form.end_date != "" &&
        form.company_job_position_id != "" &&
        form.name != ""
      ) {
        setFormPassed(true);
      } else {
        setFormPassed(false);
      }
    }
  }, [form, formPosition]);

  const handleSelectedTest = (candidate) => {
    setSelectedCandidates([...selectedCandidates, candidate]);
  };

  useEffect(() => {
    if (selectedCandidates.length > 0) {
      setFormPassed(true);
    } else {
      setFormPassed(false);
    }
  }, [selectedCandidates]);
  const handleRemoveTest = (candidate) => {
    // REMOVE CANDIDATE
    setSelectedCandidates(
      selectedCandidates.filter((item) => item.id != candidate.id)
    );
  };

  const handlNextSection = () => {
    if (formPosition == 1) {
      setCalendars([]);
    }
    setFormPosition(formPosition + 1);
  };

  const handleCreateJob = () => {
    // TEMPORARY FOR TEST
    // ENV DEV
    const isDev = config.APP_ENV == "development";
    if (isDev) {
      if (mySubscriptions.length > 0) {
        setCreateable(false);
        setShowCreateJobTokenPopup(true);
      } else {
        setCreateable(true);
        // navigate('/client/jobs/create')
      }
    } else {
      setCreateable(false);
      setShowCreateJobTokenPopup(true);
    }
  };

  const handleShowProfile = (profile) => {
    setShowProfile(profile);
  };

  const handleApplySerial = async (serial) => {
    setValidating(true);
    const result = await validateSubscriptionSerial({ serial });
    if (result.success) {
      toast.success(result.message, {
        toastId: "signup-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      setPackageApplied(result.data);
      setQuotaTest(result.data.quota_test);
      setShowCreateJobTokenPopup(false);
      setCreateable(true);
      const params = new URLSearchParams(window.location.search);
      if (params.has("code")) {
        setCurrentSerial(null);
        params.delete("code");
        history.replace({
          search: queryParams.toString(),
        });
      }
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setValidating(false);
  };

  const fetchMySubscriptions = async () => {
    setChecking(true);
    const result = await getMySubscriptions();
    if (result.success) {
      setMySubscriptions(result.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setChecking(false);
  };

  const handleSubmit = async (e) => {
    setSubmitting(true);
    const sanitizeSchedules = calendars
      .filter((calendar) => calendar)
      .filter((calendar) => calendar.events.length > 0)
      .map((calendar) => {
        return calendar.events
          .sort(
            (a, b) =>
              new Date(`1970/01/01 ${a.start_time}`) -
              new Date(`1970/01/01 ${b.start_time}`)
          )
          .map((event) => {
            return {
              date: moment(calendar.data, "DD MMMM YYYY").format("YYYY-MM-DD"),
              start_time: event.start_time,
              end_time: event.end_time,
            };
          });
      });

    const santizeForm = {
      company_job_position_id: form.company_job_position_id,
      name: form.name,
      host_email: user.email,
      start_date: form.start_date,
      end_date: form.end_date,
      co_host_email: "",
      schedules: sanitizeSchedules.flat(),
      invitees: selectedCandidates.map((candidate) => candidate.id),
    };

    const response = await axios.post("/api/client/interview", santizeForm);
    const result = response.data;
    if (result.success) {
      toast.success(result.message, {
        toastId: "signup-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
        onClose: () => navigate("/client/interviews"),
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  const getCandidateInPosition = async () => {
    await axios
      .get(
        `/api/client/interview/candidate-job-position/${form.company_job_position_id}?limit=100`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setCandidates(result.data.data);
          setFilters(result.additional.filter);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    fetchMySubscriptions();
  }, []);

  useEffect(() => {
    handleCreateJob();
  }, [mySubscriptions]);

  useEffect(() => {
    if (formPosition == 2) {
      getCandidateInPosition();
    }
  }, [formPosition]);

  useEffect(() => {
    if (formPosition == 2) {
      if (calendars.length > 0) {
        if (
          calendars
            .filter((calendar) => calendar)
            .some((calendar) => calendar.events.length > 0)
        ) {
          setFormPassed(true);
        } else {
          setFormPassed(false);
        }
      }
    }
  }, [calendars]);

  // useEffect(() => {
  //   if(quotaTest != null && quotaTest == 0){

  //   }
  // }, [quotaTest]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflowX: "hidden",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      {/* {showCreateJobTokenPopup && <PopUp><CreateJobTokenPopup currentSerial={currentSerial} onClose={(e) => setShowCreateJobTokenPopup(false)} onSend={(serial) => handleApplySerial(serial)} validating={validating} /></PopUp>}
      {showQuotaExceed && <PopUp><QuotaExceedPopup onClose={(e) => setShowQuotaExceed(false)} /></PopUp>} */}
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <VideoCameraIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Create Session</p>
          <BreadCrumb
            list={[
              { href: "/client/interviews", title: "Interviews" },
              { href: null, title: "Create Session" },
            ]}
          />
        </div>
      </div>
      {checking && <PageSpinner />}
      {!checking && (
        <>
          {!createable && (
            <div className="card mb-6">
              <div className="w-full py-6 flex flex-col items-center">
                <LockClosedIcon width={120} className="text-gray-500" />
                <p className="mt-4 mb-4 text-gray-600">Page Locked</p>
                <button
                  className="hover:bg-violet-600 cursor-pointer bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[42px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                  onClick={(e) => setShowCreateJobTokenPopup(true)}
                >
                  Apply Subscription Code
                </button>
              </div>
            </div>
          )}
          {showProfile && (
            <PopUp>
              <CandidateProfilePopup
                onClose={() => setShowProfile(null)}
                profile={showProfile}
              />
            </PopUp>
          )}
          {createable && (
            <>
              <Stepper formPosition={formPosition} />
              <div className="mb-6">
                {formPosition == 1 && (
                  <>
                    <CreateSession
                      jobRoles={jobRoles}
                      jobRole={jobRole}
                      name={form.name}
                      startDate={form.start_date}
                      endDate={form.end_date}
                      duration={form.duration}
                      onJobRoleChange={(value) => {
                        setForm({
                          ...form,
                          company_job_position_id: value.value,
                        });
                        setJobRole(value);
                      }}
                      onNameChange={(value) =>
                        setForm({ ...form, name: value })
                      }
                      onStartDateChange={(value) =>
                        setForm({ ...form, start_date: value })
                      }
                      onEndDateChange={(value) =>
                        setForm({ ...form, end_date: value })
                      }
                      onDurationChange={(value) =>
                        setForm({ ...form, duration: value })
                      }
                      // departments={departments}
                      // positions={positions}
                      // jobDepartmentId={form.job_department_id}
                      // jobPositionId={form.job_position_id}
                      // onDepartmentChange={(value) =>
                      //   handleDepartmentChange(value)
                      // }
                      // onPositionChange={(value) =>
                      //   setForm({ ...form, job_position_id: value })
                      // }
                    />
                  </>
                )}

                {formPosition == 2 && (
                  <SelectCalendar
                    duration={form.duration}
                    startDate={form.start_date}
                    endDate={form.end_date}
                    onApplyCalendar={(calendars) => setCalendars(calendars)}
                    storedCalendars={calendars}
                  />
                )}

                {formPosition == 3 && (
                  <>
                    <SelectCandidate
                      filters={filters}
                      selectedCandidates={selectedCandidates}
                      candidates={candidates}
                      tests={tests}
                      onSelect={handleSelectedTest}
                      onShowProfile={handleShowProfile}
                      onRemove={handleRemoveTest}
                      filterAssessment={filterAssessment}
                      quotaTest={quotaTest}
                    />
                  </>
                )}

                {formPosition == 4 && (
                  <PreviewSession
                    calendars={calendars}
                    form={form}
                    selectedCandidates={selectedCandidates}
                    department={selectedDepartment}
                    position={selectedPosition}
                    role={jobRole}
                    tests={tests.filter((test) => test.selected == true)}
                    onDelete={(e) => handleRemoveTest(e)}
                    onShowProfile={handleShowProfile}
                  />
                )}

                <div className="flex justify-end">
                  {formPosition > 1 ? (
                    <>
                      <button
                        className={`${
                          (submitting ? "hidden " : "") +
                          "mr-4 border border-line-grey-1 w-[160px] px-4 text-[16px] leading-[22.4px] text-grey-98 font-semibold h-[46px] rounded-lg"
                        }`}
                        onClick={(e) => {
                          setFormPosition(formPosition - 1);
                        }}
                      >
                        Previous
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className={`${
                          (submitting ? "hidden " : "") +
                          "mr-4 border border-line-grey-1 w-[160px] px-4 text-[16px] leading-[22.4px] text-grey-98 font-semibold h-[46px] rounded-lg"
                        }`}
                        type="submit"
                        onClick={(e) => {
                          navigate("/client/interviews");
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  )}

                  {submitting ? (
                    <button
                      className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                      type=""
                      disabled={true}
                    >
                      <BtnSpinner />
                    </button>
                  ) : (
                    <button
                      className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                      type=""
                      disabled={!formPassed}
                      onClick={(e) => {
                        if (formPosition < 4) {
                          handlNextSection();
                        } else {
                          handleSubmit();
                        }
                      }}
                    >
                      {formPosition < 4 ? "Next" : "Finish & Submit"}
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CreatePage;
