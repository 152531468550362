import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AcademicCapIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  ClipboardDocumentListIcon,
  PlusCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { getSummaries } from "../../api";
import { useDispatch } from "react-redux";
import { setSummary } from "../../features/dashboards/dashboardsSlice";
import { useSelector } from "react-redux";
import JobList from "./JobsPage/JobList";
import { documentTitle } from "../../helpers";

const DashboardProfile = () => {
  const permission = useSelector((state) => state.user.permissions);
  const { data: positions } = useSelector((state) => state.jobs.jobsTable);
  const { summary } = useSelector((state) => state.dashboards);
  let navigate = useNavigate();
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const dispatch = useDispatch();
  const [, setSummaryLoading] = useState(false);
  const [companyTab, setCompanyTab] = useState({
    cp: true,
  });

  const fetchSummaries = async () => {
    setSummaryLoading(true);
    const result = await getSummaries();
    dispatch(setSummary(result));
  };

  useEffect(() => {
    documentTitle("Dashboard");
    if ("dashboard.companyTab" in localStorage) {
      const companyTabCache = JSON.parse(
        localStorage.getItem("dashboard.companyTab")
      );
      setCompanyTab(companyTabCache);
    }
  }, []);
  //  personal tab changing
  useEffect(() => {
    localStorage.setItem("dashboard.companyTab", JSON.stringify(companyTab));
  }, [companyTab.cp]);

  const HeroSection = () => {
    let backgroundClass = "default";
    if (summary.profile_completed > 25 && summary.profile_completed < 100) {
      backgroundClass = "ongoing";
    } else if (summary.profile_completed == 100) {
      backgroundClass = "finished";
    }
    return (
      <>
        <div
          className={`${backgroundClass + " dashboard-hero rounded-lg mb-8"}`}
        >
          <div className="px-5 py-5 mdb:px-10 mdb:py-10">
            <h2 className="text-white md:text-[30px] font-bold leading-[150%] mb-3 text-lg">
              Welcome!
            </h2>
            <p className="text-white text-sm md:text-[16px] font-normal leading-[140%] mb-[2px]">
              Welcome to Highr ID Client Dashboard!
              <br />
              Complete your profile first to able to start assessing prospective
              employees in your company.
            </p>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    fetchSummaries();
  }, []);

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <h2 className="text-lg md:text-xl font-semibold leading-[150%] mb-6">
        Dashboard
      </h2>
      <HeroSection />
      <div className="summary-stats grid-cols-1 lg:grid-cols-3 grid gap-4 mb-6">
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div className="dashboard total-test h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
            <UserGroupIcon width={32} color={"#F5F5F5"} />
          </div>
          {/* <img src={dummy1} alt='dummy' className='mr-6' /> */}
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Total Candidate</p>
            <div className="flex">
              <p className="text-xl mr-1">{summary.total_candidates}</p>
              <small className="relative top-[12px]">
                {" "}
                {summary.total_candidates > 1 ? "candidates" : "candidate"}{" "}
              </small>
            </div>

            {/* <ArrowDownCircleIcon className='text-grey-600 w-[16px]' /> */}
          </div>
        </div>
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div className="dashboard finished-test h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
            <ClipboardDocumentListIcon width={32} color={"#F5F5F5"} />
          </div>
          {/* <img src={dummy1} alt='dummy' className='mr-6' /> */}
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Ongoing Test</p>
            <div className="flex">
              <p className="text-xl mr-1">{summary.total_unfinished_tests}</p>
              <small className="relative top-[12px]">
                from {summary.total_tests} tests
              </small>
            </div>

            {/* <ArrowDownCircleIcon className='text-grey-600 w-[16px]' /> */}
          </div>
        </div>
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div className="dashboard profile-percentage h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
            <AcademicCapIcon width={32} color={"#F5F5F5"} />
          </div>
          {/* <img src={dummy1} alt='dummy' className='mr-6' /> */}
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Finished Test</p>
            <div className="flex">
              <p className="text-xl mr-1">{summary.total_finished_tests}</p>
              <small className="relative top-[12px]">
                from {summary.total_tests} tests
              </small>
            </div>

            {/* <ArrowDownCircleIcon className='text-grey-600 w-[16px]' /> */}
          </div>
        </div>
      </div>
      {(positions.length <= 0 || companyTab.cp) && (
        <>
          <div className="summary-information grid-cols-1 md:grid-cols-2 grid gap-4 mb-6 bg-white p-4 rounded-lg">
            {user.base_role == "owner" ? (
              <>
                {companyTab.cp ? (
                  <>
                    <div className="card flex w-full bg-white px-4 py-4 rounded-[12px]">
                      <div className="flex flex-col justify-between w-full">
                        <div className="flex flex-col w-full mb-6">
                          <div className="flex justify-between items-center mb-4">
                            <p className="text-lg text-grey-600 font-bold mb-2 flex">
                              <BuildingOfficeIcon
                                width={25}
                                className="mr-2 text-orange-500"
                              />{" "}
                              Company Profile
                            </p>
                            <button
                              className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
                              onClick={() => {
                                setCompanyTab({ ...companyTab, cp: false });
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>

                          <p className="text-base text-grey-700 font-light mb-5">
                            {summary.profile_completed >= 100
                              ? "Your profile has been completed"
                              : "Complete your company profile"}{" "}
                          </p>
                          <small className="text-gray-500 text-end">
                            {summary.profile_completed}%
                          </small>
                          <div className="w-full h-[7px] bg-white-1 rounded-full mt-2">
                            <div
                              className="h-full text-center text-xs text-white bg-main-violate rounded-full"
                              style={{
                                width: `${summary.profile_completed}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                        {summary.profile_completed >= 100 ? (
                          ""
                        ) : (
                          <div className="flex justify-end w-2/2 items-center">
                            <button
                              className="order border-main-violate border w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:hover:bg-main-violate-100"
                              type=""
                              onClick={(e) =>
                                navigate("/client/company-profile")
                              }
                            >
                              Update Profile
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {positions.length > 0 ? (
              ""
            ) : (
              <>
                <div className="card flex w-full">
                  <div className="flex flex-col justify-between w-full">
                    <div className="flex flex-col w-full">
                      <p className="text-lg text-grey-600 font-bold mb-2 flex">
                        <BriefcaseIcon
                          width={25}
                          className="mr-2 text-orange-500"
                        />{" "}
                        Jobs
                      </p>
                      <p className="text-base text-grey-700 font-light mb-5">
                        Please make your jobs to do the assessment test
                      </p>
                    </div>
                    <div className="flex justify-end w-2/2 items-center">
                      {permission?.job_role?.includes("create") && (
                        <button
                          className="order border-main-violate border  px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate-100"
                          type=""
                          onClick={(e) => navigate("/client/jobs")}
                        >
                          Create Jobs
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}

      <div className="position-list">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">Job List</p>
              {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
            </div>
            <div className="flex justify-end w-2/2">
              {(permission?.job_role?.includes("create")) && (
                <button
                  className="flex items-center justify-center bg-main-violate px-3 py-2 text-[16px] leading-[22.4px] text-white font-semibold rounded-lg hover:bg-violet-600"
                  type=""
                  onClick={(e) => {
                    navigate("/client/jobs");
                  }}
                >
                  <PlusCircleIcon width={20} className="mr-2" /> Create Job
                </button>
              )}
            </div>
          </div>
          <JobList />
        </div>
      </div>
    </div>
  );
};

export default DashboardProfile;
