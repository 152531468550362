import React from "react";

const ProgressCircle = ({ value }) => {
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (value / 100) * circumference;

  return (
    <svg width="100" height="100">
      <circle
        stroke="#ECF0F3"
        fill="transparent"
        strokeWidth="8"
        r={radius}
        cx="50"
        cy="50"
        vectorEffect="non-scaling-stroke"
      />
      <circle
        stroke="#8F03ED"
        fill="transparent"
        strokeWidth="8"
        strokeDasharray={circumference + " " + circumference}
        style={{ strokeDashoffset: progress }}
        r={radius}
        cx="50"
        cy="50"
        vectorEffect="non-scaling-stroke"
      />
      <text x="50" y="50" textAnchor="middle" dy=".3em" fontSize="16px">
        {value}%
      </text>
    </svg>
  );
};

export default ProgressCircle;
