'use client';

import { useEffect, useState } from "react";
import { CheckIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import "./carousel.css"
import PageSpinner from "../../../../Component/PageSpinner";
import SelectTestCarousel from "../../../../Component/SelectTest/SelectTestCarousel";
import SelectTestGrid from "../../../../Component/SelectTest/SelectTestGrid";
import FilterTable from "../../../../Component/Tables/FilterTable";
const SelectTest = ({ tests, onSelect, onRemove, selected, filterAssessment = [], quotaTest = null }) => {
    const [seeAll, setSeeAll] = useState(false);
    const [selectedFilterAssessment, setSelectedFilterAssessment] = useState(null);
    const [seeAllRecomendation, setSeeAllRecomendation] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleFilterAllTest = (id) => {
        setSelectedFilterAssessment(id);
    }

    useEffect(() => {
        
    }, [quotaTest])

    // useEffect(() => {

    // },[tes])
    return (
        <>
            <div className="card mb-7 flex items-center space-x-3">
                <div className="bg-main-violate-100 rounded-lg w-[35px] p-1">
                    <CheckIcon className="w-full text-main-violate" />
                </div>
                <p className="text-gray-500">You can only select up to {quotaTest != null ? quotaTest : 'unlimited'} test. Browse the test library and add the most relevant tests.</p>
            </div>

            <div className="card mb-10">
                <div className="flex justify-between">
                    <h2 className='text-xl font-semibold leading-[150%] mb-6'>Selected Test</h2>
                    <button
                        className="flex items-center gap-2 border border-main-violate  px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[36px] rounded-lg"
                        type=""
                        onClick={(e) => setSeeAllRecomendation(!seeAllRecomendation)}
                    >
                        See All <ChevronRightIcon className="w-[16px]" />
                    </button>
                </div>
                {/* <JustTest /> */}
                {tests.find(test => test.selected == true) ? <>
                    {seeAllRecomendation ? <>
                        {<SelectTestGrid data={tests.filter(test => test.selected == true)} onSelect={onSelect} onRemove={onRemove} inSelected={true}  />}
                    </> : <>
                        {loading ? <PageSpinner /> : <SelectTestCarousel data={tests.filter(test => test.selected == true)} onSelect={onSelect} onRemove={onRemove} inSelected={true} />}
                    </>}
                </> : <>
                    <div className="flex justify-center">
                        <p className="text-gray-500">You have not selected any test yet.</p>
                    </div>
                </>}

            </div>
            <div className="card mb-10">
                <div className="flex justify-between">
                    <h2 className='text-xl font-semibold leading-[150%] mb-6'>Recommendation</h2>
                    <button
                        className="flex items-center gap-2 border border-main-violate  px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[36px] rounded-lg"
                        type=""
                        onClick={(e) => setSeeAllRecomendation(!seeAllRecomendation)}
                    >
                        See All <ChevronRightIcon className="w-[16px]" />
                    </button>
                </div>
                {/* <JustTest /> */}

                {seeAllRecomendation ? <>
                    {<SelectTestGrid data={tests.filter(test => test.tags.includes("recomendation"))} onSelect={onSelect} onRemove={onRemove}  />}
                </> : <>
                    {loading ? <PageSpinner /> : <SelectTestCarousel data={tests.filter(test => test.tags.includes("recomendation"))} onSelect={onSelect} onRemove={onRemove} />}
                </>}
            </div>
            <div className="card mb-10">
                <div className="flex justify-between">
                    <h2 className='text-xl font-semibold leading-[150%] mb-4'>All Tests</h2>
                    <button
                        className="flex items-center gap-2 border border-main-violate  px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[36px] rounded-lg"
                        type=""
                        onClick={(e) => setSeeAll(!seeAll)}
                    >
                        See All <ChevronRightIcon className="w-[16px]" />
                    </button>
                </div>
                {/* <JustTest /> */}
                <div className="px-2 mb-6 flex w-[85%] md:w-[70%] flex-wrap">
                    <FilterTable placeholder={"Select Category"} options={filterAssessment} selected={selectedFilterAssessment} onChange={(e) => { handleFilterAllTest(e) }} />
                    <a href="#" onClick={(e) => { e.preventDefault(); setSelectedFilterAssessment("") }} className="flex text-sm items-center text-gray-500 font-medium hover:text-main-violate-hover mb-1">Clear</a>
                </div>

                {seeAll ? <>
                    {<SelectTestGrid data={selectedFilterAssessment ? tests.filter((test) => test.assessment.id == selectedFilterAssessment) : tests} onSelect={onSelect} onRemove={onRemove} />}
                </> : <>
                    {loading ? <PageSpinner /> : <SelectTestCarousel data={selectedFilterAssessment ? tests.filter((test) => test.assessment.id == selectedFilterAssessment) : tests} onSelect={onSelect} onRemove={onRemove} />}
                </>}
            </div>
            {/* <div className="mb-4">
                <div className="flex justify-between">
                    <h2 className='text-xl font-semibold leading-[150%] mb-6'>Other tests</h2>
                    <button
                        className="flex items-center gap-2 border border-main-violate  px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[36px] rounded-lg"
                        type=""
                    >
                        See All <ChevronRightIcon className="w-[16px]" />
                    </button>
                </div>
            </div> */}
        </>
    );
}

export default SelectTest;