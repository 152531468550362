import { WrenchScrewdriverIcon } from "@heroicons/react/24/solid";
import React, { Component } from "react";

export class UnderDevelopment extends Component {
    
    render() {
        const {text, width} = this.props;
        return (
            <div className='w-full flex flex-col items-center py-5'>
                <WrenchScrewdriverIcon width={width ? width : 150} className='text-grey-400 mb-8' />
                <p className='text-,d text-grey-400 font-medium '>
                    {text ? text : "This Page Under Development"}
                </p>
            </div>
        )
    }

}