import React from "react";
import { useState, useEffect } from "react";
import PersonalSection from "./Sections/PersonalSection";
import axios from "axios";
import { useParams } from "react-router-dom";
import AboutSection from "./Sections/AboutSection";
import ResumeSection from "./Sections/ResumeSection";
import SocialNetworkSection from "./Sections/SocialNetworkSection";
import SkillSection from "./Sections/SkillSection";
import WorkExperienceSection from "./Sections/WorkExperienceSection";
import CertificationAndTrainingSection from "./Sections/CertificationAndTrainingSection";
import LanguageSection from "./Sections/LanguageSection";
import EducationalSection from "./Sections/EducationalSection";
import AchievementSection from "./Sections/AchievementSection";
import { getDetailData } from "../../../../../api";
const Profile = ({ data }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [resumeLoading, setResumeLoading] = useState(false);
    const [socialLoading, setSocialLoading] = useState(false);
    const [skillLoading, setSkillLoading] = useState(false);
    const [cerficationLoading, setCertificationLoading] = useState(false);
    const [workExperienceLoading, setWorkExperienceLoading] = useState(false);
    const [languageLoading, setLanguageLoading] = useState(false);
    const [achievementLoading, setAchievementLoading] = useState(false);
    const [educationLoading, setEducationLoading] = useState(false);
    const [resumes, setResumes] = useState([]);
    const [socials, setSocials] = useState([]);
    const [skills, setSkills] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [workExperiences, setWorkExperiences] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [educations, setEducations] = useState([]);
    const [achievements, setAchievements] = useState([]);
    const [account, setAccount] = useState({
        email: "",
        name: "",
        role: "",
        username: "male",
    });
    const [personal, setPersonal] = useState({
        fullname: "",
        email: "",
        phonenumber: "",
        gender: "male",
        marital: "married",
        date_of_birth: "",
        address: "",
        country: "",
        zip_code: "",
        province: "",
        city: "",
        avatar: "",
        avatar_url: "",
        resume_file: "",
        about: "",
    });

    const getPersonal = async () => {
        setLoading(true);
        const result = await getDetailData(id, "personal");
        if (result.success) {
            if (result.data != null) {
                setAccount(result.data.account);
                setPersonal(result.data.personal);
            }
        }
        setLoading(false);
    };
    const getResumes = async () => {
        setResumeLoading(true);
        const result = await getDetailData(id, "resume");
        if (result.success) {
            setResumes(result.data);
        }
        setResumeLoading(false);

    };
    const getSkills = async () => {
        setSkillLoading(true);
        const result = await getDetailData(id, "skills");
        if (result.success) {
            setSkills(result.data);
        }
        setSkillLoading(false);
    };
    const getSocials = async () => {
        setSocialLoading(true);
        const result = await getDetailData(id, "social-networks");
        if (result.success) {
            if (result.data != null) {
                setSocials(result.data);
            }
        }
        setSocialLoading(false);
    };
    const getWorkExperiences = async () => {
        setWorkExperienceLoading(true);
        const result = await getDetailData(id, "work-experiences");
        if (result.success) {
            if (result.data != null) {
                setWorkExperiences(result.data);
            }
        }
        setWorkExperienceLoading(false);
    };
    const getCertifications = async () => {
        setCertificationLoading(true);
        const result = await getDetailData(id, "certifications");
        if (result.success) {
            if (result.data != null) {
                setCertifications(result.data);
            }
        }
        setCertificationLoading(false);
    };
    const getLanguages = async () => {
        setLanguageLoading(true);
        const result = await getDetailData(id, "languages");
        if (result.success) {
            if (result.data != null) {
                setLanguages(result.data);
            }
        }
        setLanguageLoading(false);
    };
    const getEducations = async () => {
        setEducationLoading(true);
        const result = await getDetailData(id, "educations");
        if (result.success) {
            if (result.data != null) {
                setEducations(result.data);
            }
        }
        setEducationLoading(false);
    };
    const getAchievements = async () => {
        setAchievementLoading(true);
        const result = await getDetailData(id, "achievements");
        if (result.success) {
            if (result.data != null) {
                setAchievements(result.data);
            }
        }
        setAchievementLoading(false);
    };
    useEffect(() => {
        getPersonal();
        getResumes();
        getSocials();
        getSkills();
        getWorkExperiences();
        getCertifications();
        getLanguages();
        getEducations();
        getAchievements();
    }, [])

    return (
        <>
            <div className="px-4">
                <p className="text-gray-900 text-lg font-semibold mb-2">Full Profile</p>
                <div className=" justify-center px-2 md:px-0">
                    <PersonalSection personalLoading={loading} personal={personal} account={account} />
                    <AboutSection about={personal.about} loading={loading} />
                    <ResumeSection loading={resumeLoading} resumes={resumes} />
                    <SocialNetworkSection loading={socialLoading} socials={socials} />
                    <SkillSection loading={skillLoading} skill={skills} />
                    <WorkExperienceSection loading={workExperienceLoading} experiences={workExperiences} />
                    <CertificationAndTrainingSection certifications={certifications} loading={cerficationLoading} />
                    <LanguageSection languages={languages} loading={languageLoading} />
                    <EducationalSection educationals={educations} loading={educationLoading} />
                    <AchievementSection achievements={achievements} loading={achievementLoading} />
                </div>
            </div>
        </>
    )
}

export default Profile;