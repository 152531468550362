import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  InformationCircleIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
import PaginationTable from "../../../Component/Tables/PaginationTable";
import TableSpinner from "../../../Component/TableSpinner";
import FilterTable from "../../../Component/Tables/FilterTable";
import EmptyContent from "../../../Component/Tables/EmptyContent";
import { Dropdown } from "flowbite-react";
import DropdownDots from "../../../Component/Tables/DropdownDots";
import { getInterviewSession, getPositions, updatePause } from "../../../api";
import { useDispatch } from "react-redux";
import { setInterviewSessions } from "../../../features/interviews/interviewSlice";
import { useSelector } from "react-redux";
import SearchTable from "../../../Component/Tables/SearchTable";
import MiniForbidden from "../../MiniForbidden";
import { ClipboardDocumentIcon } from "@heroicons/react/20/solid";
const InterviewList = () => {
  let navigate = useNavigate();
  const permission = useSelector((state) => state.user.permissions);
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  const {
    data: positions,
    itemsPerPage,
    itemsFrom,
    itemsTo,
    totalItems,
    filterDepartments,
    filterPositions,
    currentPage,
    loaded,
  } = useSelector((state) => state.interviews.interviewsTable);
  const [tableLoading, setTableLoading] = useState(1);
  const [selectedFilterDepartment, setSelectedFilterDepartment] =
    useState(null);
  const [selectedFilterPosition, setSelectedFilterPosition] = useState(null);
  const [query, setQuery] = useState(null);
  const dispatch = useDispatch();

  const fetchPositions = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getInterviewSession(queryString);
    if (result.success) {
      dispatch(setInterviewSessions(result));
      setTableLoading(false);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDetail = (id) => {
    navigate(`/client/interviews/detail/${id}`);
  };

  useEffect(() => {
    fetchPositions();
  }, [query]);

  const handleFilterPosition = (id) => {
    setQuery({ ...query, position: id });
    setSelectedFilterPosition(id);
  };

  const handleFilterDepartment = (id) => {
    setQuery({ ...query, department: id });
    setSelectedFilterDepartment(id);
    setSelectedFilterPosition("");
  };

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSelectedFilterDepartment("");
    setSelectedFilterPosition("");
    setQuery({ ...query, position: "", department: "" });
  };

  const handleAddBatch = async (id) => {
    alert("On Progress")
    // setTableLoading(true);
    // const result = await updatePause(id);
    // if (result.success) {
    //   fetchPositions();
    // }
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {positions.length > 0 ? (
            positions.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px] hover:bg-line-grey cursor-pointer" onClick={() => handleDetail(value.id)}>
                  {/* <td className="px-6 py-4 text-sm text-gray-500">
                    <input
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                      type="checkbox"
                    />
                  </td> */}
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {itemsPerPage * (currentPage - 1) + index + 1}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{value.name}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {value.company_job_position.job_role}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {moment(value.start_date)
                      .locale("id")
                      .format("dddd, DD MMMM YYYY")}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {moment(value.end_date)
                      .locale("id")
                      .format("dddd, DD MMMM YYYY")}
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{value.batch}</div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <div className="text-sm text-gray-900">
                      {value.host_email}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.total_invitees}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.total_schedules}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {moment(value.created_at).format("DD MMM YYYY, HH:mm")}
                  </td>
                  {/* <td className="px-6 py-4 text-sm text-gray-900">
                    <StatusLabel
                      status={value.is_publish}
                      label={value.is_publish_label}
                    />
                  </td> */}
                  {/* <td className="px-6 py-4">
                    <DropdownDots isPublish={value.is_publish}>
                      <Dropdown.Item onClick={(e) => handleDetail(value.id)}>
                        <InformationCircleIcon width={16} className="mr-2" />
                        Detail
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => handlePause(value.id)}>
                        {value.is_publish ? (
                          <>
                            <PauseCircleIcon width={16} className="mr-2" />{" "}
                            Pause
                          </>
                        ) : (
                          <>
                            <PlayCircleIcon width={16} className="mr-2" />{" "}
                            Resume
                          </>
                        )}
                      </Dropdown.Item>
                    </DropdownDots>
                  </td> */}
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  const StickyContent = () => {
    if (!tableLoading) {
      return (
        <>
          {positions.length > 0 ? (
            <>
              {positions.map((value) => {
                return (
                  <tr className="whitespace-nowrap border-b-[1px]">
                    <td className="px-6 py-[16.5px]">
                      <DropdownDots isPublish={value.is_publish}>
                        <Dropdown.Item onClick={(e) => handleDetail(value.id)}>
                          <InformationCircleIcon width={16} className="mr-2" />
                          Detail
                        </Dropdown.Item>
                        {permission?.job_role?.includes("update") && (
                          <>
                            <Dropdown.Item
                              onClick={(e) => handleAddBatch(value.id)}
                            >
                              <ClipboardDocumentIcon
                                width={16}
                                className="mr-2"
                              />{" "}
                              Add Batch
                            </Dropdown.Item>
                          </>
                        )}
                      </DropdownDots>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <ToastContainer />
      <div className="border-b border-gray-200 rounded-lg shadow">
        {!permission?.job_role?.includes("read") && <MiniForbidden />}
        {permission?.job_role?.includes("read") && (
          <PaginationTable
            checkbox={false}
            onClearFilter={(e) => handleClearFilter(e)}
            search={
              <SearchTable
                onSearch={(keyword) => handleSearch(keyword)}
                placeholder={"Search Interview Session"}
              />
            }
            isSticky={true}
            stickyHeader={["ACTION"]}
            stickyContent={<StickyContent />}
            filters={[
              <FilterTable
                placeholder={"Select Department"}
                options={filterDepartments}
                onChange={(value) => handleFilterDepartment(value)}
                selected={selectedFilterDepartment}
              />,
              <FilterTable
                selected={selectedFilterPosition}
                placeholder={"Select Position"}
                options={filterPositions.filter(
                  (position) =>
                    position.job_department_id == selectedFilterDepartment
                )}
                onChange={(value) => handleFilterPosition(value)}
              />,
            ]}
            currentPage={handlePaginate}
            itemsTo={itemsTo}
            itemsFrom={itemsFrom}
            itemsPerPage={5}
            totalItems={totalItems}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            headers={[
              "NO",
              "NAME",
              "JOB ROLE",
              "START DATE",
              "END DATE",
              "BATCH",
              "HOST",
              "TOTAL INVITEES",
              "TOTAL SCHDULES",
              "CREATED",
              // "STATUS",
            ]}
            content={<TableContent />}
          />
        )}
      </div>
    </>
  );
};

export default InterviewList;
