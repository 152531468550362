import React from 'react'
import { Clients } from '../Component/Errors/Clients';

const NotFoundPage = () => {
  return (
    <div className='w-full h-[100vh] flex items-center'>
      <Clients />
    </div>
    
  )
}

export default NotFoundPage;