// jobSlice.js
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    summary: {
        total_candidates: 0,
        total_unfinished_tests: 0,
        total_finished_tests: 0,
        total_tests: 0,
        profile_completed: 0
    }
};

const dashboardsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        setSummary: (state, action) => {
            state.summary = action.payload.data;
        },

    }
});

export const { setSummary } = dashboardsSlice.actions;
export default dashboardsSlice.reducer;