import React, { useEffect, useState } from "react";
import { ExclamationTriangleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import BtnSpinner from "../../../../Component/BtnSpinner";
function CreateJobTokenPopup({ onClose, onSend, validating, currentSerial }) {
    const [serial, setSerial] = useState('');

    const handleSend = () => {
        if (typeof onSend === "function") {
            onSend(serial);
        }
    };

    useEffect(() => {
        if (currentSerial) {
            setSerial(currentSerial)
        }
    }, [currentSerial])
    return (
        // Your alert popup component JSX
        <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate  py-2 flex flex-col px-4 py-4">
            <div className="flex justify-end">
                <button
                    className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
                    onClick={() => {
                        onClose()
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div className="flex flex-col items-center justify-evenly h-full w-full" >
                <div className="text w-full">
                    <p className="font-bold text-center text-md mb-3">
                        Please Input Your Subscription Code
                    </p>
                    <div className="mb-2">
                        <input
                            className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                            type="text"
                            name="title"
                            id="title"
                            placeholder="JB-XXXX-XXXX-XXXX"
                            value={serial}
                            onChange={(e) => setSerial(e.target.value.toUpperCase())}
                            required
                        />
                    </div>
                </div>
                <div className="flex mt-5 justify-end w-[100%] gap-4">
                    <button
                        className="hover:bg-violet-600 cursor-pointer bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                        onClick={(e) => handleSend()}
                        disabled={validating || serial == ''}
                    >
                        {validating ? <BtnSpinner /> : 'Apply'}

                    </button>
                </div>
            </div>
        </div>

    );
}

export default CreateJobTokenPopup;
