import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UnderDevelopment } from "../../../../Component/Errors/UnderDevelopment";
import {
  BuildingOffice2Icon,
  InformationCircleIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon,
  CubeIcon,
  ChartPieIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import PaginationTable from "../../../../Component/Tables/PaginationTable";
import SearchTable from "../../../../Component/Tables/SearchTable";
import FilterTable from "../../../../Component/Tables/FilterTable";
import TableSpinner from "../../../../Component/TableSpinner";
import EmptyContent from "../../../../Component/Tables/EmptyContent";
import { documentTitle } from "../../../../helpers";
import { getMyClient } from "../../../../api/admin";
import DropdownDots from "../../../../Component/Tables/DropdownDots";
import { Avatar, Dropdown } from "flowbite-react";
import { getCompanies } from "../../../../api/admin/companyApi";
import { getReportCompany } from "../../../../api/admin/reportsApi";
import BtnSpinner from "../../../../Component/BtnSpinner";
import JobRoleList from "./Components/JobRoleList";
import SubscriptionList from "./Components/SubscriptionList";
import TransactionList from "./Components/TransactionList";

const DetailReportPage = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [query, setQuery] = useState({});
  const [company, setCompany] = useState({});
  const [companies, setCompanies] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);

  const [summaryLoading, setSummaryLoading] = useState(true);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const fetchCompany = async () => {
    setSummaryLoading(true);
    const result = await getReportCompany(id);
    if (result.success) {
      setCompany(result.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSummaryLoading(false);
  };

  useEffect(() => {
    documentTitle("Users Management");
  }, []);

  useEffect(() => {
    fetchCompany();
  }, [query]);

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {companies.length > 0 ? (
            companies.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.email}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.active_packages?.length}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.total_candidates}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.total_user}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <StatusLabel
                      status={value.active}
                      label={value.active ? "Active Hiring" : "Not Hiring"}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <DropdownDots isPublish={value.is_publish}>
                      <Dropdown.Item
                        onClick={(e) => {
                          navigate(`/administrator/report/clients/${value.id}`);
                        }}
                      >
                        <InformationCircleIcon width={16} className="mr-2" />
                        Detail
                      </Dropdown.Item>
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          {company?.image == null && (
            <ChartPieIcon className="w-[30px] text-main-violate" />
          )}
          {company?.image != null && <Avatar img={company?.image} />}
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">{company?.name}</p>
          <BreadCrumb list={[{ href: null, title: "Report Clients" }]} />
        </div>
      </div>
      <div className="summary-stats grid-cols-1 lg:grid-cols-3 grid gap-4 mb-6">
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div className="dashboard total-test h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
            <CubeIcon width={32} color={"#F5F5F5"} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Active Packages</p>
            <div className="flex">
              {summaryLoading && <BtnSpinner />}
              {!summaryLoading && (
                <>
                  <p className="text-xl mr-1">
                    {company?.active_packages?.length}
                  </p>
                  <small className="relative top-[12px]">
                    from {company.total_packages} packages
                  </small>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div className="dashboard finished-test h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
            <UserIcon width={32} color={"#F5F5F5"} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Active Users</p>
            <div className="flex">
              {summaryLoading && <BtnSpinner />}
              {!summaryLoading && (
                <>
                  <p className="text-xl mr-1">{company.total_user}</p>
                  <small className="relative top-[12px]">
                    from {company.total_user} users
                  </small>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div className="dashboard profile-percentage h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
            <ClipboardDocumentListIcon width={32} color={"#F5F5F5"} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Active Job Role</p>
            <div className="flex">
              {summaryLoading && <BtnSpinner />}
              {!summaryLoading && (
                <>
                  <p className="text-xl mr-1">
                    {company.total_active_job_role}
                  </p>
                  <small className="relative top-[12px]">
                    from {company.total_job_role} job roles
                  </small>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card flex flex-col w-full mb-6">
        <div className="flex justify-between w-full mb-8">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">
              Job Roles List
            </p>
          </div>
          <div className="flex justify-end w-2/2"></div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <JobRoleList company_id={id} />
        </div>
      </div>
      <div className="card flex flex-col w-full mb-6">
        <div className="flex justify-between w-full mb-8">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">
              Packages List
            </p>
          </div>
          <div className="flex justify-end w-2/2"></div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <SubscriptionList company_id={id} />
        </div>
      </div>
      <div className="card flex flex-col w-full">
        <div className="flex justify-between w-full mb-8">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">
              Transaction List
            </p>
          </div>
          <div className="flex justify-end w-2/2"></div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <TransactionList company_id={id} />
        </div>
      </div>
    </div>
  );
};

export default DetailReportPage;
