import axios from 'axios';
import moment from 'moment';

export const getReportsCandidate = async (job_id, page, limit = 5) => {
    const response = await axios
        .get(`/api/client/reports?job_id=${job_id}&page=${page}&limit=${limit}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    return response.data
}
export const reportsExport = async (company_job_id, { type = 'excel', candidate_job_ids = [] }) => {
    try {
        const response = await axios
            .post(`/api/client/reports/${company_job_id}/export`, {
                type,
                candidate_job_ids
            }, {
                responseType: 'blob',
            })

        const blob = new Blob([response.data]);
        const blobUrl = URL.createObjectURL(blob);
        // Create a link element and simulate a click to trigger download
        const link = document.createElement('a');
        const date = moment().format('YYYYMMDDHHmmss');
        link.href = blobUrl;
        link.download = `candidate_reports_${date}.xlsx`;
        link.click();

        // Clean up the Blob URL
        URL.revokeObjectURL(blobUrl);
        return true;
    } catch (error) {
        return false;
    }
}

export const unlockCandidateReport = async (candidate_job_id = '') => {
    try {
        const response = await axios
            .post(`/api/client/candidate-jobs/report/unlock`, {
                candidate_job_id
            }, {
                headers: {
                    "Content-Type": "application/json"
                },
            })

        return response.data;
    } catch (error) {
        return false;
    }
}

export const getCognitiveReports = async (candidate_id, candidate_job_id) => {
    const response = await axios
        .get(`/api/client/candidate/${candidate_id}/${candidate_job_id}/reports/cognitive`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    return response.data
}

export const getSoftSkillReports = async (candidate_id, candidate_job_id) => {
    const response = await axios
        .get(`/api/client/candidate/${candidate_id}/${candidate_job_id}/reports/soft-skill`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    return response.data
}