import { React, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { NewspaperIcon } from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import { documentTitle } from "../../../../helpers";
import Select from "react-select";
import {
  createArticleTopic,
  getArticleCategory,
  getArticleContent,
  updateArticleTopic,
} from "../../../../api/admin";
import BtnSpinner from "../../../../Component/BtnSpinner";

const BlogTopicCreatePage = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const [article, setArticle] = useState({
    name: "",
    lang: "",
    description: "",
  });

  const languagesData = [
    { value: "en", label: "English" },
    { value: "id", label: "Indonesia" },
  ];

  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const fetchArticleContent = async () => {
    const result = await getArticleCategory(id);
    if (result.success) {
      let data = result.data;
      data.tags = data?.tags?.join(",");
      setArticle(result.data);
      setSelectedLanguage(languagesData.find((x) => x.value === data.lang));
    }
  };

  useEffect(() => {
    documentTitle("Blog Management - Topic Create");
    if (id) {
      fetchArticleContent();
    }
  }, []);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    let result = null;
    if (id) {
      result = await updateArticleTopic(id, article);
    } else {
      result = await createArticleTopic(article);
    }
    if (result.success) {
      // resetArticle();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        onClose: () =>
          navigate("/administrator/blog/topics", { replace: false }),
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <NewspaperIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Create Content Blog</p>
          <BreadCrumb
            list={[
              { href: "/administrator/blog/topics", title: "Blog Topic" },
              { href: null, title: "Create" },
            ]}
          />
          {/* <p className='text-base text-grey-400 font-medium '>List of positions that have been entered</p> */}
        </div>
      </div>

      <div className="card flex flex-col w-full mdb:w-[50%]">
        <div className="flex justify-between w-full mb-6">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">Topic Form</p>
            {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
          </div>
        </div>
        <div className="w-full flex space-x-4 gap-4">
          <div className="w-full flex flex-col space-y-4">
            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Name
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                value={article.name}
                onChange={(e) => {
                  setArticle({
                    ...article,
                    name: e.target.value,
                  });
                }}
                required
              />
            </div>
            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Language
              </label>
              <div className="py-0.5 pl-2 mt-2 border border-line-grey rounded-lg flex items-center">
                <Select
                  className="w-full "
                  classNamePrefix={"select"}
                  options={languagesData}
                  value={selectedLanguage}
                  onChange={(e) => {
                    setSelectedLanguage(e);
                    setArticle({
                      ...article,
                      lang: e.value,
                    });
                  }}
                  placeholder={"Select Language"}
                />
              </div>
            </div>

            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Description
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="description"
                id="description"
                placeholder="Description"
                value={article.description}
                onChange={(e) => {
                  setArticle({
                    ...article,
                    description: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>
        </div>

        <hr className="mt-12 mb-2" />
        <div className="flex justify-end mt-3">
          {submitting ? (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              type=""
              disabled={true}
            >
              <BtnSpinner />
            </button>
          ) : (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              onClick={(e) => handleSubmit(e)}
              disabled={
                submitting ||
                article.name == "" ||
                article.lang == "" ||
                article.description == ""
              }
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogTopicCreatePage;
