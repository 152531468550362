export const CH_ADMIN = {
  inquiry_landing_page: {
    url: "/administrator/inquiries/web",
  },
};

export const CH_CLIENT = {
  cl_candidate_job: {
    url: "/client/my-candidates",
  },
};
