import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InformationCircleIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import PaginationTable from "../../../Component/Tables/PaginationTable";
import SearchTable from "../../../Component/Tables/SearchTable";
import FilterTable from "../../../Component/Tables/FilterTable";
import TableSpinner from "../../../Component/TableSpinner";
import EmptyContent from "../../../Component/Tables/EmptyContent";
import { documentTitle, truncateText } from "../../../helpers";
import { getLandingInquries } from "../../../api/admin";
import DropdownDots from "../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import BreadCrumb from "../../../Component/BreadCrumb";

const LandingInquiryPage = () => {
  let navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [inquiries, setInquiries] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const fetchInquiries = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getLandingInquries(queryString);
    if (result.success) {
      setInquiries(result.data.data);
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setItemsPerPage(result.data.per_page);
      setTableLoading(false);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-blue-100 text-blue-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 2) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };

  useEffect(() => {
    documentTitle("Web Inquiries");
  }, []);
  useEffect(() => {
    fetchInquiries();
  }, [query]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {inquiries.length > 0 ? (
            inquiries.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.ticket}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.fullname}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.company_name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value.email}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {truncateText(value.subject, 6)}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <StatusLabel
                      status={value.status}
                      label={value.status_label}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <DropdownDots>
                      <Dropdown.Item onClick={(e) => {
                        navigate(`/administrator/inquiries/web/${value.id}`);
                      }}>
                        <InformationCircleIcon width={16} className="mr-2" />
                        Detail
                      </Dropdown.Item>
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <EnvelopeIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Landing Inquiry</p>
          <BreadCrumb list={[{ href: null, title: "Landing Inquiry" }]} />
        </div>
      </div>
      <div className="card flex flex-col w-full">
        <div className="flex justify-between w-full mb-4">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">Inquiry List</p>
          </div>
          <div className="flex justify-end w-2/2"></div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <PaginationTable
            search={
              <SearchTable
                onSearch={(keyword) => handleSearch(keyword)}
                placeholder={"Search ticket or company"}
              />
            }
            currentPage={handlePaginate}
            itemsTo={itemsTo}
            itemsFrom={itemsFrom}
            itemsPerPage={5}
            checkbox={false}
            totalItems={totalItems}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            headers={[
              "NO.",
              "TICKET",
              "NAME",
              "COMPANY",
              "EMAIL",
              "SUBJECT",
              "STATUS",
              "ACTION",
            ]}
            content={<TableContent />}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingInquiryPage;
