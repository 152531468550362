import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Sidebar } from "flowbite-react";
import {
  ChartPieIcon,
  ClipboardDocumentListIcon,
  Cog6ToothIcon,
  CubeIcon,
  Squares2X2Icon,
  ShoppingBagIcon,
  EnvelopeIcon,
  NewspaperIcon,
} from "@heroicons/react/24/solid";
import NavbarAdmin from "../Component/NavbarAdmin";
import { fetchNotifications } from "../features/notification/notificationSlice";
import { useDispatch, useSelector } from "react-redux";

const AdminMainLayout = () => {
  const dispatch = useDispatch();
  const { data: notifications, loaded } = useSelector(
    (state) => state.notification
  );

  const navigate = useNavigate();
  const sidebar_reports = useRef(null);
  const sidebar_packages = useRef(null);
  const sidebar_inquiries = useRef(null);
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || { base_role: null };
  });
  // const userData = JSON.parse(localStorage.getItem("user"));
  const [openSidebar, setOpenSidebar] = useState(true);

  const fetchMyPermission = async () => {
    // const result = await getMyPermission();
    // console.log(result)
  };

  useEffect(() => {
    if (!user.base_role) {
      navigate("/administrator/login", { replace: true });
    } else {
      dispatch(fetchNotifications());
    }
  }, []);

  const signOut = () => {
    localStorage.removeItem("user");
    window.location.replace("/administrator/login");
  };
  return (
    <>
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>    
          </li>      
          <li>
            <Link to={ userData === null ? "/login" : "profile" }>{ userData === null ? "Log In" : "Profile" }</Link>    
          </li>    
          <li>
            <Link to="/signup">Sign Up</Link>
          </li>
        </ul>
      </nav> */}
      {/* <nav class="bg-white shadow-lg w-[100%]">
          <div class="flex justify-start px-2 h-[80px]">
            <a href="#" class="flex items-center py-3 px-2">
              <img src={logo} alt="Logo" class="h-[80px] w-[80px] mr-2 mb-3"/>
            </a>
          </div>
		  </nav> */}
      <NavbarAdmin
        openSidebar={(e) => {
          setOpenSidebar(!openSidebar);
        }}
        notifications={notifications}
        loaded={loaded}
      />
      <Sidebar
        aria-label="Sidebar with multi-level dropdown"
        className="w-full sidebar"
      >
        <Sidebar.Items className="w-full md:flex">
          <div
            className={`${
              (openSidebar ? " translate-x-[-100%] " : " translate-x-[0%] ") +
              "mdb:mt-[70px] mdb:translate-x-[0%] w-[75%] sm:w-[50%] lg:w-[18%] mdb:w-[25%] lg:max-w-xs py-4 px-2 fixed mdb:relative  bg-white z-40 h-[93%] duration-150 ease-out lg:flex-1 flex flex-col"
            }`}
          >
            <Sidebar.ItemGroup>
              <p className="text-sm text-grey-400 font-semibold px-4">
                Main Menu
              </p>

              <Sidebar.Item
                className={`${
                  (window.location.pathname.startsWith(
                    "/administrator/dashboard"
                  )
                    ? " active "
                    : "") + "sidebar-item hover:cursor-pointer px-4"
                }`}
                onClick={(e) => {
                  navigate("/administrator/dashboard");
                }}
                icon={Squares2X2Icon}
                active={window.location.pathname.startsWith(
                  "/administrator/dashboard"
                )}
              >
                <p className="text-grey-500">Dashboard</p>
              </Sidebar.Item>
              <Sidebar.Item
                className={`${
                  (window.location.pathname.startsWith(
                    "/administrator/assessments"
                  )
                    ? " active "
                    : "") + "sidebar-item hover:cursor-pointer px-4"
                }`}
                onClick={(e) => {
                  navigate("/administrator/assessments");
                }}
                icon={ClipboardDocumentListIcon}
                active={window.location.pathname.startsWith(
                  "/administrator/assessments"
                )}
              >
                <p className="text-grey-500">Assessment</p>
              </Sidebar.Item>
              <Sidebar.Collapse
                icon={ChartPieIcon}
                label="Reports"
                className="sidebar-packages hover:cursor-pointer px-4"
                ref={sidebar_reports}
              >
                <Sidebar.Item
                  className={`${
                    (window.location.pathname == "/administrator/report/clients"
                      ? " active "
                      : "") + "sidebar-item hover:cursor-pointer px-4"
                  }`}
                  onClick={(e) => {
                    navigate("/administrator/report/clients");
                  }}
                  active={
                    window.location.pathname == "/administrator/report/clients"
                  }
                >
                  <p className="text-grey-500">Clients</p>
                </Sidebar.Item>
                <Sidebar.Item
                  className={`${
                    (window.location.pathname ==
                    "/administrator/report/candidates"
                      ? " active "
                      : "") + "sidebar-item hover:cursor-pointer px-4"
                  }`}
                  onClick={(e) => {
                    navigate("/administrator/report/candidates");
                  }}
                  active={
                    window.location.pathname ==
                    "/administrator/report/candidates"
                  }
                >
                  <p className="text-grey-500">Candidates</p>
                </Sidebar.Item>
              </Sidebar.Collapse>
              <Sidebar.Collapse
                icon={ShoppingBagIcon}
                label="Client"
                className="sidebar-packages hover:cursor-pointer px-4"
                ref={sidebar_reports}
              >
                <Sidebar.Item
                  className={`${
                    (window.location.pathname.startsWith(
                      "/administrator/client/users"
                    )
                      ? " active "
                      : "") + "sidebar-item hover:cursor-pointer px-4"
                  }`}
                  onClick={(e) => {
                    navigate("/administrator/client/users");
                  }}
                  active={window.location.pathname.startsWith(
                    "/administrator/client/users"
                  )}
                >
                  <p className="text-grey-500">Manage Client</p>
                </Sidebar.Item>
                <Sidebar.Item
                  className={`${
                    (window.location.pathname.startsWith(
                      "/administrator/client/role-access"
                    )
                      ? " active "
                      : "") + "sidebar-item hover:cursor-pointer px-4"
                  }`}
                  onClick={(e) => {
                    navigate("/administrator/client/role-access");
                  }}
                  active={window.location.pathname.startsWith(
                    "/administrator/client/role-access"
                  )}
                >
                  <p className="text-grey-500">Manage Access</p>
                </Sidebar.Item>
              </Sidebar.Collapse>
              <Sidebar.Collapse
                icon={CubeIcon}
                label="Packages"
                className="sidebar-packages hover:cursor-pointer px-4"
                ref={sidebar_packages}
              >
                <Sidebar.Item
                  className={`${
                    (window.location.pathname.startsWith(
                      "/administrator/packages"
                    )
                      ? " active "
                      : "") + "sidebar-item hover:cursor-pointer px-4"
                  }`}
                  onClick={(e) => {
                    navigate("/administrator/packages");
                  }}
                  active={window.location.pathname.startsWith(
                    "/administrator/packages"
                  )}
                >
                  <p className="text-grey-500">Package Information</p>
                </Sidebar.Item>
                <Sidebar.Item
                  className={`${
                    (window.location.pathname.startsWith(
                      "/administrator/package-transaction"
                    )
                      ? " active "
                      : "") + "sidebar-item hover:cursor-pointer px-4"
                  }`}
                  onClick={(e) => {
                    navigate("/administrator/package-transaction");
                  }}
                  active={window.location.pathname.startsWith(
                    "/administrator/package-transaction"
                  )}
                >
                  <p className="text-grey-500">Transaction List</p>
                </Sidebar.Item>
              </Sidebar.Collapse>
            </Sidebar.ItemGroup>
            <Sidebar.ItemGroup>
              <p className="text-sm text-grey-400 font-semibold px-4">
                CMS Menu
              </p>
              <Sidebar.Collapse
                icon={NewspaperIcon}
                label="Blog"
                className="sidebar-packages hover:cursor-pointer px-4"
                ref={sidebar_packages}
              >
                <Sidebar.Item
                  className={`${
                    (window.location.pathname.startsWith(
                      "/administrator/blog/topics"
                    )
                      ? " active "
                      : "") + "sidebar-item hover:cursor-pointer px-4"
                  }`}
                  onClick={(e) => {
                    navigate("/administrator/blog/topics");
                  }}
                  active={window.location.pathname.startsWith(
                    "/administrator/blog/topics"
                  )}
                >
                  <p className="text-grey-500">Topics</p>
                </Sidebar.Item>
                <Sidebar.Item
                  className={`${
                    (window.location.pathname.startsWith(
                      "/administrator/blog/contents"
                    )
                      ? " active "
                      : "") + "sidebar-item hover:cursor-pointer px-4"
                  }`}
                  onClick={(e) => {
                    navigate("/administrator/blog/contents");
                  }}
                  active={window.location.pathname.startsWith(
                    "/administrator/blog/contents"
                  )}
                >
                  <p className="text-grey-500">Contents</p>
                </Sidebar.Item>
              </Sidebar.Collapse>
            </Sidebar.ItemGroup>
            <Sidebar.ItemGroup>
              <p className="text-sm text-grey-400 font-semibold px-4">
                Other Menu
              </p>
              <Sidebar.Collapse
                icon={EnvelopeIcon}
                label="Inquiries"
                className="sidebar-packages hover:cursor-pointer px-4"
                ref={sidebar_inquiries}
              >
                <Sidebar.Item
                  className={`${
                    (window.location.pathname == "/administrator/inquiries/web"
                      ? " active "
                      : "") + "sidebar-item hover:cursor-pointer px-4"
                  }`}
                  onClick={(e) => {
                    navigate("/administrator/inquiries/web");
                  }}
                  active={
                    window.location.pathname == "/administrator/inquiries/web"
                  }
                >
                  <p className="text-grey-500">Landing Page</p>
                </Sidebar.Item>
              </Sidebar.Collapse>
              <Sidebar.Item
                className={`${
                  (window.location.pathname.startsWith("/client/settings")
                    ? " active "
                    : "") + "sidebar-item hover:cursor-pointer px-4"
                }`}
                onClick={(e) => {
                  navigate("/client/settings");
                }}
                active={window.location.pathname.startsWith("/client/settings")}
                icon={Cog6ToothIcon}
              >
                <p className="text-grey-500">Settings</p>
              </Sidebar.Item>
            </Sidebar.ItemGroup>
          </div>

          <div className="mt-[70px] content w-[100%] mdb:w-[75%] sm:w-[100%] lg:w-[92%] overflow-x-hidden bg-[#F8F8F8] flex-1">
            {!openSidebar && (
              <>
                <div className="overlay fixed w-full bg-gray-500/[.5] h-full z-10"></div>
              </>
            )}

            <Outlet />
          </div>
        </Sidebar.Items>
      </Sidebar>
    </>
  );
};

export default AdminMainLayout;
