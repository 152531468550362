import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../Component/PersonalityRadarChart";
import PageSpinner from "../../../../../Component/PageSpinner";
import RangeList from "../RangeList";
const AdaptReports = ({ data }) => {
    const [communicate, setCommunicate] = useState(null);
    const [dataRange, setDataRange] = useState(null);
    useEffect(() => {
        if (data) {
            let values = [];
            let labels = [];
            let tempDataRange = [];
            data.data.map((item) => {
                values.push(item.result)
                labels.push(item.name)
                tempDataRange.push({ parameter: item.name, minLabel: item.label2, maxLabel: item.label1, maxRange: "10", minRange: "0", value: item.result, code: item.code.toLowerCase() })
            })
            setCommunicate({
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: '#E3A0087A',
                        borderColor: '#E3A008',
                        borderWidth: 2,
                        hidden: '',
                    },
                ],
            })
            setDataRange(tempDataRange)
        }
    }, [data])
    return (
        <div className='flex flex-col items-center' id="communicate-pdf">
            <div className='flex w-full items-center mb-5 gap-10 px-4 justify-center' id="radar-personality-adaption">
                <div className="w-[40%] flex justify-center">
                    <div className="max-w-[520px]">
                        {communicate ? <PersonalityRadarChart data={communicate} /> : <PageSpinner />}
                    </div>
                </div>
                <div className="w-[60%]">
                    <p className='font-semibold text-[20px] mb-2 '>Adaption</p>
                    <p className="text-gray-500 text-[20px] ">Kemampuan untuk menyesuaikan diri dengan keadaan, lingkungan, teknologi, atau peran yang baru dan berubah secara fleksibel dan efektif. Adaptasi adalah keterampilan penting di tempat kerja modern, di mana perubahan sering kali terjadi secara konstan.</p>
                </div>
            </div>
            {communicate ? <>
                {dataRange.map((item, index) => (
                    <RangeList data={item} color="#E3A008" />
                ))}
            </> : <PageSpinner />}

        </div>
    )
}

export default AdaptReports;