import React from "react";
import PageSpinner from "../../../../../../Component/PageSpinner";
import { CheckBadgeIcon } from "@heroicons/react/24/solid"
import moment from "moment";
const CertificationAndTrainingSection = ({ certifications, loading = true }) => {
  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <CheckBadgeIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Certificate & Training
            </h4>
          </div>
        </div>
        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {certifications.length > 0 ? (
              <>
                {certifications.map((experience) => (
                  <>
                    <div className="flex py-6 px-6 border border-line-gray-200 rounded-md mb-4 px" style={{ justifyContent: "space-between" }}>
                      <div className="desc-section">
                        <p className="font-bold mb-0.5">{experience.title}</p>
                        <p className="font-light text-slate-600 mb-0.5">
                          {experience.institution}
                        </p>
                        <p className="font-light text-slate-500 text-sm mb-2">
                          {moment(experience.issued_date).format("DD/MM/Y")} -{" "}
                          {experience.is_lifetime
                            ? "No Expiration Date"
                            : moment(experience.expired_date).format("DD/MM/Y")}
                        </p>
                        <p className="text-slate-600 text-base mb-2">
                          <b>Credential ID</b> : {experience.credential_id}
                        </p>
                        <a
                          href={experience.credential_url}
                          className="inline-flex items-center text-blue-600 hover:underline"
                        >
                          Show Credential &nbsp;
                          <svg
                            className="h-6 w-6 text-blue-600"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />{" "}
                            <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />{" "}
                            <line x1="16" y1="21" x2="16" y2="19" />{" "}
                            <line x1="19" y1="16" x2="21" y2="16" />{" "}
                            <line x1="3" y1="8" x2="5" y2="8" />{" "}
                            <line x1="8" y1="3" x2="8" y2="5" />
                          </svg>
                        </a>
                      </div>
                      <div className="edit-section">
                        
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CertificationAndTrainingSection;
