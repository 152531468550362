import React, { Component } from "react";
import ReactApexChart from 'react-apexcharts';
class CircleProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            value: {
                                show: true,
                                formatter: () => {
                                    return ''; // Return an empty string to hide the percentage value
                                },
                            },
                        },
                        hollow: {
                            size: '60%',
                        },
                    },
                },
                labels: [this.props.label], // The label for the chart
            },
            series: [this.props.percentage], // The percentage value for the chart
        };
    }

    render() {
        return (
            <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={this.props.height} />
        );
    }
}

export default CircleProgressBar;