import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { documentTitle } from "../../../../../helpers";
import BtnSpinner from "../../../../../Component/BtnSpinner";
import { createQuestionTagCategory } from "../../../../../api/admin/assessmentApi";
import { useDispatch } from "react-redux";
import { setCategory } from "../../../../../features/admin/assessments/questionTagCategorySlice";

const CategoryForm = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [form, setForm] = useState({
    name: null,
    code: null,
  });
  const [formPassed, setFormPassed] = useState(true);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const handleSubmit = async (e) => {
    setSubmitting(true);
    e.preventDefault();
    const result = await createQuestionTagCategory(form);
    if (result.success) {
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(setCategory(result));
      setForm({
        name: "",
        code: "",
      })
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    setForm({
      name: "",
      image: "",
      description: "",
    });
  }, [assessment]);

  useEffect(() => {
    if (form.code && form.name) {
      setFormPassed(true);
    } else {
      setFormPassed(false);
    }
  },[form.code, form.name])
  useEffect(() => {
    documentTitle("Create Assessment Category");
  }, []);

  return (
    <>
      <ToastContainer />
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="card flex flex-col w-[100%]">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">
                Tagging Category
              </p>
            </div>
          </div>
          <div className="flex space-x-8 mb-4">
            <div className="w-[100%]">
              <div className="mb-4">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Category Code
                </label>
                <input
                  // ref={this.jobRoleInputRef}
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Input text"
                  value={form.code}
                  onChange={(e) => setForm({ ...form, code: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Category Name
                </label>
                <input
                  // ref={this.jobRoleInputRef}
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Input text"
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            {submitting ? (
              <button
                className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type=""
                disabled={true}
              >
                <BtnSpinner />
              </button>
            ) : (
              <button
                className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={!formPassed}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default CategoryForm;
