import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgLogin from "../../Assets/bgAuth.webp";
import "../../css/login.css";
import FooterGuest from "../../Component/FooterGuest";
import PhonenumberForm from "./Forms/PhonenumberForm";
import NameForm from "./Forms/NameForm";
import CompanyForm from "./Forms/CompanyForm";

const ClientProfileIntroPage = () => {
  let navigate = useNavigate();
  const [updating, setUpdating] = useState(false);
  const [profileForm, setProfileForm] = useState({
    first_name: "",
    last_name: "",
    phonenumber: "",
  });

  const [companyForm, setCompanyForm] = useState({
    name: "",
    found_date: "",
    address: "",
    industry: "",
  });

  const [formPosition, setFormPosition] = useState(1);

  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const handleUpdateProfile = (form) => {
    axios
      .post("/api/client/profile/me-first-update", JSON.stringify(form), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success("Profile Saved!", {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 550,
            onClose: () => {
              setFormPosition(3);
              setUpdating(false);
            },
          });
        }
      })
      .catch((e) => {
        setUpdating(false);
      });
  };

  const handleCreateCompany = (form) => {
    axios
      .post("/api/client/company", JSON.stringify(form), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success("Company Created!", {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 550,
            onClose: () => navigate("/client/dashboard"),
          });
        }
      })
      .catch((e) => {
        setUpdating(false);
      });
  };

  const handleContinue = (form, position) => {
    if (formPosition === 1) {
      setProfileForm({ ...profileForm, phonenumber: form.phonenumber });
      setFormPosition(position);
    }
    if (formPosition === 2) {
      // alert("HALLO");
      setProfileForm({
        ...profileForm,
        first_name: form.first_name,
        last_name: form.last_name,
      });
      setUpdating(true);
    }
    if (formPosition === 3) {
      setCompanyForm({ ...companyForm, ...form });
      setUpdating(true);
    }
  };

  useEffect(() => {
    if (formPosition === 2) {
      handleUpdateProfile(profileForm);
    }
    if (formPosition === 3) {
      handleCreateCompany(companyForm);
    }
  }, [updating]);

  const FormFields = () => {
    if (formPosition === 1) {
      return <PhonenumberForm onContinue={handleContinue} />;
    } else if (formPosition === 2) {
      return <NameForm onContinue={handleContinue} updating={updating} />;
    } else if (formPosition === 3) {
      return (
        <CompanyForm
          onContinue={handleContinue}
          updating={updating}
          userName={user.name.split(" ")[0]}
        />
      );
    }
  };
  // TEST 2
  const myStyle = {
    backgroundImage: `linear-gradient(242.55deg, rgba(143, 3, 237, 0.7) 0%, rgba(85, 33, 181, 0.7) 99.39%), url(${bgLogin})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div>
      <div className="h-full flex flex-col md:justify-center">
        <ToastContainer />
        <div className="flex w-full flex-col md:flex-row h-[100vh]">
          <div
            className="w-full hidden md:flex md:w-1/2 text-left mb-6 md:flex-col items-center"
            style={myStyle}
          >
            <div>
              <p className="text-xl md:text-4xl text-white-1 font-light mt-[230px]">
                Welcome To
              </p>
              <p className="text-xl md:text-5xl text-white-1 leading-[110%] font-light">
                Highr ID
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center justify-center items-center h-full">
            <FormFields />
          </div>
        </div>
      </div>
      <FooterGuest />
    </div>
  );
};

export default ClientProfileIntroPage;
