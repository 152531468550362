import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ClipboardDocumentListIcon,
  RectangleStackIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import { documentTitle } from "../../../../helpers";
import Select from "react-select";
import PageSpinner from "../../../../Component/PageSpinner";
import BtnSpinner from "../../../../Component/BtnSpinner";
import {
  createAssessment,
  createAssessmentTest,
} from "../../../../api/admin/assessmentApi";
import { getJobPositions } from "../../../../api/admin/jobApi";
import CategoryForm from "./Forms/CategoryForm";
import MasterForm from "./Forms/MasterForm";
import TagList from "./Components/TagList";

const CreatePage = () => {
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Create Assessment Category");
  }, []);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <RectangleStackIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">
            Module & Answer Tagging
          </p>
          <BreadCrumb
            list={[
              {
                href: "/administrator/assessments",
                title: "Assessment & Test",
              },
              {
                href: null,
                title: "Create Module & Answer Tagging",
              },
            ]}
          />
        </div>
      </div>
      <div className="card flex flex-col w-full mb-4">
        <div className="flex justify-between w-full mb-8">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold">
              Tag List
            </p>
          </div>
          <div className="flex justify-end w-2/2"></div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <TagList />
        </div>
      </div>
      <div className="flex w-[100%] space-y-4 mdb:space-y-0 mdb:space-x-4 mdb:flex-row flex-col">
        <div className="flex flex-col w-full mdb:w-[50%]">
          <CategoryForm />
        </div>
        <div className="flex flex-col w-full mdb:w-[50%]">
          <MasterForm />
        </div>
      </div>
    </div>
  );
};

export default CreatePage;
