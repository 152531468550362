import React from "react";
import { useState, useEffect } from "react";
import EmptyState1 from "../../../../Assets/images/empty_state_1.svg";
const EmptyState = ({ data, id = '', text = 'No result yet' }) => {
    return (
        <div className='flex flex-col items-center justify-center h-[280px]'>
            <img src={EmptyState1} width={200} alt="" className='mb-4' id={id} />
            <p className='text-base text-gray-500 mb-4'>{text}</p>
        </div>
    )
}

export default EmptyState;