import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./Pages/Admin/LoginPage";
import AdminLayout from "./Layouts/AdminLayout";
import DashboardPage from "./Pages/Admin/DashboardPage";
import AdminMainLayout from "./Layouts/AdminMainLayout";
import AssessmentPage from "./Pages/Admin/AssessmentPage";
import AssessmentCreatePage from "./Pages/Admin/AssessmentPage/Assessment/CreatePage";
import ModuleAnswerTaggingCreatePage from "./Pages/Admin/AssessmentPage/ModuleAnswerTagging/CreatePage";
import AssessmentTestCreatePage from "./Pages/Admin/AssessmentPage/AssessmentTest/CreatePage";
import AssessmentTestDetailPage from "./Pages/Admin/AssessmentPage/DetailPage";
import AssessmentTestEditPage from "./Pages/Admin/AssessmentPage/AssessmentTest/EditPage";
import ReportClientPage from "./Pages/Admin/ReportPage/Clients";
import ReportCandidatePage from "./Pages/Admin/ReportPage/Candidates";
import UserPage from "./Pages/Admin/ClientPage/UserPage";
import EditClientUser from "./Pages/Admin/ClientPage/UserPage/EditPage";
import PackagePage from "./Pages/Admin/PackagePage";
import DetailReportClientPage from "./Pages/Admin/ReportPage/Clients/DetailPage";
import CreateCompany from "./Pages/Admin/ClientPage/CompanyPage/CreatePage";
import CreateClientUser from "./Pages/Admin/ClientPage/UserPage/CreatePage";
import DetailCompany from "./Pages/Admin/ClientPage/CompanyPage/DetailPage";
import RoleAccessPage from "./Pages/Admin/ClientPage/RoleAccessPage";
import PermissionPage from "./Pages/Admin/ClientPage/RoleAccessPage/PermissionPage";
import PackageTransactionPage from "./Pages/Admin/PackagePage/TransactionPage";
import CreatePackageTransactionPage from "./Pages/Admin/PackagePage/TransactionPage/CreatePage";
import LandingInquiryPage from "./Pages/Admin/LandingInquiryPage";
import LandingInquiryDetailPage from "./Pages/Admin/LandingInquiryPage/DetailPage";
import BlogContentPage from "./Pages/Admin/BlogPage/ContentPage";
import BlogContentCreatePage from "./Pages/Admin/BlogPage/ContentPage/CreatePage";
import BlogCategoryPage from "./Pages/Admin/BlogPage/CategoryPage";
import BlogTopicCreatePage from "./Pages/Admin/BlogPage/CategoryPage/CreatePage";
import OAuthCallbackPage from "./Pages/Admin/OAuthCallbackPage";
const AppAdmin = () => {
  return (
    <Routes>
      {/* OAUTH CALLBACK */}
      <Route path="auth/google/callback" element={<OAuthCallbackPage />} />
      <Route path="/" element={<AdminLayout />}>
        <Route path="login" element={<LoginPage />} />
      </Route>
      <Route path="/" element={<AdminMainLayout />}>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="assessments" element={<AssessmentPage />} />
        <Route path="assessments-create" element={<AssessmentCreatePage />} />
        <Route
          path="assessments/module-tag-create"
          element={<ModuleAnswerTaggingCreatePage />}
        />
        <Route
          path="assessments/test-create"
          element={<AssessmentTestCreatePage />}
        />
        <Route
          path="assessments/test/:id/edit"
          element={<AssessmentTestEditPage />}
        />
        <Route
          path="assessments/test/:id"
          element={<AssessmentTestDetailPage />}
        />

        {/* REPORTS CLIENT */}
        <Route path="report/clients" element={<ReportClientPage />} />
        <Route path="report/clients/:id" element={<DetailReportClientPage />} />

        {/* REPORTS CANDIDATE */}
        <Route path="report/candidates" element={<ReportCandidatePage />} />

        {/* CLIENT */}
        <Route path="client/users" element={<UserPage />} />
        <Route path="client/users/:id" element={<EditClientUser />} />
        <Route path="client/create-user" element={<CreateClientUser />} />
        <Route path="client/create-company" element={<CreateCompany />} />
        <Route path="client/company/:id" element={<DetailCompany />} />
        <Route path="client/role-access" element={<RoleAccessPage />} />
        <Route path="client/role-access/:id" element={<PermissionPage />} />

        <Route path="packages" element={<PackagePage />} />
        <Route
          path="package-transaction"
          element={<PackageTransactionPage />}
        />
        <Route
          path="package-transaction/create"
          element={<CreatePackageTransactionPage />}
        />

        {/* LANDING / WEB INQURIES */}
        <Route path="inquiries/web" element={<LandingInquiryPage />} />
        <Route
          path="inquiries/web/:id"
          element={<LandingInquiryDetailPage />}
        />

        {/* BLOG */}
        {/* BLOG CONTENT */}
        <Route path="blog/contents" element={<BlogContentPage />} />
        <Route path="blog-content/create" element={<BlogContentCreatePage />} />
        <Route
          path="blog-content/edit/:id"
          element={<BlogContentCreatePage />}
        />
        {/* BLOG CATEGORY */}
        <Route path="blog/topics" element={<BlogCategoryPage />} />
        <Route path="blog-topic/create" element={<BlogTopicCreatePage />} />
        <Route path="blog-topic/edit/:id" element={<BlogTopicCreatePage />} />
        {/* <Route path="blog/contents/:id" element={<ContentPage />} /> */}
      </Route>
    </Routes>
  );
};

export default AppAdmin;
